import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
} from "react-bootstrap";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import { toast } from "react-hot-toast";
import { Form, Link } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import pin from "../../assets/Images/svg/pin.svg"
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';
import PersonOffSharpIcon from '@mui/icons-material/PersonOffSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import HideSourceSharpIcon from '@mui/icons-material/HideSourceSharp';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';

const SinglePostInteraction = ({
  postId,
  AllPostList,
  singlepostList,
  isfollowed,
  setIsfollowed,
  setSinglePostList,
  //   setPage,
  //   page,
  //   setTotalPages,
  //   totalPages,
  //   item,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputFileRef = useRef(null);
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deletePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/DeletePost`, responseData).then((res) => {
        setShow(!show);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        // setPostList((prevPostList) =>
        //     prevPostList.filter((post) => post.postId !== postId)
        // );
        // if ((postList.length - 1) % 10 === 0 && page === totalPages) {
        //     setTotalPages(totalPages - 1);
        // }
        // if (page <= totalPages) {
        //     AllPostList(page);
        // }
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//

  //************************Repost Post start  ************************//
  const [reportPost, setReportPost] = useState(false);
  const [remark, setRemark] = useState("");
  const handlereportPost = () => {
    setReportPost(false);
  };

  const ReportPost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      remark: remark,
    };
    try {
      await axios.post(`PostAction/ReportPost`, responseData).then((res) => {
        setReportPost(!reportPost);
        setRemark("");
        // console.log("resData", res.data.message);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Repost Post End  ************************//

  //************************Pin Post start  ************************//
  const PinPost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/PinPost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Pin Post End  ************************//

  //************************Pin Post start  ************************//
  const UnPinPost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/UnpinPost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Pin Post End  ************************//

  //************************Hide Post start  ************************//
  const hidePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/HidePost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Hide Post End  ************************//

  //************************UnHide Post start  ************************//
  const UnhidePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/UnhidePost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnHide Post End  ************************//

  const [postData, setPostData] = useState("");
  // console.log('result', companyDetails);
  const PostsDetails = async () => {
    setShowModal(true);
    try {
      // const result = await GetProfileInfo();
      const result = await axios.get(`/Posts/GetPost/${postId}`);
      const Response = result.data.responseData.postContent;
      // console.log("post data", Response);
      setPostData(Response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const UpdatePostAsDraft = async () => {
    try {
      // if (postContent !== "" && imageNames !== '') {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append(
        "PrivacyType",
        privacyType === "" ? "Public" : privacyType
      );
      formData.append("PostOriginType", "User");
      // Convert comma-separated imageNames string into an array
      const imageNameArray = imageNames
        .split(",")
        .filter((name) => name.trim() !== "");

      // Function to check if the list contains any video files
      const containsVideo = (names) => {
        const videoExtensions = [
          ".mp4",
          ".webm",
          ".avi",
          ".mov",
          ".mkv",
          ".flv",
          ".wmv",
        ];
        return names.some((name) =>
          videoExtensions.includes(
            name.slice(name.lastIndexOf(".")).toLowerCase()
          )
        );
      };

      // Determine PostCategory
      let postCategory;
      const hasPostContent = postContent !== "";
      const hasImages =
        imageNameArray.length > 0 && !containsVideo(imageNameArray);
      const hasVideos =
        imageNameArray.length > 0 && containsVideo(imageNameArray);

      if (hasPostContent && hasImages && hasVideos) {
        postCategory = "UserStory";
      } else if (hasPostContent && hasImages) {
        postCategory = "UserStory";
      } else if (hasPostContent && hasVideos) {
        postCategory = "UserStory";
      } else if (hasImages && hasVideos) {
        postCategory = "UserStory";
      } else if (hasPostContent) {
        postCategory = "Text";
      } else if (hasImages) {
        postCategory = "Image";
      } else if (hasVideos) {
        postCategory = "Video";
      } else {
        postCategory = "UserStory"; // Default case
      }

      formData.append("PostCategory", postCategory);
      formData.append("PostContent", postContent);
      formData.append("FilesToUpload", imageNames);
      formData.append("PostOriginID", userUid);
      formData.append("PostId", postId);
      // console.log("formData", formData);

      const res = await axios.post("/Posts/UpdatePost", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response: ", res);
      //   toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      setPostID(res.data.responseData.id);
      setPostContent("");
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [privacyType, setPrivacyType] = useState(null);

  const [showPost, setShowPost] = useState(false);

  useEffect(() => {
    if (showPost && inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [showPost]);

  const handleShowPost = () => {
    setShowModal(false);
    setShowPost(true);
  };

  const checkDuplicate = (name) => {
    return images.some((image) => image.name === name);
  };

  const imageSelect = (event) => {
    // console.log("image selected");
    // setShowTestModal(true);
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const imageName = selectedFiles[i].name;
      if (!checkDuplicate(imageName)) {
        const imageUrl = URL.createObjectURL(selectedFiles[i]);
        setImages((prevImages) => [
          ...prevImages,
          {
            name: imageName,
            url: imageUrl,
            file: selectedFiles[i],
          },
        ]);
      } else {
        alert(`${imageName} is already added to the list`);
      }
    }
    event.target.value = null; // Reset file input
  };

  const [images, setImages] = useState([]);

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className="image_container d-flex justify-content-center position-relative"
      >
        <img src={image.url} alt={image.name} />
        <span className="position-absolute" onClick={() => deleteImage(index)}>
          &times;
        </span>
      </div>
    ));
  };

  const Privacy = [
    { value: "Public", label: "Public" },
    { value: "Friends", label: "Friends" },
    { value: "OnlyMe", label: "OnlyMe" },
    { value: "Custom", label: "Custom" },
  ];

  const handleClosePost = () => {
    setShowPost(false);
  };

  const [postContent, setPostContent] = useState("");
  const [postID, setPostID] = useState("");
  const [display, setDisplay] = useState(false);
  const [imageNames, setImageNames] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (event) => {

    UpdatePostAsDraft();
    const files = Array.from(event.target.files);
    const fileNames = files.map((file) => file.name);
    setImageNames(fileNames.join(", "));
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleRemoveImage = (index) => {
    UpdatePostAsDraft();
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageNames((prevNames) => {
      const namesArray = prevNames.split(", ");
      namesArray.splice(index, 1);
      return namesArray.join(", ");
    });
  };



  const copyPost = async () => {
    const copyData = {
      deviceType: "Web",
      id: postId,
      type: 1,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", copyData).then((res) => {
        navigator.clipboard.writeText(res.data.responseData.value);
        // toast.success('Link Copied')
        setToastMessage("Link Copied");
        setShowToast(true);
      });
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  const FollowUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        console.log("resDataaaaa", resData);
        setIsfollowed(true)
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const handleCloseUnfollowModal = () => setShowUnfollowModal(false);
  const handleShowunfollowModal = () => setShowUnfollowModal(true);
  const unFollowUser = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: singlepostList?.userUid,
    };
    try {
      await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
        console.log("res", res);
        setIsfollowed(false)
        setToastMessage(res.data.message);
        setShowToast(true);
        handleCloseUnfollowModal()
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  useEffect(() => {
    if (showModal || showPost || show || reportPost || showUnfollowModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showModal, showPost, show, reportPost, showUnfollowModal]);

  console.log('singlepostList', singlepostList);

  return (
    <>
      <div className="d-flex">
        {singlepostList?.isOwned === false ? <>
          {isfollowed === true || singlepostList?.isFollowedByYou === true ? '' : <div className='ms-auto'>
            <button className='mainBtn1 me-2' style={{ width: '90px', height: '30px' }} onClick={() => {
              FollowUser(singlepostList?.userUid)
            }} >
              Follow</button>
          </div>}

        </> : ''}
        <div
          className="postMenu pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <MoreHorizSharpIcon className="material-symbols-sharp" />
          {showDropdown && (
            <Dropdown.Menu
              show={showDropdown}
              ref={dropdownRef}
              className="InteractionModal"
            >
              {singlepostList?.isOwned === false && (
                <Dropdown.Item as="button" className="dropdownList">
                  <Link
                    onClick={() => {
                      setReportPost(!reportPost);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <ReportGmailerrorredSharpIcon className="material-symbols-sharp me-2" />
                      <span className="dropdownTxt f-14">Report Post</span>
                    </div>
                    <p className="f-12 dropdownPara">
                      We won't let {singlepostList.userName} know who reported
                    </p>
                  </Link>
                </Dropdown.Item>
              )}
              {isfollowed === true || singlepostList?.isFollowedByYou === true ? <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    handleShowunfollowModal()
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <PersonOffSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Unfollow</span>
                  </div>
                  <p className="f-12 dropdownPara">
                    Unfollow User
                  </p>
                </Link>
              </Dropdown.Item> : ''}
              {singlepostList?.isOwned && (
                <>
                  <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      onClick={() => {
                        setShow(!show);
                        toggleDropdown();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />

                        <span className="dropdownTxt f-14">Delete</span>
                      </div>
                      <p className="f-12 dropdownPara">
                        Once Deleted it can't be recovered
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button" className="dropdownList">
                    <Link onClick={PostsDetails}>
                      <div className="d-flex align-items-center">
                        <UpdateSharpIcon className="material-symbols-sharp me-2" />
                        <span className="dropdownTxt f-14">Update Post</span>
                      </div>
                      <p className="f-12 dropdownPara">Update post</p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      onClick={() => {
                        PinPost();
                        toggleDropdown();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img src={pin} />
                        <span className="dropdownTxt f-14">Pin</span>
                      </div>
                      <p className="f-12 dropdownPara">Pin Post</p>
                    </Link>
                  </Dropdown.Item>
                </>
              )}


              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    hidePost();
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <HideSourceSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Hide Post</span>
                  </div>
                  <p className="f-12 dropdownPara">See fewer post like this</p>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    copyPost();
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ContentCopySharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Copy Link</span>
                  </div>
                  <p className="f-12 dropdownPara">Copy Post Link</p>
                </Link>
              </Dropdown.Item>


            </Dropdown.Menu>
          )}
        </div>
      </div>
      {/* // Update Modal */}
      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Post Something</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>
              <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={NewsFeed.companyLogo} alt="" />
                </div>
                <p className="f-16  AddPost ms-2">What's on your mind?</p>
              </div>
              <div className="m-3">
                <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="Update your post here"
                  name="about"
                  value={postData}
                  onChange={(e) => setPostData(e.target.value)}
                  //   invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                ></textarea>
              </div>
              <div className="m-3 d-flex align-items-center">
                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                    onClick={handleShowPost}
                  />
                </form>
                <button
                  className="mainBtn ms-auto"
                //  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPost && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex  align-items-center p-2">
                <h6>Post Something</h6>
                {/* <div className="post-dropdown-cont"> */}
                <Select
                  defaultValue={privacyType}
                  onChange={(e) => {
                    setPrivacyType(e.value);
                  }}
                  options={Privacy}
                  placeholder={"Select Privacy"}
                  styles={customStyles}
                />
                {/* </div> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    // if (postContent === "") {
                    //   setShowSecondModal(false);
                    // } else {
                    handleClosePost(false);
                    // }
                  }}
                />
              </div>
              <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={NewsFeed.companyLogo} alt="" />
                </div>
                <p className="f-16  AddPost ms-2">What's on your mind?</p>
              </div>
              <div className="m-3">
                <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="What do you want to talk about?"
                  name="about"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                  style={{ width: "100%" }}
                ></textarea>
              </div>

              <div className="modal-border d-flex justify-content-center align-items-center">
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  multiple // Allow multiple file selection
                />

                {selectedImages.length > 0 && (
                  <div className="post-selected-images d-flex justify-content-center flex-wrap">
                    {selectedImages.map((imageUrl, index) => (
                      <div key={index} className="position-relative m-2">
                        <img
                          src={imageUrl}
                          alt={`Selected Image ${index + 1}`}
                          style={{
                            maxWidth: "200px",
                            maxHeight: "300px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          onClick={() => handleRemoveImage(index)}
                          className="btn btn-danger position-absolute top-0 end-0"
                          style={{
                            transform: "translate(50%, -50%)",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="m-3 d-flex align-items-center">

                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                  />
                </form>

                <button
                  className="mainBtn ms-auto"
                  onClick={() => {
                    setShowPost(false);
                    UpdatePostAsDraft();
                  }}
                >
                  Update
                </button>
                {/* <div className="post-button m-2">
                
                {/* </div> */}
              </div>

              <div
                className="card-body d-flex flex-wrap justify-content-start"
                id="container"
              >
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
      )}



      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete post?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deletePost()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}





      {reportPost && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Report Post</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlereportPost();
                  }}
                />
              </div>

              <div className="lableStyle">
                <input
                  type="text"
                  placeholder="Enter feedback here"
                  required
                  value={remark}
                  style={{ width: "100%" }}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>

              <div className="col-md-12 text-end mt-4">
                <button type="button" className="mainBtn1" onClick={ReportPost}>
                  Report
                </button>
              </div>

            </div>
          </div>
        </div>
      )}


      {showUnfollowModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                {/* <h6>Unfollow User</h6> */}
                <h6> Are you sure you want to unfollow user?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseUnfollowModal();
                  }}
                />
              </div>

              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={() => unFollowUser()}
                >
                  Unfollow
                </button>
              </div>

            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default SinglePostInteraction;
