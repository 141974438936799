import React, { useRef, useState } from 'react';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from '../../API/axios';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const ExpiredJobAction = ({
  item,
  AllJobList,
  setJobList,
  jobList,
  totalPages,
  setTotalPages,
  page,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deleteJob = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: item.jobId,
    };
    try {
      await axios
        .delete(`Job/DeleteJob`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShow(!show);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          setJobList((prevPostList) =>
            prevPostList.filter((post) => post.jobId !== item.jobId)
          );
          if ((jobList.length - 1) % 10 === 0 && page === totalPages) {
            setTotalPages(totalPages - 1);
          }
          // if (page <= totalPages) {
          //   AllJobList(page);
          // }
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//

  const cloneJob = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: item.jobId,
    };
    try {
      await axios
        .post(`JobAction/CloneJob`,
          responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        )
        .then((res) => {
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          setJobList((prevPostList) =>
            prevPostList.filter((post) => post.jobId !== item.jobId)
          );
          if ((jobList.length - 1) % 10 === 0 && page === totalPages) {
            setTotalPages(totalPages - 1);
          }
          // if (page <= totalPages) {
          //   AllJobList(page);
          // }
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  return (
    <>
      <div
        className="postMenu pointer "
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            //   ref={dropdownRef}
            className="InteractionModal"
          >
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  setShow(!show);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <DeleteOutlineSharpIcon class="material-symbols-sharp" />
                  <span className="dropdownTxt f-14">Delete</span>
                </div>
                <p className="f-12 dropdownPara">
                  Once Deleted it can't be recovered
                </p>
              </Link>
            </Dropdown.Item>

            <Dropdown.Item as="button" className="dropdownList">
              <Link
                // onClick={() => {
                //     setShow(!show);
                //     toggleDropdown();
                // }}
                onClick={(e) => {
                  e.preventDefault();
                  cloneJob();
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <UpdateSharpIcon class="material-symbols-sharp" />
                  <span className="dropdownTxt f-14">Clone</span>
                </div>
                <p className="f-12 dropdownPara">CLone Job</p>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </div>



      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete Job?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deleteJob()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


    </>
  )
}

export default ExpiredJobAction

