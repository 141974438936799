import React, { useEffect, useState } from "react";
import { Form, Link, NavLink, useNavigate } from "react-router-dom";
import FloatingLabelInput from "react-floating-label-input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import { SignInImages } from "../assets/Images/ImagesLink";
// import { Register, TestApi } from '../Services/Service'
import { useFormik } from "formik";
import { registerValidations } from "./Validations";
import axios, { PublicKey } from "../API/axios";
import fetch_axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useToaster } from "react-hot-toast";
import SuccessToast from "./SuccessToast";
import WarningToast from "./WarningToast";
import ErrorToast from "./ErrorToast";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";

const firebaseConfig = {
  apiKey: "AIzaSyD8GJvS-To2H4dOM8lbn5JRUQVhCL8uxJM",
  authDomain: "welinkjobs-23583.firebaseapp.com",
  projectId: "welinkjobs-23583",
  storageBucket: "welinkjobs-23583.appspot.com",
  messagingSenderId: "375220227786",
  appId: "1:375220227786:web:bb1fd63622ce66d4936e33",
  measurementId: "G-VY0LG9HEKF",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const SignUp = () => {
  const { success } = useToaster();

  const navigate = useNavigate();
  const [User_Name, setuserName] = useState("");
  const [Response_Message, setmessage] = useState("");
  //************************ Country Flag code start  ************************//

  // const [items, setItems] = useState([]);

  // useEffect(() => {
  //     fetch(
  //         "https://raw.githubusercontent.com/iamspruce/search-filter-painate-reactjs/main/data/countries.json"
  //     )
  //         .then((res) => res.json())
  //         .then(
  //             (result) => {
  //                 // setLoaded(true);
  //                 setItems(result);
  //                 // console.log(result);
  //             },
  //             (error) => {
  //                 // setLoaded(true);
  //                 // setError(error);
  //             }
  //         );
  // }, []);
  // const data = Object.values(items);

  // const [isOpen, setIsOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [searchTerm, setSearchTerm] = useState('');
  // // console.log('selectedItem', selectedItem.callingCode);
  // const filteredData = data.filter(item =>
  //     item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const handleItemClick = (item) => {
  //     setSelectedItem(item);
  //     setIsOpen(false);
  // };
  //************************ Country Flag code End  ************************//

  //************************ Register With Email Start  ************************//

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [userID, setuserID] = useState("");
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => setOtp(otp);

  const initialValues = {
    mail: "",
    password: "",
    confirmPassword: "",
    register_checkbox: false,
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };
  console.log("res", sessionId, userID);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: registerValidations,
      onSubmit: async () => {
        setLoading(true);
        const RegisterData = {
          deviceType: "Web",
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          firstName: values.firstname,
          lastName: values.lastname,
        };

        try {
          await axios
            .post("OAuth/RegisterWithEmail", RegisterData, {
              headers: {
                PublicKey,
              },
            })
            .then((response) => {
              debugger;
              if (response.status === 'Success') {
                const responseNew = response.responseData.profile;
                const userRole = response.responseData.userRoles;
                setToastMessage(response.data.message);
                setShowToast(true);
                localStorage.setItem("user-Id", responseNew.id);
                localStorage.setItem("userUid", responseNew.userUid);
                localStorage.setItem("user-email", responseNew.email);
                localStorage.setItem("user-nameTag", responseNew.nameTag);
                localStorage.setItem("user-profilPic", responseNew.profilPic);
                localStorage.setItem("user-referCode", responseNew.referCode);
                localStorage.setItem("sessionId", responseNew.sessionId);
                localStorage.setItem("token", responseNew.token);
                localStorage.setItem("loginType", responseNew.loginType);
                localStorage.setItem("profilPic", responseNew.profilPic);
                localStorage.setItem("firstName", responseNew.firstName);
                localStorage.setItem("lastName", responseNew.lastName);
                localStorage.setItem("userRole", userRole);

                localStorage.removeItem("guestMode");
                setTimeout(() => {
                  navigate("/Newsfeed")
                }, 2000);
              } else {
                setToastMessage(response.data.message);
                setShowToast(true);
                setFlag(true);
                setuserName(response.data.responseData.userName);
                setmessage(response.data.message);
                setSessionId(response.data.responseData.sessionID);
                setuserID(response.data.responseData.userID);
              }

            });
        } catch (error) {
          debugger;
          if (error.response && error.response.status === 403) {
            setToastMessage(error.response.data.message);
            setShowToast(true);
            setFlag(true);
            setuserName(error.response.data.responseData.userName);
            setmessage(error.response.data.message);
            setSessionId(error.response.data.responseData.sessionID);
            setuserID(error.response.data.responseData.userID);
          } else if (error.response && error.response.status === 404) {
            setWarningToastMessage(error.response.data.message);
            setShowWarningToast(true);
          } else {
            setErrorToastMessage(error.response.data.message);
            setShowErrorToast(true);
          }
        } finally {
          setLoading(false);
        }
      },
    });
  const otpCheck = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          `OAuth/ConfirmAccountByOTP?OTP=${otp}&SessioID=${sessionId}&UserID=${userID}&DeviceType=Web`
        )
        .then((res) => {
          console.log("res", res);
          setToastMessage(res.data.message);
          setShowToast(true);
          setFlag(false);
          setTimeout(() => {
            navigate("/SignIn");
          }, 2000);
          // if (res.data.length === 0) {

          // } else {
          //   toast.error("Wrong OTP");
          // }
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Register With Email End  ************************//

  //************************ Register With Google Start  ************************//
  const [googleloading, setGoogleloading] = useState(false);

  const externalLogin = async (responseData) => {
    setGoogleloading(true);
    try {
      const response = await (
        await axios.post("OAuth/RegisterWithGoogle", responseData, {
          headers: {
            PublicKey,
          },
        })
      ).data;
      console.log("google response", response);
      if (response.status === 'Success') {
        const responseNew = response.responseData.profile;
        const userRole = response.responseData.userRoles;
        setToastMessage(response.message);
        setShowToast(true);
        localStorage.setItem("user-Id", responseNew.id);
        localStorage.setItem("userUid", responseNew.userUid);
        localStorage.setItem("user-email", responseNew.email);
        localStorage.setItem("user-nameTag", responseNew.nameTag);
        localStorage.setItem("user-profilPic", responseNew.profilPic);
        localStorage.setItem("user-referCode", responseNew.referCode);
        localStorage.setItem("sessionId", responseNew.sessionId);
        localStorage.setItem("token", responseNew.token);
        localStorage.setItem("loginType", responseNew.loginType);
        localStorage.setItem("profilPic", responseNew.profilPic);
        localStorage.setItem("firstName", responseNew.firstName);
        localStorage.setItem("lastName", responseNew.lastName);
        localStorage.setItem("userRole", userRole);

        localStorage.removeItem("guestMode");
        setTimeout(() => {
          navigate("/Newsfeed")
        }, 2000);
      } else {
        setToastMessage(response.message);
        setShowToast(true);
        setFlag(true);
        setuserName(response.data.responseData.userName);
        setmessage(response.data.message);
        setSessionId(response.responseData.sessionID);
        setuserID(response.responseData.userID);
      }
      // toast.success(response.message);
      // navigate("/SignIn");
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setToastMessage(error.response.data.message);
        setShowToast(true);
        setFlag(true);
        setuserName(error.response.data.responseData.userName);
        setmessage(error.response.data.message);
        setSessionId(error.response.data.responseData.sessionID);
        setuserID(error.response.data.responseData.userID);
      } else if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    } finally {
      setGoogleloading(false);
    }
  };
  const googleSignin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // setIsLoading(false);
      try {
        const resp = await fetch_axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        const responseData = {
          deviceType: "Web",
          email: resp.data.email,
          externalLoginId: resp.data.sub,
          loginType: "Google",
          firstName: resp.data.given_name,
          lastName: resp.data.family_name,
          // profilPic: resp.data.picture,
        };
        externalLogin(responseData);
        // setIsLoading(true);
        // navigate("/NewsFeeds");
      } catch (error) {
        console.log(error);
        // setIsLoading(true);
      }
    },
  });
  //************************ Register With Google End  ************************//

  // Load Apple Sign-In script
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleAppleSignIn = () => {
    try {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: "com.welinkjob.welink",
          scope: "email",
          redirectURI: "https://v2-web-app-pd29.vercel.app/signIn",
          state: "4ee08dcf-73ac-4569-7336-4e5d867b0599",
          usePopup: true,
        });

        window.AppleID.auth
          .signIn()
          .then((response) => {
            console.log("response", response); // Handle the response from Apple
          })
          .catch((error) => {
            console.error("Sign-In Error:", error); // Handle sign-in errors
          });
      } else {
        console.error(
          "AppleID is not defined. Ensure the script is loaded correctly."
        );
      }
    } catch (error) {
      console.error("Unexpected Error:", error);
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [originalemail, setOriginalEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [externalEmail, setExernalEmail] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const RegisterWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // const credential = OAuthProvider.credentialFromResult(result);
      // const accessToken = credential.accessToken;
      console.log("accessToken", user);
      setFirstName(user.displayName?.split(" ")[0]);
      setLastName(user.displayName?.split(" ")[1]);
      setExernalEmail(user.email);
      setAccessToken(user.accessToken);
      setPhotoUrl(user.photoURL);
      handleModalOpen();
    } catch (error) {
      console.error("Error signing in with Apple:", error);
    }
  };

  const _registerWithAppleApi = async () => {
    try {
      const responseData = {
        deviceType: "Web",
        appleAccessToken: accessToken,
        loginType: "Apple",
        email: originalemail,
      };
      if (originalemail !== "" && firstName !== "" && lastName !== "") {
        const response = await axios.post(
          "OAuth/RegisterWithApple",
          responseData,
          {
            headers: {
              PublicKey,
            },
          }
        );

        if (response.status === 'Success') {
          const responseNew = response.responseData.profile;
          const userRole = response.responseData.userRoles;
          setToastMessage(response.data.message);
          setShowToast(true);
          localStorage.setItem("user-Id", responseNew.id);
          localStorage.setItem("userUid", responseNew.userUid);
          localStorage.setItem("user-email", responseNew.email);
          localStorage.setItem("user-nameTag", responseNew.nameTag);
          localStorage.setItem("user-profilPic", responseNew.profilPic);
          localStorage.setItem("user-referCode", responseNew.referCode);
          localStorage.setItem("sessionId", responseNew.sessionId);
          localStorage.setItem("token", responseNew.token);
          localStorage.setItem("loginType", responseNew.loginType);
          localStorage.setItem("profilPic", responseNew.profilPic);
          localStorage.setItem("firstName", responseNew.firstName);
          localStorage.setItem("lastName", responseNew.lastName);
          localStorage.setItem("userRole", userRole);

          localStorage.removeItem("guestMode");
          setTimeout(() => {
            navigate("/Newsfeed")
          }, 2000);
        } else {
          setToastMessage(response.data.message);
          setShowToast(true);
          setFlag(true);
          setuserName(response.data.responseData.userName);
          setmessage(response.data.message);
          setSessionId(response.data.responseData.sessionID);
          setuserID(response.data.responseData.userID);
        }
      } else {
        setErrorToastMessage("Please fill all the required fields");
        setShowErrorToast(true);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setErrorToastMessage(error.response.data.message);
      setShowErrorToast(true);
    }
  };
  const [ConnectionListForGuest, setConnectionListForGuest] = useState("");
  const ConnectionListAllForGuest = async (id) => {
    try {
      await axios
        .get("UserFollow/GetMostFollowingUser?DeviceType=Web")
        .then((resData) => {
          // console.log("ConnectionListAllForGuest", resData.data.responseData);
          setConnectionListForGuest(resData.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log('ConnectionListForGuest', ConnectionListForGuest);

  useEffect(() => {
    ConnectionListAllForGuest();
  }, []);
  const [guestMode, setGuestMode] = useState(false);
  const LinktoGuest = () => {
    setGuestMode(true);
    localStorage.setItem("guestMode", "true");
  };

  useEffect(() => {
    if (flag) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [flag]);

  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(30)
  const resendOTP = async (e) => {
    setMinutes(1);
    setSeconds(30);
    e.preventDefault();
    try {
      await axios
        .post(
          `OAuth/ResendOTP?UserID=${userID}`
        )
        .then((res) => {
          console.log("res", res);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 full-height p-2 loginImage">
            <div className="bg-cover">
              <div className="imageData">
                <div className="text-center">
                  <img src={SignInImages.whiteLogo} alt="" />
                </div>
                <p>
                  Stay connected for{" "}
                  <NavLink
                    to={`/SearchJob`}
                    onClick={LinktoGuest}
                    className="border-bottom"
                  >
                    Jobs
                  </NavLink>{" "}
                  &{" "}
                  <NavLink
                    to={`/NewsFeed`}
                    onClick={LinktoGuest}
                    className="border-bottom"
                  >
                    More
                  </NavLink>
                </p>
                {/* <div className="text-center mt-4">
                  <img src={SignInImages.ovalwithtext} alt="" width="80%" />
                </div> */}
                {/* <div className="userLink text-center mt-4 d-flex justify-content-center">
                  <Link to='/People' onClick={LinktoGuest} >
                    <div className="circle-container">
                      {ConnectionListForGuest && ConnectionListForGuest?.slice(0, 3).map((user, userIndex) => (
                        <img
                          src={user?.profilPic}
                          alt={`Circle ${userIndex + 1}`}
                          className="circle-img"
                          key={userIndex}
                        />
                      ))}
                    </div>
                  </Link>
                  <span className="f-14">12k+ people joined us, now it's your turn</span>
                </div> */}
                <div className="userLink text-center mt-2  justify-content-center">
                  <Link to="/People" onClick={LinktoGuest}>
                    <div className="circle-container">
                      {ConnectionListForGuest &&
                        ConnectionListForGuest?.slice(0, 3).map(
                          (user, userIndex) => (
                            <img
                              src={user?.profilPic}
                              alt={`Circle ${userIndex + 1}`}
                              className="circle-img"
                              key={userIndex}
                            />
                          )
                        )}
                    </div>
                  </Link>
                  <span className="f-14 fw-bold">
                    12k+ people joined us, now it's your turn
                  </span>
                  {/* <img src={SignInImages.ovalwithtext} alt="" width="80%" /> */}
                </div>
              </div>
              <div className="linksHelp">
                <p>
                  <a href="/privacy-policy.html" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  |{" "}
                  <a href="/terms-and-conditions.html" target="_blank">
                    Terms and Condition
                  </a>
                </p>
                <p>
                  Having Troubles?{" "}
                  <a
                    href="/faq.html"
                    className="border-bottom me-3"
                    target="_blank"
                  >
                    Get Help
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4  p-2">
            <div className="form-container pb-3">
              <p className="f-20 mb-2 text-center borderSignUp">
                Already have an account? <Link to="/SignIn">Sign In</Link>
              </p>

              <div className="formBorder p-1">
                <p className="ms-1">
                  by clicking Sign Up, or sign up with google or Apple account,
                  I agree that I have read and accepted the{" "}
                  <NavLink to="/terms-and-conditions.html" target="blank">
                    Terms of Use
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to="/privacy-policy.html" target="blank">
                    Privacy Policy.
                  </NavLink>
                </p>

                <Form onSubmit={handleSubmit}>
                  <div className="checker d-flex align-items-center mt-2">
                    {/* <input
                      type="checkbox"
                      id="terms"
                      name="register_checkbox"
                      value={values.register_checkbox}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}
                  </div>
                  {errors.register_checkbox && touched.register_checkbox ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.register_checkbox}
                    </p>
                  ) : null}
                  <h2 className="fw-bold text-center f-22 SigninHeading">
                    Sign Up
                  </h2>

                  <FloatingLabelInput
                    id="email"
                    label="Email address"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.email}
                    </p>
                  )}
                  <div className="password-input d-flex align-items-baseline">
                    <FloatingLabelInput
                      id="firstname"
                      label="First Name"
                      // type={showConfirmPassword ? "text" : "password"}
                      name="firstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.firstname && touched.firstname ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.firstname}
                    </p>
                  ) : null}
                  <div className="password-input d-flex align-items-baseline">
                    <FloatingLabelInput
                      id="lastname"
                      label="Last Name"
                      // type={showConfirmPassword ? "text" : "password"}
                      name="lastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.lastname && touched.lastname ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.lastname}
                    </p>
                  ) : null}
                  <div className="password-input d-flex align-items-baseline">
                    <FloatingLabelInput
                      id="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  {errors.password && touched.password && (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.password}
                    </p>
                  )}

                  <div className="password-input d-flex align-items-baseline">
                    <FloatingLabelInput
                      id="confirmPassword"
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      className="password-toggle"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.confirmPassword}
                    </p>
                  ) : null}

                  <div className="my-4 text-center">
                    <button
                      type="submit"
                      className="mainBtn"
                      disabled={
                        !values.email ||
                        !values.password ||
                        !values.confirmPassword ||
                        // !values.register_checkbox ||
                        loading
                      }
                    >
                      {loading ? "Loading..." : "Sign Up"}
                    </button>
                  </div>
                </Form>
                {/* <Form
                  className="auth-register-form mt-1 phoneSignUp pt-2"
                  onSubmit={otpCheck}
                  style={{ display: flag ? "block" : "none" }}
                >
                  <div className="mb-1 d-flex justify-content-center otp-input">
                    <OtpInput
                      value={otp}
                      onChange={handleChangeOtp}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>

                  <div className="text-center mt-3">
                    <button
                      type="submit"
                      className="mainBtn"
                    >
                      {loading ? "loading..." : "Verify OTP"}
                    </button>
                  </div>
                </Form> */}
                <div className="divider">or</div>
                <div className="socialLogin d-flex justify-content-between pt-2 pb-2">
                  <button
                    className="socialBtn"
                    onClick={googleSignin}
                    disabled={googleloading}
                  // disabled={!values.register_checkbox || googleloading}
                  >
                    <img src={SignInImages.google} alt="google" />
                    <p>
                      {googleloading ? "Loading..." : "Sign up with Google"}{" "}
                    </p>
                  </button>
                  {/* <button className="socialBtn">
                  <img src={SignInImages.facebook} alt="facebook" />
                  <p>Facebook</p>
                </button> */}
                  {/* <button className="socialBtn" onClick={handleAppleSignIn} disabled={!values.register_checkbox}>
                  <img src={SignInImages.icloud} alt="icloud" />
                  <p>iCloud</p>
                </button> */}

                  <button
                    className="socialBtn"
                    onClick={RegisterWithApple}
                  // disabled={!values.register_checkbox}
                  >
                    <img src={SignInImages.apple} alt="google" width={20} />
                    <p>Sign up with Apple</p>
                  </button>
                </div>
              </div>

              <div className="divider my-3">Apps</div>
              <div className="socialLogin d-flex justify-content-evenly pt-2">
                {/* <img src="https://welinkjobs.com/Images/Default%20Images/bit.ly_welinkjobsapp.png"
                  // style={{height: 103px;width: 103px; margin-left: 5px }}
                  style={{ height: '103px', width: '103px' }}
                  alt="iOS App" /> */}
                <a href="https://bit.ly/welinkjobsapp" target="_blank">
                  <img src={SignInImages.playstore} alt="" width="150px" />
                </a>
                <a href="https://bit.ly/welinkjobs-iOS" target="_blank">
                  <img src={SignInImages.appstore} alt="" width="150px" />
                </a>
              </div>
              {/* <div className="checker">
                <p className="border-top mt-2">
                  Protected by reCAPTCHA and subject to the{" "}
                  <NavLink to="/privacy-policy.html" target="blank">
                    Privacy Policy
                  </NavLink>
                  and{" "}
                  <NavLink to="/terms-and-conditions.html" target="blank">
                    Terms of Service.
                  </NavLink>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {showModal && (
        <div
          className="modal fade show modal-md"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              {/* <div className="setting-header d-flex justify-content-between align-items-center p-2">
                <h6>Additional Details</h6>
                <span
                  className="material-symbols-sharp me-3 pointer"
                  onClick={handleModalClose}
                >
                  close
                </span>
              </div> */}
              <div className="p-2 pt-4 text-center">
                <h6>
                  Hi {" "}
                </h6>
                <p>
                  Please provide an email address to complete your WLJ profile
                  with associated apple account
                </p>
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Email
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={originalemail}
                      onChange={(e) => setOriginalEmail(e.target.value)}
                    />
                  </div>

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleModalClose}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={_registerWithAppleApi}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        // <div
        //   className="modal fade show postAdd"
        //   tabIndex="-1"
        //   role="dialog"
        //   style={{ display: "block" }}
        // >
        //   <div className="modal-dialog  modal-dialog-centered " role="document">
        //     <div className="modal-content d-flex justify-content-around p-2">
        //       <div className="modalBorder d-flex justify-content-between align-items-center p-2">
        //         <h6>Additional Detail</h6>
        //         <span
        //           className="material-symbols-sharp me-2 pointer"
        //           onClick={() => {
        //             handleModalClose();
        //           }}
        //         >
        //           close
        //         </span>
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           Email
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={email}
        //           onChange={(e) => setEmail(e.target.value)}
        //         // invalid={display && email === ""}
        //         />
        //         {/* {display && !email ? (
        //           <span className="error_msg_lbl">Enter Email </span>
        //         ) : null} */}
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           FirstName
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={firstName}
        //           onChange={(e) => setFirstName(e.target.value)}
        //         // invalid={display && firstName === ""}
        //         />
        //         {/* {display && !firstName ? (
        //           <span className="error_msg_lbl">Enter FirstName </span>
        //         ) : null} */}
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           LastName
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={lastName}
        //           onChange={(e) => setLastName(e.target.value)}
        //         // invalid={display && lastName === ""}
        //         />
        //         {/* {display && !lastName ? (
        //           <span className="error_msg_lbl">Enter LastName </span>
        //         ) : null} */}
        //       </div>
        //       <div className='mt-2 text-end'>
        //         <button
        //           type="button"
        //           className="closebtn me-2"
        //           onClick={handleModalClose}
        //           style={{ width: "60px" }}
        //         >
        //           close
        //         </button>
        //         <button
        //           type="button"
        //           className="mainBtn1"
        //           onClick={_registerWithAppleApi}
        //           style={{ width: "70px" }}
        //         >
        //           Update
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
      {flag && (
        <div
          className="modal fade show modal-md"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="p-2 pt-4 text-center">
                <h4>Hi {User_Name},</h4>
                <h6>
                  <span className="fw-bold signUpModal">
                    Verify account by OTP
                  </span>{" "}
                </h6>
                {/* <p>Registration data saved, check mail for verification OTP</p> */}
                <p>{Response_Message}</p>
              </div>
              <Form
                className="auth-register-form mt-1 phoneSignUp pt-2 pb-3"
                onSubmit={otpCheck}
                style={{ display: flag ? "block" : "none" }}
              >
                <div className="mb-1 d-flex justify-content-center otp-input">
                  {/* <OTPInput
                    value={otp}
                    onChange={handleChangeOtp}
                    numInputs={6}
                  /> */}
                  <OtpInput
                    value={otp}
                    onChange={handleChangeOtp}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <div className="text-center mt-3">
                  {/* <Button color="" className="theme-bg" type="submit">
                  </Button> */}
                  <div className="countdown-text d-flex justify-content-center">
                    {seconds > 0 || minutes > 0 ? (
                      <p className="me-3">
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p className="me-3">Didn't recieve code?</p>
                    )}

                    <span
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                      onClick={resendOTP}
                      className={seconds > 0 || minutes > 0 ? "" : "pointer"}
                    >
                      Resend OTP
                    </span>
                  </div>
                  <button type="submit" className="mainBtn mt-3">
                    {loading ? "loading..." : "Verify OTP"}
                  </button>

                </div>
              </Form>
            </div>
          </div>
        </div>

        // <div
        //   className="modal fade show postAdd"
        //   tabIndex="-1"
        //   role="dialog"
        //   style={{ display: "block" }}
        // >
        //   <div className="modal-dialog  modal-dialog-centered " role="document">
        //     <div className="modal-content d-flex justify-content-around p-2">
        //       <div className="modalBorder d-flex justify-content-between align-items-center p-2">
        //         <h6>Additional Detail</h6>
        //         <span
        //           className="material-symbols-sharp me-2 pointer"
        //           onClick={() => {
        //             handleModalClose();
        //           }}
        //         >
        //           close
        //         </span>
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           Email
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={email}
        //           onChange={(e) => setEmail(e.target.value)}
        //         // invalid={display && email === ""}
        //         />
        //         {/* {display && !email ? (
        //           <span className="error_msg_lbl">Enter Email </span>
        //         ) : null} */}
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           FirstName
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={firstName}
        //           onChange={(e) => setFirstName(e.target.value)}
        //         // invalid={display && firstName === ""}
        //         />
        //         {/* {display && !firstName ? (
        //           <span className="error_msg_lbl">Enter FirstName </span>
        //         ) : null} */}
        //       </div>
        //       <div className="col-md-12 mt-2">
        //         <label htmlFor="about">
        //           LastName
        //           <span className="text-danger ms-2">*</span>
        //         </label>
        //         <input
        //           type="text"
        //           value={lastName}
        //           onChange={(e) => setLastName(e.target.value)}
        //         // invalid={display && lastName === ""}
        //         />
        //         {/* {display && !lastName ? (
        //           <span className="error_msg_lbl">Enter LastName </span>
        //         ) : null} */}
        //       </div>
        //       <div className='mt-2 text-end'>
        //         <button
        //           type="button"
        //           className="closebtn me-2"
        //           onClick={handleModalClose}
        //           style={{ width: "60px" }}
        //         >
        //           close
        //         </button>
        //         <button
        //           type="button"
        //           className="mainBtn1"
        //           onClick={_registerWithAppleApi}
        //           style={{ width: "70px" }}
        //         >
        //           Update
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default SignUp;
