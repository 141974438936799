import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "bootstrap";
import "../../src/assets/css/Message.css";
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';

const SuccessToast = ({ show, message, onClose }) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (show) {
      const toastElement = toastRef.current;
      const bootstrapToast = new Toast(toastElement);
      bootstrapToast.show();

      toastElement.addEventListener("hidden.bs.toast", onClose);
      return () => {
        toastElement.removeEventListener("hidden.bs.toast", onClose);
      };
    }
  }, [show, onClose]);

  return (
    <>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1000 }}>
        <div
          className="toast fade no-border-radius"
          ref={toastRef}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header text-white success-toast">
            <TaskAltSharpIcon className="material-symbols-sharp me-2" />
            <strong className="me-auto">Success</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>

          <div className="toast-body">{message}</div>
        </div>
      </div>
    </>
  );
};

export default SuccessToast;
