import React from 'react'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
const ManageSession = () => {
    return (
        <>
            <div className='setting-header'>
                <h4>Manage sessions </h4>
            </div>
            <div className='p-3 privacy'>
                <div>
                    <p className='sessionName'>Android App</p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>Samsung 14 . Since 14th July 2023, 12:09 pm</p>
                        <LogoutSharpIcon className="material-symbols-sharp" />
                    </div>
                </div>
                <div>
                    <p className='sessionName'>iOS App</p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>iPhone 12 . Since 14th July 2023, 12:09 pm</p>
                        <LogoutSharpIcon className="material-symbols-sharp" />
                    </div>
                </div>
                <div>
                    <p className='sessionName'>Google Chrome</p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>IP Address: 103.207.56.138 . Since 14th July 2023, 12:09 pm</p>
                        <LogoutSharpIcon className="material-symbols-sharp" />
                    </div>
                </div>
                <div>
                    <p className='sessionName'>Mozilla Firefox </p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>IP Address: 1.186.106.173 . Since 14th July 2023, 12:09 pm</p>
                        <LogoutSharpIcon className="material-symbols-sharp" />
                    </div>
                </div>
                <div className='text-end mt-3'>
                    <button type='submit' className='btnDanger mt-3 text-center'>Sign out all</button>
                </div>
            </div>


        </>
    )
}

export default ManageSession