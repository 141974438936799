import React, { useEffect, useState } from 'react'
import { Form, Link, NavLink } from 'react-router-dom'
import FloatingLabelInput from 'react-floating-label-input';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { FcGoogle } from "react-icons/fc";
import { SignInImages } from '../assets/Images/ImagesLink';
import WarningToast from "./WarningToast";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";
import axios from '../API/axios'
// import SignInImages from '../assets/Images/ImagesLink'
const ForgotPassword = () => {
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    //************************ Confirm Acount Start  ************************//
    const [mail, setMail] = useState('')
    const ForgotPassword = async () => {
        const data = {
            deviceType: "Web",
            email: mail
        };
        try {
            await axios.post("OAuth/ResetPasswordByEmail", data).then((resData) => {
                console.log("resData", resData);
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ Confirm Acount End  ************************//
    const [ConnectionListForGuest, setConnectionListForGuest] = useState("");
    const ConnectionListAllForGuest = async (id) => {
        try {
            await axios
                .get("UserFollow/GetMostFollowingUser?DeviceType=Web")
                .then((resData) => {
                    // console.log("ConnectionListAllForGuest", resData.data.responseData);
                    setConnectionListForGuest(resData.data.responseData);
                });
        } catch (error) {
            console.log(error);
        }
    };
    // console.log('ConnectionListForGuest', ConnectionListForGuest);

    useEffect(() => {
        ConnectionListAllForGuest();
    }, []);
    const [guestMode, setGuestMode] = useState(false);
    const LinktoGuest = () => {
        setGuestMode(true);
        localStorage.setItem("guestMode", "true");
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 full-height p-2 loginImage">
                        <div className="bg-cover">
                            <div className='imageData'>
                                <div className='text-center'>
                                    <img src={SignInImages.whiteLogo} alt="" />
                                </div>
                                <p>
                                    Stay connected for{" "}
                                    <NavLink to={`/SearchJob`} onClick={LinktoGuest} className="border-bottom">
                                        Jobs
                                    </NavLink>{" "}
                                    &{" "}
                                    <NavLink to={`/NewsFeed`} onClick={LinktoGuest} className="border-bottom">
                                        More
                                    </NavLink>
                                </p>
                                {/* <div className='text-center mt-4'>
                                    <img src={SignInImages.ovalwithtext} alt="" width='80%' />
                                </div> */}
                                {/* <div className="userLink text-center mt-4 d-flex justify-content-center">
                                    <Link to='/People' onClick={LinktoGuest}>
                                        <div className="circle-container">
                                            {ConnectionListForGuest && ConnectionListForGuest?.slice(0, 3).map((user, userIndex) => (
                                                <img
                                                    src={user?.profilPic}
                                                    alt={`Circle ${userIndex + 1}`}
                                                    className="circle-img"
                                                    key={userIndex}
                                                />
                                            ))}
                                        </div>
                                    </Link>
                                    <span className="f-14">12k+ people joined us, now it's your turn</span>
                                </div> */}
                                <div className="userLink text-center mt-2  justify-content-center">
                                    <Link to='/People' onClick={LinktoGuest}>
                                        <div className="circle-container">
                                            {ConnectionListForGuest && ConnectionListForGuest?.slice(0, 3).map((user, userIndex) => (
                                                <img
                                                    src={user?.profilPic}
                                                    alt={`Circle ${userIndex + 1}`}
                                                    className="circle-img"
                                                    key={userIndex}
                                                />
                                            ))}
                                        </div>
                                    </Link>
                                    <span className="f-14 fw-bold">12k+ people joined us, now it's your turn</span>
                                    {/* <img src={SignInImages.ovalwithtext} alt="" width="80%" /> */}
                                </div>
                            </div>
                            <div className="linksHelp">
                                <p><a href="/privacy-policy.html" target='_blank'>Privacy Policy</a> | <a href="/terms-and-conditions.html" target='_blank'>Terms and Condition</a></p>
                                <p>Having Troubles? <a href="/faq.html" className='border-bottom me-3' target='_blank'>Get Help</a> </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4  p-2">
                        <div className="form-container full-height pb-3 pt-5">
                            <Form>
                                <h2 className='header'>Forgot Password</h2>
                                <p className='paraClass1 mt-4 mb-4'>Enter your email to receive an email reset your password</p>

                                <FloatingLabelInput
                                    id="email"
                                    label="Registered Email address"
                                    type="text"
                                    value={mail}
                                    onChange={(e) => {
                                        setMail(e.target.value)
                                    }}
                                />
                                {/* <div className='checker d-flex align-items-center mt-2'>
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="register_checkbox"
                                    // value={values.register_checkbox}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    />
                                    <p className='ms-2'>By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                                </div> */}
                                <div className="my-4  text-center">
                                    <button type='submit' className='mainBtn' onClick={ForgotPassword}>Send</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default ForgotPassword