import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../API/axios";
import BlogCard from "../Blogs/BlogCard";

const BlogsNav = ({ setTab }) => {
  const [blogmenu, setBlogmenu] = useState([]);

  const getblogsmenu = () => {
    axios
      .get(`Blog/GetBlogCategoryDDL`)
      .then((response) => {
        console.log("nav data response", response.data);
        setBlogmenu(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log("isLoading", isLoading);
  useEffect(() => {
    getblogsmenu();
  }, []);

  return (
    <>
      <div className="blog-nav-bar py-3">
        <div className="px-2 px-lg-3">
          <ul className="p-0 m-0 d-flex justify-content-evenly align-items-center">
            <li>
              <NavLink to="/blogs">
                <p className="p-0 m-0 nav-text">All Blogs</p>
              </NavLink>
            </li>

            {blogmenu != null &&
              blogmenu.map((val) => {
                return (
                  <li>
                    <NavLink to={"/blogs/category/" + val.catgryURLvalue}>
                      <p
                        className="p-0 m-0 nav-text"
                      onClick={() => setTab(val.bcatgName)}
                      >
                        {val.bcatgName}
                      </p>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>


    </>
  );
};

export default BlogsNav;
