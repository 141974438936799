import React, { useEffect, useState } from "react";
import { notificationImages } from "../../assets/Images/ImagesLink";
import "../../assets/css/JobNotification.css";
import {
  Table,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Navigation/Header";
import LeftNavigation from "../Navigation/LeftNavigation";
import ReactSelect from "react-select";
import axios from "../../API/axios";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
// import { Link } from 'react-router-dom'
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import Flatpickr from 'react-flatpickr'
import ApplicationStatus from "./ApplicationStatus";
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
const JobNotification = () => {
  const navigate = useNavigate();
  const [acceptNotification, setacceptNotification] = useState(false);
  const handleRemoveCertificateClose = () => {
    setacceptNotification(false);
  };

  const [showEducationDelete, setShowEducationDelete] = useState(false);
  const handleRemoveEducationClose = () => {
    setShowEducationDelete(false);
  };
  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "grape", label: "Grape" },
  ];

  const sessionId = localStorage.getItem("sessionId");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  //************************Job Application Start  ************************//
  const [loading, setLoading] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [jobApplication, setJobApplication] = useState([]);
  const [jobApplicationid, setJobApplicationid] = useState('');
  const getJobApplication = (page) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 12
    };
    axios
      .post(`JobApplication/GetAllJobApplications`, data)
      .then((response) => {
        if (response.status === 204) {
          setErrorImage(true)
        } else {
          setTotalPages(response.data.responseData.paging.totalPages);
          const newPosts = response.data.responseData.jobApplications;
          // console.log('jhgdsjhgfsd', newPosts);
          setJobApplication((prev) => {
            const postSet = new Set(prev.map((post) => post.jbApplctnId));
            const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.jbApplctnId));
            return [...prev, ...uniqueNewPosts];
          });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (page <= totalPages) {
      getJobApplication(page);
    }
  }, [page]);

  const handleInfiniteScroll = async () => {
    try {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  //************************Job Application End  ************************//


  //************************Accept Job Start  ************************//
  const AcceptJob = (jobId) => {
    const data =
    {
      deviceType: "Web",
      sessionId: sessionId,
      jbApplctnId: jobId,
      hiringStatus: 'Shortlisted',
      remark: null
    }
    axios
      .post(`JobApplication/UpdateHiringStatus`, data)
      .then((response) => {
        // console.log("getJobApplication", response.data.responseData);
        const res = response.data.responseData;

        setToastMessage(response.data.message);
        setShowToast(true);
        getJobApplication(page)
        setJobApplication((prevJobs) =>
          prevJobs.map((job) => job.jbApplctnId === jobId
            ? { ...job, hiringStatus: 4, hiringStatusName: '"Shortlisted"' } // Update hiringStatus
            : job
          )
        );

      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 404) {
          setWarningToastMessage(e.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(e.response.data.message);
          setShowErrorToast(true);
        }
      });
  };
  //************************Accept Job End  ************************//

  //************************Reject Job Start  ************************//
  const RejectJob = (jobId) => {
    const data =
    {
      deviceType: "Web",
      sessionId: sessionId,
      jbApplctnId: jobApplicationid,
      hiringStatus: 'Declined',
      remark: null
    }
    axios
      .post(`JobApplication/UpdateHiringStatus`, data)
      .then((response) => {
        // console.log("getJobApplication", response.data.responseData);
        const res = response.data.responseData;
        setToastMessage(response.data.message);
        setShowToast(true);

        getJobApplication(page)

        setJobApplication((prevJobs) =>
          prevJobs.filter((job) => job.jbApplctnId !== jobApplicationid)
        );
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 404) {
          setWarningToastMessage(e.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(e.response.data.message);
          setShowErrorToast(true);
        }
      });
  };
  //************************Reject Job End  ************************//


  useEffect(() => {
    if (acceptNotification || showEducationDelete) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [acceptNotification, showEducationDelete]);

  console.log('jobApplication', jobApplication);
  const InterviewPlaceType = [
    { value: '1', label: 'OnSite' },
    { value: '2', label: 'Remote' },
    { value: '3', label: 'Other' },
  ]
  const [startDate, setStartDate] = useState("");
  const [interviewPT, setInterviewPT] = useState("");
  const [placeDetail, setPlaceDetail] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [remark, setRemark] = useState("");
  const handleScheduleModal = (jobId = null) => {
    setCurrentJobId(jobId);
    setScheduleModal(!scheduleModal);
  };

  const handleSubmitSechdule = () => {
    if (currentJobId) {
      Schedule(currentJobId);
    }

  };
  const Schedule = (jobId) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      jbApplctnId: jobId,
      scheduleTime: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      placeType: interviewPT,
      placeDetails: placeDetail,
      remark: remark,
    };
    console.log(data);
    if (startDate === "" || interviewPT === "") {
      setWarningToastMessage('Please fill all the required fields');
      setShowWarningToast(true)
    } else {
      axios
        .post(`JobApplication/ScheduleInterview`, data)
        .then((response) => {
          const res = response.data.responseData;
          setToastMessage(response.data.message);
          setShowToast(true);
          setRemark('')
          setStartDate('')
          setInterviewPT('')
          setPlaceDetail('')
          setScheduleModal(false);
          // setJobApplication((prevJobs) =>
          //   prevJobs.map((job) => job.jbApplctnId === jobId
          //     ? { ...job, hiringStatus: 4, hiringStatusName: 'Scheduled' } // Update hiringStatus
          //     : job
          //   )
          // );
          setJobApplication((prevJobs) =>
            prevJobs.filter((job) => job.jbApplctnId !== jobId)
          );
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 404) {
            setWarningToastMessage(e.response.data.message);
            setShowWarningToast(true);
          } else {
            setErrorToastMessage(e.response.data.message);
            setShowErrorToast(true);
          }
        });
    }
  };
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      <div className="jobHeader p-3 d-flex justify-content-between">
        <div className="friendsHeaderText">
          <h1>Job Applications</h1>
        </div>
        <a
          className="linkSearch f-14 d-flex align-items-center  pointer"
          onClick={() => navigate('/Interview')}
        >
          Interviews
          <ArrowForwardSharpIcon className="material-symbols-sharp" />

        </a>
      </div>
      {errorImage ? <div className="mt-2 text-center">
        <img
          src={`https://welinkjobs.com/Images/Default%20Images/404/PNG/No%20Result%202.png`}
          alt="404 Not Found"
          width={'400px'}
        />
      </div> : <div className="job-notification-main-container">
        <div className="job-notification-card ">
          <Card className="transaction-table vh-100">
            <Table responsive className="notification-table">
              <thead>
                <tr className="f-12 f-600 table-header">
                  <th>Candidates Name</th>
                  <th>Job Description</th>
                  <th>Apply On</th>
                  <th>Recent Works</th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                {jobApplication &&
                  jobApplication?.map((item, index) => {
                    return (
                      <tr >
                        <td className="row-gap">
                          <div className="card-image-text d-flex align-items-center">
                            <div className="card-image me-1">
                              <img
                                src={item?.userProfilePic}
                                alt=""
                                srcset=""
                                width={40}
                                height={40}
                                style={{ borderRadius: '50%' }}
                              />
                            </div>
                            <div>
                              <UncontrolledDropdown
                                tag="li"
                                className="nav-item user-detail"
                              >
                                <Link
                                  to={`/User/${item.userUid}`}
                                  tag="a"
                                  // onClick={`/User/${item.userUid}`}
                                  target="blank"
                                // onClick={(e) => e.preventDefault()}
                                >
                                  <div className="card-text f-14 f-500 d-flex">
                                    {item?.userName}
                                  </div>
                                </Link>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </td>
                        <td className="w-20">
                          {" "}
                          <div className="job-desc-text f-14 f-400 ">
                            <Link
                              className="job-desc-text "
                              to={`/JobDetail/${item.jobId}`} target="_blank">
                              {item?.jobTitle}
                              <small className="d-block f-10">{item?.shortDescription}</small>
                            </Link>
                            {/* {item?.jobTitle}
                            <small className="d-block f-10">{item?.shortDescription}</small> */}
                          </div>
                        </td>
                        <td>
                          <div className="apply-on-text f-14 f-400">
                            {/* Jun 21, 2019 */}
                            {moment(item.appliedTime).format('ll')}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="recent-work d-flex align-items-center">
                            {/* <div className="recent-icon me-2">
                              <img
                                className="rounded-circle"
                                src={notificationImages.notifyImg13}
                                alt=""
                                srcset=""
                              />
                            </div> */}
                            <div className="recent-text f-14 f-400">
                              {item?.userCurrentDesignation}
                            </div>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="actions-cont">
                            <ul className="d-flex mb-0 p-0">
                              <li className="me-2">
                                <Link to={`/Resume/${item.userUid}`} target="_blank">
                                  <img
                                    src={notificationImages.notifyImg4}
                                    alt=""
                                    srcset=""
                                  />
                                </Link>
                              </li>
                              {item?.hiringStatus === 4 ?
                                // <ApplicationStatus item={item} />
                                <li className="me-2 pointer"
                                  onClick={() => {
                                    handleScheduleModal(item?.jbApplctnId);
                                  }}
                                >
                                  <CalendarMonthSharpIcon />
                                </li>
                                : <li className="me-2 pointer"
                                  onClick={() => {
                                    // setacceptNotification(!acceptNotification);
                                    // setJobApplicationid(item?.jbApplctnId)
                                    AcceptJob(item?.jbApplctnId);
                                  }}
                                >
                                  <img
                                    src={notificationImages.notifyImg1}
                                    alt=""
                                    srcset=""
                                  />
                                </li>}


                              <li className="pointer" onClick={() => {
                                setShowEducationDelete(!showEducationDelete);
                                setJobApplicationid(item?.jbApplctnId)
                                // RejectJob(item?.jbApplctnId)
                              }}>
                                <img
                                  src={notificationImages.notifyImg2}
                                  alt=""
                                  srcset=""
                                />
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                  })}


              </tbody>
            </Table>
          </Card>
        </div>
      </div>}


      {acceptNotification && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Acept</h6>

                <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveCertificateClose();
                  }}
                >
                  close
                </span>
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="mainBtn1"
                  onClick={() => {
                    AcceptJob();
                    handleRemoveCertificateClose()
                  }
                  }
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEducationDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Reject</h6>

                <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }}
                >
                  close
                </span>
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => {
                    RejectJob()
                    handleRemoveEducationClose();
                  }
                  }
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {scheduleModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Interview Schedule Details</h6>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => handleScheduleModal()}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleScheduleModal()
                  }}
                />
              </div>
              {/* <div className="d-flex modalBorder pb-4 d-flex justify-content-center"> */}
              <div className="row settings mt-2  mb-2 userInput d-flex flex-column align-items-center px-4">
                <div className="col-md-12 mt-2">
                  <label htmlFor="remark">Schedule Time <span style={{ color: "red" }}>*</span></label>

                  <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob'
                    options={{
                      minDate: "today",
                      enableTime: true, // Enables time selection
                      time_24hr: true,  // Enables 24-hour time format, if needed
                      dateFormat: "Y-m-d H:i", // Format: Year-Month-Day Hour:Minute
                      minuteIncrement: 1,
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="remark">Place Type <span style={{ color: "red" }}>*</span></label>
                  <ReactSelect
                    name="country"
                    onChange={(e) => {
                      console.log(e?.label);
                      setInterviewPT(e?.label)
                    }}
                    defaultValue={interviewPT}
                    className="react-select w-100"
                    classNamePrefix="select"
                    options={InterviewPlaceType}
                    isClearable={true}
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="remark">Place Details</label>
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    value={placeDetail}
                    onChange={(e) => setPlaceDetail(e.target.value)}
                    className='form-control'
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="about">
                    Remark
                  </label>
                  <textarea
                    id="about"
                    name="about"
                    rows="2"
                    cols="65"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  ></textarea>
                </div>
              </div>
              {/* </div> */}
              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={handleSubmitSechdule}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobNotification;
