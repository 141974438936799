import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../API/axios";
import "../../assets/css/ManageResume.css";
import { NewsFeed, resume } from "../../assets/Images/ImagesLink";
import Footer from "../Navigation/Footer";
import RightNavigation from "../Navigation/RightNavigation";
import CCVEducation from "./CCVEducation";
import CCVExperience from "./CCVExperience";
import CCVSKill from "./CCVSKill";
import UpdateCCV from "./UpdateCCV";
import moment from "moment";
import CCVCertification from "./CCVCertification";
import { Modal } from "react-bootstrap";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Input, Label } from "reactstrap";
import CCVRole from "./CCVRole";
import CCVAchievement from "./CCVAchievement";
import CCVAward from "./CCVAward";
import CCVAttachement from "./CCVAttachement";
import RoleList from "./RoleList";
import AchievementList from "./AchievementList";
import { color } from "framer-motion";
import CCVEducationUpdate from "./CCVEducationUpdate";
import CCVExperienceUpdate from "./CCVExperienceUpdate";
import CCVCertificationUpdate from "./CCVCertificationUpdate";
import CCVSkillUpdate from "./CCVSkillUpdate";
import CCVAwardUpdate from "./CCVAwardUpdate";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';

const ManageResume = () => {
  const guestModeData = localStorage.getItem("guestMode");
  const userUid = localStorage.getItem("userUid");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const { UserId } = useParams();
  const [userCcv, setUserCcv] = useState([]);
  const sessionId = localStorage.getItem("sessionId");

  const getUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUid: UserId,
    };
    try {
      await axios.post(`Resume/GetUserResume`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getUserCcv();
  }, []);

  console.log("userCcv", userCcv);

  const [showAddSkill, setShowAddSkill] = useState(false);

  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);

  const [showUpdateCCV, setShowUpdateCCV] = useState(false);

  const handleOpenUpdateCCV = () => setShowUpdateCCV(true);
  const handleCloseCCV = () => setShowUpdateCCV(false);

  const [showCCVEducation, setShowCCVEducation] = useState(false);

  const handleOpenCCVEducation = () => setShowCCVEducation(true);
  const handleCloseCCVEducation = () => setShowCCVEducation(false);

  const [showCCVExperience, setShowCCVExperience] = useState(false);

  const handleOpenCCVExperience = () => setShowCCVExperience(true);
  const handleCloseCCVExperience = () => setShowCCVExperience(false);

  const [showCCVAward, setShowCCVAward] = useState(false);

  const handleOpenCCVAward = () => setShowCCVAward(true);
  const handleCloseCCVAward = () => setShowCCVAward(false);

  const [showCCVAttachement, setShowCCVAttachement] = useState(false);

  const handleOpenCCVAttachement = () => setShowCCVAttachement(true);
  const handleCloseCCVAttachement = () => setShowCCVAttachement(false);

  const [showCCVCertification, setShowCCVCertification] = useState(false);

  const handleOpenCCVCertification = () => setShowCCVCertification(true);
  const handleCloseCCVCertification = () => setShowCCVCertification(false);

  const [showCCVRole, setShowCCVRole] = useState(false);

  const handleOpenCCVRole = () => setShowCCVRole(true);
  const handleCloseCCVRole = () => setShowCCVRole(false);

  const [showCCVAchivement, setShowCCVAchivement] = useState(false);

  const handleOpenCCVAchivement = () => setShowCCVAchivement(true);
  const handleCloseCCVAchivement = () => setShowCCVAchivement(false);

  //************************Remove Certification Start  ************************//

  const [showExperienceDelete, setShowExperienceDelete] = useState(false);
  const handleRemoveExperienceClose = () => {
    setShowExperienceDelete(false);
  };
  const [ExperienceId, setExperienceId] = useState("");
  const [usrExpId, setUsrExpId] = useState("");
  const [experienceSynced, setExperienceSynced] = useState(false);
  const removeExperience = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(experienceSynced
        ? { usrExpId: usrExpId }
        : { rsmExpId: ExperienceId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmExperienceInfo`, { data: responseData })
        .then((res) => {
          setShowExperienceDelete(!showExperienceDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Certificat End  ************************//

  //************************Remove Skill Start  ************************//

  const [showSkillDelete, setShowSkillDelete] = useState(false);
  const handleRemoveSkillClose = () => {
    setShowSkillDelete(false);
  };
  const [SkillId, setSkillId] = useState("");
  const [usrSkllId, setUsrSkllId] = useState("");
  const [skillSynced, setSkillSynced] = useState(false);
  const [skillDataId, setSkillDataId] = useState("");

  const removeSkill = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmId: userCcv?.resumeInfo?.resumeId,
      // ccvSkllId: SkillId,
      // rsmSkllId: SkillId,
      ...(skillSynced
        ? { usrSkllId: usrSkllId }
        : { rsmSkllId: SkillId }),
      // rsmSkllUsrDataLnkdID: skillDataId
    };
    try {
      await axios.delete(`ResumeData/UnLinkRsmSkill`, { data: responseData }).then((res) => {
        setShowSkillDelete(!showSkillDelete);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Skill End  ************************//

  //************************Remove Education Start  ************************//
  const [educationData, setEducationData] = useState([]);
  const [showEducationUpdate, setShowEducationUpdate] = useState(false);
  const handleUpdateEducationClose = () => {
    setShowEducationUpdate(false);
    setEducationData([])
  };
  const [showEducationDelete, setShowEducationDelete] = useState(false);
  const handleRemoveEducationClose = () => {
    setShowEducationDelete(false);
  };
  const [EducationId, setEducationId] = useState("");
  const [usrEductnId, setUsrEductnId] = useState("");
  const [educationSynced, setEducationSynced] = useState(false);
  const removeEducation = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      // rsmEductnId: EducationId,
      // usrEductnId:usrEductnId,
      ...(educationSynced
        ? { usrEductnId: usrEductnId }
        : { rsmEductnId: EducationId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmEducationInfo`, { data: responseData })
        .then((res) => {
          setShowEducationDelete(!showEducationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Education End  ************************//

  //************************Remove Certification Start  ************************//

  const [showCertificationDelete, setShowCertificationDelete] = useState(false);
  const handleRemoveCertificationClose = () => {
    setShowCertificationDelete(false);
  };
  const [CertificationId, setCertificationId] = useState("");
  const [usrCerId, setUsrCerId] = useState("");
  const [certificateSynced, setCertificateSynced] = useState(false);
  const removeCertification = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(certificateSynced
        ? { usrCrtfctnId: usrCerId }
        : { rsmCrtfctnId: CertificationId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmCertificationInfo`, { data: responseData })
        .then((res) => {
          setShowCertificationDelete(!showCertificationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Certification End  ************************//
  //************************Remove Award Start  ************************//

  const [showAwardDelete, setShowAwardDelete] = useState(false);
  const handleRemoveAwardClose = () => {
    setShowAwardDelete(false);
  };
  const [AwardId, setAwardId] = useState("");
  const [usrAwrId, setUsrAwrId] = useState("");
  const [awardSynced, setAwardSynced] = useState(false);
  const removeAward = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(awardSynced
        ? { usrAwardId: usrAwrId }
        : { rsmAwardId: AwardId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.delete(`ResumeData/DeleteRsmAwardInfo`, { data: responseData }).then((res) => {
        setShowAwardDelete(!showAwardDelete);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Award End  ************************//

  //************************Remove Award Start  ************************//

  const [showAttachementDelete, setShowAttachementDelete] = useState(false);
  const handleRemoveAttachementClose = () => {
    setShowAttachementDelete(false);
  };
  const [AttachementId, setAttachementId] = useState("");

  const removeAttachement = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: AttachementId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeAction/DeleteAttachment`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShowAttachementDelete(!showAttachementDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Attachement End  ************************//

  //************************Enable Certification  ************************//
  const EnableExperience = async (ccvExpId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ccvExpId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/EnableRsmExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableExperience = async (ccvExpId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ccvExpId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/DisableRsmExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Skill  ************************//
  const EnableSkill = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ccvSkllId: Id,
      resumeId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.post(`CcvAction/EnableCcvSkill`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Skill End  ************************//
  //************************Disable Skill  ************************//
  const DisableSkill = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ccvSkllId: Id,
      resumeId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .post(`CcvAction/DisableCcvSkill`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Skill End  ************************//

  //************************Enable Education  ************************//
  const EnableEducation = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmEductnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId
    };
    try {
      await axios
        .put(`ResumeData/EnableRsmEducationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Education End  ************************//
  //************************Disable Education  ************************//
  const DisableEducation = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmEductnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/DisableRsmEducationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Education End  ************************//

  //************************Enable Certification  ************************//
  const EnableCertification = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmCrtfctnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/EnableRsmCertificationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableCertification = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmCrtfctnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/DisableRsmCertificationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//
  //************************Enable Certification  ************************//
  const EnableAttachement = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeAction/EnableAttachment`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableAttachement = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeAction/DisableAttachment`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Award  ************************//
  const EnableAwards = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAwardId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.put(`ResumeData/EnableAwardInfo`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Award End  ************************//
  //************************Disable Award  ************************//
  const DisableAwards = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAwardId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeData/DisableAwardInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Award End  ************************//

  //************************Enable Userccv  ************************//
  const EnableUserCCV = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.put(`ResumeAction/EnableResume`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Userccv End  ************************//
  //************************Disable Userccv  ************************//
  const DisableUserCCV = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.put(`ResumeAction/DisableResume`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Userccv End  ************************//



  useEffect(() => {
    if (showExperienceDelete || showSkillDelete || showEducationDelete || showCertificationDelete || showAwardDelete || showAttachementDelete) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showExperienceDelete || showSkillDelete, showEducationDelete, showCertificationDelete, showAwardDelete, showAttachementDelete]);
  console.log('userCcv', userCcv?.resumeInfo?.status);


  const [resumeStatus, setResumeStatus] = useState();
  useEffect(() => {
    setResumeStatus(userCcv?.resumeInfo?.status === "Active")
  }, [userCcv])

  const handleToggle = () => {
    if (resumeStatus) {
      DisableUserCCV().then(() => {
        setResumeStatus(false);
      });
    } else {
      EnableUserCCV().then(() => {
        setResumeStatus(true);
      });
    }
  };
  const [ExperienceData, setExperienceData] = useState([]);
  const [showExperienceUpdate, setShowExperienceUpdate] = useState(false);
  const handleUpdateExperienceClose = () => {
    setShowExperienceUpdate(false);
  };

  const [showCertificateUpdate, setShowCertificateUpdate] = useState(false);
  const handleUpdateCertificateClose = () => {
    setShowCertificateUpdate(false);
  };
  const [certificateData, setCertificateData] = useState([]);

  const [skillsData, setSkillsData] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const handleUpdateSkillClose = () => {
    setShowSkills(false);
  };

  const [showAwardUpdate, setShowAwardUpdate] = useState(false);
  const [showAwardData, setAwardData] = useState([]);
  const handleUpdateAwardClose = () => {
    setShowAwardUpdate(false);
  };

  const [showResumeDelete, setShowResumeDelete] = useState(false)
  const handleRemoveResumeClose = () => {
    setShowResumeDelete(false)
  }

  const navigate = useNavigate();
  const removeResume = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeAction/DeleteResume`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShowResumeDelete(!showResumeDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
          setTimeout(() => {
            navigate(`/User/${userUid}`);
          }, 2000);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [userPer, setUserPer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        const res = response.data.responseData;
        setUserPer(res.percentage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingalert(false); // Set loading to false once the data is fetched
      });
  };

  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
  const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

  // Condition to show the alert
  const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;

  return (
    <>
      {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )}
      {/* {(guestModeData === 'false' || guestModeData === null) && userPer <= 60 && alert === false && (
        <div className='p-3 closeAccount m-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon style={{ width: '20px' }}
                className="pointer" onClick={() => {
                  setAlert(true);
                }} />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have valid resume to apply for job.</span>

          </div>
          <div className=" mt-2">
                  <Link to={`/User/${userUid}`}>
                       <button
                          color=""
                           className="closebtn"
                             style={{ width: "150px" }}  >
                        Manage Profile
                      </button>
                    </Link>
                </div>
        </div>)} */}
      <div className="p-3">
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Basic Info</p>
            <div className="d-flex align-items-center">
              {userCcv?.length === 0 ? <span className="f-14 me-3">View Resume</span> : <Link
                to={`/Resume/${UserId}`}
                target="_blank"
                className="me-3"
              >
                <p className="f-14 ms-1">View Resume</p>
              </Link>}
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenUpdateCCV}
              >
                edit
              </span> */}
              <EditSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUpdateCCV} />
              {/* <span
                className="material-symbols-sharp pointer delete-icon"
                onClick={() => {
                  setShowResumeDelete(!showResumeDelete);
                }}
              >
                delete
              </span> */}
              <DeleteOutlineSharpIcon
                className="material-symbols-sharp pointer delete-icon"
                onClick={() => {
                  setShowResumeDelete(!showResumeDelete);
                }} />


            </div>
          </div>
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
          >
            <div className="ms-2">
              <div className="d-flex align-items-center verified">
                <p className="f-14 ms-1 pb-1 fw-bold me-2">
                  Visibility
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-switch form-check-primary CCVvisibility ms-3 ">
                  <Input
                    type="switch"
                    checked={resumeStatus}
                    onChange={handleToggle}
                    className=""
                  />
                </div>
                {/* {userCcv?.length === 0 ? <span className="f-14 me-3">View Resume</span> : <Link
                  to={`/Resume/${UserId}`}
                  target="_blank"
                  className="me-3"
                >
                  <p className="f-14 ms-1">View Resume</p>
                </Link>} */}
              </div>
            </div>
          </div>

          {/* <div className="d-flex justify-content-between">
            <p className="f-16 fw-bold ms-2">{userCcv?.resumeInfo?.fullName}</p>

          </div> */}
          {userCcv?.resumeInfo?.fullName &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}

            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Full Name
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.fullName}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.title &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}

            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Title
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.title}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.dob &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}

            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Date Of Birth
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.dob}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.currentCity &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      City
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.currentCity}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.email &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Email
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.email}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.phoneNumber &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Phone Number
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.phoneNumber}
                  </p>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.webSiteLink &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Website
                    </p>
                  </div>
                  <a
                    className="ms-1 f-12"
                    href={
                      userCcv?.resumeInfo?.webSiteLink?.startsWith('http://') || userCcv?.resumeInfo?.webSiteLink?.startsWith('https://')
                        ? userCcv?.resumeInfo?.webSiteLink
                        : `http://${userCcv?.resumeInfo?.webSiteLink}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {userCcv?.resumeInfo?.webSiteLink}
                  </a>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.summary &&
            <div
              className={`analytic-sub mt-2 mb-1 pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex align-items-center verified">
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Summary
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.summary}
                  </p>
                </div>

              </div>
            </div>
          }
        </div>


        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Education</p>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVEducation} />
            </div>
          </div>

          {userCcv?.resumeEducations &&
            userCcv?.resumeEducations?.map((item, index) => {
              // console.log('itegfsdfdm', item);
              const isLastItem = index === userCcv?.resumeEducations?.length - 1;
              return (
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                  key={index}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-14 ms-1 fw-bold me-2">
                          {item.institutionName}
                          {/* <span className="fw-normal f-12">({item.orgType})</span> */}
                          {item?.isOrgVrfd === true ? (
                            <img src={resume.verified} width={18} height={18} className="ms-1" />
                          ) : item?.isOrgRgd === true ? (
                            <img src={resume.register} width={18} height={18} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={18} height={18} className="ms-1" />
                          )}

                          {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                        </p>

                      </div>
                      <div className="d-flex">
                        <p className="ms-1 me-1 f-14 fw-bold">Field: {item.field}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-14 fw-bold">
                          {item.degree}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <PlaceSharpIcon
                          className="material-symbols-sharp locationIcon" />
                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          ( {item.startYear} -
                          {item.isPresent === true ? "Present" : item.endYear})
                        </p>
                      </div>
                    </div>
                    {/* {userData?.profileInfo?.isOwn && ( */}
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                      {item.isSyncedWithUserData ? '' : <>
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableEducation(item.rsmEductnId);
                              } else {
                                EnableEducation(item.rsmEductnId);
                              }
                            }}
                            className=""
                          />
                        </div>
                        {/* <span
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowEducationUpdate(!showEducationUpdate);
                            setEducationData(item);
                          }}
                        >
                          edit
                        </span> */}
                        <EditSharpIcon className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowEducationUpdate(!showEducationUpdate);
                            setEducationData(item);
                          }} />
                      </>}
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowEducationDelete(!showEducationDelete);
                          setEducationSynced(item.isSyncedWithUserData)
                          setEducationId(item.rsmEductnId);
                          setUsrEductnId(item.usrEductnId);
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowEducationDelete(!showEducationDelete);
                          setEducationSynced(item.isSyncedWithUserData)
                          setEducationId(item.rsmEductnId);
                          setUsrEductnId(item.usrEductnId);
                        }}
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
        </div>


        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Experience</p>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenCCVExperience}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVExperience}
              />
            </div>
          </div>

          {userCcv?.resumeExperiences &&
            userCcv?.resumeExperiences?.map((item, index) => {
              // console.log('ExperienceId', item);
              const isLastItem = index === userCcv?.resumeExperiences.length - 1;
              return (
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-14 ms-1 fw-bold me-2">
                          {item.jobTitle}
                          {/* <span className="fw-normal f-12">({item.orgType})</span> */}
                          {item?.isOrgVrfd === true ? (
                            <img src={resume.verified} width={18} height={18} className="ms-1" />
                          ) : item?.isOrgRgd === true ? (
                            <img src={resume.register} width={18} height={18} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={18} height={18} className="ms-1" />
                          )}

                          {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                        </p>

                      </div>
                      <p className="ms-1 me-1 f-14 fw-bold"> {item.companyName}</p>
                      <div className="d-flex align-items-center">
                        <p className="ms-1 me-2 f-12 ">{item.jobTypeName}</p>
                        <div className="dot"></div>
                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          ({moment(item.startDate).format("YYYY")} -
                          {item.isPresent === true ? "Present" : moment(item.endDate).format("YYYY")})
                        </p>
                      </div>
                    </div>
                    {/* {userData?.profileInfo?.isOwn && ( */}
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                      {item.isSyncedWithUserData ? '' : <>
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableExperience(item.rsmExpId);
                              } else {
                                EnableExperience(item.rsmExpId);
                              }
                            }}
                            className=""
                          />
                        </div>
                        {/* <span
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowExperienceUpdate(!showEducationUpdate);
                            setExperienceData(item);
                          }}
                        >
                          edit
                        </span> */}
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowExperienceUpdate(!showEducationUpdate);
                            setExperienceData(item);
                          }}
                        />
                      </>}
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowExperienceDelete(!showExperienceDelete);
                          setExperienceId(item.rsmExpId);
                          setUsrExpId(item.userExprncId)
                          setExperienceSynced(item.isSyncedWithUserData)
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowExperienceDelete(!showExperienceDelete);
                          setExperienceId(item.rsmExpId);
                          setUsrExpId(item.userExprncId)
                          setExperienceSynced(item.isSyncedWithUserData)
                        }}
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
        </div>


        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Certifications</p>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVCertification}
              />
            </div>
          </div>
          {/* <div className='analytics d-flex justify-content-between mt-3'> */}
          {/* ccvCertifications */}
          {userCcv?.resumeCertifications &&
            userCcv?.resumeCertifications?.map((item, index) => {
              // console.log('item', item);
              const isLastItem = index === userCcv?.resumeCertifications.length - 1;
              return (

                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-14 ms-1 fw-bold me-2">
                          {item.name}
                          {/* <span className="fw-normal f-12">({item.issuingOrgTypeName})</span> */}
                          {item?.isOrgVrfd === true ? (
                            <img src={resume.verified} width={18} height={18} className="ms-1" />
                          ) : item?.isOrgRgd === true ? (
                            <img src={resume.register} width={18} height={18} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={18} height={18} className="ms-1" />
                          )}

                          {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                        </p>
                        {/* <p className="f-14 ms-1  fw-bold me-2">
                        {item.name}
                      </p>
                      <p className=" f-12">
                        ({item.issuingOrgTypeName})
                      </p>
                      {item?.isOrgVrfd === true ? (
                        <img src={resume.verified} width={18} height={18} className="ms-1" />
                      ) : item?.isOrgRgd === true ? (
                        <img src={resume.register} width={18} height={18} className="ms-1" />
                      ) : (
                        <img src={resume.unregister} width={18} height={18} className="ms-1" />
                      )}

                      {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                      </div>
                      <p className="ms-1 me-1 f-14 fw-bold"> {item.orgName}</p>
                      <div className="d-flex align-items-center">
                        <p className="ms-1 me-1 f-12">{item.recognition}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          ID: {item.credentialId}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          ({moment(item.issueDate).format("YYYY")} -{" "}
                          {moment(item.expirationDate).format("YYYY")})
                        </p>
                      </div>
                    </div>
                    {/* {userData?.profileInfo?.isOwn && ( */}
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                      {item.isSyncedWithUserData ? '' : <>
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableCertification(item.rsmCertfctnId);
                              } else {
                                EnableCertification(item.rsmCertfctnId);
                              }
                            }}
                            className=""
                          />
                        </div>
                        {/* <span
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowCertificateUpdate(!showCertificateUpdate);
                            setCertificateData(item);
                          }}
                        >
                          edit
                        </span> */}
                        <EditSharpIcon className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowCertificateUpdate(!showCertificateUpdate);
                            setCertificateData(item);
                          }}
                        />
                      </>}
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowCertificationDelete(!showCertificationDelete);
                          setCertificationId(item.rsmCertfctnId);
                          setUsrCerId(item.userCrtfctnId)
                          setCertificateSynced(item.isSyncedWithUserData)
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowCertificationDelete(!showCertificationDelete);
                          setCertificationId(item.rsmCertfctnId);
                          setUsrCerId(item.userCrtfctnId)
                          setCertificateSynced(item.isSyncedWithUserData)
                        }}
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Awards</p>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAward}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAward}
              />
            </div>
          </div>
          {userCcv?.resumeAwards &&
            userCcv?.resumeAwards?.map((item, index) => {
              // console.log('item', item);
              const isLastItem = index === userCcv?.resumeAwards.length - 1;
              return (
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-14 ms-1 fw-bold me-2">
                          {item.name}
                          {/* <span className="fw-normal f-12">({item.issuerOrgTypeName})</span> */}
                          {item?.isOrgVrfd === true ? (
                            <img src={resume.verified} width={18} height={18} className="ms-1" />
                          ) : item?.isOrgRgd === true ? (
                            <img src={resume.register} width={18} height={18} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={18} height={18} className="ms-1" />
                          )}

                          {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                        </p>

                      </div>
                      <p className="ms-1 me-1 f-14 fw-bold"> {item.orgName}</p>
                      <div className="d-flex align-items-center">
                        <p className="ms-1 me-1 f-12">{item.recognition}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          {item.description}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                        <div className="dot"></div>
                        <p className="ms-2 f-12 ">
                          ({moment(item.issueDate).format("YYYY")} -{" "}
                          {moment(item.expirationDate).format("YYYY")})
                        </p>
                      </div>
                    </div>
                    {/* {userData?.profileInfo?.isOwn && ( */}
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                      {item.isSyncedWithUserData ? '' :
                        <>
                          <div className="form-switch form-check-primary ms-2">
                            <Input
                              type="switch"
                              defaultChecked={item?.status === 1}
                              name="icon-primary"
                              onClick={() => {
                                if (item?.status === 1) {
                                  DisableAwards(item.rsmAwrdId);
                                } else {
                                  EnableAwards(item.rsmAwrdId);
                                }
                              }}
                              className=""
                            />
                          </div>
                          {/* <span
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowAwardUpdate(!showAwardUpdate);
                              setAwardData(item);
                            }}
                          >
                            edit
                          </span> */}
                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowAwardUpdate(!showAwardUpdate);
                              setAwardData(item);
                            }}
                          />
                        </>}
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAwardDelete(!showAwardDelete);
                          setAwardId(item.rsmAwrdId);
                          setUsrAwrId(item.userAwardId)
                          setAwardSynced(item.isSyncedWithUserData)
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAwardDelete(!showAwardDelete);
                          setAwardId(item.rsmAwrdId);
                          setUsrAwrId(item.userAwardId)
                          setAwardSynced(item.isSyncedWithUserData)
                        }} />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Skills</p>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenAddSkill}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenAddSkill}
              />
            </div>
          </div>
          {/* <div className='analytics d-flex justify-content-between mt-3'> */}
          {userCcv?.resumeSkills &&
            userCcv?.resumeSkills?.map((item, index) => {
              console.log('ExperienceId', item);
              const isLastItem = index === userCcv?.resumeSkills.length - 1;
              return (
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                  key={index}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center verified ">
                      <p className="f-14 ms-1 pb-0 fw-bold  me-2">
                        {item.skillName}
                      </p>
                    </div>
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} className="me-2" />}
                      {item.isSyncedWithUserData ? '' :
                        // <span
                        //   className="material-symbols-sharp pointer me-2 ms-2"
                        //   onClick={() => {
                        //     setShowSkills(!showSkills);
                        //     setSkillsData(item);
                        //   }}
                        // >
                        //   edit
                        // </span>
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2 ms-2"
                          onClick={() => {
                            setShowSkills(!showSkills);
                            setSkillsData(item);
                          }}
                        />
                      }
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowSkillDelete(!showSkillDelete);
                          setSkillId(item.rsmSkllId);
                          setUsrSkllId(item?.usrSkllId)
                          setSkillSynced(item.isSyncedWithUserData)
                          setSkillDataId(item?.usrSkllId)
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowSkillDelete(!showSkillDelete);
                          setSkillId(item.rsmSkllId);
                          setUsrSkllId(item?.usrSkllId)
                          setSkillSynced(item.isSyncedWithUserData)
                          setSkillDataId(item?.usrSkllId)
                        }} />
                    </div>
                    {/* )} */}
                  </div>
                  <p className="ms-1 f-12">
                    {item.firmIndustryTypeName}
                  </p>

                </div>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2" >
            <p className="f-16 fw-bold">Attachement</p>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAttachement}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAttachement}
              />
            </div>
          </div>
          {userCcv?.resumeAttachments &&
            userCcv?.resumeAttachments?.map((item, index) => {
              // console.log('item', item);
              const isLastItem = index === userCcv?.resumeAttachments?.length - 1;
              return (
                <div
                  // className= "analytic-sub mt-2 mb-1 border-custom pb-3"
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                  key={index}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex">
                        <p className="f-14 ms-1 pb-1 fw-bold">
                          {item?.docName}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="form-switch form-check-primary ms-3">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableAttachement(item.rsmAttchmntId);
                            } else {
                              EnableAttachement(item.rsmAttchmntId);
                            }
                            // console.log('click')
                            // Experinecevisibilty(item?.status)
                          }}
                          className=""
                        />
                      </div>
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAttachementDelete(!showAttachementDelete);
                          setAttachementId(item.rsmAttchmntId);
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAttachementDelete(!showAttachementDelete);
                          setAttachementId(item.rsmAttchmntId);
                        }} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <CCVSKill
        show={showAddSkill}
        handleClose={handleCloseAddSkill}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}

      />
      <UpdateCCV
        showUpdateCCV={showUpdateCCV}
        handleCloseCCV={handleCloseCCV}
        userCcv={userCcv}
        getUserCcv={getUserCcv}
      />
      <CCVEducation
        showCCVEducation={showCCVEducation}
        handleCloseCCVEducation={handleCloseCCVEducation}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVExperience
        showCCVExperience={showCCVExperience}
        handleCloseCCVExperience={handleCloseCCVExperience}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAward
        showCCVAward={showCCVAward}
        handleCloseCCVAward={handleCloseCCVAward}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAttachement
        showCCVAttachement={showCCVAttachement}
        setShowCCVAttachement={setShowCCVAttachement}
        handleCloseCCVAttachement={handleCloseCCVAttachement}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVCertification
        showCCVCertification={showCCVCertification}
        handleCloseCCVCertification={handleCloseCCVCertification}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVRole
        showCCVRole={showCCVRole}
        handleCloseCCVRole={handleCloseCCVRole}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
        ExperienceId={ExperienceId}
      />
      <CCVAchievement
        showCCVAchivement={showCCVAchivement}
        handleCloseCCVAchivement={handleCloseCCVAchivement}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
        ExperienceId={ExperienceId}
      />

      <CCVEducationUpdate
        showEducationUpdate={showEducationUpdate}
        setShowEducationUpdate={setShowEducationUpdate}
        handleUpdateEducationClose={handleUpdateEducationClose}
        resumeId={userCcv?.resumeInfo?.resumeId}
        getUserCcv={getUserCcv}
        educationData={educationData}
      />
      <CCVExperienceUpdate
        showExperienceUpdate={showExperienceUpdate}
        handleUpdateExperienceClose={handleUpdateExperienceClose}
        getUserCcv={getUserCcv}
        ExperienceData={ExperienceData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVCertificationUpdate
        showCertificateUpdate={showCertificateUpdate}
        handleUpdateCertificateClose={handleUpdateCertificateClose}
        getUserCcv={getUserCcv}
        certificateData={certificateData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVSkillUpdate
        showSkills={showSkills}
        handleUpdateSkillClose={handleUpdateSkillClose}
        getUserCcv={getUserCcv}
        skillsData={skillsData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAwardUpdate
        showAwardUpdate={showAwardUpdate}
        handleUpdateAwardClose={handleUpdateAwardClose}
        showAwardData={showAwardData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />


      {showResumeDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Delete Resume?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveResumeClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveResumeClose();
                  }} />

              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeResume()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showExperienceDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Delete Experience?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveExperienceClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveExperienceClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeExperience()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showSkillDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Skill?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeSkill()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEducationDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Delete Education?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeEducation()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showCertificationDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Certification?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveCertificationClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveCertificationClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeCertification()}
                >
                  Delete
                </button>
              </div>

            </div>
          </div>
        </div>
      )}



      {showAwardDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>  Are You Sure You want to Delete Award?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAwardClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAwardClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeAward()}
                >
                  Delete
                </button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* Delete Attachement */}

      {showAttachementDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Attachement? </h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAttachementClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAttachementClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeAttachement()}
                >
                  Delete
                </button>
              </div>

            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default ManageResume;
