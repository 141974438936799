import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import CommentAction from "./CommentAction";
import CommentInteraction from "./CommentInteraction";
import CommentReplyInteraction from "./CommentReplyInteraction";
import CommentReplyAction from "./CommentReplyAction";
import EditComment from "./EditComment";
import axios from "../../API/axios";

const Comment = ({
  isOwnedPost,
  item,
  postId,
  postList,
  setPostList,
  emojiList,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  console.log('setPostList', postList, item);

  // State for tracking which comment is being edited
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingReplyId, setEditingReplyId] = useState(null); // State for reply editing
  // const [commentsState, setCommentsState] = useState(
  //   item?.postComments.map((comment) => ({
  //     ...comment,
  //     editedComment: comment.comment, // Store edited comment per comment
  //     postCommentsReplies: comment.postCommentsReplies?.map((reply) => ({
  //       ...reply,
  //       editedReply: reply.reply, // Store edited reply per reply
  //     })),
  //   }))
  // );

  const [commentsState, setCommentsState] = useState([]);

  // useEffect to initialize commentsState when singlepostList is available or updated
  useEffect(() => {
    if (item?.postComments) {
      setCommentsState(
        item.postComments.map((comment) => ({
          ...comment,
          editedComment: comment.comment, // Store the editable version of comment
          postCommentsReplies: comment.postCommentsReplies?.map((reply) => ({
            ...reply,
            editedReply: reply.reply, // Store the editable version of reply
          })),
        }))
      );
    }
  }, [item]); // Re-run when singlepostList changes

  const updateComment = (pstCmntId, newComment) => {
    const updatedPostList = postList.map((post) => {
      if (post.id === postId) {
        return {
          ...post,
          postComments: post.postComments.map((comment) => {
            if (comment.pstCmntId === pstCmntId) {
              return { ...comment, comment: newComment };
            }
            return comment;
          }),
        };
      }
      return post;
    });
    setPostList(updatedPostList);
  };

  const handleEdit = (commentId) => {
    setEditingCommentId(commentId); // Set the comment to be edited
  };

  const handleSave = async (commentId) => {
    const commentToEdit = commentsState.find(
      (comment) => comment.pstCmntId === commentId
    );

    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      pstCmntId: commentId,
      comment: commentToEdit.editedComment, // Use the updated comment
    };

    try {
      const res = await axios.post(`PostAction/UpdateCommentPost`, responseData);
      updateComment(commentId, commentToEdit.editedComment); // Update comment in UI
      setEditingCommentId(null); // Exit edit mode
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  const handleCommentChange = (commentId, newComment) => {
    setCommentsState(
      commentsState.map((comment) =>
        comment.pstCmntId === commentId
          ? { ...comment, editedComment: newComment }
          : comment
      )
    );
  };

  const handleReplyEdit = (replyId) => {
    setEditingReplyId(replyId);
  };

  const handleReplySave = async (commentId, replyId) => {
    const commentToEdit = commentsState.find(
      (comment) => comment.pstCmntId === commentId
    );
    const replyToEdit = commentToEdit.postCommentsReplies.find(
      (reply) => reply.pstCmntRplyId === replyId
    );

    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      pstCmntRplyId: replyId,
      reply: replyToEdit.editedReply, // Use the updated reply
    };

    try {
      await axios.post(`PostAction/UpdateCommentReply`, responseData);
      setEditingReplyId(null); // Exit reply edit mode
    } catch (error) {
      console.error("Error saving reply:", error);
    }
  };

  const handleReplyChange = (commentId, replyId, newReply) => {
    setCommentsState(
      commentsState.map((comment) =>
        comment.pstCmntId === commentId
          ? {
            ...comment,
            postCommentsReplies: comment.postCommentsReplies.map((reply) =>
              reply.pstCmntRplyId === replyId
                ? { ...reply, editedReply: newReply }
                : reply
            ),
          }
          : comment
      )
    );
  };

  return (
    <>
      <div className="comment-section mt-2">
        {commentsState &&
          commentsState.slice(0, 3).map((commentItem, index) => {
            return (
              <div key={index}>
                <div className="mainComment d-flex mb-4">
                  <div>
                    <Link to={`/User/${commentItem.useruid}`}>
                      <div>
                        <img
                          src={commentItem?.userAvatar}
                          width={40}
                          height={40}
                          alt=""
                          style={{
                            borderRadius: "50px",
                            border: "1px solid black",
                          }}
                        />
                      </div>
                    </Link>
                  </div>

                  <div className="w-100">
                    <div className="userCommentdetail ms-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="f-14 fw-bold mb-0">
                          {commentItem?.userName}
                        </h4>
                        <CommentInteraction
                          isOwnedPost={isOwnedPost}
                          item={commentItem}
                          handleSave={() => handleSave(commentItem.pstCmntId)}
                          handleEdit={() => handleEdit(commentItem.pstCmntId)}
                          isEditing={editingCommentId === commentItem.pstCmntId}
                          setPostList={setPostList}
                          postList={postList}
                          postId={postId}
                        />
                      </div>
                      <p className="f-12">
                        {moment
                          .utc(commentItem?.creationTime)
                          .local()
                          .format("DD MMMM YYYY [at] h:mm a")}
                      </p>
                      <div className="commentText mt-2">
                        {editingCommentId === commentItem.pstCmntId ? (
                          <EditComment
                            isEditing={true}
                            editedComment={commentItem.editedComment} // Pass specific edited comment
                            handleCommentChange={(newComment) =>
                              handleCommentChange(
                                commentItem.pstCmntId,
                                newComment
                              )
                            }
                            handleSave={() => handleSave(commentItem.pstCmntId)}
                            setIsEditing={() => setEditingCommentId(null)}
                          />
                        ) : (
                          <p className="f-14">{commentItem.editedComment}</p> // Render updated comment
                        )}
                      </div>
                    </div>
                    <CommentAction item={commentItem} emojiList={emojiList} postId={postId} setPostList={setPostList} />
                  </div>
                </div>

                {/* Comment Replies */}
                {commentItem.postCommentsReplies &&
                  commentItem.postCommentsReplies.slice(0, 3).map((reply, replyIndex) => (
                    <div className="mainComment d-flex mb-4 ms-5" key={replyIndex}>
                      <div>
                        <img
                          src={reply?.userAvatar}
                          width={40}
                          height={40}
                          alt=""
                          style={{
                            borderRadius: "50px",
                            border: "1px solid black",
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <div className="userCommentdetail ms-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="f-14 fw-bold mb-0">
                              {reply?.userName}
                            </h4>
                            <CommentReplyInteraction
                              isOwnedPost={isOwnedPost}
                              item={reply}
                              handleReplySave={() =>
                                handleReplySave(
                                  commentItem.pstCmntId,
                                  reply.pstCmntRplyId
                                )
                              }
                              handleReplyEdit={() =>
                                handleReplyEdit(reply.pstCmntRplyId)
                              }
                              isEditing={editingReplyId === reply.pstCmntRplyId}
                              setPostList={setPostList}
                              postList={postList}
                              postId={postId}
                              commentId={commentItem.pstCmntId}
                            />
                          </div>
                          <p className="f-12">
                            {moment
                              .utc(reply?.creationTime)
                              .local()
                              .format("DD MMMM YYYY [at] h:mm a")}
                          </p>
                          <div className="commentText mt-2">
                            {editingReplyId === reply.pstCmntRplyId ? (
                              <EditComment
                                isEditing={true}
                                editedComment={reply.editedReply} // Pass specific edited reply
                                handleCommentChange={(newReply) =>
                                  handleReplyChange(
                                    commentItem.pstCmntId,
                                    reply.pstCmntRplyId,
                                    newReply
                                  )
                                }
                                handleSave={() =>
                                  handleReplySave(
                                    commentItem.pstCmntId,
                                    reply.pstCmntRplyId
                                  )
                                }
                                setIsEditing={() => setEditingReplyId(null)}
                              />
                            ) : (
                              <p className="f-14">{reply.editedReply}</p> // Render updated reply
                            )}
                          </div>
                        </div>
                        <CommentReplyAction item={reply} emojiList={emojiList} setPostList={setPostList} postId={postId} />
                      </div>
                    </div>
                  ))}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Comment;
