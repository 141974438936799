

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CreateCompany from './CreateCompany/CreateCompany';


const CompanyWarning = ({ closewarningmodal }) => {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <div
                className="modal modal-md fade show postAdd"
                tabIndex="-1"
                role="dialog"
                style={{ display: "block" }}
            >
                <div className="modal-dialog " role="document">
                    <div className="modal-content d-flex justify-content-around">
                        <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                            <h6 className='mb-0'>Company Creation Required</h6>
                            {/* <span
                className="material-symbols-sharp me-2 pointer"
                onClick={closeModalData}
              >
                close
              </span> */}
                            <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                                onClick={closewarningmodal}
                            />
                        </div>

                        <div
                            className="card-body d-flex flex-wrap justify-content-center p-3 f-14"
                            id="container"
                        >
                            <div>
                                <p>You must create a company page before posting a job. Please complete your company profile to proceed.</p>

                                <button
                                    type="button"
                                    className="mainBtn1 mt-3 createCompBtn  align-items-center"
                                    onClick={(e) => {
                                        openModal();
                                        // closewarningmodal()
                                    }}
                                >
                                    Create Company
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <CreateCompany
                    openModal={openModal}
                    showModal={showModal}
                    closeModal={closeModal}
                    getCompanyList={''}
                />
            )}
        </>

    )
}

export default CompanyWarning