import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import useLocalStorage from 'use-local-storage'
import Footer from './Navigation/Footer'
import Header from './Navigation/Header'
import LeftNavigation from './Navigation/LeftNavigation'
import RightNavigation from './Navigation/RightNavigation'
import useWindowSize from './useWindowSize';
import GuestModeFooter from './GuestModeFooter'
import CreateCompany from './CreateCompany/CreateCompany'
const RootLayout = () => {
    const [isDark, setIsDark] = useLocalStorage('isDark', false)
    useEffect(() => {
        document.body.style.backgroundColor = isDark ? '#1a1a1e' : '#F2F2F2';
        // if (isDark) {
        //     import("flatpickr/dist/themes/dark.css");
        // } else {
        //     import("flatpickr/dist/themes/light.css");
        // }
    }, [isDark]);
    const [showModal, setShowModal] = useState(false);


    const openModal = () => {
        setShowModal(true)
        // console.log("Menu is toggling...");
    };


    const closeModal = () => {
        setShowModal(false);
    };
    const size = useWindowSize();
    const isMobile = size.width <= 768;
    const guestModeData = localStorage.getItem("guestMode");
    // console.log('guestModeData', guestModeData);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen((prevState) => !prevState);
        // console.log("Menu is toggling...");
    };
    return (
        <div className='app'>
            <Header isDark={isDark} setIsDark={setIsDark} />
            <div className="container-fluid ps-0 ">
                {isMobile ? (
                    <>
                        <div className="row rootLayout mobileView">
                            <div className="col-md-12">
                                {/* <div onClick={openModal}>asdsadasdasd</div> */}
                                <Outlet />
                            </div>
                        </div>
                        <Footer />
                    </>
                ) : (
                    <div className="row rootLayout desktopView">
                        <div className="col-md-3 left ">
                            <LeftNavigation isOpen={isOpen} toggleMenu={toggleMenu} setIsOpen={setIsOpen}

                                openModal={openModal} />
                        </div>
                        <div className="col-md-6 center ps-0 pe-0">
                            <Outlet />
                        </div>
                        <div className="col-md-3 right ps-3">
                            <RightNavigation />
                        </div>
                    </div>
                )}
            </div>

            {guestModeData === 'true' ? <GuestModeFooter /> : ''}
            {/* {console.warn("===========1=========>>", showModal)} */}
            {showModal && (
                <CreateCompany
                    openModal={openModal}
                    showModal={showModal}
                    closeModal={closeModal}
                    getCompanyList={''}
                />
            )}

        </div>
    )
}

export default RootLayout