import React, { useEffect, useRef, useState } from "react";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import ReactQuill from "react-quill";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddAPhotoSharpIcon from '@mui/icons-material/AddAPhotoSharp';

const PostUpdate = ({
  // showModal,
  openModal,
  closeModal,
  item,
  PostOriginType,
  postOriginId,
  postId,
  setShowModal,
  setPostList,
  AllPostList
}) => {
  const [postDataItem, setPostDataItem] = useState('')
  const profilPic = localStorage.getItem("profilPic");
  const [postContent, setPostContent] = useState('');
  const GetPostData = async () => {
    try {
      await axios
        .get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`)
        .then((resData) => {
          console.log('postDataItem', resData);
          setPostContent(resData.data.responseData.postContent)
          setPostDataItem(resData.data.responseData);
          setUserSelectedImage(resData.data.responseData.mediaFilesList || []); // Initialize the state with the media files list
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    GetPostData()
  }, [])

  const [removedImageIds, setRemovedImageIds] = useState([]); // To track removed image IDs
  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem("userUid");
  const inputFileRef = useRef(null);
  let mediaFilesImage = postDataItem?.mediaFilesList;
  const [postData, setPostData] = useState("");
  const [privacyType, setPrivacyType] = useState(null);
  const [imageNames, setImageNames] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  // const [postContent, setPostContent] = useState(postDataItem?.postContent);
  const [images, setImages] = useState([]);
  const [showPost, setShowPost] = useState(false);
  const [display, setDisplay] = useState(false);
  const [postID, setPostID] = useState("");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);



  const [isloading, setIsloading] = useState(false);
  const determinePostCategory = (postContent, newImages, existingImages) => {
    const containsVideo = (names) => {
      const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv", ".wmv"];
      return names.some((name) => videoExtensions.includes(name?.slice(name.lastIndexOf(".")).toLowerCase()));
    };

    const hasPostContent = postContent !== null && postContent.trim() !== '';
    const allImages = [...newImages, ...existingImages];
    const imageNames = allImages.map((image) => image.name || image.mediaName);

    const hasImages = imageNames.length > 0 && !containsVideo(imageNames);
    const hasVideos = imageNames.length > 0 && containsVideo(imageNames);

    if ((hasPostContent && hasImages) || (hasPostContent && hasVideos) || (hasImages && hasVideos)) {
      return "UserStory";
    } else if (hasPostContent) {
      return "Text";
    } else if (hasImages) {
      return "Image";
    } else if (hasVideos) {
      return "Video";
    } else {
      return "UserStory";
    }
  };

  const handleSubmit = async () => {
    setIsloading(true)
    try {
      const formData = new FormData();
      formData.append("PostId", postId);
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append("PrivacyType", privacyType === null ? "Public" : privacyType);
      formData.append("PostOriginType", PostOriginType);

      // Check both new images and existing images
      const newImages = images.map((image) => image.name);
      const existingImages = userSelectedImage.map((image) => image.mediaName);
      const postCategory = determinePostCategory(postContent, newImages, existingImages);

      formData.append("PostCategory", postCategory);
      formData.append("PostContent", postContent || '');

      // Add only images that need to be deleted
      removedImageIds.forEach((id) => {
        formData.append("OldFilesToDelete", id);
      });

      // Add only new images to the API call
      images.forEach((file) => {
        formData.append(`FilesToUpload`, file.file);
      });

      formData.append("PostOriginID", postOriginId);

      const res = await axios.post("/Posts/UpdatePost", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setTimeout(() => {
        closeModal();
      }, 300);
      // closeModal();
      setPostContent("");
      AllPostList(1); // Refresh post list
      // const updatedPost = res.data.responseData;
      // setPostList((prev) =>
      //   prev.map((post) => (post.postId === updatedPost.postId ? updatedPost : post))
      // );
      const newPostId = postId;
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${newPostId}`);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);
      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
      setToastMessage(res.data.message);
      setShowToast(true);
      setIsloading(false)

    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
      setIsloading(false)
    }
  };


  const imageSelect = (event) => {
    console.log("image selected", event);
    const selectedFiles = event.target.files;
    console.log("selectedFiles", selectedFiles);
    const newImages = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const imageName = selectedFiles[i].name;
      if (!checkDuplicate(imageName)) {
        const imageUrl = URL.createObjectURL(selectedFiles[i]);
        newImages.push({
          name: imageName,
          url: imageUrl,
          file: selectedFiles[i],
        });
      } else {
        alert(`${imageName} is already added to the list`);
      }
    }

    setImages((prevImages) => [...prevImages, ...newImages]);
    event.target.value = null; // Reset file input
    console.log("image selected1", images);
  };

  const checkDuplicate = (name) => {
    return images.some((image) => image.name === name);
  };

  const [userSelectedImage, setUserSelectedImage] = useState({});
  console.log('image index', userSelectedImage);
  const userImages = (index) => {
    const removedImage = userSelectedImage[index];
    setRemovedImageIds((prevIds) => [...prevIds, removedImage.pstMid]); // Add removed image ID to the state
    setUserSelectedImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setUserSelectedImage(postDataItem?.mediaFilesList); // Initialize the state with the media files list
    console.log("useEffect is run");
  }, [postDataItem?.mediaFilesList]);

  console.log("userSelectedImage", userSelectedImage, images);

  const handleShowPost = () => {
    setShowPost(true);
  };

  const handleClosePost = () => {
    setShowPost(false);
  };
  const mediaFiles = postDataItem?.mediaFilesList?.map((item) => item?.pstMid);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const fileNames = files.map((file) => file.name);
    setImageNames(fileNames.join(", "));
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageNames((prevNames) => {
      const namesArray = prevNames.split(", ");
      namesArray.splice(index, 1);
      return namesArray.join(", ");
    });
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className="image_container d-flex justify-content-center position-relative"
      >
        <img src={image.url} alt={image.name} />
        <span className="position-absolute" onClick={() => deleteImage(index)}>
          &times;
        </span>
      </div>
    ));
  };

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const Privacy = [
    { value: "Public", label: "Public" },
    { value: "Friends", label: "Friends" },
    { value: "OnlyMe", label: "OnlyMe" },
    { value: "Custom", label: "Custom" },
  ];

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handlePaste = (e) => {
    const isCtrlV = e.ctrlKey && e.key === 'v'; // Check if Ctrl+V was pressed

    if (isCtrlV) {
      e.preventDefault(); // Prevent the default paste action
      alert('Please use Ctrl + Shift + V to paste.'); // Show alert
    }
  };

  return (
    <>
      {openModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center">
                <h6>Update Post</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>
              {/* <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={profilPic} alt="" width={50} style={{ borderRadius: '50%' }} />
                </div>
                <p className="f-16 AddPost ms-2">What's on your mind?</p>
              </div> */}
              <div className="m-3">
                {/* <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="Update your post here"
                  name="about"
                  defaultValue={postDataItem?.postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  rows="4"
                  cols="65"
                ></textarea> */}
                <ReactQuill
                  value={postContent}
                  onChange={setPostContent}
                  onKeyDown={handlePaste} // Use onKeyDown to detect key presses
                  placeholder="Update Post Here"
                  // modules={{
                  //   toolbar: [
                  //     [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  //     [{ size: [] }],
                  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  //     ['link'],
                  //     ['clean']
                  //   ]
                  // }}
                  modules={{
                    toolbar: false, // Hide the toolbar
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link'
                  ]}
                />
              </div>

              <div className="modal-border d-flex justify-content-center align-items-center">
                <input
                  id="fileInput"
                  type="file"
                  onChange={(event) => { imageSelect(event) }}
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  multiple
                />

                {images.length > 0 && (
                  <div className="post-selected d-flex justify-content-center flex-wrap">
                    {images.map((image, index) => (
                      <div key={index} className="position-relative m-2">
                        <img
                          src={image.url}
                          alt={`Selected Image ${index}`}
                          style={{
                            maxWidth: "200px",
                            maxHeight: "300px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          onClick={() => removeImage(index)}
                          className="btn btn-danger position-absolute top-0 end-0"
                          style={{
                            transform: "translate(50%, -50%)",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {userSelectedImage?.length > 0 && (
                <div className="post-selected hhh d-flex justify-content-center flex-wrap">
                  {userSelectedImage?.map((imageUrl, index) => (
                    <div key={index} className="position-relative m-2">
                      <img
                        src={imageUrl.mediaName}
                        alt={`Selected Image ${index}`}
                        style={{
                          maxWidth: "200px",
                          maxHeight: "300px",
                          objectFit: "cover",
                        }}
                      />
                      <button
                        onClick={() => userImages(index)}
                        className="btn btn-danger position-absolute top-0 end-0"
                        style={{
                          transform: "translate(50%, -50%)",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="m-3 mb-1 d-flex align-items-center">
                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={(event) => { imageSelect(event) }}
                    ref={inputFileRef}
                  />
                  <AddAPhotoSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                    onClick={() => inputFileRef.current.click()} // Trigger the file input click
                  />
                </form>
                <button className="mainBtn ms-auto" disabled={isloading} onClick={handleSubmit}>
                  {isloading === true ? 'Updating..' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default PostUpdate;
