import React from 'react'
import ReactSelect from 'react-select';

const Auth = () => {
  const options = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Thane", label: "Thane" },
    { value: "Pune", label: "Pune" },
  ];
  return (
    <>
      <div className='setting-header'>
        <h4>2F Auth</h4>
      </div>
      <div className='p-3 privacy'>
        <div>
          <p className='sessionName'>Two-factor authentication method </p>
          <ReactSelect
            className="custom-select"
            styles={{
              // control: (provided) => ({
              //     ...provided,
              //     width: 170, 
              // }),
              menu: (provided) => ({
                ...provided,
                // width: 300,

              }),
            }}
            options={options}
            placeholder="Address Type"
          />
        </div>
        <div className='confirmationTag'>
          <p>We have sent you an email with the confirmation code.</p>
        </div>
        <div className='text-end'>
          <button type='submit' className='mainBtn1 mt-3 text-center'>Update</button>
        </div>
      </div>
    </>
  )
}

export default Auth