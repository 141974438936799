import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects';
import axios from '../../API/axios';
import { post } from '../../assets/Images/ImagesLink';
// import { NewsFeed, post } from '../../assets/Images/ImagesLink';
// import PostComment from '../NewsFeed/PostComment';
// import PostEngagement from '../NewsFeed/PostEngagement';
// import PostInteractionModal from '../NewsFeed/PostInteractionModal';
import DraftInteraction from './DraftInteraction';
import { icons, JobSearch } from '../../assets/Images/ImagesLink';
// import JobAction from '../JobSearch/JobAction';
import JobDraftInteraction from './JobDraftInteraction';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ShimmerDiv } from 'shimmer-effects-react';
import PostComponent from '../NewsFeed/PostComponent';
const Draft = () => {
    const sessionId = localStorage.getItem("sessionId");
    const [activeTab, setActiveTab] = useState(1);

    //************************ Draft Job Start  ************************//
    const [draftJob, setDraftJob] = useState([]);
    const [isLoadingJob, setIsLoadingJob] = useState(false);

    const [isPostDraftError, setIsPostDraftError] = useState(false);
    console.log('draftJob', draftJob);
    const DraftJob = async () => {
        const Data = {
            "deviceType": "Web",
            "sessionId": sessionId,
        }
        try {
            setIsLoadingJob(true)
            await axios.post(`/Job/GetAllDraftJobs`, Data).then((resData) => {
                console.log('resData', resData);
                if (resData?.status === 204) {
                    setIsError(true)
                } else {
                    setDraftJob(resData.data.responseData)
                }
                setIsLoadingJob(false);
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.log(error);
            }
            setIsLoadingJob(false);
        }
    };


    useEffect(() => {
        DraftJob()
    }, []);

    //************************ Draft Job End  ************************//

    //************************ Draft Post Start  ************************//

    const [draftPost, setDraftPost] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const DraftPost = async () => {
        const Data = {
            "deviceType": "Web",
            "sessionId": sessionId
        }
        try {
            setIsLoading(true);
            await axios.post(`/Posts/GetDraftPosts`, Data).then((resData) => {
                setIsLoading(false);
                // console.log('resData', resData.data.responseData);
                const data = resData.data.responseData.reverse()
                setDraftPost(data)
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsPostDraftError(true);
            } else {
                console.log(error);
            }
            console.log(error);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        DraftPost()
    }, []);
    //************************ Draft Post End  ************************//
    const addTargetBlankToAnchors = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        const anchors = div.querySelectorAll('a');
        anchors.forEach(anchor => {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
        });

        return div.innerHTML;
    };

    const linkifyContent = (htmlContent) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return htmlContent.replace(urlPattern, function (url) {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };


    const renderTabContent = () => {
        switch (activeTab) {
            case 1:
                return (
                    <>
                        {isLoading ? (
                            <div className="mt-3">
                                <ShimmerDiv mode="light" height={200} width={'100%'} />
                                <ShimmerDiv mode="light" height={200} width={'100%'} />
                                <ShimmerDiv mode="light" height={200} width={'100%'} />
                                {/* <ShimmerThumbnail height={250} rounded width={"50px"} />
                                <ShimmerThumbnail height={250} rounded width={"50px"} />
                                <ShimmerThumbnail height={250} rounded width={"50px"} /> */}
                            </div>
                        )
                            : isPostDraftError || draftPost.length === 0 ? (
                                <div className="mt-2 error-image-cont error-connection">
                                    <img src={post.errorSmall} alt="404 Not Found" />
                                </div>
                            ) : (
                                draftPost &&
                                draftPost
                                    .filter((item) => !item.isDeleted)
                                    ?.map((item, index) => {
                                        console.log('draftPost', item);
                                        return (
                                            <div
                                                className="featured-card user-postOnlyContent draftPost p-2 mb-3 m-auto"
                                                key={index}

                                            >
                                                <div className="feature-content p-2 d-flex justify-content-between">
                                                    <Link to={`/User/${item.userUid}`}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="ImgAvatar">
                                                                <img src={item?.userAvatar} alt="" style={{ borderRadius: '50px' }} />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h4 className="f-14 fw-bold mb-0">{item.userName}</h4>
                                                                <p className="f-12">
                                                                    {/* {moment(item.creationTime).format("MMMM Do YYYY, h:mm:ss a")} */}
                                                                    {moment
                                                                        .utc(item.creationTime)
                                                                        .local()
                                                                        .format("DD MMMM YYYY [at] h:mm a")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    {/* <div className="d-flex align-items-center">
                                                        <div className="ImgAvatar">
                                                            <img src={NewsFeed.companyLogo} alt="" />
                                                        </div>
                                                        <div className="ms-2">
                                                            <h4 className="f-14 fw-bold mb-0">Ahmad Nur Fawaid</h4>
                                                            <p className="f-12">12 April at 09.29 PM</p>
                                                        </div>
                                                    </div> */}
                                                    <DraftInteraction
                                                        postId={item?.postId}
                                                        DraftPost={DraftPost}
                                                    // AllPostList={AllPostList}
                                                    // postList={postList}
                                                    // setPage={setPage}
                                                    // setPostList={setPostList}
                                                    // page={page}
                                                    // setTotalPages={setTotalPages}
                                                    // totalPages={totalPages}
                                                    />
                                                </div>
                                                {/* <p className="f-14 p-2">{item?.postContent}</p> */}
                                                {/* {item?.postContent && (
                                                    <div className="f-14 p-2 postContent">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: linkifyContent(
                                                                    addTargetBlankToAnchors(item?.postContent)
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                )} */}
                                                <PostComponent item={item} />

                                                <div className="feed-post p-2">
                                                    {/* <div className="row">
                                                        {item.mediaFilesList.slice(0, 4).map((file, idx) => (
                                                            <div
                                                                key={idx}
                                                                className={`col-${item.postMediaCount === 1 ? "12" : "6"
                                                                    } ${item.postMediaCount === 3 && idx === 0
                                                                        ? "col-12"
                                                                        : ""
                                                                    }`}
                                                                style={{
                                                                    position: "relative",
                                                                    padding:
                                                                        item.postMediaCount === 3 && idx === 0
                                                                            ? "0 0 5px 0"
                                                                            : item.postMediaCount === 3 && idx === 1
                                                                                ? "0 5px 5px 0"
                                                                                : item.postMediaCount === 4 && idx === 2
                                                                                    ? "0 5px 0 0"
                                                                                    : item.postMediaCount === 4 && idx === 0
                                                                                        ? "0 5px 5px 0"
                                                                                        : item.postMediaCount === 2 && idx === 0
                                                                                            ? "0 5px 5px 0"
                                                                                            : "",
                                                                }}
                                                            >
                                                                <img
                                                                    src={file.mediaName}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    width={"100%"}
                                                                />
                                                                {idx === 3 && item.postMediaCount > 4 && (
                                                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                                                                        +{item.postMediaCount - 4}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div> */}
                                                    <div className="row">
                                                        {item.mediaFilesList.slice(0, Math.min(item.postMediaCount, 4)).map((file, idx) => (
                                                            <div
                                                                key={idx}
                                                                className={`col-${item.postMediaCount === 1 ? "12" : "6"} 
        ${item.postMediaCount === 3 && idx === 0 ? "col-12" : ""}`}
                                                                style={{
                                                                    position: "relative",
                                                                    padding:
                                                                        item.postMediaCount === 3 && idx === 0
                                                                            ? "0 0 5px 0"
                                                                            : item.postMediaCount === 3 && idx === 1
                                                                                ? "0 5px 5px 0"
                                                                                : item.postMediaCount === 4 && idx === 2
                                                                                    ? "0 5px 0 0"
                                                                                    : item.postMediaCount === 4 && idx === 0
                                                                                        ? "0 5px 5px 0"
                                                                                        : item.postMediaCount === 2 && idx === 0
                                                                                            ? "0 5px 5px 0"
                                                                                            : "",
                                                                }}
                                                            >
                                                                <img
                                                                    src={file.mediaName}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    width={"100%"}
                                                                />
                                                                {idx === 3 && item.postMediaCount > 4 && (
                                                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                                                                        +{item.postMediaCount - 4}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>


                                                </div>
                                                {/* <div className="postengagement">
                                                <PostEngagement
                                                    item={item}
                                                    postId={item?.postId}
                                                // AllPostList={AllPostList}
                                                // postList={postList}
                                                // setPage={setPage}
                                                // setPostList={setPostList}
                                                // page={page}
                                                // setTotalPages={setTotalPages}
                                                // totalPages={totalPages}

                                                />
                                            </div> */}

                                            </div>
                                        );
                                    })
                            )}
                    </>
                );
            case 2:
                return (

                    <>
                        {/* <div className="row"> */}
                        {isLoadingJob ? (
                            <div className="mt-3">
                                <ShimmerThumbnail height={250} rounded width={"50px"} />
                            </div>
                        ) : isError || draftJob?.length === 0 ? (
                            <div className="mt-2 error-image-cont error-connection">
                                <img src={post.errorSmall} alt="404 Not Found" />
                            </div>

                        ) : (
                            draftJob &&
                            draftJob
                                .filter((item) => !item.isDeleted)
                                ?.map((item, index) => {
                                    {
                                        //  console.log('item', item)
                                    }
                                    const now = moment();
                                    const itemTime = moment.utc(item?.creationTime);
                                    const duration = moment.duration(now.diff(itemTime));

                                    const years = duration.years();
                                    const months = duration.months();
                                    const days = duration.days();
                                    const hours = duration.hours();
                                    const minutes = duration.minutes();

                                    let result = "";

                                    if (years > 0) {
                                        result = `${years} year${years > 1 ? "s" : ""} ago`;
                                    } else if (months > 0) {
                                        result = `${months} month${months > 1 ? "s" : ""} ago`;
                                    } else if (days > 0) {
                                        result = `${days} day${days > 1 ? "s" : ""} ago`;
                                    } else if (hours > 0) {
                                        result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
                                    } else if (minutes > 0) {
                                        result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
                                    } else {
                                        result = "just now";
                                    }
                                    return (

                                        <div className="col-md-6 mt-2 d-flex align-items-stretch">
                                            <div className="jobCard p-3 w-100 d-flex flex-column">
                                                <div className="d-flex justify-content-start align-items-center" style={{ height: "60px" }}>
                                                    <Link to={`/Company/${item.compUid}`}>
                                                        <div className="pointer me-2">
                                                            <img
                                                                src={item?.compLogo}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                style={{ borderRadius: "50px" }}
                                                            />
                                                        </div>
                                                    </Link>
                                                    <Link to={`/Company/${item.compUid}`}>
                                                        <span className="f-14 f-700 job-company-name">
                                                            {item.compName}
                                                        </span>
                                                    </Link>
                                                    <div className="ml-auto">
                                                        <JobDraftInteraction
                                                            DraftJob={DraftJob}
                                                            jobId={item?.jobId}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center mt-2 emoji-container">
                                                    <Link to={`/JobDetail/${item.jobId}`}>
                                                        <p className="f-16 fw-bold pointer">
                                                            {/* {item.title.length > 10
                                                                    ? `${item.title.substring(0, 10)}...`
                                                                    : item.title} */}
                                                            {item.title}
                                                        </p>
                                                    </Link>


                                                </div>

                                                <p className="f-14">
                                                    {/* {item.shortDescription.length > 15
                                                            ? `${item.shortDescription.substring(0, 15)}...`
                                                            : item.shortDescription} */}
                                                    {item.shortDescription}
                                                </p>
                                                <h6 className="f-10 mt-1">{item.cityName}</h6>

                                                <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                                                    <small className="f-8">Posted {result}</small>
                                                    <div className="badge badge-date">{item?.expiringInDays} days Left</div>

                                                </div>

                                                <div className="jobType mt-2 mb-3">
                                                    <div className="badge badge-job me-3 mb-1">
                                                        {item.jbTypName}
                                                    </div>
                                                    <div className="badge badge-job me-3 mb-1">
                                                        {item.workLocationTypeName}
                                                    </div>
                                                    <div className="badge badge-job mb-1">
                                                        {item.designationName}
                                                    </div>
                                                </div>
                                                <div className="jobAction d-flex justify-content-between mt-3">
                                                    {/* <button className='mainBtn'>Apply Now</button> */}
                                                    {/* <button className='mainBtn4'>View</button> */}
                                                    <Link to={`/JobDetail/${item.jobId}`}>
                                                        <button
                                                            className="mainBtn4"
                                                            to={`/JobDetail/${item.jobId}`}
                                                        >
                                                            View
                                                        </button>
                                                    </Link>
                                                </div>
                                                {/* <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={getAllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} /> */}
                                            </div>


                                        </div>

                                    )
                                }))}
                        {/* </div> */}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                <div className="friendsHeaderText p-2 pt-0 pb-0 mt-4 ">
                    <h1>Drafts</h1>
                </div>
                <div className="tabButton d-flex p-2 w-100 connectionTab">
                    <NavLink
                        onClick={() => setActiveTab(1)}
                        className={`text-center ${activeTab === 1 ? "activeTab" : ""}`}
                    >
                        <p className="f-14 tabName">Post Drafts</p>
                    </NavLink>
                    <NavLink
                        onClick={() => setActiveTab(2)}
                        className={`text-center ${activeTab === 2 ? "activeTab" : ""}`}
                    >
                        <p className="f-14 tabName">Job Drafts</p>
                    </NavLink>
                </div>

                <div className="resume-section">{renderTabContent()}</div>
            </div>
        </>
    )
}

export default Draft