import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { NewsFeed, resume } from "../../assets/Images/ImagesLink";
import AddSkill from "./AddSkill";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Connection from "./Connection";
import { Link } from "react-router-dom";
import UpdateProfile from "./UpdateProfile";
import ProfileEducation from "./ProfileEducation";
import ProfileExperience from "./ProfileExperience";
import ProfileCertification from "./ProfileCertification";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import ProfileAward from "./ProfileAward";
import { Input } from "reactstrap";
import ProfileEducationUpdate from "./ProfileEducationUpdate";
import ProfileExperienceUpdate from "./ProfileExperienceUpdate";
import ProfileCertificateUpdate from "./ProfileCertificateUpdate";
import ProfileAwardUpdate from "./ProfileAwardUpdate";
import AddSkillModal from "./AddSkill";
import UpdateSkills from "./UpdateSkills";
import ProfileNegativeActions from "./ProfileNegativeActions";
import Skills from "./Skills";
import Flatpickr from 'react-flatpickr';
import ReactSelect from "react-select";
import Select from "react-select";
import ProfileAchiementExperience from "./ProfileAchiementExperience";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const About = ({ uid, userData, getUserProfile }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      className: state.isFocused
        ? "react-select__option--is-focused"
        : state.isSelected
          ? "react-select__option--is-selected"
          : "",
    }),
  };
  // console.log("userData.viewEducationInfo", userData.viewEducationInfo);
  console.log("uid", uid);
  const guestModeData = localStorage.getItem("guestMode");

  const [showAddSkill, setShowAddSkill] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  console.log("userData=====>", userData);
  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  // const [userSkillId, setUserSkillId] = useState("");
  const [comment, setComment] = useState("");

  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);

  const userUid = localStorage.getItem("userUid");
  const sessionId = localStorage.getItem("sessionId");
  //************************User Skill Get Start  ************************//
  const [skillList, setSkillList] = useState([]);
  // console.log('skillList', skillList);
  const getUserSkill = () => {
    axios
      .post(`UserSkill/GetUserLinkedSkills?DeviceType=Web&UID=${uid}`)
      .then((response) => {
        const res = response.data.responseData;
        setSkillList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserSkill();
  }, [userData]);

  //************************User Skill Get End  ************************//

  //************************Remove Skill start  ************************//
  const [show, setShow] = useState(false);
  const [showMoreSkills, setShowMoreSkills] = useState(false);
  const handleRemoveSkillClose = () => {
    setShow(false);
  };
  const [skillId, setSkillId] = useState("");

  const [skillsData, setSkillsData] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const handleUpdateSkillClose = () => {
    setShowSkills(false);
  };

  const removeSkills = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      // skllId: skillId
    };
    try {
      await axios.post(`UserSkill/UnlinkSkill`, responseData).then((res) => {
        setShow(!show);
        // toast.success("Skill Deleted");
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserSkill();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Skill End  ************************//

  //************************Remove Certificate End  ************************//

  const [showCertificateDelete, setShowCertificateDelete] = useState(false);
  const handleRemoveCertificateClose = () => {
    setShowCertificateDelete(false);
  };

  const [showCertificateUpdate, setShowCertificateUpdate] = useState(false);
  const handleUpdateCertificateClose = () => {
    setShowCertificateUpdate(false);
  };
  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState([]);
  const removeCertificate = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: certificateId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteCertificationInfo`, { data: responseData })
        .then((res) => {
          setShowCertificateDelete(!showCertificateDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Certificate End  ************************//

  //************************Remove Education Start  ************************//

  const [showEducationDelete, setShowEducationDelete] = useState(false);
  const handleRemoveEducationClose = () => {
    setShowEducationDelete(false);
  };

  const [showEducationUpdate, setShowEducationUpdate] = useState(false);
  const handleUpdateEducationClose = () => {
    setShowEducationUpdate(false);
  };

  const [EducationId, setEducationId] = useState("");
  const removeEducation = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: EducationId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteEducationInfo`, { data: responseData })
        .then((res) => {
          setShowEducationDelete(!showEducationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Education End  ************************//

  //************************Remove Education Start  ************************//

  const [showAwardUpdate, setShowAwardUpdate] = useState(false);
  const [showAwardData, setAwardData] = useState([]);
  const handleUpdateAwardClose = () => {
    setShowAwardUpdate(false);
  };

  const [showAwardDelete, setShowAwardDelete] = useState(false);
  const handleRemoveAwardClose = () => {
    setShowAwardDelete(false);
  };
  const [AwardId, setAwardId] = useState("");
  const removeAward = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: AwardId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteAwardInfo`, { data: responseData })
        .then((res) => {
          setShowAwardDelete(!showAwardDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Education End  ************************//

  //************************Remove Certification Start  ************************//

  const [showExperienceDelete, setShowExperienceDelete] = useState(false);
  const handleRemoveExperienceClose = () => {
    setShowExperienceDelete(false);
  };

  const [showExperienceUpdate, setShowExperienceUpdate] = useState(false);
  const handleUpdateExperienceClose = () => {
    setShowExperienceUpdate(false);
    setExperienceData([])
  };
  const [ExperienceId, setExperienceId] = useState("");
  const [ExperienceData, setExperienceData] = useState([]);
  const removeExperience = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: ExperienceId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteWorkExperienceInfo`, { data: responseData })
        .then((res) => {
          setShowExperienceDelete(!showExperienceDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  console.log("ExperienceData", ExperienceData);

  //************************Remove Certificat End  ************************//

  //************************Endorse User  ************************//
  const [showEndorseModal, setShowEndorseModal] = useState(false);
  const handleClose = () => {
    setShowEndorseModal(false);
  };
  const EndorseData = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      feedback: comment,
    };
    // console.log("user feedback data", data);
    if (comment === "") {
      setWarningToastMessage("Please Add Feedback");
      setShowWarningToast(true);
    } else {
      try {
        await axios.post("UserSkill/EndorseSkill", data).then((resData) => {
          // console.log("resData", resData);
          setComment("");
          setShowEndorseModal(false);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserSkill();
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };
  //************************Endorse User  ************************//

  //************************Undo Endorse User  ************************//
  const [showUndoEndorseModal, setShowUndoEndorseModal] = useState(false);
  const handleUndoEndorse = () => {
    setShowUndoEndorseModal(false);
  };
  const UndoEndorseData = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      feedback: comment,
    };
    // console.log("user feedback data", data);
    if (comment === "") {
      setWarningToastMessage("Please Add Feedback");
      setShowWarningToast(true);
    } else {
      try {
        await axios.post("UserSkill/UndoEndorseSkill", data).then((resData) => {
          // console.log("resData", resData);
          setComment("");
          setShowUndoEndorseModal(false);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserSkill();
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };
  //************************Endorse User  ************************//

  //************************Followed User  ************************//
  const [isfollowed, setIsfollowed] = useState(false);

  const FollowedUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        // console.log("resData", resData);
        // toast.success(resData.data.message);
        // getUserProfileList();
        setIsfollowed(true);
        setToastMessage(resData.data.message);
        setShowToast(true);
        getUserProfile();
        // CheckFollowedUser();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  console.log("isfollowed", isfollowed);
  //************************Followed User  ************************//

  //************************Check Followed User  ************************//
  // const [checkFollowedUser, setCheckFollowedUser] = useState("");
  // // api removed from backend
  // const CheckFollowedUser = async (id) => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //     userUID: uid,
  //   };
  //   try {
  //     await axios.post("CheckConnection/CheckFollowing", data).then((resData) => {
  //       // console.log("checkresData", resData.data);
  //       setCheckFollowedUser(resData.data.status);
  //     });
  //   } catch (error) {
  //     if (error.response && error.response.status === 404) {
  //       setWarningToastMessage(error.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(error.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   CheckFollowedUser();
  // }, []);

  //************************Check Followed User  ************************//

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  //************************ Connect User Start  ************************//

  const ConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios
        .post("Connection/SendConnectionRequest", data)
        .then((resData) => {
          // console.log("resData", resData.data.message);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          // CheckFollowedUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Connect User  End  ************************//

  //************************Check Connect User Start  ************************//
  const [connectionCheck, setConnectionCheck] = useState("");
  console.log('connectionCheck', connectionCheck);

  const [connectionId, setConnectionId] = useState("");
  // api removed from backend
  const CheckConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios.post("Connection/CheckConnection", data).then((resData) => {
        console.log("checkFollowedUser", resData.data);
        if (resData.data.status === "Warning") {
          setConnectionCheck("Connect");
        } else {
          setConnectionCheck(resData.data.responseData.value);
          setConnectionId(resData.data.responseData.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //************************ Connect User  End  ************************//

  const [educationData, setEducationData] = useState([]);
  const [showUSEREducation, setShowUSEREducation] = useState(false);

  const handleOpenUSEREducation = () => setShowUSEREducation(true);
  const handleCloseUSEREducation = () => setShowUSEREducation(false);

  const [showUserExperience, setShowUserExperience] = useState(false);
  const [showAchievementUserExperience, setshowAchievementUserExperience] = useState(false);

  const handleOpenUserExperience = () => setShowUserExperience(true);
  const handleCloseUserExperience = () => setShowUserExperience(false);

  const handleAchievementUserExperience = () => setshowAchievementUserExperience(true);
  const handleAchievementCloseUserExperience = () => setshowAchievementUserExperience(false);



  const [showUserCertification, setShowUserCertification] = useState(false);

  const handleOpenUserCertification = () => setShowUserCertification(true);
  const handleCloseUserCertification = () => setShowUserCertification(false);

  const [showUserAward, setShowUserAward] = useState(false);

  const handleOpenUserAward = () => setShowUserAward(true);
  const handleCloseUserAward = () => setShowUserAward(false);

  //************************Enable Certification  ************************//
  const EnableExperience = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/EnableWorkExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableExperience = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/DisableWorkExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Education  ************************//
  const EnableEducation = async (Id) => {
    console.log("hhsjhsjds");
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/EnableEducationInfo`, responseData)
        .then((res) => {
          getUserProfile();
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Education End  ************************//
  //************************Disable Education  ************************//
  const DisableEducation = async (Id) => {
    console.log("hhsjhsjds");
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/DisableEducationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Education End  ************************//

  //************************Enable Certification  ************************//
  const EnableCertification = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/EnableCertificationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableCertification = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/DisableCertificationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Award  ************************//
  const EnableAwards = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios.put(`UserData/EnableAwardInfo`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserProfile();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Award End  ************************//
  //************************Disable Award  ************************//
  const DisableAwards = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios.put(`UserData/DisableAwardInfo`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserProfile();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Award End  ************************//
  console.log("userData?.viewExperienceInfo", userData);

  useEffect(() => {
    if (
      showUndoEndorseModal ||
      showEndorseModal ||
      showAwardDelete ||
      showExperienceDelete ||
      showEducationDelete ||
      showCertificateDelete ||
      show
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [
    showUndoEndorseModal,
    showEndorseModal,
    showAwardDelete,
    showExperienceDelete,
    showEducationDelete,
    showCertificateDelete,
    show,
  ]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
  }, []);



  //************************ DOB   ************************//
  const [startDate, setStartDate] = useState("");
  const [dob, setDob] = useState(false);
  const handledob = () => {
    setDob(false);
  };
  const DobUpload = async () => {
    try {
      if (startDate !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append(
          "Dob",
          startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD")
        );
        const res = await axios.post("/UserAccount/SetDob", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setDob(false);
        getUserProfile()
        // userConfig();
        // getUserProfilePer();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ DOB  End ************************//


  //************************ Gender   ************************//
  const [genderModal, setGenderModal] = useState(false);
  const handleGenderModal = () => {
    setGenderModal(false);
  };
  const [genderValue, setGenderValue] = useState(null);
  const [genderOptionList, setGenderOptionList] = useState([]);
  const getGenderData = () => {
    try {
      axios.get(`/Master/GetGenderDDL`).then((response) => {
        // console.log("gender data", response.data.responseData);
        setGenderOptionList(response.data.responseData);
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  let genderOptions = genderOptionList.map((val) => {
    return { value: val.gndrId, label: val.name, id: val.gndrId };
  });

  const selectGender = (val) => {
    // console.log("gender", val.value);
    setGenderValue(val.value);
  };

  useEffect(() => {
    getGenderData();
  }, []);

  const GenderUpload = async () => {
    try {
      if (genderValue !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("GndrId", genderValue);
        const res = await axios.post("/UserAccount/SetGender", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setGenderModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Gender End   ************************//
  //************************ About   ************************//
  const [length, setLength] = useState(userData?.profileInfo?.about?.length);
  const [aboutContent, setAboutContent] = useState("");
  const [aboutModal, setAboutModal] = useState(false);
  const handleAboutModal = () => {
    setAboutModal(false);
  };
  const AboutUpload = async () => {
    try {
      if (aboutContent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("AboutContent", aboutContent);
        const res = await axios.post("/UserAccount/SetAbout", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setAboutModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ About  End ************************//

  //************************ Website   ************************//
  const [websitecontent, setWebsitecontent] = useState("");
  const [websiteModal, setWebsiteModal] = useState(false);
  const handlewebsiteModal = () => {
    setWebsiteModal(false);
  };
  const websiteUpload = async () => {
    try {
      if (websitecontent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("Website", websitecontent);
        const res = await axios.post("/UserAccount/SetWebsite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setWebsiteModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Website  End ************************//





  //************************ City   ************************//
  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City List End  ********//
  const [languageModal, setlanguageModal] = useState(false);
  const handlelanguageModal = () => {
    setlanguageModal(false);
  };
  // const [languageValue, setlanguageValue] = useState(null);
  // const [languageOptionList, setlanguageOptionList] = useState([]);
  // const getlanguageData = () => {
  //   try {
  //     axios.get(`/Master/GetLanguageDDL`).then((response) => {
  //       // console.log("language data", response.data.responseData);
  //       setlanguageOptionList(response.data.responseData);
  //       // setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // let languageOptions = languageOptionList.map((val) => {
  //   return { value: val.langId, label: val.name, id: val.langId };
  // });

  // const selectlanguage = (val) => {
  //   // console.log("language", val.value);
  //   setlanguageValue(val.value);
  // };

  // useEffect(() => {
  //   getlanguageData();
  // }, []);

  const languageUpload = async () => {
    try {
      if (cityId !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("CityID", cityId);
        const res = await axios.post("/UserAccount/SetCity", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setlanguageModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ City End   ************************//

  useEffect(() => {
    setStartDate(userData?.profileInfo?.dob)
    setGenderValue(userData?.profileInfo?.genderId)
    setCityId(userData?.profileInfo?.cityID)
    setWebsitecontent(userData?.profileInfo?.website)
    setAboutContent(userData?.profileInfo?.about)
  }, [userData?.profileInfo])


  useEffect(() => {
    if (
      websiteModal ||
      aboutModal ||
      languageModal ||
      genderModal ||
      dob
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [
    websiteModal,
    aboutModal,
    languageModal,
    genderModal,
    dob
  ]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };

  return (
    <>
      {userData?.profileInfo?.isOwn ? (
        <UpdateProfile getUserProfile={getUserProfile} />
      ) : guestModeData === "true" ? (
        ""
      ) : (
        <div className="d-flex mb-3 userprofileactions">
          <Connection
            uid={uid}
            connectionCheck={connectionCheck}
            connectionId={connectionId}
            CheckConnectUser={CheckConnectUser}
          />

          {userData?.profileInfo?.isConnectedByYou === true ? (
            ""
          ) : userData?.profileInfo?.isFollowedByYou === true ||
            isfollowed === true ? (
            ""
          ) : (
            <div className="unFollowBtn ms-3">
              <button type="button" className="mainBtn1" onClick={FollowedUser}>
                Follow
              </button>
            </div>
          )}
          <Link to={`/Message/${userData?.profileInfo?.userUid}`}>
            <button type="button" className="mainBtn1 ms-3 me-3">
              Message
            </button>
          </Link>

          <ProfileNegativeActions
            userData={userData}
            uid={uid}
            getUserProfile={getUserProfile}
            connectionCheck={connectionCheck}
            CheckConnectUser={CheckConnectUser}
          />
        </div>
      )}

      {/* Basic Info */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <p className="f-16 fw-bold">Basic Info</p>
          {/* <Link to={`/Resume/${uid}`} target="_blank" className="me-3">
            <p className="f-14 ms-1">View Resume</p>
          </Link> */}
        </div>
        {(!userData?.profileInfo?.dob && !userData?.profileInfo?.cityName && !userData?.profileInfo?.genderName && !userData?.profileInfo?.website && !userData?.profileInfo?.about) && (
          <p className="text-center">No Basic Info Added</p>
        )}
        {userData?.profileInfo?.dob &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}

          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex align-items-center verified">
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Date Of Birth
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.dob}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setDob(true);
                    }}
                  />

                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.cityName &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex align-items-center verified">
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    City
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.cityName}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setlanguageModal(true)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.genderName &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex align-items-center verified">
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Gender
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.genderName}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">
                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setGenderModal(true);
                    }}
                  />

                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.website &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex align-items-center verified">
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Website
                  </p>
                </div>
                <a
                  className="ms-1 f-12"
                  href={
                    userData?.profileInfo?.website?.startsWith('http://') || userData?.profileInfo?.website?.startsWith('https://')
                      ? userData?.profileInfo?.website
                      : `http://${userData?.profileInfo?.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userData?.profileInfo?.website}
                </a>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">
                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setWebsiteModal(true)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.about &&
          <div
            className={`analytic-sub mt-2 mb-1 pb-3`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex align-items-center verified">
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    About
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.about}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setAboutModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
      </div>
      {/* Education */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-2 border-custom pb-2">
          <p className="f-16 fw-bold">Education</p>
          {userData?.profileInfo?.isOwn ? (
            <div>
              <div>
                <AddSharpIcon
                  className="material-symbols-sharp pointer"
                  onClick={handleOpenUSEREducation}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {!userData?.viewEducationInfo?.length && (
          <p className="text-center">No Education Added</p>
        )}

        {userData?.viewEducationInfo &&
          userData?.viewEducationInfo?.map((item, index) => {
            const isLastItem = index === userData?.viewEducationInfo.length - 1;
            console.log('fnsvnbd', item);

            return (
              <div
                className={`analytic-sub mt-3 mb-1 ${isLastItem ? "" : "border-custom"
                  } pb-3`}
                key={index}
              >
                <div className="d-flex align-items-start justify-content-between">
                  <div className="ms-2">
                    <div className="d-flex verified">
                      <p className="f-14 ms-1 fw-bold me-2">
                        {item.institutionName}
                        {/* <span className="fw-normal f-12">({item.orgType})</span> */}
                        {item?.isOrgVrfd === true ? (
                          <img src={resume.verified} width={18} height={18} className="ms-1" />
                        ) : item?.isOrgRgd === true ? (
                          <img src={resume.register} width={18} height={18} className="ms-1" />
                        ) : (
                          <img src={resume.unregister} width={18} height={18} className="ms-1" />
                        )}

                        {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                      </p>

                    </div>
                    <div className="d-flex">
                      <p className="ms-1 me-1 f-14 fw-bold">Field: {item.field}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-14 fw-bold">
                        {item.degree}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <LocationOnSharpIcon
                        className="material-symbols-sharp locationIcon"
                      />
                      <p className="ms-1 me-1 f-12">{item.cityName}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        ( {item.startYear} -
                        {item.isPresent === true ? "Present" : item.endYear})
                      </p>
                    </div>
                  </div>
                  {userData?.profileInfo?.isOwn && (
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                      <div className="form-switch form-check-primary ms-2">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableEducation(item.userEductnId);
                            } else {
                              EnableEducation(item.userEductnId);
                            }
                          }}
                          className=""
                        />
                      </div>
                      <EditSharpIcon
                        className="material-symbols-sharp pointer me-2"
                        onClick={() => {
                          setShowEducationUpdate(!showEducationUpdate);
                          setEducationData(item);
                        }}
                      />
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowEducationDelete(!showEducationDelete);

                          setEducationId(item.userEductnId);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {/* Experience */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <p className="f-16 fw-bold">Experience</p>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserExperience}
              />
            </div>
          )}
        </div>
        {!userData?.viewExperienceInfo?.length && (
          <p className="text-center">No Experience Added</p>
        )}
        {/* <div className='analytics d-flex justify-content-between mt-3'> */}
        {/* viewExperienceInfo */}
        {userData?.viewExperienceInfo &&
          userData?.viewExperienceInfo?.map((item, index) => {
            console.log("itejfdjfjfjm", item);
            const isLastItem =
              index === userData?.viewExperienceInfo.length - 1;
            return (
              <div
                className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                  } pb-3`}
              >
                <div className="d-flex align-items-start justify-content-between">
                  <div className="ms-2">
                    <div className="d-flex verified">
                      <p className="f-14 ms-1 fw-bold me-2">
                        {item.jobTitle}
                        {/* <span className="fw-normal f-12">({item.orgType})</span> */}
                        {item?.isOrgVrfd === true ? (
                          <img src={resume.verified} width={18} height={18} className="ms-1" />
                        ) : item?.isOrgRgd === true ? (
                          <img src={resume.register} width={18} height={18} className="ms-1" />
                        ) : (
                          <img src={resume.unregister} width={18} height={18} className="ms-1" />
                        )}

                        {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                      </p>

                    </div>
                    {/* <p className="ms-1 me-1 f-14 fw-bold"> {item.companyName}</p> */}
                    {item?.isOrgRgd === true ? (
                        <a href={`/Company/${item?.companyUid}`} className="ms-1 me-1 f-14 fw-bold "
                        style={{ cursor: "pointer", color: "black !important" }}>
                          {item.companyName}
                        </a>
                      ) : 
                    <p className="ms-1 me-1 f-14 fw-bold"> {item.companyName}</p>  
                  }
                    <div className="d-flex align-items-center">
                      <p className="ms-1 me-2 f-12 ">{item.jobTypeName}</p>
                      <div className="dot"></div>
                      <LocationOnSharpIcon className="material-symbols-sharp locationIcon ms-3 expericenLocation" />
                      <p className="ms-1 me-1 f-12">{item.cityName}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        ({moment(item.startDate).format("YYYY")} -
                        {item.isPresent === true ? "Present" : moment(item.endDate).format("YYYY")})
                      </p>
                    </div>
                    {/* <button class="wljPrimaryBtn"  onClick={handleAchievementUserExperience}>Achievement</button> */}
                  </div>
                  {userData?.profileInfo?.isOwn && (
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                      <div className="form-switch form-check-primary ms-2">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableExperience(item.userExpId);
                            } else {
                              EnableExperience(item.userExpId);
                            }
                          }}
                          className=""
                        />
                      </div>
                      <EditSharpIcon
                        className="material-symbols-sharp pointer me-2"
                        onClick={() => {
                          setShowExperienceUpdate(!showEducationUpdate);
                          setExperienceData(item);
                        }}
                      />
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowExperienceDelete(!showExperienceDelete);
                          setExperienceId(item.userExpId);
                        }}
                      />

                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {/* Certificate */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <p className="f-16 fw-bold">Certification</p>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserCertification}
              />
            </div>
          )}
        </div>
        {!userData?.viewCertificationInfo?.length && (
          <p className="text-center">No Certification Added</p>
        )}

        {/* <div className='analytics d-flex justify-content-between mt-3'> */}
        {userData?.viewCertificationInfo &&
          userData?.viewCertificationInfo?.map((item, index) => {
            console.log('itemghjghjgh', item);
            const isLastItem =
              index === userData?.viewCertificationInfo.length - 1;
            return (
              <div
                className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                  } pb-3`}
              >
                <div className="d-flex align-items-start justify-content-between">
                  {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                  <div className="ms-2">
                    <div className="d-flex verified">
                      <p className="f-14 ms-1 fw-bold me-2">
                        {item.name}
                        {/* <span className="fw-normal f-12">({item.issuingOrgTypeName})</span> */}
                        {item?.isOrgVrfd === true ? (
                          <img src={resume.verified} width={18} height={18} className="ms-1" />
                        ) : item?.isOrgRgd === true ? (
                          <img src={resume.register} width={18} height={18} className="ms-1" />
                        ) : (
                          <img src={resume.unregister} width={18} height={18} className="ms-1" />
                        )}

                        {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                      </p>
                      {/* <p className="f-14 ms-1  fw-bold me-2">
                        {item.name}
                      </p>
                      <p className=" f-12">
                        ({item.issuingOrgTypeName})
                      </p>
                      {item?.isOrgVrfd === true ? (
                        <img src={resume.verified} width={18} height={18} className="ms-1" />
                      ) : item?.isOrgRgd === true ? (
                        <img src={resume.register} width={18} height={18} className="ms-1" />
                      ) : (
                        <img src={resume.unregister} width={18} height={18} className="ms-1" />
                      )}

                      {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                    </div>
                    <p className="ms-1 me-1 f-14 fw-bold"> {item.orgName}</p>
                    <div className="d-flex align-items-center">
                      <p className="ms-1 me-1 f-12">{item.recognition}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        ID: {item.credentialId}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <LocationOnSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                      <p className="ms-1 me-1 f-12">{item.cityName}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        ({moment(item.issueDate).format("YYYY")} -{" "}
                        {moment(item.expirationDate).format("YYYY")})
                      </p>
                    </div>
                  </div>
                  {userData?.profileInfo?.isOwn && (
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                      <div className="form-switch form-check-primary ms-2">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableCertification(item.userCrtfctnId);
                            } else {
                              EnableCertification(item.userCrtfctnId);
                            }
                          }}
                          className=""
                        />
                      </div>
                      <EditSharpIcon
                        className="material-symbols-sharp pointer me-2"
                        onClick={() => {
                          setShowCertificateUpdate(!showCertificateUpdate);
                          setCertificateData(item);
                        }}
                      />
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowCertificateDelete(!showCertificateDelete);
                          setCertificateId(item.userCrtfctnId);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {/* Award */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <p className="f-16 fw-bold">Awards</p>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserAward}
              />
            </div>
          )}
        </div>

        {!userData?.viewAwardsInfo?.length && (
          <p className="text-center">No Award Added</p>
        )}

        {/* {userData?.profileInfo?.isOwn &&
          (!userData?.viewAwardsInfo || userData?.viewAwardsInfo.length === 0 ? (
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="mainBtn1 f-14"
                onClick={handleOpenUserAward}
              >
                Add Awards
              </button>
            </div>
          ) : (
            " "
          )
          )} */}
        {/* <div className='analytics d-flex justify-content-between mt-3'> */}
        {userData?.viewAwardsInfo &&
          userData?.viewAwardsInfo?.map((item, index) => {
            console.log('itemmgjhghg', item);
            const isLastItem = index === userData?.viewAwardsInfo.length - 1;
            return (
              <div
                className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                  } pb-3`}
              >
                <div className="d-flex align-items-start justify-content-between">
                  {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                  <div className="ms-2">
                    <div className="d-flex verified">
                      <p className="f-14 ms-1 fw-bold me-2">
                        {item.name}
                        {/* <span className="fw-normal f-12">({item.issuerOrgTypeName})</span> */}
                        {item?.isOrgVrfd === true ? (
                          <img src={resume.verified} width={18} height={18} className="ms-1" />
                        ) : item?.isOrgRgd === true ? (
                          <img src={resume.register} width={18} height={18} className="ms-1" />
                        ) : (
                          <img src={resume.unregister} width={18} height={18} className="ms-1" />
                        )}

                        {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                      </p>

                    </div>
                    <p className="ms-1 me-1 f-14 fw-bold"> {item.orgName}</p>
                    <div className="d-flex align-items-center">
                      <p className="ms-1 me-1 f-12">{item.recognition}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        {item.description}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <LocationOnSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                      <p className="ms-1 me-1 f-12">{item.cityName}</p>
                      <div className="dot"></div>
                      <p className="ms-2 f-12 ">
                        ({moment(item.issueDate).format("YYYY")} -{" "}
                        {moment(item.expirationDate).format("YYYY")})
                      </p>
                    </div>
                  </div>
                  {userData?.profileInfo?.isOwn && (
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                      <div className="form-switch form-check-primary ms-2">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableAwards(item.userAwardId);
                            } else {
                              EnableAwards(item.userAwardId);
                            }
                          }}
                          className=""
                        />
                      </div>
                      <EditSharpIcon
                        className="material-symbols-sharp pointer me-2"
                        onClick={() => {
                          setShowAwardUpdate(!showAwardUpdate);
                          setAwardData(item);
                        }}
                      />
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAwardDelete(!showAwardDelete);
                          setAwardId(item.userAwardId);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {/* Skills */}

      {guestModeData === "true" ? (
        ""
      ) : (
        <div className="UpdateProfile p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Skills</p>
            <div>
              {userData?.profileInfo?.isOwn ? (
                <AddSharpIcon
                  className="material-symbols-sharp pointer f-24 "
                  onClick={handleOpenAddSkill}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {!userData?.viewSkillInfo?.length && (
            <p className="text-center">No Skill Added</p>
          )}

          {userData?.viewSkillInfo && (
            <>
              {userData?.viewSkillInfo.slice(0, 2).map((item, index, arr) => {
                console.log("skills", item);
                const isLastItem = index === arr.length - 1;
                return (
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3`}
                    key={index}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <div className="d-flex align-items-center verified ">
                        <p className="f-14 ms-1 pb-0 fw-bold  me-2">
                          {item.skillName}
                        </p>
                      </div>
                      {userData?.profileInfo?.isOwn && (
                        <div className="d-flex align-items-center updateActions">
                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} className="me-2" />}

                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2 ms-2"
                            onClick={() => {
                              setShowSkills(!showSkills);
                              setSkillsData(item);
                            }}
                          />
                          <DeleteOutlineSharpIcon
                            className="material-symbols-sharp pointer delete-icon"
                            onClick={() => {
                              setShow(!show);
                              setSkillId(item.usrSkllId);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <p className="ms-1 f-12">
                      {item.firmIndustryTypeName}
                    </p>
                    {item.endorsedCount && <p className="ms-1 f-12">
                      {/* Frontend Web Developer at HyperautoX Information
                          Technology LLC */}
                      {item.endorsedCount} endorsement
                    </p>}
                    {uid !== userUid && (
                      <div className="endorseBtn mt-3">
                        {item.isEndorsedByYou === true ? (
                          <button
                            className="button followStyle"
                            onClick={() => {
                              setShowUndoEndorseModal(!showUndoEndorseModal);
                              setSkillId(item.usrSkllId);
                            }}
                            style={{ width: "100px" }}
                          >
                            Endorsed
                          </button>
                        ) : (
                          <button
                            className="button followStyle"
                            onClick={() => {
                              setShowEndorseModal(!showEndorseModal);
                              setSkillId(item.usrSkllId);
                            }}
                          >
                            Endorse
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}

      {userData?.viewSkillInfo?.length > 2 ? (
        <div className="empty-container d-flex justify-content-center align-items-center mb-2">
          <button>
            <span
              className="d-flex justify-content-center align-items-center skills-more-cont"
              onClick={() => {
                setShowMoreSkills(!showMoreSkills);
              }}
            >
              {/* <Link to={`/User/${uid}/Skills`} className="me-1"> */}
              Show all {userData?.viewSkillInfo?.length} skills
              {/* </Link> */}
              <ArrowForwardSharpIcon className="material-symbols-sharp" />
            </span>
          </button>
        </div>
      ) : (
        ""
      )}

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to delete skill?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }}
                />

              </div>
              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeSkills()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMoreSkills && (
        <div
          className="modal fade show postAdd modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0 me-2"> All Skills</h6>
                  <div>
                    {uid === userUid ? (
                      <AddSharpIcon
                        className="material-symbols-sharp pointer f-24 "
                        onClick={handleOpenAddSkill}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowMoreSkills(false);
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <Skills
                  userData={userData}
                  showUndoEndorseModal={showUndoEndorseModal}
                  setShowUndoEndorseModal={setShowUndoEndorseModal}
                  setShowEndorseModal={setShowEndorseModal}
                  showEndorseModal={showEndorseModal}
                  setSkillId={setSkillId}
                  skillId={skillId}
                  skillList={userData?.viewSkillInfo}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showCertificateDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to delete Certificate?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveCertificateClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeCertificate()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEducationDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Delete Education?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeEducation()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showExperienceDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Experience?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveExperienceClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeExperience()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAwardDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Award?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAwardClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeAward()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEndorseModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> FeedBack</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>

              <div>
                <div className="lableStyle pt-3">
                  <textarea
                    id="address"
                    name="about"
                    rows="4"
                    cols="55"
                    className="border"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 "
                  onClick={EndorseData}
                >
                  Endorse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUndoEndorseModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Undo Endorse</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUndoEndorse();
                  }}
                />
              </div>

              <div>
                <div className="lableStyle">
                  <textarea
                    id="address"
                    name="about"
                    rows="4"
                    cols="55"
                    className="border"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={UndoEndorseData}
                >
                  UnEndorse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <AddSkill
        show={showAddSkill}
        handleClose={handleCloseAddSkill}
        getUserSkill={getUserSkill}
        getUserProfile={getUserProfile}
      />

      <ProfileEducation
        showUSEREducation={showUSEREducation}
        handleCloseUSEREducation={handleCloseUSEREducation}
        // getUserUser={getUserUser}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileEducationUpdate
        showEducationUpdate={showEducationUpdate}
        setShowEducationUpdate={setShowEducationUpdate}
        handleUpdateEducationClose={handleUpdateEducationClose}
        educationData={educationData}
        // getUserUser={getUserUser}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileExperience
        showUserExperience={showUserExperience}
        handleCloseUserExperience={handleCloseUserExperience}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileAchiementExperience
        showAchievementUserExperience={showAchievementUserExperience}
        handleAchievementCloseUserExperience={handleAchievementCloseUserExperience}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileExperienceUpdate
        showExperienceUpdate={showExperienceUpdate}
        handleUpdateExperienceClose={handleUpdateExperienceClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        ExperienceData={ExperienceData}
      />

      <ProfileCertification
        showUserCertification={showUserCertification}
        handleCloseUserCertification={handleCloseUserCertification}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileCertificateUpdate
        showCertificateUpdate={showCertificateUpdate}
        handleUpdateCertificateClose={handleUpdateCertificateClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        certificateData={certificateData}
      />
      <ProfileAward
        showUserAward={showUserAward}
        handleCloseUserAward={handleCloseUserAward}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileAwardUpdate
        showAwardUpdate={showAwardUpdate}
        handleUpdateAwardClose={handleUpdateAwardClose}
        userUId={userUid}
        showAwardData={showAwardData}
        getUserProfile={getUserProfile}
      />
      <UpdateSkills
        showSkills={showSkills}
        handleUpdateSkillClose={handleUpdateSkillClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        skillsData={skillsData}
      />
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {dob && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>DOB</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handledob();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex align-items-center flex-column">
                  {/* <h5>DOB</h5> */}
                  <div className="dateofbirth">
                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      placeholder="Select Date"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={DobUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {genderModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Gender</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleGenderModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Gender</h4> */}
                  <div className="col-md-12">
                    {/* <label htmlFor="about">Gender</label> */}
                    <ReactSelect
                      defaultValue={genderOptions.find((option) => option.value === genderValue)}
                      options={genderOptions}
                      onChange={selectGender}
                      loadOptions={selectGender}
                      placeholder="Select Gender"
                      styles={customStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={GenderUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {aboutModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>About</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleAboutModal();
                  }}
                />
              </div>

              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 about-textArea">
                    <textarea
                      id="address"
                      name="about"
                      rows="4"
                      cols="55"
                      value={aboutContent}
                      onChange={(e) => {
                        setAboutContent(e.target.value)
                        setLength(e.target?.value.length)
                      }}
                      maxLength={500}
                    ></textarea>
                    {/* <Input type='textarea' onChange={(e) => {
                            setAbout(e.target?.value)
                            setLength(e.target?.value.length)
                        }} defaultValue={data} maxLength={500} /> */}
                    <p className='info'>{aboutContent?.length && length}/500</p>
                  </div>
                </div>
              </div>
              <p className="p-2 text-danger f-14">
                About content should be less than 500 words
              </p>
              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={AboutUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {websiteModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Website</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlewebsiteModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-4 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 web-input">
                    {/* <label htmlFor="about" className="web-text">Website </label> */}
                    <input
                      type="text"
                      style={{ width: "100%" }}
                      value={websitecontent}
                      onChange={(e) => setWebsitecontent(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={websiteUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {languageModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>City</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlelanguageModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Language</h4> */}
                  <div className="col-md-12 mt-2">
                    {/* <label htmlFor="about">Language</label> */}
                    {/* <ReactSelect
                      options={languageOptions}
                      onChange={selectlanguage}
                      loadOptions={selectlanguage}
                      placeholder="Select Language"
                      styles={customStyles}
                    /> */}
                    <Select
                      defaultValue={cityData.find((option) => option.value === cityId)}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={languageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default About;
