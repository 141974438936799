import React, { useEffect, useState } from 'react'
import axios from '../../API/axios'
import { ShimmerDiv } from 'shimmer-effects-react'
import { ShimmerTitle } from 'react-shimmer-effects'
import { post } from '../../assets/Images/ImagesLink'
import { Link, useLocation } from 'react-router-dom'


const BlogCard = () => {
  const location = useLocation();

  const categoryslug = window.location.href.split("category/")[1];
  const [isLoading, setIsLoading] = useState(true)
  const [isCategoryLoading, setIsCategoryLoading] = useState(true)
  const [nextBatchLoading, setNextBatchLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isCategoryError, setIsCategoryError] = useState(false)
  const [blogdata, setBlogdata] = useState([])
  const [blogCategorydata, setBlogCategorydata] = useState([])
  const [page, setPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  console.log('blogdata', blogdata, blogCategorydata);


  const getblogsdata = async () => {
    console.log(categoryslug);
    if (categoryslug === undefined || categoryslug === '') {
      try {
        setNextBatchLoading(true);
        const response = await axios.get(
          `Blog/GetAllApprovedBlogsWithPagination?pageNumber=${page}&pageSize=9`
        );
        setIsLoading(false);
        console.log('hns', response);

        setTotalPages(response.data.paging.totalPages)
        const newBlogs = response.data.blogVM;
        setBlogCategorydata([])
        setBlogdata((prev) => {
          const postSet = new Set(prev.map((post) => post.blogEntryId));
          const uniqueNewPosts = newBlogs.filter(
            (post) => !postSet.has(post.blogEntryId)
          );
          return [...prev, ...uniqueNewPosts];
        });
        setNextBatchLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setIsError(true);
        } else {
          console.error(error);
        }
        setNextBatchLoading(false);
      }
    } else {
      try {
        setNextBatchLoading(true);
        const response = await axios.get(
          `Blog/GetAllBlogByCategoryURLWithPagination/${categoryslug}?pageNumber=${categoryPage}&pageSize=9`
        );
        setIsCategoryLoading(false);
        setTotalPages(response.data.paging.totalPages)
        const newBlogs = response.data.blogVM;
        setBlogdata([]);
        setBlogCategorydata(newBlogs)
        setNextBatchLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setIsCategoryError(true);
        } else {
          console.error(error);
        }
        setNextBatchLoading(false);
      }


    }

  };



  useEffect(() => {
    // if (page <= totalPages) {
    getblogsdata()
    // }
  }, [page, categoryPage, categoryslug])

  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
        // setCategoryPage((prev) => prev + 1)

      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);
  return (
    <>
      <div className='row blogMainContainer'>
        {blogCategorydata.length === 0 ?
          isLoading ? (
            <div className="mt-3 row allData">
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
            </div>
          ) : isError ? (
            <div className="mt-2 error-image-cont allData">
              <img src={post.errorbig} alt="404 Not Found" />
            </div>
          ) : (
            blogdata && blogdata.map((item, index) => {
              return (
                <div className='col-md-4 allData' key={index}>
                  <div className='blogSingleCard'>
                    {/* <div className='blogImage'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        <img src={item?.image} />
                      </Link>
                    </div> */}
                    <div className='blogImage'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        {item?.image ? (
                          <img src={item?.image} loading="lazy" alt={item?.headerTitle} />
                        ) : (
                          <ShimmerDiv mode="light" height={100} width={100} />
                        )}
                      </Link>
                    </div>

                    <div className='blogContent d-flex flex-column justify-content-center'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        <div className="blog-heading f-16 f-600 mb-3">
                          {item?.headerTitle}
                        </div>
                      </Link>
                      <div className="sub-heading f-12 f-500 mb-3">
                        {/* {element.shortDescription} */}
                        <p>{item?.shortDescription}</p>
                      </div>
                      <div className="blog-bottom d-flex justify-content-between">
                        <strong>
                          <span>{item?.blogDate}</span>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : isCategoryLoading ? (
            <div className="mt-3 row allCategory">
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="shimmer-cont">
                  <div className="shimmer-header d-flex">
                    {/* <ShimmerDiv mode="light" height={60} width={60} rounded={50} /> */}
                    <div className="shimmer-title ms-3">
                      <ShimmerTitle mode="light" line={2} gap={8} />
                    </div>
                  </div>
                  <div className="square-shimmer mt-2">
                    <ShimmerDiv mode="light" height={100} width={100} />
                  </div>
                </div>
              </div>
            </div>
          ) : isCategoryError ? (
            <div className="mt-2 error-image-cont allCategory">
              <img src={post.errorbig} alt="404 Not Found" />
            </div>
          ) : (
            blogCategorydata && blogCategorydata.map((item, index) => {
              return (
                <div className='col-md-4 allCategory' key={index} >
                  <div className='blogSingleCard'>
                    {/* <div className='blogImage'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        <img src={item?.image} />
                      </Link>
                    </div> */}
                    <div className='blogImage'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        {item?.image ? (
                          <img src={item?.image} loading="lazy" alt={item?.headerTitle} />
                        ) : (
                          <ShimmerDiv mode="light" height={100} width={100} />
                        )}
                      </Link>
                    </div>

                    <div className='blogContent'>
                      <Link to={"/blogs/" + item.slugURLvalue}>
                        <div className="blog-heading f-16 f-600 mb-3">
                          {item?.headerTitle}
                        </div>
                      </Link>
                      <div className="sub-heading f-12 f-500 mb-3">
                        {/* {element.shortDescription} */}
                        <p>{item?.shortDescription}</p>
                      </div>
                      <div className="blog-bottom d-flex justify-content-between">
                        <strong>
                          <span>{item?.blogDate}</span>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          )}
      </div>
    </>
  )
}

export default BlogCard
