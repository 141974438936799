import React, { useEffect, useState } from 'react'
import axios from '../../API/axios';
import JobList from './JobList';
import SearchHeader from './SearchHeader';
import JobFilter from './JobFilter';
import { header } from "../../assets/Images/ImagesLink";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const JobSearch = () => {
    const sessionId = localStorage.getItem("sessionId");
    const guestModeData = localStorage.getItem("guestMode");
    const userUid = localStorage.getItem("userUid");
    const [filterJobList, setFilterJobList] = useState([]);
    const [country, setCountry] = useState([]);
    const [selectedCountryData, setSelectedCountryData] = useState([]);
    const [city, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompanyData, setSelectedCompanyData] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [selectedDesignationData, setSelectedDesignationData] = useState([]);
    const [selectedJobTypeData, setSelectedJobTypeData] = useState([]);
    const [jobTypeList, setJobTypeList] = useState([]);
    const [salaryTypeList, setSalaryTypeList] = useState([]);
    const [selectedSalaryType, setSelectedSalaryType] = useState([]);
    const [selectedWorkLevel, setSelectedWorkLevel] = useState([]);
    const [workLevelTypeList, setWorkLevelTypeList] = useState([]);
    const [workLocationType, setWorkLocationTypeList] = useState([]);
    const [selectedWorkLocation, setSelectedWorkLocationList] = useState([]);
    //************************ jobs list Start  ************************//
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [jobsList, setJobsList] = useState([]);
    const [searchjobsList, setSearchJobsList] = useState([]);
    const [isError, setIsError] = useState(false);
    const [reset, setReset] = useState(false);
    const [searchValue, setSearchValue] = useState("");


    const getAllJobList = (page) => {
        const data = {
            search: searchValue,
            countryIDs: selectedCountryData,
            cityIDs: selectedCity,
            companyIDs: selectedCompanyData,
            jobtypeIDs: selectedJobTypeData,
            workLevelTypeIDs: selectedWorkLevel,
            workLocationTypeIDs: selectedWorkLocation,
            salaryTypeIDs: selectedSalaryType,
            designationIDs: selectedDesignationData,
            skillIDs: [],
            deviceType: "Web",
            pageNumber: page,
            pageSize: 24
        }

        axios
            .post(`Job/GetJobsWithFilters`, data)
            .then((response) => {
                const res = response.data.responseData.jobsList;
                console.log("getAllJobList", res);
                // setjobsList(res);
                setIsLoading(true);
                setTotalPages(response.data.responseData.paging.totalPages);
                setCountry(response.data.responseData.filterOptions.countryList);
                setCity(response.data.responseData.filterOptions.cityList);
                setCompanyList(response.data.responseData.filterOptions.companyList);
                setDesignationList(response.data.responseData.filterOptions.designationList);
                setJobTypeList(response.data.responseData.filterOptions.jobTypeList);
                setSalaryTypeList(response.data.responseData.filterOptions.salaryTypeList);
                setWorkLevelTypeList(response.data.responseData.filterOptions.workLevelTypeList);
                setWorkLocationTypeList(response.data.responseData.filterOptions.workLocationTypeList);
                let newPosts = response.data.responseData.jobsList;
                if (reset === false) {
                    console.log('checkif', reset);
                    setFilterJobList([])
                    setJobsList((prev) => {
                        const postSet = new Set(prev.map((post) => post.jobId));
                        const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.jobId));
                        return [...prev, ...uniqueNewPosts];
                    });
                } else {
                    console.log('checkelse');
                    setJobsList([])
                    setFilterJobList(newPosts)
                    // setFilterJobList((prev) => {
                    //     const postSet = new Set(prev.map((post) => post.jobId));
                    //     const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.jobId));
                    //     return [...prev, ...uniqueNewPosts];
                    // });

                    console.log('jobsList', jobsList, filterJobList, page);
                }
                setIsLoading(false);
                setIsError(false);

            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    setIsError(true);
                } else {
                    console.log(error);
                }
                setIsLoading(false);
            });
    };


    useEffect(() => {
        // console.log('log', page, totalPages);
        if (page <= totalPages && searchValue == '') {
            getAllJobList(page + 1);
        }
    }, [page]);

    useEffect(() => {
        // console.log('log', searchValue.length);
        if (searchValue.length === 1) {
            setPage(0)
            setReset(true)
        }
        getAllJobList(page + 1);

    }, [searchValue, selectedCountryData, selectedCity, selectedCompanyData, selectedDesignationData, selectedJobTypeData, selectedSalaryType, selectedWorkLocation]);

    const handleInfiniteScroll = async () => {
        try {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                setLoading(true);
                console.log('log', page, totalPages);

                page < totalPages && setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleInfiniteScroll);
        return () => window.removeEventListener("scroll", handleInfiniteScroll);
    }, []);
    //************************ jobs list End  ************************//

    const [userPer, setUserPer] = useState([]);
    const [alert, setAlert] = useState(false);
    const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

    const getUserProfilePer = () => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
        };
        axios
            .post(`UserProfile/GetProfilePercentage`, data)
            .then((response) => {
                const res = response.data.responseData;
                setUserPer(res.percentage);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoadingalert(false); // Set loading to false once the data is fetched
            });
    };

    useEffect(() => {
        getUserProfilePer();
    }, [sessionId]);

    // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
    const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

    // Condition to show the alert
    const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;

    return (
        <>
            <Helmet>
                <title>Find Your Next Opportunity: WeLink Jobs Search</title>
                <meta name="description" content="Unleash your job search potential with WeLink Jobs. Explore opportunities on the most trusted job search sites and find your dream job." />
                {/* <meta name="keywords" content="react, meta tags, seo" /> */}
                {/* <meta name="author" content="Your Name" /> */}
                <meta property="og:title" content="Find Your Next Opportunity: WeLink Jobs Search" />
                <meta property="og:description" content="Unleash your job search potential with WeLink Jobs. Explore opportunities on the most trusted job search sites and find your dream job." />
                {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
                <meta property="og:url" content="https://welinkjobs.com/SearchJob" />
            </Helmet>
            <div className="p-5 mt-5">
                {shouldShowAlert && (
                    <div className='p-3 closeAccount m-0 mb-3'>
                        <div className='d-flex mb-3 align-items-center'>
                            <InfoSharpIcon />
                            <span className='cardHeader ms-1'>Announcement!</span>
                            <span className="ms-auto">
                                <CloseSharpIcon
                                    style={{ width: '20px' }}
                                    className="pointer"
                                    onClick={() => setAlert(true)} // Dismiss the alert
                                />
                            </span>
                        </div>
                        <div>
                            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
                        </div>
                        <div className="mt-2">
                            <Link to={`/User/${userUid}`}>
                                <button
                                    color=""
                                    className="closebtn"
                                    style={{ width: "150px" }}
                                >
                                    Manage Profile
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
                {/* {(guestModeData === 'false'|| guestModeData===null) && userPer <= 60 && alert === false && (
                    <div className='p-3 closeAccount m-0 mb-3'>
                        <div className='d-flex mb-3 align-items-center'>
                            <InfoSharpIcon />
                            <span className='cardHeader ms-1'>Announcement!</span>
                            <span className="ms-auto">
                                <CloseSharpIcon style={{ width: '20px' }} className="pointer" onClick={() => {
                                    setAlert(true);
                                }} />
                            </span>
                        </div>
                        <div>
                            <span>Applying for a new job ! Update your profile and resume Now.</span>

                        </div>
                        <div className=" mt-2">
                    <Link to={`/User/${userUid}`}>
                       <button
                          color=""
                           className="closebtn"
                             style={{ width: "150px" }}  >
                        Manage Profile
                      </button>
                    </Link>
                </div>
                    </div>)} */}
                <SearchHeader
                    setSelectedCountryData={setSelectedCountryData}
                    country={country}
                    setSelectedCity={setSelectedCity}
                    city={city}
                    companyList={companyList}
                    setSelectedCompanyData={setSelectedCompanyData}
                    designationList={designationList}
                    setSelectedDesignationData={setSelectedDesignationData}
                    setReset={setReset}
                />
                <div className="row jobMainContainer">
                    <div className="col-md-3">
                        <JobFilter
                            setSelectedJobTypeData={setSelectedJobTypeData}
                            jobTypeList={jobTypeList}
                            salaryTypeList={salaryTypeList}
                            setSelectedSalaryType={setSelectedSalaryType}
                            workLevelTypeList={workLevelTypeList}
                            setSelectedWorkLevel={setSelectedWorkLevel}
                            workLocationType={workLocationType}
                            setSelectedWorkLocationList={setSelectedWorkLocationList}
                            setReset={setReset}
                        />
                    </div>
                    <div className="col-md-9">
                        <JobList
                            jobsList={jobsList}
                            setJobsList={setJobsList}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isLoading={isLoading}
                            isError={isError}
                            page={page}
                            getAllJobList={getAllJobList}
                            totalPages={totalPages}
                            setTotalPages={setTotalPages}
                            setReset={setReset}
                            filterJobList={filterJobList}
                            reset={reset}
                        />
                    </div>
                    {/* <div className="col-md-2">
                        <div className="jobList py-3 text-center desktopView">
                            <img src={header.ad1} alt="" width={"100%"} />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default JobSearch