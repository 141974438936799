import axios from "../API/axios"
const sessionId = localStorage.getItem('sessionId')
// export const TestApi = async () => {
//     const result = await axios.get(`/Test`)
//     return result.data
// }
// console.log(sessionId);

export const GetProfileInfo = async () => {
    try {
      const response = await axios.post(`/UserProfile/GetOwnInfo`,{
            sessionId,
            deviceType: "Web",
      } );
      return response;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

// export const Register = async (RegisterData) => {
//     const result = await axios.post(`/OAuth/Register`, RegisterData)
//     return result
// }


// const apiService = {
//     getPosts: (url) => axios.get(url),
//     createPost: (data) => axios.post(`${BASE_URL}/posts`, data),
//     updatePost: (id, data) => axios.put(`${BASE_URL}/posts/${id}`, data),
//     deletePost: (id) => axios.delete(`${BASE_URL}/posts/${id}`)
//   };
  
//   export default apiService;