import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
// import { Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

// import React from 'react'
const AddAchievement = async () => {
  // setDisplay(true);
  // const data = {
  //   deviceType: "Web",
  //   sessionId: sessionId,
  //   companyUid: valueC,
  //   jobTitle: value,
  //   positionHeadline: headline,
  //   jobType: jobTypeId,
  //   workLocationType: workLocationId,
  //   workLevelType: workTypeId,
  //   description: description,
  //   locationId: null,
  //   cityId: cityId,
  //   startDate:
  //     startDate === "" ? null : moment(startDate).format("YYYY-MM-DD"),
  //   endDate: endDate === "" ? null : moment(endDate).format("YYYY-MM-DD"),
  //   isPresent: present,
  // };

  console.log("Add Achievement");
  // if (
  //   value === null ||
  // //   value === null ||
  // //   jobTypeId === null ||
  // //   workLocationId === null ||
  // //   workTypeId === null ||
  // //   cityId === null ||
  //   startDate === ""
  // ) {
  // //   setErrorToastMessage("Please fill all the required fields");
  // //   setShowErrorToast(true);
  // } else {
  try {
    await axios
      .post(`UserData/AddExperienceAcheivement`)
      .then((resData) => {
        console.log("resData", resData);
        // getUserProfile();
        //   toast.success(resData.data.message);
        // setToastMessage(resData.data.message);
        // setShowToast(true);
        // handleAchievementCloseUserExperience();
        // reset();
      });
  } catch (error) {
    alert("eroror")
    // if 
    // (error.response && error.response.status === 404) {
    //   setWarningToastMessage(error.response.data.message);
    //   setShowWarningToast(true);
    // } else {
    //   setErrorToastMessage(error.response.data.message);
    //   setShowErrorToast(true);
    // }
  }
  // }
};

const ProfileAchiementExperience = ({
  showAchievementUserExperience,
  handleAchievementCloseUserExperience
}) => {
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      {showAchievementUserExperience && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Achievement</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-3 pointer"
                  onClick={handleAchievementCloseUserExperience}
                />
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Profile Headline
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="1"
                      cols="30"
                    //   value={headline}
                    //   onChange={(e) => setHeadline(e.target.value)}
                    // invalid={display && headline === ""}
                    ></textarea>
                    {/* {display && !headline ? (
                      <span className="error_msg_lbl">
                        Please Enter Profile Headline{" "}
                      </span>
                    ) : null} */}
                  </div>


                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Start Year
                      <span className="text-danger ms-2">*</span>
                    </label>

                    <Flatpickr
                      className="form-control"
                      //   value={startDate}
                      //   onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />

                    {/* {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Start Year{" "}
                      </span>
                    ) : null} */}
                  </div>


                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleAchievementCloseUserExperience}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={AddAchievement}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}


    </>
  )
}

export default ProfileAchiementExperience