
import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const CCVExperienceUpdate = ({
    showExperienceUpdate,
    handleUpdateExperienceClose,
    getUserCcv,
    ExperienceData,
    resumeId
}) => {
    const sessionId = localStorage.getItem("sessionId");
    const [startDate, setStartDate] = useState("");
    const [present, setPresent] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [cityData, setCityData] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [citySearch, setCitySearch] = useState(null);

    const [display, setDisplay] = useState(false);
    console.log("ExperienceData1", ExperienceData);
    // const [companyName, setCompanyName] = useState("");
    // const [email, setEmail] = useState("");
    // const [phoneNo, setPhoneNo] = useState("");
    const [description, setDescription] = useState("");
    // const [publicUrl, setPublicUrl] = useState("");
    // const [website, setWebsite] = useState("");
    // const [tagline, setTagline] = useState("");
    // const [about, setAbout] = useState("");
    // const [avgTurnOver, setAvgTurnOver] = useState("");
    // const [jobTitle, setJobTitle] = useState("");
    const [valueC, setValueC] = useState(null);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [value, setValue] = useState(null);
    const [pageD, setPageD] = useState(0);
    const [typed, setTyped] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [totalPagesDesignation, setTotalPagesDesignation] = useState(0);

    const [pageC, setPageC] = useState(0);
    const [typeC, setTypeC] = useState("");
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [optionsC, setOptionsC] = useState([]);
    const [totalPagesCom, setTotalPagesCom] = useState(0);

    const logValue = debounce((typed) => {
        setTyped(typed);
    }, 250);

    const logValueC = debounce((typeC) => {
        setTypeC(typeC);
    }, 250);

    const UpdateExperience = async () => {
        setDisplay(true);
        const data = {
            rsmId: resumeId,

            deviceType: "Web",
            sessionId: sessionId,
            rsmExpId: ExperienceData?.rsmExpId,
            startDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
            endDate: endDate === "" ? "" : moment(endDate).format("YYYY-MM-DD"),
            isPresent: false,
            jobTitle: value,
            companyUid: valueC,
            description: description,
            locationId: null,
            cityId: cityId,
            positionHeadline: headline,
            jobType: jobTypeId,
            workLocationType: workLocationId,
            workLevelType: workTypeId,
        };
        console.log("UpdateExperience", data);
        try {
            await axios
                .put(`ResumeData/UpdateRsmExperienceInfo`, data)
                .then((resData) => {
                    console.log("resData exp", resData.data.responseData);
                    getUserCcv();
                    setToastMessage(resData.data.message);
                    setShowToast(true);
                    handleUpdateExperienceClose();
                });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    const addOptions = () => {
        setIsLoading(true);
        axios
            .get(
                `Master/GetDesignationDDL?search=${typed}&pageNumber=${pageD + 1
                }&pageSize=100`
            )
            .then((response) => {
                console.log(response);
                // const data = response?.data?.responseData?.data.map((item) => ({
                //   value: item.dsgntnId,
                //   label: item.name,
                // }));
                const data = response?.data?.responseData?.data;

                // Check if data is an array
                if (Array.isArray(data)) {
                    const mappedData = data.map((item) => ({
                        value: item.dsgntnId,
                        label: item.name,
                    }));

                    setOptions((prevOptions) => [...prevOptions, ...mappedData]);
                } else {
                    console.error("Expected data to be an array, but got:", data);
                }
                setPageD((prevPage) => prevPage + 1);
                setIsLoading(false);
                // setOptions((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesDesignation(response.data.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    const handleOpen = () => {
        setPageD(1);
    };

    // const handleChange = (value) => {
    //   setValue(value.label);
    //   setPageD(0);
    // };



    const handleChange = (value) => {
        if (value === null) {
            setValue(null);
        } else {
            setValue(value.label);
        }
    };

    const handleInputChange = (typed) => {
        logValue(typed);
        if (!typed) setPageD(0);
    };

    const handleMenuScrollToBottom = () => {
        if (pageD < totalPagesDesignation) {
            addOptions();
        }
    };

    const handleOpenC = () => {
        setPageC(1);
    };

    const handleChangeC = (value) => {
        if (value === null) {
            setValueC(null);
        } else {
            setValueC(value.value);
        }

        setPageC(0);
    };

    const handleInputChangeC = (typed) => {
        logValueC(typed);
        if (!typed) setPageC(0);
    };

    const handleMenuScrollToBottomC = () => {
        if (pageC < totalPagesCom) {
            Companies();
        }
    };

    const Companies = () => {
        setIsLoadingC(true);
        axios
            .get(
                `Master/GetAllCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
                }&pageSize=100&DeviceType=Web`
            )
            .then((response) => {
                console.log(response);
                const data = response?.data?.responseData?.list.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                    isOrgVrfd: item.isVerified,
                    isOrgRgd: item.isRgstrd,
                }));
                setPageC((prevPage) => prevPage + 1);
                setIsLoadingC(false);
                setOptionsC((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesCom(response.data.responseData.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingC(false);
            });
    };

    useEffect(() => {
        if (typeC) {
            Companies();
        }
    }, [typeC]);

    console.log("optionsC", optionsC);
    const renderOption = ({
        focusedOption,
        focusOption,
        key,
        labelKey,
        option,
        selectValue,
        style,
        valueArray,
    }) => {
        // Your rendering logic here
    };

    const renderOptionC = ({
        focusedOption,
        focusOption,
        key,
        labelKey,
        option,
        selectValue,
        style,
        valueArray,
    }) => {
        // Your rendering logic here
    };

    useEffect(() => {
        if (typed) {
            addOptions();
        }
    }, [typed]);

    const getCity = () => {
        axios
            .get(`Master/GetCitiesDDL?search=${citySearch}`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                }));
                setCityData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (citySearch) {
            getCity();
        }
    }, [citySearch]);
    console.log("optionsC", optionsC);
    const [headline, setHeadline] = useState("");

    //********Job Type List Start  ********//
    const [jobTypeData, setJobTypeData] = useState([]);
    const [jobTypeId, setJobTypeId] = useState(null);
    const getJobType = () => {
        axios
            .get(`Master/GetJobTypesDDL`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({
                    value: item.jbTypId,
                    label: item.name,
                }));
                setJobTypeData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getJobType();
    }, []);
    //********Job Type List End  ********//

    //********Work Level Type List Start  ********//
    const [workTypeData, setWorkTypeData] = useState([]);
    const [workTypeId, setWorkTypeId] = useState(null);
    const getWorkType = () => {
        axios
            .get(`Master/GetWorkLevelTypesDDL`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({ value: item.id, label: item.name }));
                setWorkTypeData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getWorkType();
    }, []);
    //********Work Level Type List End  ********//

    //********Work Location Type List Start  ********//
    const [workLocationData, setWorkLocationData] = useState([]);
    const [workLocationId, setWorkLocationId] = useState(null);
    const getWorkLocationType = () => {
        axios
            .get(`Master/GetWorkLocationTypesDDL`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({ value: item.id, label: item.name }));
                setWorkLocationData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getWorkLocationType();
    }, []);
    //********Work Location Type List End  ********//

    useEffect(() => {
        if (showExperienceUpdate) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [showExperienceUpdate]);

    useEffect(() => {
        if (ExperienceData) {
            setValue(ExperienceData?.jobTitle);
            setTyped(ExperienceData?.jobTitle);
            setValueC(ExperienceData?.companyUid);
            setTypeC(ExperienceData?.companyName);
            setHeadline(ExperienceData?.positionHeadline);
            setJobTypeId(ExperienceData?.jobType);
            setWorkTypeId(ExperienceData?.workLevelType);
            setWorkLocationId(ExperienceData?.workLocationType);
            setCityId(ExperienceData?.cityId);
            setCitySearch(ExperienceData?.cityName);
            setDescription(ExperienceData?.description);
            setStartDate(ExperienceData?.startDate);
            setEndDate(ExperienceData?.endDate);
            setPresent(ExperienceData?.isPresent);
        }
    }, [ExperienceData]);
    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };
    return (
        <>
            {showExperienceUpdate && (
                <div className="profile-experince-upadate">
                    <div
                        className="modal fade show modal-lg"
                        tabIndex="-1"
                        role="dialog"
                        style={{ display: "block" }}
                    >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="setting-header d-flex justify-content-between align-items-center p-2">
                                    <h6>Update Experience</h6>
                                    <CloseSharpIcon
                                        className="material-symbols-sharp me-3 pointer"
                                        onClick={handleUpdateExperienceClose}
                                    />
                                </div>
                                <Form>
                                    <div className="row modal-p settings CreateCompany">
                                        <div className="col-md-6 ">
                                            <label htmlFor="about">
                                                Designation (Job Title)
                                                <span className="text-danger ms-2">*</span>
                                            </label>

                                            <CreatableSelect
                                                value={options.find((option) => option.value === value)}
                                                options={options}
                                                optionRenderer={renderOption}
                                                placeholder={"Search and Select Designation"}
                                                onOpen={handleOpen}
                                                styles={customStyles}
                                                onChange={handleChange}
                                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                                onInputChange={handleInputChange}
                                                isClearable
                                            />


                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Company<span className="text-danger ms-2">*</span>
                                            </label>
                                            <Select
                                                value={optionsC.find(
                                                    (option) => option.value === valueC
                                                )}
                                                // defaultValue={valueC}
                                                //  value={countryList.filter(
                                                //   (c) => c.CountryName === editCountry
                                                // )}
                                                options={optionsC}
                                                optionRenderer={renderOptionC}
                                                placeholder={"Search and Select Designation"}
                                                onOpen={handleOpenC}
                                                onChange={handleChangeC}
                                                onMenuScrollToBottom={handleMenuScrollToBottomC}
                                                onInputChange={handleInputChangeC}
                                                isClearable
                                                formatOptionLabel={(option) => (
                                                    <div>
                                                        {option.label}

                                                        {option.isOrgVrfd === true ? (
                                                            <img src={resume.verified} width={15} className="ms-1" />
                                                        ) : option.isOrgRgd === true ? (
                                                            <img src={resume.register} width={15} className="ms-1" />
                                                        ) : (
                                                            <img src={resume.unregister} width={15} className="ms-1" />
                                                        )}
                                                    </div>
                                                )}
                                                className="iconsDropdown"
                                                styles={customStyles}

                                            // invalid={display && valueC === ""}
                                            />
                                            {/* {display && !valueC ? (
                        <span className="error_msg_lbl">
                          Please Select Company{" "}
                        </span>
                      ) : null} */}
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Profile Headline
                                                {/* <span className="text-danger ms-2">*</span> */}
                                            </label>
                                            <textarea
                                                id="about"
                                                name="about"
                                                rows="2"
                                                cols="50"
                                                value={headline}
                                                onChange={(e) => setHeadline(e.target.value)}
                                            // invalid={display && headline === ""}
                                            ></textarea>
                                            {/* {display && !headline ? (
                        <span className="error_msg_lbl">
                          Please Enter Profile Headline{" "}
                        </span>
                      ) : null} */}
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Employment type (Job Type){" "}
                                                <span style={{ color: "red" }}>*</span>
                                            </label>

                                            <Select
                                                // defaultValue={jobTypeId}
                                                value={jobTypeData.find(
                                                    (options) => options.value === jobTypeId
                                                )}
                                                onChange={(e) => {
                                                    setJobTypeId(e.value);
                                                }}
                                                options={jobTypeData}
                                                styles={customStyles}
                                                placeholder={"Select Job Type"}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Work Level Type <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Select
                                                // defaultValue={workTypeId}
                                                value={workTypeData.find(
                                                    (options) => options.value === workTypeId
                                                )}
                                                onChange={(e) => {
                                                    setWorkTypeId(e.value);
                                                }}
                                                options={workTypeData}
                                                styles={customStyles}
                                                placeholder={"Select Work Level Type"}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Work Location Type{" "}
                                                <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Select
                                                // defaultValue={workLocationId}
                                                value={workLocationData.find(
                                                    (options) => options.value === workLocationId
                                                )}
                                                onChange={(e) => {
                                                    setWorkLocationId(e.value);
                                                }}
                                                options={workLocationData}
                                                styles={customStyles}
                                                placeholder={"Select Work Location Type"}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                City<span className="text-danger ms-2">*</span>
                                            </label>
                                            <Select
                                                // defaultValue={cityId}
                                                value={cityData.find(
                                                    (options) => options.value === cityId
                                                )}
                                                onChange={(selectedOption) =>
                                                    setCityId(selectedOption.value)
                                                }
                                                options={cityData}
                                                placeholder={"Search and Select City"}
                                                onInputChange={(inputValue) => {
                                                    setCitySearch(inputValue);
                                                }}
                                                styles={customStyles}

                                            // invalid={display && citySearch === ""}
                                            />
                                            {/* {display && !citySearch ? (
                        <span className="error_msg_lbl">
                          Please Select City{" "}
                        </span>
                      ) : null} */}
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Description
                                            </label>
                                            <textarea
                                                id="about"
                                                name="about"
                                                rows="2"
                                                cols="50"
                                                defaultValue={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            // invalid={display && description === ""}
                                            ></textarea>
                                            {/* {display && !description ? (
                        <span className="error_msg_lbl">
                          Please Select Designation{" "}
                        </span>
                      ) : null} */}
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                Start Year<span className="text-danger ms-2">*</span>
                                            </label>

                                            <Flatpickr
                                                className="form-control"
                                                value={startDate}
                                                onChange={(date) => setStartDate(date[0])}
                                                id="dob"
                                                options={{
                                                    maxDate: "today", // Disables future dates
                                                    onReady: (selectedDates, dateStr, instance) => {
                                                        preventYearScroll(instance); // Call the function to stop scroll on year
                                                    },
                                                    onOpen: (selectedDates, dateStr, instance) => {
                                                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                                                    },
                                                }}
                                            />

                                            {display && !startDate ? (
                                                <span className="error_msg_lbl">
                                                    Please Select Start Year{" "}
                                                </span>
                                            ) : null}
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label htmlFor="about">
                                                End Year
                                                {/* <span className="text-danger ms-2">*</span> */}
                                            </label>

                                            <Flatpickr
                                                className="form-control"
                                                value={endDate}
                                                onChange={(date) => setEndDate(date[0])}
                                                id="dob"
                                                options={{
                                                    maxDate: "today", // Disables future dates
                                                    onReady: (selectedDates, dateStr, instance) => {
                                                        preventYearScroll(instance); // Call the function to stop scroll on year
                                                    },
                                                    onOpen: (selectedDates, dateStr, instance) => {
                                                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                                                    },
                                                }}
                                            />

                                            {/* {display && !endDate ? (
                        <span className="error_msg_lbl">
                          Please Select End Year{" "}
                        </span>
                      ) : null} */}
                                        </div>
                                        {/* 
                    <div className="col-md-12 mt-2 d-flex ">
                      <input
                        type="checkbox"
                        style={{ width: "40px" }}
                        //   id={`check-${item.id}`}
                        //   checked={selectedWorkLevelList.includes(item.id)}
                        onChange={() => setPresent(true)}
                      />
                      <label htmlFor="about">IsPresent</label>
                    </div> */}

                                        <div className="text-end mt-4">
                                            <button
                                                type="button"
                                                className="closebtn me-2"
                                                onClick={handleUpdateExperienceClose}
                                                style={{ width: "60px" }}
                                            >
                                                close
                                            </button>
                                            <button
                                                type="button"
                                                className="mainBtn1"
                                                onClick={UpdateExperience}
                                                style={{ width: "70px" }}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default CCVExperienceUpdate;
