import React from "react";
import MainPage from "./MainPage";
import { header } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="about-main intro-section p-3 pt-1">
        {/* <MainPage /> */}
        <div className="logo-content-cont d-flex align-items-center">
          <div className="weLinkLogo ps-2">
            <img src={header.mainLogo} alt="" />
          </div>
          <div>
            <nav>
              <ul className="d-flex privacy-nav-text">
                <li className="p-4">
                  <Link to="/about">ABOUT</Link>
                </li>
                <li className="p-4">
                  <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                </li>
                <li className="p-4">
                  <Link to="/termsAndCondition">TERMS AND CONDITION</Link>
                </li>
                <li className="p-4">
                  <Link to="/userAgreement">USER AGREEMENT</Link>
                </li>
                <li className="p-4">
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="privacy-policy-main-cont">

          <div className="policyHeading">
            <h1 className="f-700 f-45 mb-4 content-color">Privacy Policy</h1>
            <div className="content-para">
              <p className="mb-3 f-14 content-color privacy-para">
                This Privacy Policy applies when you use our Services (described
                below). We offer our users choices about the data we collect,
                use, and share as described in this Privacy Policy, Cookie
                Policy, Settings, and our Help Center
              </p>
              <p className="f-14 content-color">
                This Privacy Policy applies when you use our Services (described
                below). We offer our users choices about the data we collect,
                use, and share as described in this Privacy Policy, Cookie
                Policy, Settings, and our Help Center
              </p>
            </div>
          </div>

          <div className="policy-sub-content">
            <h3 className="f-28 content-color mt-4 f-600">1. Introduction</h3>
            <p className="f-14 content-color">
              We are a social network and online platform for all, employers,
              job seekers, and others. People use our Services to find and be
              found for business opportunities, to connect with others and find
              information. Our Privacy Policy applies to any Member or Visitor
              to our Services
            </p>
            <p className="f-14 content-color">
              Our registered users (“Members”) share their professional
              identities, engage with their network, exchange knowledge and
              professional insights, post and view relevant content, learn and
              develop skills, and find business and career opportunities.
              Content and data on some of our Services is viewable to
              non-members (“Visitors”).
            </p>

            <h4 className="f-16 content-color mt-4 f-600">Services</h4>
            <p className="f-14 content-color">
              This Privacy Policy, including our Cookie Policy applies to your
              use of our Services. This Privacy Policy applies to
              WeLinkjobs.com, WeLinkjobs apps and other current and/or future
              WeLinkjobs -related sites, apps, communications and services
              (“Services”), including off-site Services, but excluding services
              that state that they are offered under a different privacy policy.
              For certain countries’ residents, additional disclosures required
              by the local laws may be applicable.
            </p>

            <h4 className="f-16 content-color mt-4 f-600">
              Data Controllers and Contracting Parties
            </h4>
            <p className="f-14 content-color">
              WeLinkjobs LLC will be the controller of your personal data
              provided to, or collected by or for, or processed in connection
              with, our Services. As a Visitor or Member of our Services, the
              collection, use and sharing of your personal data is subject to
              this Privacy Policy and other documents referenced in this Privacy
              Policy, as well as updates.
            </p>

            <h4 className="f-16 content-color mt-4 f-600">Change</h4>
            <p className="f-14 content-color">
              Changes to the Privacy Policy apply to your use of our Services
              after the “effective date.” WeLinkjobs (“we” or “us”) can modify
              this Privacy Policy, and if we make material changes to it, we
              will provide notice through our Services, or by other means, to
              provide you the opportunity to review the changes before they
              become effective. If you object to any changes, you may close your
              account. You acknowledge that your continued use of our Services
              after we publish or send a notice about our changes to this
              Privacy Policy means that the collection, use and sharing of your
              personal data is subject to the updated Privacy Policy, as of its
              effective date.
            </p>
          </div>

          <div className="policy-sub-content">
            <div>
              <h3 className="f-28 content-color mt-4 mb-3 f-600">
                2.1. Data We Collect
              </h3>
              <h5 className="f-18 mb-3 content-color">1.1 Data You Provide To Us</h5>
              <p className="mb-3 content-color">
                You provide data to create an account with us.
              </p>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Registration
              </h4>
              <p className="f-14 content-color">
                We are a social network and online platform for all, employers,
                job seekers, and others. People use our Services to find and be
                found for business opportunities, to connect with others and
                find information. Our Privacy Policy applies to any Member or
                Visitor to our Services Our registered users (“Members”) share
                their professional identities, engage with their network,
                exchange knowledge and professional insights, post and view
                relevant content, learn and develop skills, and find business
                and career opportunities. Content and data on some of our
                Services is viewable to non-members (“Visitors”).
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Profile
              </h4>
              <p className="f-14 content-color">
                You have choices about the information on your profile, such as
                your education, work experience, skills, photo, city and
                endorsements. You don’t have to provide additional information
                on your profile; however, profile information helps you to get
                more from our Services, including helping recruiters and
                business opportunities find you. It’s your choice whether to
                include more personal or sensitive information on your profile
                and to make that sensitive information public. Please do not
                post or add personal data to your profile that you would not
                want to be publicly available. You give other data to us, such
                as by syncing your address book or calendar.
              </p>

              <h4 className="f-16 content-color mt-4 f-600">
                Posting and Uploading
              </h4>
              <p className="f-14 content-color">
                Posting and Uploading We collect personal data from you when you
                provide, post or upload it to our Services, such as when you
                fill out a form, (e.g., with demographic data or salary),
                respond to a survey, or submit a resume or fill out a job
                application on our Services. If you opt to import your address
                book, we receive your contacts (including contact information
                your service provider(s) or app automatically added to your
                address book when you communicated with addresses or numbers not
                already in your list).
              </p>
              <p className="f-14 content-color">
                If you sync your contacts or calendars with our Services, we
                will collect your address book and calendar meeting information
                to keep growing your network by suggesting connections for you
                and others, and by providing information about events, e.g.
                times, places, attendees and contacts. You don’t have to post or
                upload personal data; though if you don’t, it may limit your
                ability to grow and engage with your network over our Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">1.2 Data From Others</h5>
              <p className="mb-3 content-color">Others may post or write about you.</p>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Content and News
              </h4>
              <p className="f-14 content-color">
                You and others may post content that includes information about
                you (as part of articles, posts, comments, videos) on our
                Services. We also may collect public information about you, such
                as professional-related news and accomplishments, and make it
                available as part of our Services, including, as permitted by
                your settings, in notifications to others of mentions in the
                news. Others may sync their contacts or calendar with our
                Services
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Contact and Calendar Information
              </h4>
              <p className="f-14 content-color">
                We receive personal data (including contact information) about
                you when others import or sync their contacts or calendar with
                our Services, associate their contacts with Member profiles,
                scan and upload business cards, or send messages using our
                Services (including invites or connection requests). If you or
                others opt-in to sync email accounts with our Services, we will
                also collect “email header” information that we can associate
                with Member profiles. Customers and partners may provide data to
                us.
              </p>

              <h4 className="f-16 content-color mt-4 f-600">Partners</h4>
              <p className="f-14 content-color">
                We receive personal data (e.g., your job title and work email
                address) about you when you use the services of our customers
                and partners, such as employers or prospective employers and
                applicant tracking systems providing us job application data.
              </p>
              <h4 className="f-16 content-color mt-4 f-600">
                Related Companies and Other Services
              </h4>
              <p className="f-14 content-color">
                We receive data about you when you use some of the other
                services provided by us or our affiliates. For example, you may
                choose to send us information about your contacts in Microsoft
                apps and services, such as Outlook, for improved professional
                networking activities on our Services
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">1.3 Service Use</h5>
              <p className="mb-1 f-14 content-color">
                We log your visits and use of our Services, including mobile
                apps
              </p>
              <p className="f-14 content-color">
                We may log usage data when you visit or otherwise use our
                Services, including our sites, app and platform technology, such
                as when you view or click on content or ads (on or off our sites
                and apps), perform a search, install or update one of our mobile
                apps, share articles or apply for jobs. We use log-ins, cookies,
                device information and internet protocol (“IP”) addresses to
                identify you and log your use.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                1.4 Cookies and Similar Technologies
              </h5>
              <p className="mb-1 f-14 content-color">
                We may collect data through cookies and similar technologies.
              </p>
              <p className="f-14 content-color">
                We may use cookies and similar technologies (e.g., pixels and ad
                tags) to collect data (e.g., device IDs) to recognize you and
                your device(s) on, off and across different services and devices
                where you have engaged with our Services. We may also allow some
                others to use cookies. We also collect (or rely on others who
                collect) information about your device where you have not
                engaged with our Services (e.g., ad ID, IP address, operating
                system and browser information) so we can provide our Members
                with relevant ads and better understand their effectiveness. You
                can manage our use of data from cookies and similar technologies
                that track your behavior on the sites of others for ad targeting
                and other ad-related purposes.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">1.5 Your Device and Location</h5>
              <p className="mb-1 f-14 content-color">
                We may receive data through cookies and similar technologies
              </p>
              <p className="f-14 content-color">
                When you visit or leave our Services (including some plugins and
                our cookies or similar technology on the sites of others), we
                may receive the URL of both the site you came from and the one
                you go to and the time of your visit. We also may get
                information about your network and device (e.g., IP address,
                proxy server, operating system, web browser and add-ons, device
                identifier and features, cookie IDs and/or ISP, or your mobile
                carrier). If you use our Services from a mobile device, that
                device will send us data about your location based on your phone
                settings. We will ask you to opt-in before we use GPS or other
                tools to identify your precise location.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">1.6 Messages</h5>
              <p className="mb-1 f-14 content-color">
                If you communicate through our Services, we learn about that.
              </p>
              <p className="f-14 content-color">
                We collect information about you when you send, receive, or
                engage with messages in connection with our Services. For
                example, if you get a WeLinkJobs connection request, we track
                whether you have acted on it and will send you reminders. We may
                also use automatic scanning technology on messages to support
                and protect our site. For example, we use this technology to
                suggest possible responses to messages and to manage or block
                content that violates our User Agreement or other applicable
                polices from our Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                1.7 Workplace and School Provided Information
              </h5>
              <p className="f-14 content-color">
                When your organization (e.g., employer or school) buys some
                Services for you to use, they give us data about you. Others
                buying our Services for your use, such as your employer or your
                school, provide us with personal data about you and your
                eligibility to use the Services that they purchase for use by
                their workers, students or alumni. For example, we will get
                contact information for our administrators and for authorizing
                users of our Services, such as our recruiting or sales.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                1.8 Sites and Services of Others
              </h5>
              <p className="f-14 content-color">
                We get data when you visit sites that include our ads, cookies
                or some of our plugins or when you log-in to others’ services
                with your WeLinkjobs account. We receive information about your
                visits and interaction with services provided by others when you
                log-in with WeLinkjobs or visit others’ services that include
                some of our plugins (such as “Apply with WeLinkjobs”) or our
                ads, cookies or similar technologies.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">1.9 Other</h5>
              <p className="f-14 content-color">
                We get data when you visit sites that include our ads, cookies
                or some of our plugins or when you log-in to others’ services
                with your WeLinkjobs account. We receive information about your
                visits and interaction with services provided by others when you
                log-in with WeLinkjobs or visit others’ services that include
                some of our plugins (such as “Apply with WeLinkjobs”) or our
                ads, cookies or similar technologies.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.2. How We Use Your Data</h5>
              <p className="f-14 content-color">
                We use your data to provide, support, personalize and develop
                our Services.
              </p>
              <p className="f-14 content-color">
                How we use your personal data will depend on which Services you
                use, how you use those Services and the choices you make in your
                settings. We use the data that we have about you to provide and
                personalize our Services, including with the help of automated
                systems and inferences we make, so that our Services (including
                ads) can be more relevant and useful to you and others.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">2.1 Services</h5>
              <p className="f-14 content-color">
                Our Services help you connect with others, find and be found for
                work and business opportunities, stay informed, get training and
                be more productive. We use your data to authorize access to our
                Services and honor your settings.
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Stay Connected
              </h4>
              <p className="f-14 content-color">
                Our Services allow you to stay in touch and up to date with
                colleagues, partners, clients, and other professional contacts.
                To do so, you can connect with the professionals who you choose,
                and who also wish to connect with you. Subject to your and their
                settings, when you connect with other Members, you will be able
                to search each others’ connections in order to exchange
                professional opportunities.
              </p>
              <p className="f-14 content-color">
                opportunities. We use data about you (such as your profile,
                profiles you have viewed or data provided through address book
                uploads or partner integrations) to help others find your
                profile, suggest connections for you and others, and enable you
                to invite others to become a Member and connect with you. You
                can also opt-in to allow us to use your precise location or
                proximity to others for certain tasks. It is your choice whether
                to invite someone to our Services, send a connection request, or
                allow another Member to become your connection. When you invite
                someone to connect with you, your invitation will include your
                network and basic profile information (e.g., name, profile
                photo, job title, region). We will send invitation reminders to
                the person you invited. You can choose whether or not to share
                your own list of connections with your connections.
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Stay Informed
              </h4>
              <p className="f-14 content-color">
                Our Services allow you to stay informed about news, events and
                ideas regarding professional topics you care about, and from
                professionals you respect. Our Services also allow you to
                improve your professional skills, or learn new ones. We use the
                data we have about you (e.g., data you provide, data we collect
                from your engagement with our Services and inferences we make
                from the data we have about you), to personalize our Services
                for you, such as by recommending or ranking relevant content and
                conversations on our Services. We also use the data we have
                about you to suggest skills you could add to your profile and
                skills that you might need to pursue your next opportunity. So,
                if you let us know that you are interested in a new skill (e.g.,
                by watching a learning video), we will use this information to
                personalize content in your feed, suggest that you follow
                certain members on our site, or suggest related learning content
                to help you towards that new skill. We use your content,
                activity and other data, including your name and photo, to
                provide notices to your network and others. For example, subject
                to your setting, we may notify others that you have updated your
                profile, posted content, used a feature, made new connections or
                were mentioned.
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Career
              </h4>
              <p className="f-14 content-color">
                Our Services allow you to explore careers, evaluate educational
                opportunities, and seek out, and be found for, career
                opportunities. Your profile can be found by those looking to
                hire or be hired by you. We will use your data to recommend jobs
                or mentees, show you and others relevant professional contacts.
                You can signal that you are interested in changing jobs and
                share information with recruiters. We will use your data to
                recommend jobs to you and you to recruiters. We may use
                automated systems to provide content and recommendations to help
                make our Services more relevant to our Members, Visitors and
                customers. Keeping your profile accurate and up-to-date may help
                you better connect to others and to opportunities through our
                Services. Productivity Our Services allow you to collaborate
                with colleagues, search for potential clients, customers,
                partners and others to do business with. Our Services allow you
                to communicate with other Members and schedule and prepare
                meetings with them. We may scan messages to provide “bots” or
                similar tools that facilitate tasks such as scheduling meetings,
                drafting responses, summarizing messages or recommending next
                steps.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">2.2 Paid Services</h5>
              <p className="f-14 content-color">
                Some of our Services maybe paid and support paying users to
                search for and contact Members through our Services, such as
                searching for and contacting job candidates, sales leads and
                co-workers, manage talent and promote content through social
                media.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-colors">2.3 Communications</h5>
              <p className="f-14 content-color">
                We contact you and enable communications between Members. We
                offer settings to control what messages you receive and how
                often you receive some types of messages.
              </p>
              <p className="f-14 content-color">
                We will contact you through email, mobile phone, notices posted
                on our websites or apps, messages to your WeLinkjobs inbox, and
                other ways through our Services, including text messages and
                push notifications. We will send you messages about the
                availability of our Services or other service-related issues. We
                may also send messages about how to use our Services, network
                updates, reminders, job suggestions and promotional messages
                from us and our partners. You may change your communication
                preferences at any time. Please be aware that you cannot opt out
                of receiving service messages from us, including security and
                legal notices.
              </p>
              <p className="f-14 content-color">
                We also enable communications between you and others through our
                Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">2.4 Advertising</h5>
              <p className="f-14 content-color">
                We serve you tailored ads both on and off our Services. We offer
                you choices regarding personalized ads, but you cannot opt-out
                of seeing other ads.
              </p>
              <p className="f-14 content-color">
                We target (and measure the performance of) ads to Members,
                Visitors and others both on and off our Services directly or
                through a variety of partners, using the collected data, whether
                separately or combined. If you take a social action (such as
                like, comment or share) on ads, your action is associated with
                your name and may be viewable by others, including the
                advertiser. Subject to your setting, if you take a social action
                on the WeLinkjobs Services, that action may be mentioned with
                related ads. For example, when you like a company we may include
                your name and photo when their sponsored content is shown.
              </p>
            </div>

            <div>
              <h4 className="f-18 content-color mt-4 f-600 italic-font">
                Info to Ad Providers
              </h4>
              <p className="f-14 content-color">
                We do not share your personal data with any third-party
                advertisers or ad networks except for: (i) hashed IDs or device
                identifiers (to the extent they are personal data in some
                countries); (ii) with your separate permission (e.g., in a lead
                generation form) or (iii) data already visible to any users of
                the Services (e.g., profile). However, if you view or click on
                an ad on or off our Services, the ad provider will get a signal
                that someone visited the page that displayed the ad, and they
                may, through the use of mechanisms such as cookies, determine it
                is you. Advertising partners can associate personal data
                collected by the advertiser directly from you with hashed IDs or
                device identifiers received from us
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">2.5 Marketing</h5>
              <p className="f-14 content-color">We promote our Services to you and others.</p>
              <p className="f-14 content-color">
                In addition to advertising our Services, we use Members’ data
                and content for invitations and communications promoting
                membership and network growth, engagement and our Services, such
                as by showing your connections that you have used a feature on
                our Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                2.6 Developing Services and Research
              </h5>
              <p className="f-14 content-color">
                We develop our Services and conduct research
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Service Development
              </h4>
              <p className="f-14 content-color">
                We use data, including public feedback, to conduct research,
                analysis and development for our Services in order to provide
                you and others with a better, more intuitive and personalized
                experience, drive membership growth and engagement on our
                Services, and help connect professionals to each other and to
                economic opportunity.
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Other Research
              </h4>
              <p className="f-14 content-color">
                We seek to create economic opportunity for Members of the global
                workforce and to help them be more productive and successful. We
                use the personal data available to us to research social,
                economic and workplace trends, such as jobs availability and
                skills needed for these jobs and policies that help bridge the
                gap in various industries and geographic areas. In some cases,
                we work with third parties to perform this research, under
                controls that are designed to protect your privacy. We publish
                or allow others to publish economic insights, presented as
                aggregated data rather than personal data.
              </p>
            </div>

            <div>
              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Surveys
              </h4>
              <p className="f-14 content-color">
                Polls and surveys are conducted by us and others through our
                Services. You are not obligated to respond to polls or surveys,
                and you have choices about the information you provide.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">2.7 Customer Support</h5>
              <p className="f-14 content-color">We use data to help you and fix problems.</p>
              <p className="f-14 content-color">
                We use data (which can include your communications) to
                investigate, respond to and resolve complaints and for Service
                issues (e.g., bugs).
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                2.8 Insights That Do Not Identify You
              </h5>
              <p className="f-14 content-color">
                We use data to generate insights that do not identify you. We
                use your data to produce and share insights that do not identify
                you. For example, we may use your data to generate statistics
                about our members, their profession or industry, to calculate ad
                impressions served or clicked on, or to publish visitor
                demographics for a Service or create demographic workforce
                insights.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                2.9 Security and Investigations
              </h5>
              <p className="f-14 content-color">
                We use data for security, fraud prevention and investigations.
              </p>
              <p className="f-14 content-color">
                We use your data (including your communications) for security
                purposes or to prevent or investigate possible fraud or other
                violations of our policies and/or attempts to harm our Members,
                Visitors or others.
              </p>
            </div>
          </div>

          <div className="policy-sub-content">
            <div>
              <h3 className="f-28 content-color mt-4 mb-3 f-600">
                4.3. Share Information
              </h3>
              <h5 className="f-18 mb-3 content-color">3.1 Our Services</h5>
              <p className="mb-3 content-color">
                Any data that you include on your profile and any content you
                post or social action (e.g., likes, follows, comments, shares)
                you take on our Services will be seen by others, consistent with
                your settings
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Profile
              </h4>
              <p className="f-14 content-color">
                Your profile is fully visible to all Members. Subject to your
                settings, it can also be visible to others on or off of our
                Services (e.g., Visitors to our Services or users of third-
                party search engines).
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Posts, Likes, Follows, Comments, Messages, and other Activities
              </h4>
              <p className="f-14 content-color">
                Our Services allow viewing and sharing information including
                through posts, likes, follows and comments. Your employer can
                see how you use Services they provided for your work (e.g. as a
                recruiter or sales agent) and related information. We will not
                show them your job searches or personal messages.
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Enterprise Accounts
              </h4>
              <p className="f-14 content-color">
                Your employer may offer you access to our enterprise Services
                such as Recruiter, Sales Navigator, or others. Your employer can
                review and manage your use of such enterprise Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 content-color mb-3 mt-4">
                3.2 Communication Archival
              </h5>
              <p className="f-14 content-color">
                Regulated Members may need to store communications outside of
                our Service. Some Members (or their employers) need, for legal
                or professional compliance, to archive their communications and
                social media activity, and will use services of others to
                provide these archival services. We may enable archiving of
                messages by and to those Members outside of our Services. For
                example, a financial advisor needs to archive communications
                with her clients through our Services in order to maintain her
                professional financial advisor license
              </p>
            </div>

            <div>
              <h5 className="f-18 content-color mt-4">3.3 Others’ Services</h5>
              <p className="f-14 content-color">
                You may link your account with others’ services so that they can
                look up your contacts’ profiles, post your shares on such
                platforms, or enable you to start conversations with your
                connections on such platforms. Excerpts from your profile will
                also appear on the services of others.
              </p>
            </div>

            <div>
              <h5 className="f-18 content-color mt-4">3.4 Related Services</h5>
              <p className="f-14 content-color">
                We share your data across our different Services and WeLinkjobs
                affiliated entities. We will share your personal data with our
                affiliates to provide and develop our Services. We may combine
                information internally across the different Services covered by
                this Privacy Policy to help our Services be more relevant and
                useful to you and others. For example, we may personalize your
                feed or job recommendations based on your learning history
              </p>
            </div>

            <div>
              <h5 className="f-18 content-color mt-4">3.5 Service Providers</h5>
              <p className="f-14 content-color">
                We may use others to help us with our Services.
              </p>
              <p className="f-14 content-color">
                We use others to help us provide our Services (e.g.,
                maintenance, analysis, audit, payments, fraud detection,
                marketing, and development). They will have access to your
                information as necessary to perform these tasks on our behalf
                and are obligated not to disclose or use it for other purposes.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.6 Legal Disclosures</h5>
              <p className="mb-1 f-14 content-color">
                We may need to share your data when we believe it’s required by
                law or to help protect the rights and safety of you, us, or
                others. It is possible that we will need to disclose information
                about you when required by law, subpoena, or other legal process
                or if we have a good faith belief that disclosure is reasonably
                necessary.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.7 Change in Control or Sale</h5>
              <p className="mb-1 f-14 content-color">
                We may share your data when our business is sold to others, but
                it must continue to be used in accordance with this Privacy
                Policy. We can also share your personal data as part of a sale,
                merger or change in control, or in preparation for any of these
                events. Any other entity which buys us, or part of our business
                will have the right to continue to use your data, but only in
                the manner set out in this Privacy Policy unless you agree
                otherwise.
              </p>
            </div>

            <div>
              <h3 className="f-28 content-color mt-4 mb-3">
                4.3. Share Information
              </h3>
              <h5 className="f-18 mb-3 content-color">3.1 Our Services</h5>
              <p className="mb-3 content-color">
                Any data that you include on your profile and any content you
                post or social action (e.g., likes, follows, comments, shares)
                you take on our Services will be seen by others, consistent with
                your settings
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Profile
              </h4>
              <p className="f-14 content-color">
                Your profile is fully visible to all Members. Subject to your
                settings, it can also be visible to others on or off of our
                Services (e.g., Visitors to our Services or users of third-
                party search engines).
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Posts, Likes, Follows, Comments, Messages, and other Activities
              </h4>
              <p className="f-14 content-color">
                Our Services allow viewing and sharing information including
                through posts, likes, follows and comments. Your employer can
                see how you use Services they provided for your work (e.g. as a
                recruiter or sales agent) and related information. We will not
                show them your job searches or personal messages.
              </p>

              <h4 className="f-16 content-color mt-4 f-600 italic-font">
                Enterprise Accounts
              </h4>
              <p className="f-14 content-color">
                Your employer may offer you access to our enterprise Services
                such as Recruiter, Sales Navigator, or others. Your employer can
                review and manage your use of such enterprise Services.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.2 Communication Archival</h5>
              <p className="mb-1 f-14 content-color">
                Regulated Members may need to store communications outside of
                our Service. Some Members (or their employers) need, for legal
                or professional compliance, to archive their communications and
                social media activity, and will use services of others to
                provide these archival services. We may enable archiving of
                messages by and to those Members outside of our Services. For
                example, a financial advisor needs to archive communications
                with her clients through our Services in order to maintain her
                professional financial advisor license.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.3 Others’ Services</h5>
              <p className="mb-1 f-14 content-color">
                You may link your account with others’ services so that they can
                look up your contacts’ profiles, post your shares on such
                platforms, or enable you to start conversations with your
                connections on such platforms. Excerpts from your profile will
                also appear on the services of others.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.4 Related Services</h5>
              <p className="f-14 content-color">
                We share your data across our different Services and WeLinkjobs
                affiliated entities. We will share your personal data with our
                affiliates to provide and develop our Services. We may combine
                information internally across the different Services covered by
                this Privacy Policy to help our Services be more relevant and
                useful to you and others. For example, we may personalize your
                feed or job recommendations based on your learning history
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.5 Service Providers</h5>
              <p className="f-14 content-color">
                We may use others to help us with our Services.
              </p>
              <p className="f-14 content-color">
                We may use others to help us with our Services. We use others to
                help us provide our Services (e.g., maintenance, analysis,
                audit, payments, fraud detection, marketing, and development).
                They will have access to your information as necessary to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for other purposes.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.6 Legal Disclosures</h5>
              <p className="f-14 content-color">
                We may need to share your data when we believe it’s required by
                law or to help protect the rights and safety of you, us, or
                others. It is possible that we will need to disclose information
                about you when required by law, subpoena, or other legal process
                or if we have a good faith belief that disclosure is reasonably
                necessary.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">3.7 Change in Control or Sale</h5>
              <p className="f-14 content-color">
                We may share your data when our business is sold to others, but
                it must continue to be used in accordance with this Privacy
                Policy. We can also share your personal data as part of a sale,
                merger or change in control, or in preparation for any of these
                events. Any other entity which buys us, or part of our business
                will have the right to continue to use your data, but only in
                the manner set out in this Privacy Policy unless you agree
                otherwise.
              </p>
            </div>
          </div>

          <div className="policy-sub-content">
            <div>
              <h3 className="f-28 content-color mt-4 mb-3 f-600">
                5.4. Your Choices & Obligations
              </h3>
              <h5 className="f-18 mb-3 content-color">4.1 Data Retention</h5>
              <p className="mb-3 content-color">
                We may keep most of your personal data for as long as your
                account is open. We generally retain your personal data as long
                as you keep your account open or as needed to provide you
                Services. This includes data you or others provided to us, and
                data generated or inferred from your use of our Services. Even
                if you only use our Services when looking for a new job every
                few years, we will retain your information and keep your profile
                open, unless you close your account. In some cases, we choose to
                retain certain information (e.g., insights about Services use)
                in a depersonalized or aggregated form.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                4.2 Rights to Access and Control Your Personal Data
              </h5>
              <p className="f-14 content-color">
                You can access or delete your personal data. You have many
                choices about how your data is collected, used, and shared. We
                provide many choices about the collection, use and sharing of
                your data, including deleting or correcting data you include in
                your profile and controlling the visibility of your posts. We
                offer you settings to control and manage the personal data we
                have about you.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">4.3 Account Closure</h5>
              <p className="f-14 content-color">
                We may keep some of your data even after you close your account
              </p>
              <p className="f-14 content-color">
                If you choose to close your WeLinkjobs account, your personal
                data will generally stop being visible to others on our Services
                within 24-72 hours.
              </p>
              <p className="f-14 content-color">
                We may at our discretion retain your personal data or part of it
                even after you have closed your account if reasonably necessary
                to comply with our legal obligations (including law enforcement
                requests), meet regulatory requirements, resolve disputes,
                maintain security, prevent fraud and abuse, enforce our User
                Agreement, or fulfill your request to "unsubscribe" from further
                messages from us. We will retain de-personalized information
                after your account has been closed.
              </p>
              <p className="f-14 content-color">
                Information you have shared with others (e.g., through InMail,
                updates or group posts) will remain visible after you close your
                account or delete the information from your own profile or
                mailbox, and we do not control data that other Members have
                copied out of our Services. Groups content and ratings or review
                content associated with closed accounts will show an unknown
                user as the source. Your profile may continue to be displayed in
                the services of others (e.g., search engine results) until they
                refresh their cache.
              </p>
            </div>
          </div>

          <div className="policy-sub-content">
            <div>
              <h3 className="f-28 content-color mt-4 mb-3 f-600">
                6.5. Other Important Information
              </h3>
              <h5 className="f-18 mb-3 content-color">5.1. Security </h5>
              <p className="mb-3 content-color">
                We monitor for and try to prevent security breaches. Please use
                the security features available through our Services. We
                implement security safeguards designed to protect your data. We
                regularly monitor our systems for possible vulnerabilities and
                attacks. However, we cannot warrant the security of any
                information that you send us. There is no guarantee that data
                may not be accessed, disclosed, altered, or destroyed by breach
                of any of our physical, technical, or managerial safeguards.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                5.2. Cross-Border Data Transfers
              </h5>
              <p className="f-14 content-color">
                We may store and use your data outside your country. We process
                data across borders. Countries where we process data may have
                laws which are different from, and potentially not as protective
                as, the laws of your own country.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">
                5.3 Lawful Bases for Processing
              </h5>
              <p className="f-14 content-color">
                We have lawful bases to collect, use and share data about you.
                You have choices about our use of your data. At any time, you
                can withdraw consent you have provided by going to settings.
              </p>
            </div>

            <div>
              <h5 className="f-18 mb-3 mt-4 content-color">5.4 Contact Information</h5>
              <p className="f-14 content-color">
                You can contact us or use other options to resolve any
                complaints. If you have questions or complaints regarding this
                Policy, please first contact WeLinkjobs online. You can also
                reach us by physical mail to our addresses. If contacting us
                does not resolve your complaint, you have more options under
                your laws.
              </p>
            </div>

            <div>
              <h4 className="f-18 content-color mt-4 f-600 italic-font">
                Consent
              </h4>
              <p className="f-14 content-color">
                Where we process data based on consent, we will ask for your
                explicit consent. You may withdraw your consent at any time, but
                that will not affect the lawfulness of the processing of your
                personal data prior to such withdrawal. Where we rely on
                contract, we will ask that you agree to the processing of
                personal data that is necessary for entering into or performance
                of your contract with us. We will rely on legitimate interests
                as a basis for data processing where the processing of your data
                is not overridden by your interests or fundamental rights and
                freedoms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
