import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

const ChatInteraction = ({ getChatList, chatId, item }) => {
  console.log('hasdgjhd', item);

  // console.log(isPined);
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //************************Archive Chat ************************//
  const StarChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
    };
    try {
      await axios.post(`Message/StarChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        getChatList();
      });
    } catch (e) {
      console.log(e);

      //   toast.error(error.response.data.message);
      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************Archive Chat End  ************************//
  //************************Delete Chat ************************//
  // const [deleteEveryOne, setDeleteEveryOne] = useState(false)
  const DeleteChat = async (deleteEveryOne) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
      forEveryOne: deleteEveryOne
    };
    console.log('responseData', responseData);

    try {
      await axios
        .delete(`Message/DeleteChat`, { data: responseData }).then((res) => {
          // toast.success(res.data.message);
          // setToastMessage(res.data.message);
          // setShowToast(true);
          getChatList();
          localStorage.setItem("DeletedChatId", chatId);
        });
    } catch (e) {
      console.log(e);

      //   toast.error(error.response.data.message);
      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************Delete Chat End  ************************//


  //************************ReadChat Chat ************************//
  const ReadChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
    };
    try {
      await axios.post(`Message/ReadChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        // getArchivedList();
        // getMessageList();
        getChatList()
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************ReadChat Chat End  ************************//
  //************************UnReadChat Chat ************************//
  const UnReadChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
    };
    try {
      await axios.post(`Message/UnReadChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        // getArchivedList();
        // getMessageList();
        getChatList()
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************UnReadChat Chat End  ************************//

  return (
    <>
      <div
        className="postMenu pointer chatInteraction"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp" />

        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
          >
            {/* <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  StarChat();
                  // setShow(!show);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="dropdownTxt f-14">Star Chat</span>
                </div>
              </Link>
            </Dropdown.Item> */}
            {item?.isOwned === false ? item?.isRead === false ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    ReadChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">Mark as Read</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    UnReadChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">Mark as UnRead</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : ''}

            {item?.isDeleted === false ? <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  DeleteChat(false);
                  toggleDropdown();
                  // setDeleteEveryOne(false)
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="dropdownTxt f-14">Delete For Me</span>
                </div>
              </Link>
            </Dropdown.Item> : ''}

            {item?.isOwned === true && item?.isCompleteDeleted === false ? <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  DeleteChat(true);
                  toggleDropdown();
                  // setDeleteEveryOne(true)
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="dropdownTxt f-14">Delete For Everyone</span>
                </div>
              </Link>
            </Dropdown.Item> : ''}

          </Dropdown.Menu>
        )}
      </div>

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default ChatInteraction;
