import React from 'react'
import { NewsFeed } from '../../assets/Images/ImagesLink'

const Photos = () => {

    return (
        <div className="aboutMe mb-3 p-2">
            <div className='d-flex justify-content-between pb-3'>
                <p className='f-16'>Photos</p>
            </div>
            <div className="row">
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto1} alt="" />
                </div>
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto2} alt="" />
                </div>
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto3} alt="" />
                </div>
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto1} alt="" />
                </div>
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto2} alt="" />
                </div>
                <div className="col-md-4 photoGallery">
                    <img src={NewsFeed.postphoto3} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Photos