
import React, { useState } from "react";
import like from "../../assets/Images/Post/f4ly07ldn7194ciimghrumv3l.svg";
import celebrate from "../../assets/Images/Post/3c4dl0u9dy2zjlon6tf5jxlqo.svg";
import support from "../../assets/Images/Post/9whrgl1hq2kfxjqr9gqwoqrdi.svg";
import love from "../../assets/Images/Post/asmf650x603bcwgefb4heo6bm.svg";
import insightful from "../../assets/Images/Post/39axkb4qe8q95ieljrhqhkxvl.svg";
import funny from "../../assets/Images/Post/ktcgulanbxpl0foz1uckibdl.svg";
import likegif from "../../assets/Images/Post/Animation - 1714999935336.gif";
import commentLike from "../../assets/Images/Post/8ekq8gho1ruaf8i7f86vd1ftt.svg";
import { motion } from "framer-motion";
import axios from "../../API/axios";
import { toast } from "react-hot-toast";
import CommentReply from "./CommentReply";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { post } from "../../assets/Images/ImagesLink";
import LikesDetails from "./LikesDetails";
import SingleCommentReply from "./SingleCommentReply";
const SingleCommentAction = ({
    item,
    AllPostList,
    setPostList,
    postId,
    emojiList,
}) => {
    console.log("commentres", item, postId);
    const sessionId = localStorage.getItem("sessionId");
    const [isHovered, setIsHovered] = useState(false);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const list = {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
    };

    //************************like Post start  ************************//
    const [selectedImg, setSelectedImage] = useState("");
    const [isLiking, setIsLiking] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likedType, setLikedType] = useState(null);
    const LikePost = async (liketype, imageName) => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            pstCmntId: item.pstCmntId,
            likeType: liketype,
        };
        setIsLiking(true);
        setTimeout(async () => {
            try {
                await axios.post(`PostAction/LikeComment`, responseData)
                .then((res) => {
                    console.log(res);
                    //   toast.success(res.data.message);
                    setToastMessage(res.data.message);
                    setShowToast(true);
                    // AllPostList();
                    setSelectedImage(imageName);
                    setIsHovered(false);
                    setIsLiking(false);
                    setIsLiked(true);
                    setLikedType(liketype);

                });
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    setWarningToastMessage(e.response.data.message);
                    setShowWarningToast(true);
                } else {
                    setErrorToastMessage(e.response.data.message);
                    setShowErrorToast(true);
                }
            }
        }, 1000);
    };

    const unLikePost = async () => {

        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            id: item.pstCmntId,
        };
        setIsLiking(true);
        setTimeout(async () => {
            try {
              const res =  await axios
                    .post(`PostAction/UnlikeComment`, responseData)
                    // .then((res) => {
                        // console.log(res);
                        // toast.success(res.data.message);
                        setToastMessage(res.data.message);
                        setShowToast(true);
                        // AllPostList();
                        setIsHovered(false);
                        setIsLiking(false);
                        setIsLiked(false);
                        setLikedType(null);

                        const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
        console.log('postDetailsResponse', postDetailsResponse);
        const updatedPost = postDetailsResponse.data.responseData;
        console.log('updatedPost', updatedPost);
                        setPostList(updatedPost)

                        
                    // });
                // console.log('checking like conditions', isLiked, item?.isLiked);
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    setWarningToastMessage(e.response.data.message);
                    setShowWarningToast(true);
                } else {
                    setErrorToastMessage(e.response.data.message);
                    setShowErrorToast(true);
                }
            }
        }, 1000);
    };

    //************************like Post End  ************************//

    const [showCommentsReply, setShowCommentsReply] = useState(false);

    const toggleReplyComment = () => {
        setShowCommentsReply(!showCommentsReply);
    };
    console.log("item.likeCount", item.likeCount);
    const customCount = item.likeCount + 1;
    console.log("customCount", customCount);

    const [showCommentLikeModal, setShowCommentLikeModal] = useState(false);
    const [postCommentId, setPostCommentsId] = useState(false);
    const [commentsLikedType, setCommentsLikedType] = useState(false);

    const handleShowCommentLike = (item) => {
        console.log('hghjsjhgsjhg', item);

        setPostCommentsId(item.pstCmntId);
        setCommentsLikedType(item.likedType);
        console.log("item comment id", item);
        setShowCommentLikeModal(true);
    };

    const handleCommentLikeCloseModal = () => {
        setShowCommentLikeModal(false);
    };
    const [changeLikedType, setChangeLikedType] = useState(null);
    const [isHoveredRelike, setIsHoveredRelike] = useState(false);
    const ReLikePost = async (liketype, imageName) => {

        try {
            const responseData = {
                deviceType: "Web",
                sessionId: sessionId,
                postId: item.pstCmntId,
                likeType: liketype,
            };
            // console.log(responseData);
            setIsLiking(true);
            setLikedType(liketype);

            const res = await axios.post("/PostAction/ChangeLikeType", responseData);

            console.log("likedTyphgjhhje", res, item.likedType);
            // toast.success('gdhghdg', res.data.message);
            setToastMessage(res.data.message);
            setShowToast(true);
            AllPostList();
            setSelectedImage(imageName);
            setIsHovered(false);
            setIsLiking(false);
            setIsLiked(true);
            setLikedType(liketype);
            setChangeLikedType(likedType)



        } catch (e) {
            console.log(e);
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }

    };
    return (
        <>
            <div className="commentAction ms-3 d-flex align-items-center">
                <span className="CommentlikeActions d-flex align-items-center">
                    {isLiked === true || item.isLiked === true ? (
                        <div
                            className="like-container pointer d-flex align-items-center me-2"
                            onClick={() => unLikePost()}
                        // whileHover={{ scale: 1.3 }}
                        // onMouseEnter={() => setIsHoveredRelike(true)}
                        // onMouseLeave={() => setIsHoveredRelike(false)}
                        >
                            {item?.likedType === 1 || likedType === 1 || changeLikedType === 1 ? (
                                <>
                                    <img src={post.LikeGif} width={15} className="likepadding" />
                                    <p className="f-12 ms-1">Like</p>
                                </>
                            ) : item?.likedType === 2 || likedType === 2 || changeLikedType === 2 ? (
                                <>
                                    <img src={post.LoveGif} width={15} className="likepadding" />
                                    <p className="f-12 ms-1">Love</p>
                                </>
                            ) : item?.likedType === 3 || likedType === 3 || changeLikedType === 3 ? (
                                <>
                                    <img
                                        src={post.LaughingGif}
                                        width={15}
                                        className="likepadding"
                                    />
                                    <p className="f-12 ms-1">Laugh</p>
                                </>
                            ) : item?.likedType === 4 || likedType === 4 || changeLikedType === 4 ? (
                                <>
                                    <img src={post.WowGif} width={15} className="likepadding" />
                                    <p className="f-12 ms-1">Wow</p>
                                </>
                            ) : item?.likedType === 5 || likedType === 5 || changeLikedType === 5 ? (
                                <>
                                    <img src={post.AngryGif} width={15} className="likepadding" />
                                    <p className="f-12 ms-1">Angry</p>
                                </>
                            ) : (
                                <>
                                    <img src={post.LikeGif} width={15} className="likepadding" />
                                    <p className="f-12 ms-1">Like</p>
                                </>
                            )}
                        </div>
                    ) : (
                        <motion.span
                            className="f-12 me-2 pointer"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={() => {
                                LikePost(1, like);
                            }}
                        >
                            Like
                        </motion.span>
                    )}
                    {isLiked === true ? (
                        <span className="commentLike me-2" onClick={() => handleShowCommentLike(item)}> {customCount}</span>
                    ) : item.likeCount > 0 ? (
                        <span
                            className="commentLike me-2 like-details"
                            onClick={() => handleShowCommentLike(item)}
                        >
                            {isLiked === true ? `${item.likeCount} + ${customCount}` : `${item.likeCount}`}
                        </span>
                    ) : (
                        ""
                    )}

                    {isHovered && (
                        <motion.div
                            className="reactionsHolder"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            variants={list}
                            animate={isHovered ? "visible" : "hidden"}
                        >
                            {emojiList?.map((item) => {
                                return (
                                    <div className="emoji-container" key={item.value}>
                                        <motion.img
                                            whileHover={{ scale: 1.5 }}
                                            src={item.url}
                                            alt=""
                                            onClick={() => {
                                                LikePost(item.value, item.name);
                                            }}
                                        />
                                        <span className="emoji-name">{item.name}</span>
                                    </div>
                                );
                            })}

                        </motion.div>
                    )}
                    {isHoveredRelike && (
                        <motion.div
                            className="reactionsHolder"
                            onMouseEnter={() => setIsHoveredRelike(true)}
                            onMouseLeave={() => setIsHoveredRelike(false)}
                            variants={list}
                        >
                            {emojiList?.map((item) => {
                                return (
                                    <div className="emoji-container" key={item.value}>
                                        <motion.img
                                            whileHover={{ scale: 1.5 }}
                                            src={item.url}
                                            alt=""
                                            onClick={() => {
                                                ReLikePost(item.value, item.name);
                                            }}
                                        />
                                        <span className="emoji-name">{item.name}</span>
                                    </div>
                                );
                            })}

                        </motion.div>
                    )}
                </span>
                |{" "}
                <span className="f-12 pointer" onClick={toggleReplyComment}>
                    Reply{" "}
                </span>
            </div>
            {showCommentsReply && (
                <SingleCommentReply
                    item={item}
                    AllPostList={AllPostList}
                    setPostList={setPostList}
                    postId={postId}
                    commentId={item.pstCmntId}
                    toggleReplyComment={toggleReplyComment}
                />
            )}

            <LikesDetails
                showCommentLikeModal={showCommentLikeModal}
                handleCommentLikeCloseModal={handleCommentLikeCloseModal}
                postCommentId={postCommentId}
                commentsLikedType={commentsLikedType}
            />

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default SingleCommentAction;
