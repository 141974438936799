import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const EditorTopic = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
  };
  console.log("dropdown value", selectedValue);

  return (
    <>
      <div className="editor-topic-container p-3">
        <div className="topic-main-text f-16 f-600 mb-2">Choose Topics</div>
        <div className="topic-sub-text f-14 mb-2">This article will appear in:</div>
        <div className="topic-dropdown">
          <Dropdown onSelect={handleSelect} className="mb-4">
            <Dropdown.Toggle className="btnColor" id="dropdown-basic">
              Dropdown Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-2">Programming</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Copy Writing</Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                Accounting & Financial
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                Social Media Management
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">Design</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown onSelect={handleSelect} className="mb-4">
            <Dropdown.Toggle className="btnColor" id="dropdown-basic">
              Dropdown Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-2">Programming</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Copy Writing</Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                Accounting & Financial
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                Social Media Management
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">Design</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default EditorTopic;
