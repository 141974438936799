import React, { useEffect, useRef, useState } from 'react'
import axios from '../../API/axios';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import { Dropdown } from 'bootstrap';
import { Link } from 'react-router-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const RecommendationAction = ({ showDropdown, setShowDropdown, item, GetRecommendation, setRecommendationList, index }) => {
    const sessionId = localStorage.getItem("sessionId");
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const handleToggleDropdown = (index) => {
        setShowDropdown((prev) => (prev === index ? null : index));
    };
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(null);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const [followedId, setFollowedId] = useState('')
    const [isFollowed, setIsFollowed] = useState(false)

    const FollowedUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios.post("UserFollow/FollowUser", data).then((resData) => {
                setToastMessage(resData.data.message);
                setShowToast(true);
                setIsFollowed(true)
                setRecommendationList((prev) => prev.filter((item) => item.userUid !== id));
                GetRecommendation(1)
                setFollowedId(item?.userUid)
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    //************************ Connect User Start  ************************//
    const [connectId, setConnectId] = useState('')
    const [isConnected, setIsConnected] = useState(false)

    const ConnectUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios
                .post("Connection/SendConnectionRequest", data)
                .then((resData) => {
                    //   toast.success(resData.data.message);
                    setToastMessage(resData.data.message);
                    setShowToast(true);
                    setIsConnected(true)
                    setRecommendationList((prev) => prev.filter((item) => item.userUid !== id));
                    GetRecommendation(1)
                });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    //************************ Connect User  End  ************************//
    //************************Cancel Request   ************************//
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = (item) => {
        // setSelectedItem(item);
        // setSelectedUserId(item.userUid);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        // setSelectedItem(null);
    };
    const [show, setShow] = useState(false);
    const cancelRequestClose = () => {
        setShow(false);
    };
    // const [skillId, setSkillId] = useState("");
    const [userUID, setuserUID] = useState("");
    const CancelRequest = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: userUID,
        };

        try {
            await axios.post("Connection/CancelConnectionRequest", data).then((resData) => {
                setShow(!show);
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    //************************Cancel Request ************************//

    return (

        <>
            {item?.isFollowedByYou === true || (followedId === item?.userUid && isFollowed === true) ? '' :
                <button
                    type="submit"
                    className="wljPrimaryBtn me-3"
                    style={{ width: '70px' }}
                    onClick={() => {
                        FollowedUser(item.userUid)
                        // setFollowedId(item.userUid)
                    }}
                >
                    Follow
                </button>

            }
            {item?.isConnectedByYou === true || (connectId === item?.userUid && isConnected === true) ?
                ''
                :

                <button
                    type="submit"
                    className="wljPrimaryBtn me-3"
                    style={{ width: '70px' }}
                    onClick={() => {
                        ConnectUser(item.userUid)
                        setConnectId(item.userUid)
                    }}
                >
                    Connect
                </button>
            }

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
            {show && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are You Sure You want to Cancel Request?</h6>

                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        cancelRequestClose();
                                    }}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        cancelRequestClose();
                                    }}
                                />
                            </div>

                            <div className="text-center mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => CancelRequest()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>
                                    Are you sure you want to Disconnect{" "}
                                    {/* <b>{selectedItem?.fullName}</b> ? */}
                                </h6>

                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                />
                            </div>

                            <div className="text-end mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => CancelRequest()}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default RecommendationAction