import React, { useState, useRef, useEffect } from "react";
import "../Custome-modal/custom-modal.css";
import { Form, Modal } from "react-bootstrap";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import axios from "../../API/axios";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';



const sessionId = localStorage.getItem("sessionId");

const CustomModal = () => {
  const fileInputRef = useRef(null);
  const fileInputRefBg = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const [openUserDetailshow, setOpenUserDetailShow] = useState(false);
  const handleOpenUserDetailShow = () => setOpenUserDetailShow(true);
  const handleOpenUserDetailClose = () => setOpenUserDetailShow(false);
  const [startDate, setStartDate] = useState(new Date());

  const [updateData, setUpdateData] = useState("");
  const [updateDataMessage, setUpdateDataMessage] = useState("");

  //   Data from Api

  const GetProfileInfo = async () => {
    try {
      const response = await axios.post(`/User/AskToUpdateProfile`, {
        sessionId,
        deviceType: "Web",
      });
      // console.log("response", response);
      setUpdateData(response.data.responseData);
      setUpdateDataMessage(response.data.message);
      //   return response;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    GetProfileInfo();
  }, []);

  //   Functions

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  // Options

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpenUserDetailShow(true);
    }, 5000);

    // Clear the interval when component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (openUserDetailshow) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [openUserDetailshow]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      {/* <Modal
        show={openUserDetailshow}
        onHide={handleOpenUserDetailClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {updateData === "ProfileImage" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
              <hr />
            </Modal.Header>
            <hr />
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="f-10">Choose a picture</h6>
                  <div className="uploadImage-cont">
                    <img
                      src={userAuthImage.dummy}
                      alt="Upload Image"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={handleClick}
                    />
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
                <div className="uploaded-image">
                  {selectedImage && <img src={selectedImage} alt="Selected" />}
                </div>
              </div>
            </Modal.Body>
          </>
        )}
        {updateData === "BackgroundImage" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
              <hr />
            </Modal.Header>
            <hr />
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="f-10">Choose a picture</h6>
                  <div className="uploadImage-cont">
                    <img
                      src={userAuthImage.dummy}
                      alt="Upload Image"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={handleClick}
                    />
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
                <div className="uploaded-image">
                  {selectedImage && <img src={selectedImage} alt="Selected" />}
                </div>
              </div>
            </Modal.Body>
          </>
        )}
        {updateData === "DOB" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
            </Modal.Header>
            <hr />
            <Modal.Body>
              <div className="date-container pl-0 nav nav-pills p-0 pb-3 shadow-none">
                <div>
                  <Form.Group controlId="ibFromDate" className="mb-0">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
        {updateData === "Language" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
            </Modal.Header>
            <hr />
            <Modal.Body>
              <Select
                // defaultValue={selectedOption}
                // onChange={setSelectedOption}
                options={options}
              />
            </Modal.Body>
          </>
        )}
        {updateData === "Gender" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
            </Modal.Header>
            <hr />
            <Modal.Body>
              <Select
                // defaultValue={selectedOption}
                // onChange={setSelectedOption}
                options={options}
              />
            </Modal.Body>
          </>
        )}
        {updateData === "Location" && (
          <>
            <Modal.Header closeButton>
              <div className="user-form-main-text f-16 f-600 mt-3 mb-3">
                {updateDataMessage}
              </div>
            </Modal.Header>
            <hr />
            <Modal.Body>
              <Select
                // defaultValue={selectedOption}
                // onChange={setSelectedOption}
                options={options}
              />
            </Modal.Body>
          </>
        )}
        <Modal.Footer>
          <div className="text-end mt-4 d-flex justify-content-center">
            <button type="button" className="mainBtn1">
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}

      {openUserDetailshow && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              {/* Profile Image */}
              {updateData === "ProfileImage" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h6>Website</h6>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="f-10">Choose a picture</h6>
                      <div className="uploadImage-cont">
                        <img
                          src={userAuthImage.dummy}
                          alt="Upload Image"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={handleClick}
                        />
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    </div>
                    <div className="uploaded-image">
                      {selectedImage && (
                        <img src={selectedImage} alt="Selected" />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* BG Image */}
              {updateData === "BackgroundImage" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h3>Website</h3>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="f-10">Choose a picture</h6>
                      <div className="uploadImage-cont">
                        <img
                          src={userAuthImage.dummy}
                          alt="Upload Image"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={handleClick}
                        />
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    </div>
                    <div className="uploaded-image">
                      {selectedImage && (
                        <img src={selectedImage} alt="Selected" />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* DOB */}
              {updateData === "DOB" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h3>Website</h3>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <div className="date-container pl-0 nav nav-pills p-0 pb-3 shadow-none">
                    <div>
                      <Form.Group controlId="ibFromDate" className="mb-0">

                        <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                          maxDate: "today", // Disables future dates
                          onReady: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Call the function to stop scroll on year
                          },
                          onOpen: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Also prevent scroll when the picker opens
                          },
                        }} />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}

              {/* Language */}
              {updateData === "Language" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h3>Website</h3>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <Select
                    // defaultValue={selectedOption}
                    // onChange={setSelectedOption}
                    options={options}
                    styles={customStyles}

                  />
                </div>
              )}

              {/* Gender */}
              {updateData === "Gender" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h3>Website</h3>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <Select
                    // defaultValue={selectedOption}
                    // onChange={setSelectedOption}
                    options={options}
                    styles={customStyles}
                  />
                </div>
              )}

              {/* Loaction */}
              {updateData === "Loaction" && (
                <div>
                  {/* Header */}
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h3>Website</h3>
                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleOpenUserDetailClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon 
                        className="material-symbols-sharp me-2 pointer"
                        onClick={() => {
                          handleOpenUserDetailClose();
                        }}
                  />
                  </div>
                  <hr />

                  {/* Body */}
                  <Select
                    // defaultValue={selectedOption}
                    // onChange={setSelectedOption}
                    options={options}
                    styles={customStyles}
                  />
                </div>
              )}
              <hr />
              <div className="text-end mt-4 d-flex justify-content-center">
                <button type="button" className="mainBtn1">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
