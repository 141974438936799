import React, { useEffect, useState } from "react";
import { JobSearch, post } from "../../assets/Images/ImagesLink";
import { ShimmerThumbnail } from "react-shimmer-effects";
import moment from "moment";
import { Link } from "react-router-dom";
import JobAction from "./JobAction";
import axios from "../../API/axios";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import ModalForGuest from "../ModalForGuest";
import { ShimmerDiv } from "shimmer-effects-react";
import JobApply from "./JobApply";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
const JobList = ({
  reset,
  filterJobList,
  setReset,
  jobsList,
  setJobsList,
  getAllJobList,
  totalPages,
  setTotalPages,
  page,
  setSearchValue,
  searchValue,
  isLoading,
  isError,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const userUid = localStorage.getItem("userUid");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [bookmarkModal, setBookmarkModal] = useState(false);
  const closeModalData = () => {
    setBookmarkModal(false);
  };

  //************************Save job End  ************************//
  const [savedJobs, setSavedJobs] = useState(new Set());
  const saveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/SaveJob`, responseData).then((res) => {
        console.log("save post", res);
        setToastMessage(res.data.message);
        setShowToast(true);
        setSavedJobs((prev) => new Set(prev).add(jobId));
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Save job End  ************************//

  //************************UnSave job start  ************************//
  const unsaveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/UnSaveJob`, responseData).then((res) => {
        // console.log(res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(false);
        setSavedJobs((prev) => {
          const newSavedJobs = new Set(prev);
          newSavedJobs.delete(jobId);
          return newSavedJobs;
        });
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnSave job End  ************************//

  //************************Apply and Unapply job  ************************//
  const [isappliedF, setIsappliedF] = useState(false);
  const [appliedId, setAppliedId] = useState("");
  const [showAppliedModal, setShowAppliedModal] = useState(false);
  const handleCloseModal = () => setShowAppliedModal(false);
  const handleShow = () => setShowAppliedModal(true);
  const [appliedJobId, setAppliedJobId] = useState("");


  //************************Apply and Unapply job  ************************//

  //************************User Profile Stats Start  ************************//
  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        // console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [showResumeModal, setShowResumeModal] = useState(false);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);

  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************User Profile Stats End  ************************//

  useEffect(() => {
    if (showAppliedModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showAppliedModal]);

  console.log("jobsList", jobsList);

  return (
    <>
      <div className="row job-header">
        <div className="col-md-12 mt-4">
          <div className="search-container ">
            <SearchSharpIcon className="material-symbols-sharp" />
            <div className="search-input-container">
              <input
                type="text"
                // value={inputValue}
                // onChange={handleInputChange}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                placeholder="Search..."
                className="search-input"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        {isLoading ? (
          <div className="mt-3">
            {/* <ShimmerThumbnail height={250} rounded width={"50px"} /> */}
            <ShimmerDiv mode="light" height={250} width={"50px"} />
          </div>
        ) : isError ? (
          <div className="mt-2 error-image-cont error-image-job">
            <img src={post.errorbig} alt="404 Not Found" width={200} />
          </div>
        ) : reset === false ? (
          jobsList &&
          jobsList
            .filter((item) => !item.isDeleted)
            ?.map((item, index) => {
              const now = moment();
              const itemTime = moment.utc(item?.creationTime);
              const duration = moment.duration(now.diff(itemTime));

              const years = duration.years();
              const months = duration.months();
              const days = duration.days();
              const hours = duration.hours();
              const minutes = duration.minutes();

              let result = "";

              if (years > 0) {
                result = `${years} year${years > 1 ? "s" : ""} ago`;
              } else if (months > 0) {
                result = `${months} month${months > 1 ? "s" : ""} ago`;
              } else if (days > 0) {
                result = `${days} day${days > 1 ? "s" : ""} ago`;
              } else if (hours > 0) {
                result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
              } else if (minutes > 0) {
                result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
              } else {
                result = "just now";
              }
              return (
                <div className="col-md-4 d-flex align-items-stretch mt-4">
                  <div className="jobCard p-3 w-100 d-flex flex-column">
                    <div className="d-flex justify-content-start align-items-center" style={{ height: "60px" }}>
                      <Link to={`/Company/${item.compUid}`}>
                        <div className="pointer me-2">
                          <img
                            src={item?.compLogo}
                            alt=""
                            width={50}
                            height={50}
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                      </Link>
                      <Link to={`/Company/${item.compUid}`}>
                        <span className="f-14 f-700 job-company-name">
                          {item.compName}
                        </span>
                      </Link>
                      <div className="ml-auto">
                        <JobAction
                          item={item}
                          AllJobList={getAllJobList}
                          setJobList={setJobsList}
                          jobList={jobsList}
                          totalPages={totalPages}
                          setTotalPages={setTotalPages}
                          page={page}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-2 emoji-container">
                      <Link to={`/JobDetail/${item.jobId}`}>
                        <p className="f-16 fw-bold pointer">
                          {/* {item.title.length > 10
                            ? `${item.title.substring(0, 10)}...`
                            : item.title} */}
                          {item.title}
                        </p>
                      </Link>

                      {savedJobs.has(item?.jobId) || item?.isSaved === true ? (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkSharpIcon
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          />

                        </div>
                      ) : (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkBorderSharpIcon
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"} />
                        </div>
                      )}
                    </div>

                    <p className="f-14">
                      {/* {item.shortDescription.length > 15
                        ? `${item.shortDescription.substring(0, 15)}...`
                        : item.shortDescription} */}
                      {item.shortDescription}
                    </p>
                    <h6 className="f-10 mt-1">{item.cityName}</h6>

                    <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                      <small className="f-8">Posted {result}</small>
                      <div className="badge badge-date">{item?.expiringInDays} days Left</div>

                    </div>

                    <div className="jobType mt-2 mb-3">
                      <div className="badge badge-job me-3 mb-1">
                        {item.jbTypName}
                      </div>
                      <div className="badge badge-job me-3 mb-1">
                        {item.workLocationTypeName}
                      </div>
                      <div className="badge badge-job mb-1">
                        {item.designationName}
                      </div>
                    </div>

                    <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={getAllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                  </div>
                </div>

              );
            })
        ) : (
          filterJobList &&
          filterJobList
            .filter((item) => !item.isDeleted)
            ?.map((item, index) => {
              const now = moment();
              const itemTime = moment.utc(item?.creationTime);
              const duration = moment.duration(now.diff(itemTime));

              const years = duration.years();
              const months = duration.months();
              const days = duration.days();
              const hours = duration.hours();
              const minutes = duration.minutes();

              let result = "";

              if (years > 0) {
                result = `${years} year${years > 1 ? "s" : ""} ago`;
              } else if (months > 0) {
                result = `${months} month${months > 1 ? "s" : ""} ago`;
              } else if (days > 0) {
                result = `${days} day${days > 1 ? "s" : ""} ago`;
              } else if (hours > 0) {
                result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
              } else if (minutes > 0) {
                result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
              } else {
                result = "just now";
              }
              return (
                <div className="col-md-4 d-flex align-items-stretch mt-4">
                  <div className="jobCard p-3 w-100 d-flex flex-column">
                    <div className="d-flex justify-content-start align-items-center" style={{ height: "60px" }}>
                      <Link to={`/Company/${item.compUid}`}>
                        <div className="pointer me-2">
                          <img
                            src={item?.compLogo}
                            alt=""
                            width={50}
                            height={50}
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                      </Link>
                      <Link to={`/Company/${item.compUid}`}>
                        <span className="f-14 f-700 job-company-name">
                          {item.compName}
                        </span>
                      </Link>
                      <div className="ml-auto">
                        <JobAction
                          item={item}
                          AllJobList={getAllJobList}
                          setJobList={setJobsList}
                          jobList={jobsList}
                          totalPages={totalPages}
                          setTotalPages={setTotalPages}
                          page={page}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-2 emoji-container">
                      <Link to={`/JobDetail/${item.jobId}`}>
                        <p className="f-16 fw-bold pointer">
                          {/* {item.title.length > 10
                            ? `${item.title.substring(0, 10)}...`
                            : item.title} */}
                          {item.title}
                        </p>
                      </Link>

                      {savedJobs.has(item?.jobId) || item?.isSaved === true ? (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkSharpIcon
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          />
                        </div>
                      ) : (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkBorderSharpIcon
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(item?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          />
                        </div>
                      )}
                    </div>

                    <p className="f-14">
                      {/* {item.shortDescription.length > 15
                        ? `${item.shortDescription.substring(0, 15)}...`
                        : item.shortDescription} */}
                      {item.shortDescription}
                    </p>
                    <h6 className="f-10 mt-1">{item.cityName}</h6>

                    <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                      <small className="f-8">Posted {result}</small>
                      <div className="badge badge-date">{item?.expiringInDays} days Left</div>

                    </div>

                    <div className="jobType mt-2 mb-3">
                      <div className="badge badge-job me-3 mb-1">
                        {item.jbTypName}
                      </div>
                      <div className="badge badge-job me-3 mb-1">
                        {item.workLocationTypeName}
                      </div>
                      <div className="badge badge-job mb-1">
                        {item.designationName}
                      </div>
                    </div>

                    <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={getAllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                  </div>
                </div>
              );
            })
        )}
      </div>

      {bookmarkModal && <ModalForGuest closeModalData={closeModalData} />}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />



      {showResumeModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Please complete your profile up to 60% and must have valid resume to apply for job</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowResumeModal(false);
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowResumeModal(false);
                  }} />
              </div>
              <div className="text-center mt-2">
                <Link to={`/User/${userUid}`}>
                  <button
                    color=""
                    className="closebtn"
                    style={{ width: "150px" }}
                  // onClick={() => {
                  //   userCcv !== "True" && AddUserCcv();
                  // }}
                  >
                    Manage Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobList;
