import React from "react";
import "../../assets/css/Articles.css";
import { Accordion, Col, ProgressBar, Row } from "react-bootstrap";
import { icons, blogs } from "../../assets/Images/ImagesLink";
import ProgressBars from "./ProgressBars";
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';


const Articles = (props) => {
  return (
    <>
      <div className="articles-main-container pt-5">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="article-card-header d-flex ">
                <div className="draft-icon d-flex align-items-center ">
                  <span className="f-10 me-2">Draft (4)</span>
                </div>
                <div className="trash-setting-cont d-flex">
                  <div className="trash-cont">
                    <DeleteOutlineSharpIcon className="material-symbols-sharp" />

                  </div>
                  <div className="setting">
                    <SettingsSharpIcon className="material-symbols-sharp " />
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="articles-card-main-cont d-flex justify-content-between">
                <Row>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className=" col-md-6 col-sm-12 articles-custom-card p-4 d-flex flex-column justify-content-around align-items-center ">
                      <div className="plus-cont">
                        <AddSharpIcon className="material-symbols-sharp" />
                      </div>
                      <div className="articles-cards-main-text f-14 f-600">
                        Write New Article
                      </div>
                      <div className="article-card-sub-text f-10 gray-color">
                        First steps to join this program You must Login &
                        Register
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className="col-md-6 col-sm-12 articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="articles-cards-main-text f-14 f-600">
                        How to Create an Invoice for First Time
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        There is no better advertisement campaign that is low
                        cost and also successful at the same time.{" "}
                      </div>
                      <div className="progress mt-3 mb-3">
                        <ProgressBars />
                      </div>
                      <div className="articles-collaps-cont">
                        <div className="blogs-card-btn-cont">
                          <div className="circle-container circle-style">
                            <img
                              src={icons.default}
                              alt="Circle 1"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 2"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 3"
                              className="circle-img"
                            />
                            <img
                              src={icons.plus}
                              alt="Circle 3"
                              className="circle-img"
                            />
                          </div>

                          <div className="edit-article-btn">
                            <button>Edit Article</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className="col-md-6 col-sm-12 articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="articles-cards-main-text f-14 f-600">
                        How to Create an Invoice for First Time
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        There is no better advertisement campaign that is low
                        cost and also successful at the same time.{" "}
                      </div>
                      <div className="progress mt-3 mb-3">
                        <ProgressBars />
                      </div>
                      <div className="articles-collaps-cont">
                        <div className="blogs-card-btn-cont">
                          <div className="circle-container circle-style">
                            <img
                              src={icons.default}
                              alt="Circle 1"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 2"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 3"
                              className="circle-img"
                            />
                            <img
                              src={icons.plus}
                              alt="Circle 3"
                              className="circle-img"
                            />
                          </div>

                          <div className="edit-article-btn">
                            <button>Edit Article</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className="col-md-6 col-sm-12 articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="articles-cards-main-text f-14 f-600">
                        How to Create an Invoice for First Time
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        There is no better advertisement campaign that is low
                        cost and also successful at the same time.{" "}
                      </div>
                      <div className="progress mt-3 mb-3">
                        <ProgressBars />
                      </div>
                      <div className="articles-collaps-cont">
                        <div className="blogs-card-btn-cont">
                          <div className="circle-container circle-style">
                            <img
                              src={icons.default}
                              alt="Circle 1"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 2"
                              className="circle-img"
                            />
                            <img
                              src={icons.default}
                              alt="Circle 3"
                              className="circle-img"
                            />
                            <img
                              src={icons.plus}
                              alt="Circle 3"
                              className="circle-img"
                            />
                          </div>

                          <div className="edit-article-btn">
                            <button>Edit Article</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="article-card-header d-flex ">
                <div className="draft-icon d-flex align-items-center">
                  <span className="f-10">Draft (4)</span>
                </div>
                <div className="trash-setting-cont d-flex">
                  <div className="trash-cont">
                    <DeleteOutlineSharpIcon className="material-symbols-sharp" />
                  </div>
                  <div className="setting">
                    <SettingsSharpIcon className="material-symbols-sharp " />
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row articles-card-main-cont d-flex justify-content-between">
                <Row>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className=" articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="blogs-card-image">
                        <img src={blogs.blogsCardImage3} alt="" srcset="" />
                      </div>
                      <div className="articles-cards-main-text f-14 f-600">
                        Getting Started
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        First steps to join this program You must Login &
                        Register
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className=" articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="blogs-card-image">
                        <img src={blogs.blogsCardImage3} alt="" srcset="" />
                      </div>
                      <div className="articles-cards-main-text f-14 f-600">
                        Getting Started
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        First steps to join this program You must Login &
                        Register
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className=" articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="blogs-card-image">
                        <img src={blogs.blogsCardImage3} alt="" srcset="" />
                      </div>
                      <div className="articles-cards-main-text f-14 f-600">
                        Getting Started
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        First steps to join this program You must Login &
                        Register
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="row-gap-blogs-card">
                    <div className=" articles-cards articles-custom-card p-3 d-flex flex-column justify-content-between">
                      <div className="blogs-card-image">
                        <img src={blogs.blogsCardImage3} alt="" srcset="" />
                      </div>
                      <div className="articles-cards-main-text f-14 f-600">
                        Getting Started
                      </div>
                      <div className="articles-cards-sub-text f-10 gray-color">
                        First steps to join this program You must Login &
                        Register
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};
export default Articles;
