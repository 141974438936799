import React, { useEffect, useState } from 'react'
import { ShimmerSocialPost, ShimmerTitle } from 'react-shimmer-effects';
import axios from '../../API/axios';
import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import PostComment from '../NewsFeed/PostComment';
import PostEngagement from '../NewsFeed/PostEngagement';
import PostInteractionModal from '../NewsFeed/PostInteractionModal'
import moment from 'moment';
import { Link } from 'react-router-dom';
import Comment from '../NewsFeed/Comment';
import ImageSliderModal from '../NewsFeed/ImageSliderModal';
import LikesDetails from '../NewsFeed/LikesDetails';
import { ShimmerDiv } from 'shimmer-effects-react';
const Saved = ({ getUserProfileStat }) => {
    const sessionId = localStorage.getItem("sessionId");
    const guestModeData = localStorage.getItem("guestMode");
    const userUid = localStorage.getItem("userUid");
    //************************ All Post List  ************************//
    const [postList, setPostList] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isError, setIsError] = useState(false);
    // const [isSavedPage, setIsSavedPage] = useState(false);
    const isSavedPage = true;
    const [initialSlideIndex, setInitialSlideIndex] = useState(0);
    const AllPostList = async () => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            pageNumber: page,
            pageSize: 24
        }
        try {
            await axios.post("Posts/GetSavedPosts?SortBy=Recent", data).then((resData) => {
                console.log('resData', resData);
                // setPostList(resData.data.responseData);
                setIsLoading(false);

                if (resData?.data?.status === "Warning" || resData?.status === 204) {
                    setIsError(true);
                } else {
                    setTotalPages(resData?.data?.responseData?.paging?.totalPages);
                    const newPosts = resData?.data?.responseData?.postsList;
                    setPostList((prev) => {
                        const postSet = new Set(prev?.map((post) => post?.postId));
                        const uniqueNewPosts = newPosts?.filter(
                            (post) => !postSet?.has(post?.postId)
                        );
                        return [...prev, ...uniqueNewPosts];
                    });
                }
            });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (totalPages >= page) {
            AllPostList(page);
        }
    }, [page]);
    console.log('postList', postList);

    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleInfiniteScroll);
        return () => window.removeEventListener("scroll", handleInfiniteScroll);
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [postLikedType, setPostLikedType] = useState(false);
    const [postId, setPostId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImages, setModalImages] = useState([]);
    const [singlePostContent, setSinglePostContent] = useState("");
    const [singlepostId, setSinglePostId] = useState("");
    const [ispostmodalOpen, setIspostmodalOpen] = useState(false);
    const [dLike, setDLike] = useState(0);
    const [emojiList, setemojiList] = useState();
    const openModal = (images) => {
        setModalImages(images);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const GetEmoji = async () => {
        try {
            await axios.get("Master/GetEmojiDDL").then((res) => {
                // console.log("share", res);
                setemojiList(res.data.responseData);
                // updateShareCount(postId, 1);
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetEmoji();
    }, []);
    const updateCommentCount = (postId, change) => {
        setPostList((prev) =>
            prev.map((post) =>
                post.postId === postId
                    ? { ...post, commentCount: post.commentCount + change }
                    : post
            )
        );
    };
    const handleShowModal = (item) => {
        setPostId(item.postId);
        setPostLikedType(item.likedType);
        setShowModal(true);
    };
    const updateLikeCount = (postId, delta) => {
        setPostList((prev) =>
            prev.map((post) =>
                post.postId === postId
                    ? { ...post, likeCount: post.likeCount + delta }
                    : post
            )
        );
    };

    const updateShareCount = (postId, change) => {
        setPostList((prev) =>
            prev.map((post) =>
                post.postId === postId
                    ? { ...post, shareCount: post.shareCount + change }
                    : post
            )
        );
    };

    //************************ All Post List  ************************//

    const handleLikesCloseModal = () => {
        setShowModal(false);
        // setSelectedItem(null);
    };

    const [isfollowed, setIsfollowed] = useState(false);
    const addTargetBlankToAnchors = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        const anchors = div.querySelectorAll('a');
        anchors.forEach(anchor => {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
        });

        return div.innerHTML;
    };

    const linkifyContent = (htmlContent) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return htmlContent.replace(urlPattern, function (url) {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };


    return (
        <>
            {isLoading ? (
                <div className="mt-3">
                    {/* <ShimmerSocialPost height={250} rounded width={"50px"} /> */}
                    <div className="shimmer-cont">
                        <div className="shimmer-header d-flex">
                            <ShimmerDiv mode="light" height={60} width={60} rounded={50} />
                            <div className="shimmer-title ms-3">
                                <ShimmerTitle mode="light" line={2} gap={8} />
                            </div>
                        </div>
                        <div className="square-shimmer mt-2">
                            <ShimmerDiv mode="light" height={100} width={100} />
                        </div>
                        {/* <ShimmerCategoryItems mode="light" imageRounded={50} /> */}
                    </div>
                </div>
            ) : isError ? (
                <div className="mt-2 text-center">
                    <img src={post.errorSmall} alt="404 Not Found" width={200} />
                </div>
            ) : (
                postList && postList?.map((item, index) => {
                    console.log(item);
                    return (
                        <>
                            <div
                                className="featured-card user-postOnlyContent p-2 mb-3"
                                key={index}
                            >
                                <div className="feature-content p-2 d-flex justify-content-between">
                                    <Link to={`/User/${item.userUid}`}>
                                        <div className="d-flex align-items-center">
                                            <div className="ImgAvatar">
                                                <img
                                                    src={item?.userAvatar}
                                                    alt=""
                                                    style={{ borderRadius: "50px" }}
                                                />
                                            </div>
                                            <div className="ms-2">
                                                <h4 className="f-14 fw-bold mb-0">{item.userName}</h4>
                                                <p className="f-12">{item?.title}</p>
                                                <p className="f-12">{item?.additionalInfo}</p>
                                                <p className="f-12">
                                                    {/* {moment(item.creationTime).format(
                                                        "MMMM Do YYYY, h:mm:ss a"
                                                    )} */}
                                                    {moment
                                                        .utc(item.creationTime)
                                                        .local()
                                                        .format("DD MMMM YYYY[at] h:mm a")}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>

                                    {guestModeData === "true" ? (
                                        ""
                                    ) : (
                                        <div className="row">
                                            <div>
                                                <PostInteractionModal
                                                    postId={item?.postId}
                                                    AllPostList={AllPostList}
                                                    postList={postList}
                                                    setPage={setPage}
                                                    setPostList={setPostList}
                                                    page={page}
                                                    setTotalPages={setTotalPages}
                                                    totalPages={totalPages}
                                                    item={item}
                                                    PostOriginType={"User"}
                                                    postOriginId={userUid}
                                                    isfollowed={isfollowed}
                                                    setIsfollowed={setIsfollowed}
                                                    ispinned={item?.isPinned}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* {item?.postContent && (
                                    <p className="f-14 p-2 postContent">{item?.postContent}</p>
                                )} */}

                                {item?.postContent && (
                                    <div className="f-14 p-2 postContent">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: linkifyContent(
                                                    addTargetBlankToAnchors(item?.postContent)
                                                )
                                            }}
                                        />
                                    </div>
                                )}

                                <div
                                    className="feed-post"
                                    style={{
                                        paddingTop: item?.postMediaCount === 0 ? "60px" : "4px",
                                    }}
                                >
                                    <div className="row">
                                        {item.mediaFilesList.map((file, idx) => (
                                            <div
                                                key={idx}
                                                className={`col-${item.mediaFilesList.length === 1 ? "12" : "6"
                                                    } ${item.mediaFilesList.length === 3 && idx === 0
                                                        ? "col-12"
                                                        : ""
                                                    }`}
                                                onClick={() => {
                                                    setInitialSlideIndex(idx);
                                                    openModal(
                                                        item.mediaFilesList.map((file) => file.mediaName)
                                                    );
                                                    setSinglePostContent(item);
                                                    setSinglePostId(item?.postId);
                                                }}
                                                style={{
                                                    position: "relative",
                                                    padding:
                                                        item.mediaFilesList.length === 3 && idx === 0
                                                            ? "0 0 5px 0"
                                                            : item.mediaFilesList.length === 3 && idx === 1
                                                                ? "0 5px 5px 0"
                                                                : item.mediaFilesList.length === 4 && idx === 2
                                                                    ? "0 5px 0 0"
                                                                    : item.mediaFilesList.length === 4 && idx === 0
                                                                        ? "0 5px 5px 0"
                                                                        : item.mediaFilesList.length === 2 && idx === 0
                                                                            ? "0 5px 5px 0"
                                                                            : "",
                                                }}
                                            >
                                                <img
                                                    src={file.mediaName}
                                                    alt=""
                                                    className="img-fluid"
                                                    width={"100%"}
                                                />
                                                {idx === 3 && item.postMediaCount > 4 && (
                                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                                                        +{item.postMediaCount - 4}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {guestModeData === "true" ? (
                                        ""
                                    ) : (
                                        <div className="row comment-gradient">
                                            <div
                                                className="addComment"
                                                onClick={() => {
                                                    openModal(
                                                        item.mediaFilesList.map((file) => file.mediaName)
                                                    );
                                                    setSinglePostContent(item);
                                                    setSinglePostId(item?.postId);
                                                }}
                                            >
                                                <div
                                                    className="postCommentWrapper"
                                                    onClick={(event) => event.stopPropagation()}
                                                >
                                                    <PostComment
                                                        item={item}
                                                        postId={item?.postId}
                                                        AllPostList={AllPostList}
                                                        postList={postList}
                                                        setPage={setPage}
                                                        setPostList={setPostList}
                                                        page={page}
                                                        setTotalPages={setTotalPages}
                                                        totalPages={totalPages}
                                                        updateCommentCount={updateCommentCount}
                                                        ispostmodalOpen={ispostmodalOpen}
                                                        setIspostmodalOpen={setIspostmodalOpen}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={`postData d-flex align-items-center ${item?.likeCount === 0 && item?.commentCount === 0 && item?.shareCount === 0
                                    ? ""
                                    : "border-custom1"
                                    }`}>
                                    <div className="ms-2">
                                        <p
                                            className="f-10 like-details"
                                            onClick={() => handleShowModal(item)}
                                        >
                                            {item?.likeCount + dLike} likes
                                        </p>
                                    </div>
                                    <div className="ms-auto d-flex">
                                        <p
                                            className="f-10 me-2 pointer"
                                            onClick={() => {
                                                openModal(
                                                    item.mediaFilesList.map((file) => file.mediaName)
                                                );
                                                setSinglePostContent(item);
                                                setSinglePostId(item?.postId);
                                            }}
                                        >
                                            {" "}
                                            {item?.commentCount} comments{" "}
                                        </p>
                                        <p className="f-10">
                                            {item?.shareCount === 0
                                                ? ""
                                                : `&  ${item?.shareCount} Shares`}
                                        </p>
                                    </div>
                                </div>
                                <div className="postengagement mb-3">
                                    <PostEngagement
                                        item={item}
                                        postId={item?.postId}
                                        AllPostList={getUserProfileStat}
                                        postList={postList}
                                        setPage={setPage}
                                        setPostList={setPostList}
                                        page={page}
                                        setTotalPages={setTotalPages}
                                        totalPages={totalPages}
                                        updateLikeCount={updateLikeCount}
                                        updateShareCount={updateShareCount}
                                        openModal={openModal}
                                        setSinglePostContent={setSinglePostContent}
                                        setSinglePostId={setSinglePostId}
                                        ispostmodalOpen={ispostmodalOpen}
                                        setIspostmodalOpen={setIspostmodalOpen}
                                        emojiList={emojiList}
                                        isSavedPage={isSavedPage}
                                    />
                                </div>
                                <Comment
                                    item={item}
                                    isOwnedPost={item?.isOwned}
                                    postId={item?.postId}
                                    AllPostList={AllPostList}
                                    postList={postList}
                                    setPage={setPage}
                                    setPostList={setPostList}
                                    page={page}
                                    setTotalPages={setTotalPages}
                                    totalPages={totalPages}
                                    emojiList={emojiList}
                                />
                            </div>
                        </>
                    )
                })
            )}


            {isModalOpen && (
                <ImageSliderModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    images={modalImages}
                    item={singlePostContent}
                    singlepostId={singlepostId}
                    setSinglePostId={setSinglePostId}
                    updateShareCount={updateShareCount}
                    updateLikeCount={updateLikeCount}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    page={page}
                    setPostList={setPostList}
                    setPage={setPage}
                    postList={postList}
                    AllPostList={AllPostList}
                    PostEngagement={PostEngagement}
                    dLike={dLike}
                    updateCommentCount={updateCommentCount}
                    ispostmodalOpen={ispostmodalOpen}
                    setIspostmodalOpen={setIspostmodalOpen}
                    openModal={openModal}
                    setSinglePostContent={setSinglePostContent}
                    setDLike={setDLike}
                    setShowModal={showModal}
                    handleLikesCloseModal={handleLikesCloseModal}
                    initialSlideIndex={initialSlideIndex}
                    setInitialSlideIndex={setInitialSlideIndex}
                />
            )}
            {/* {showModal &&  */}
            <LikesDetails
                showModal={showModal}
                handleLikesCloseModal={handleLikesCloseModal}
                postId={postId}
                postLikedType={postLikedType}
            />
            {/* } */}

        </>
    )
}

export default Saved