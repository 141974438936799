import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
// import { Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const CCVExperience = ({
  showCCVExperience,
  handleCloseCCVExperience,
  resumeId,
  getUserCcv,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const [startDate, setStartDate] = useState("");
  const [present, setPresent] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState("");
  const [citySearch, setCitySearch] = useState(null);

  const [display, setDisplay] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);


  const [checkCurrent, setCheckCurrent] = useState(false)
  console.log('checkCurrentExperience', checkCurrent);

  const CheckCurrentExperienceStatus = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`ResumeData/CheckCurrentRsmExperienceStatus`, responseData)
        .then((res) => {
          console.log('checkCurrentExperience', checkCurrent);

          setCheckCurrent(res?.data?.responseData)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  console.log('Type of showCCVExperience:', typeof showCCVExperience);

  useEffect(() => {
    if (showCCVExperience) {
      CheckCurrentExperienceStatus()
    }
  }, [showCCVExperience])

  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  //********Location DDL Start  ********//
  const [valueLocation, setValueLocation] = useState(null);
  const [pageLocation, setPageLocation] = useState(0);
  const [typedLocation, setTypedLocation] = useState("");
  const [optionsLocation, setOptionsLocation] = useState([]);
  const [totalPagesLocation, setTotalPagesLocation] = useState(0);
  const logValueLocation = debounce((typed) => {
    setTypedLocation(typed);
  }, 250);

  const addOptionsLocation = () => {
    axios
      .get(
        `Master/GetLocalitiesDDL?search=${typedLocation}&pageNumber=${pageLocation + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response);
        const data = response.data.responseData.localityList.map((item) => ({
          value: item.locationId,
          label: item.locationName,
        }));
        setPageLocation((prevPage) => prevPage + 1);
        setOptionsLocation((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesLocation(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenLocation = () => {
    setPageLocation(1);
  };

  const handleChangeLocation = (valueLocation) => {
    setValueLocation(valueLocation.value);
    setPageLocation(0);
  };

  const handleInputChangeLocation = (typedLocation) => {
    logValueLocation(typedLocation);
    if (!typedLocation) setPageLocation(0);
  };

  const handleMenuScrollToBottomLocation = () => {
    if (pageLocation < totalPagesLocation) {
      addOptionsLocation();
    }
  };

  const renderOptionLocation = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typedLocation) {
      addOptionsLocation();
    }
  }, [typedLocation]);

  //********Location DDL End  ********//

  const reset = () => {
    setCityId([]);
    setStartDate("");
    setEndDate("");
    setPresent(false);
    setValue(null);
    setValueC(null);
    setJobTypeId(null);
    setWorkLocationId(null);
    setWorkTypeId(null);
    setHeadline("");
    setEmail("");
    setAbout("");
    setIndustryId(null);
    setCategoryId(null);
    setCompanySizeId(null);
    setTagline("");
    setPublicUrl("");
    setWebsite("");
    setAvgTurnOver("");
    setJobTitle("");
    setDescription("");
  };

  const AddExperience = async () => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmId: resumeId,
      companyUid: valueC,
      jobTitle: value,
      positionHeadline: headline,
      jobType: jobTypeId,
      workLocationType: workLocationId,
      workLevelType: workTypeId,
      description: description,
      locationId: null,
      cityId: cityId,
      startDate:
        startDate === "" ? null : moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate === "" ? null : moment(endDate).format("YYYY-MM-DD"),
      isPresent: present,
    };

    console.log("AddExperience", data);
    if (
      valueC === null ||
      value === null ||
      jobTypeId === null ||
      workLocationId === null ||
      workTypeId === null ||
      cityId === null ||
      startDate === ""
    ) {
      setErrorToastMessage("Please fill all the required fields");
      setShowErrorToast(true);
    } else {
      try {
        await axios
          .post(`ResumeData/AddRsmExperienceInfo`, data)
          .then((resData) => {
            console.log("resData", resData.data.responseData);
            getUserCcv();
            //   toast.success(resData.data.message);
            setToastMessage(resData.data.message);
            setShowToast(true);
            handleCloseCCVExperience();
            reset();
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };

  //********Create Education Start  ********//
  const [CreateCCVExperience, setCreateCCVExperience] = useState(false);
  const handleOpenCreateCCVExperience = () => setCreateCCVExperience(true);
  const handleCloseCreateCCVExperience = () => setCreateCCVExperience(false);

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [description, setDescription] = useState("");
  const [headline, setHeadline] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [about, setAbout] = useState("");
  const [avgTurnOver, setAvgTurnOver] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const CreateExperience = async () => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmId: resumeId,
      compName: companyName,
      logoImage: "",
      cityId: cityId,
      email: email,
      about: about,
      industryId: IndustryId,
      companyCategoryId: CategoryId,
      companySizeId: CompanySizeId,
      tagline: tagline,
      publicUrl: publicUrl,
      website: website,
      backgroudImage: "",
      incorpDate: "",
      avgAnumTurnOver: avgTurnOver,
      jobTitle: jobTitle,
      positionHeadline: headline,
      jobType: jobTypeId,
      workLocationType: workLocationId,
      workLevelType: workTypeId,
      description: description,
      locationId: null,
      startDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate === "" ? "" : moment(endDate).format("YYYY-MM-DD"),
      isPresent: present,
    };

    console.log("data create exp", data);
    if (
      companyName === "" ||
      IndustryId === "" ||
      CompanySizeId === "" ||
      cityId === null ||
      startDate === "" ||
      jobTitle === "" ||
      jobTypeId === null ||
      workLocationId === null ||
      workTypeId === null || email === ''
    ) {
      setErrorToastMessage("Please fill all the required fields");
      setShowErrorToast(true);
    } else {
      try {
        await axios
          .post(`ResumeData/CreateAndAddRsmExperienceInfo`, data)
          .then((resData) => {
            console.log("resData crt exp", resData.data.responseData);
            getUserCcv();
            //   toast.success(resData.data.message);
            setToastMessage(resData.data.message);
            setShowToast(true);
            handleCloseCreateCCVExperience();
            handleCloseCCVExperience();
            reset();
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };

  //********Create Education End  ********//

  //************************Designation Start  ************************//
  const [value, setValue] = useState(null);
  const [pageD, setPageD] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalPagesDesignation, setTotalPagesDesignation] = useState(0);

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  // const addOptions = () => {
  //   setIsLoading(true);
  //   axios
  //     .get(
  //       `Master/GetDesignationDDL?search=${typed}&pageNumber=${pageD + 1
  //       }&pageSize=100`
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       const data = response?.data?.responseData?.data.map((item) => ({
  //         value: item.dsgntnId,
  //         label: item.name,
  //       }));
  //       setPageD((prevPage) => prevPage + 1);
  //       setIsLoading(false);
  //       setOptions((prevOptions) => [...prevOptions, ...data]);
  //       setTotalPagesDesignation(response.data.paging.totalPages);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };
  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetDesignationDDL?search=${typed}&pageNumber=${pageD + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response);

        const data = response?.data?.responseData?.data;

        // Check if data is an array
        if (Array.isArray(data)) {
          const mappedData = data.map((item) => ({
            value: item.dsgntnId,
            label: item.name,
          }));

          setOptions((prevOptions) => [...prevOptions, ...mappedData]);
        } else {
          console.error("Expected data to be an array, but got:", data);
        }

        setPageD((prevPage) => prevPage + 1);
        setIsLoading(false);
        setTotalPagesDesignation(response?.data?.paging?.totalPages || 0);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
        setIsLoading(false);
      });
  };

  console.log("options====>", options);

  const handleOpen = () => {
    setPageD(1);
  };

  const handleChange = (value) => {
    if (value === null) {
      // Handle the case when the value is cleared
      setValue(null);
    } else {
      setValue(value.label);
    }
    // setValue(value.label);
    // setPageD(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPageD(0);
  };

  const handleMenuScrollToBottom = () => {
    if (pageD < totalPagesDesignation) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  //************************ Designation End  ************************//

  //************************Companied DDL Start  ************************//
  const [valueC, setValueC] = useState(null);
  console.log("value", valueC);
  const [pageC, setPageC] = useState(0);
  const [typeC, setTypeC] = useState("");
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [optionsC, setOptionsC] = useState([]);
  const [totalPagesCom, setTotalPagesCom] = useState(0);

  const logValueC = debounce((typeC) => {
    setTypeC(typeC);
  }, 250);

  const Companies = () => {
    setIsLoadingC(true);
    axios
      .get(
        `Master/GetAllCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
        }&pageSize=100&DeviceType=Web`
      )
      .then((response) => {
        console.log(response);
        const data = response?.data?.responseData?.list.map((item) => ({
          value: item.uiDno,
          label: item.name,
          isOrgVrfd: item.isVerified,
          isOrgRgd: item.isRgstrd,
        }));
        setPageC((prevPage) => prevPage + 1);
        setIsLoadingC(false);
        setOptionsC((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesCom(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingC(false);
      });
  };

  const handleOpenC = () => {
    setPageC(1);
  };

  const handleChangeC = (value) => {
    if (value === null) {
      setValueC(null);
    } else {
      setValueC(value.value);
    }

    setPageC(0);
  };

  const handleInputChangeC = (typed) => {
    logValueC(typed);
    if (!typed) setPageC(0);
  };

  const handleMenuScrollToBottomC = () => {
    if (pageC < totalPagesCom) {
      Companies();
    }
  };

  const renderOptionC = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typeC) {
      Companies();
    }
  }, [typeC]);

  //************************ Companies DDL End  ************************//

  //********Industry DDL Start  ********//
  const [Industry, setIndustry] = useState([]);
  const [IndustryId, setIndustryId] = useState(null);
  const getIndustryType = () => {
    axios
      .get(`Master/GetFirmIndustryTypeDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fitid,
          label: item.name,
        }));
        setIndustry(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getIndustryType();
  }, []);
  //********Industry DDL End  ********//

  //********Category DDL Start  ********//
  const [Category, setCategory] = useState([]);
  const [CategoryId, setCategoryId] = useState(null);
  const getCategory = () => {
    axios
      .get(`Master/GetFirmCategoryDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fcid,
          label: item.name,
        }));
        setCategory(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);
  console.log("Category", Category);
  //********Category DDL End  ********//

  //********CompanySize DDL Start  ********//
  const [CompanySize, setCompanySize] = useState([]);
  const [CompanySizeId, setCompanySizeId] = useState(null);
  const getCompanySize = () => {
    axios
      .get(`Master/GetFirmSizeDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setCompanySize(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompanySize();
  }, []);
  //********CompanySize DDL End  ********//
  const handleCityChange = (selectedOption) => {
    if (selectedOption === null) {
      setCityId(null);
    } else {
      setCityId(selectedOption.value);
    }
  };
  const handleIndustryChange = (selectedOption) => {
    if (selectedOption === null) {
      setIndustryId(null);
    } else {
      setIndustryId(selectedOption.value);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    if (selectedOption === null) {
      setCategoryId(null);
    } else {
      setCategoryId(selectedOption.value);
    }
  };
  const handleCompanyChange = (selectedOption) => {
    if (selectedOption === null) {
      setCompanySizeId(null);
    } else {
      setCompanySizeId(selectedOption.value);
    }
  };

  useEffect(() => {
    if (showCCVExperience || CreateCCVExperience) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showCCVExperience, CreateCCVExperience]);

  //********Job Type List Start  ********//
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const getJobType = () => {
    axios
      .get(`Master/GetJobTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbTypId,
          label: item.name,
        }));
        setJobTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getJobType();
  }, []);
  //********Job Type List End  ********//

  //********Work Level Type List Start  ********//
  const [workTypeData, setWorkTypeData] = useState([]);
  const [workTypeId, setWorkTypeId] = useState(null);
  const getWorkType = () => {
    axios
      .get(`Master/GetWorkLevelTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkType();
  }, []);
  //********Work Level Type List End  ********//

  //********Work Location Type List Start  ********//
  const [workLocationData, setWorkLocationData] = useState([]);
  const [workLocationId, setWorkLocationId] = useState(null);
  const getWorkLocationType = () => {
    axios
      .get(`Master/GetWorkLocationTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkLocationType();
  }, []);
  //********Work Location Type List End  ********//
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      {showCCVExperience && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Experience</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-3 pointer"
                  onClick={handleCloseCCVExperience}
                />
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Designation (Job Title)
                      <span className="text-danger ms-2">*</span>
                    </label>

                    <CreatableSelect
                      defaultValue={value}
                      options={options}
                      styles={customStyles}
                      optionRenderer={renderOption}
                      placeholder={"Search and Select Designation"}
                      onOpen={handleOpen}
                      onChange={handleChange}
                      onMenuScrollToBottom={handleMenuScrollToBottom}
                      onInputChange={handleInputChange}
                      isClearable
                      invalid={display && value === ""}
                    />
                    {display && !value ? (
                      <span className="error_msg_lbl">
                        Please Select Designation{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company<span className="text-danger ms-2">*</span>
                    </label>
                    <CreatableSelect
                      defaultValue={valueC}
                      options={optionsC}
                      styles={customStyles}
                      optionRenderer={renderOptionC}
                      placeholder={"Search and Select Company"}
                      onOpen={handleOpenC}
                      onChange={handleChangeC}
                      onMenuScrollToBottom={handleMenuScrollToBottomC}
                      onInputChange={handleInputChangeC}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateCCVExperience();
                        handleCloseCCVExperience();
                      }}
                      className="iconsDropdown"
                      invalid={display && valueC === ""}
                      formatOptionLabel={(option) => (
                        <div>
                          {option.label}

                          {option.isOrgVrfd === true ? (
                            <img src={resume.verified} width={15} className="ms-1" />
                          ) : option.isOrgRgd === true ? (
                            <img src={resume.register} width={15} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={15} className="ms-1" />
                          )}
                        </div>
                      )}
                    />
                    {display && !valueC ? (
                      <span className="error_msg_lbl">
                        Please Select Company{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Profile Headline
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={headline}
                      onChange={(e) => setHeadline(e.target.value)}
                    // invalid={display && headline === ""}
                    ></textarea>
                    {/* {display && !headline ? (
                      <span className="error_msg_lbl">
                        Please Enter Profile Headline{" "}
                      </span>
                    ) : null} */}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Employment type (Job Type){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      defaultValue={jobTypeId}
                      onChange={(e) => {
                        setJobTypeId(e.value);
                      }}
                      options={jobTypeData}
                      styles={customStyles}
                      placeholder={"Select Job Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Work Level Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={workTypeId}
                      onChange={(e) => {
                        setWorkTypeId(e.value);
                      }}
                      options={workTypeData}
                      styles={customStyles}
                      placeholder={"Select Work Level Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Work Location Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={workLocationId}
                      onChange={(e) => {
                        setWorkLocationId(e.value);
                      }}
                      options={workLocationData}
                      styles={customStyles}
                      placeholder={"Select Work Location Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      City<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={cityId}
                      onChange={handleCityChange}
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      isClearable
                      invalid={display && cityId === ""}
                      styles={customStyles}
                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">Please Select City </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Description
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    // invalid={display && description === ""}
                    ></textarea>
                    {/* {display && !description ? (
                      <span className="error_msg_lbl">
                        Please Enter Description{" "}
                      </span>
                    ) : null} */}
                  </div>

                  {checkCurrent === false ? <div className="col-md-12 mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(!present)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> : ''}

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Start Year
                      <span className="text-danger ms-2">*</span>
                    </label>

                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />

                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Start Year{" "}
                      </span>
                    ) : null}
                  </div>
                  {present === true ? '' : <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      End Year
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>

                    <Flatpickr
                      className="form-control"
                      value={endDate}
                      onChange={(date) => setEndDate(date[0])}
                      id="dob"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />

                    {/* {display && !endDate ? (
                      <span className="error_msg_lbl">
                        Please Select End Year{" "}
                      </span>
                    ) : null} */}
                  </div>}

                  {/* <div className="col-md-6 mt-2 d-flex ">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      onChange={() => setPresent(true)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> */}

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleCloseCCVExperience}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={AddExperience}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      {CreateCCVExperience && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Create Experience</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleCloseCreateCCVExperience}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company Name<span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      invalid={display && companyName === ""}
                    />
                    {display && !companyName ? (
                      <span className="error_msg_lbl">Enter Company Name </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Job Title
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      invalid={display && jobTitle === ""}
                    />
                    {display && !jobTitle ? (
                      <span className="error_msg_lbl">Enter Job Title </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Profile Headline
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={headline}
                      onChange={(e) => setHeadline(e.target.value)}
                    // invalid={display && headline === ""}
                    ></textarea>
                    {/* {display && !headline ? (
                      <span className="error_msg_lbl">
                        Please Enter Profile Headline{" "}
                      </span>
                    ) : null} */}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Employment type (Job Type){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      defaultValue={jobTypeId}
                      onChange={(e) => {
                        setJobTypeId(e.value);
                      }}
                      options={jobTypeData}
                      styles={customStyles}
                      placeholder={"Select Job Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Work Level Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={workTypeId}
                      onChange={(e) => {
                        setWorkTypeId(e.value);
                      }}
                      options={workTypeData}
                      styles={customStyles}
                      placeholder={"Select Work Level Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Work Location Type <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input type='text' /> */}
                    <Select
                      defaultValue={workLocationId}
                      onChange={(e) => {
                        setWorkLocationId(e.value);
                      }}
                      options={workLocationData}
                      styles={customStyles}
                      placeholder={"Select Work Location Type"}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      City<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={cityId}
                      onChange={handleCityChange}
                      // onChange={(selectedOption) =>
                      //   setCityId(selectedOption.value)
                      // }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      styles={customStyles}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      isClearable
                      invalid={display && cityId === ""}
                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">Please Select City </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Email
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={display && email === ""}
                    />
                    {display && !email ? (
                      <span className="error_msg_lbl">Enter Email </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      About
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <input
                      type="text"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    // invalid={display && about === ""}
                    />
                    {/* {display && !about ? (
                      <span className="error_msg_lbl">Enter About </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Industry<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={IndustryId}
                      onChange={handleIndustryChange}
                      // onChange={(selectedOption) =>
                      //   setIndustryId(selectedOption.value)
                      // }
                      options={Industry}
                      styles={customStyles}
                      placeholder="Select Industry"
                      invalid={display && IndustryId === ""}
                      isClearable
                    />
                    {display && !IndustryId ? (
                      <span className="error_msg_lbl">
                        Please Select Industry{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company Category
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={CategoryId}
                      onChange={handleCategoryChange}
                      // onChange={(selectedOption) =>
                      //   setCategoryId(selectedOption.value)
                      // }
                      options={Category}
                      styles={customStyles}
                      placeholder="Select Category"
                      // invalid={display && CategoryId === ""}
                      isClearable
                    />
                    {/* {display && !CategoryId ? (
                      <span className="error_msg_lbl">
                        Please Select Company Category{" "}
                      </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company Size<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={CompanySizeId}
                      onChange={handleCompanyChange}
                      // onChange={(selectedOption) =>
                      //   setCompanySizeId(selectedOption.value)
                      // }
                      options={CompanySize}
                      styles={customStyles}
                      placeholder="Select CompanySize"
                      invalid={display && CompanySizeId === ""}
                      isClearable
                    />
                    {display && !CompanySizeId ? (
                      <span className="error_msg_lbl">
                        Please Select Company Size{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Public URL
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <input
                      type="text"
                      value={publicUrl}
                      onChange={(e) => setPublicUrl(e.target.value)}
                    // invalid={display && publicUrl === ""}
                    />
                    {/* {display && !publicUrl ? (
                      <span className="error_msg_lbl">Enter Public URL </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Website
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <input
                      type="text"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    // invalid={display && website === ""}
                    />
                    {/* {display && !website ? (
                      <span className="error_msg_lbl">Enter Website </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      TagLine
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <input
                      type="text"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                    // invalid={display && tagline === ""}
                    />
                    {/* {display && !tagline ? (
                      <span className="error_msg_lbl">Enter TagLine </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Average Turn Over
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <input
                      type="text"
                      value={avgTurnOver}
                      onChange={(e) => setAvgTurnOver(e.target.value)}
                    // invalid={display && avgTurnOver === ""}
                    />
                    {/* {display && !avgTurnOver ? (
                      <span className="error_msg_lbl">
                        Enter Average Turn Over{" "}
                      </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Description
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    // invalid={display && description === ""}
                    ></textarea>
                    {/* {display && !description ? (
                      <span className="error_msg_lbl">Enter Description </span>
                    ) : null} */}
                  </div>
                  {checkCurrent === false ? <div className="col-md-12 mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(!present)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> : ''}

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Start Year
                      <span className="text-danger ms-2">*</span>
                    </label>

                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />

                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Start Year{" "}
                      </span>
                    ) : null}
                  </div>
                  {present === true ? '' : <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      End Year
                      {/* <span className="text-danger ms-2">*</span> */}
                    </label>

                    <Flatpickr
                      className="form-control"
                      value={endDate}
                      onChange={(date) => setEndDate(date[0])}
                      id="dob"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />

                    {/* {display && !endDate ? (
                      <span className="error_msg_lbl">
                        Please Select End Year{" "}
                      </span>
                    ) : null} */}
                  </div>}


                  {/* <div className="col-md-6 mt-2 d-flex ">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(true)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> */}

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      style={{ width: "170px" }}
                      onClick={CreateExperience}
                    >
                      Create Experience
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default CCVExperience;
