import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
// import About from "../PrivacyAndTerms/About";
// import Contact from "../PrivacyAndTerms/Contact";
import { header } from "../../assets/Images/ImagesLink";
import "../../../src/assets/css/PrivacyPolicy.css";

const MainPage = () => {
  return (
    <>
      <div className="logo-content-cont d-flex align-items-center">
        <div className="weLinkLogo ps-2">
          <img src={header.mainLogo} alt="" />
        </div>
        <div>
          <nav>
            <ul className="d-flex privacy-nav-text">
              <li className="p-4">
                <Link to="/about">ABOUT</Link>
              </li>
              <li className="p-4">
                <Link to="/privacyPolicy">PRIVACY POLICY</Link>
              </li>
              <li className="p-4">
                <Link to="/termsAndCondition">TERMS AND CONDITION</Link>
              </li>
              <li className="p-4">
                <Link to="/userAgreement">USER AGREEMENT</Link>
              </li>
              <li className="p-4">
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MainPage;
