import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
// import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
// import pin from "../../assets/Images/svg/pin.svg"
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const MessageInteraction = ({
  isRead,
  chatId,
  isPined,
  getChatList,
  archiveMessage,
  msgId,
  getArchivedList,
  getMessageList,
  UserId,
  showClear, setShowClear,
  showDelete, setShowDelete
  // toUserId,
}) => {
  // console.log(isPined);
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //************************Archive Chat ************************//
  const ArchiveChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
    };
    try {
      await axios.post(`Message/ArchiveChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        getArchivedList();
        getMessageList();
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************Archive Chat End  ************************//

  //************************UnArchive Chat ************************//
  const UnArchiveChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
    };
    try {
      await axios.post(`Message/UnArchiveChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        getArchivedList();
        getMessageList();
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************UnArchive Chat End  ************************//
  //************************PinChat Chat ************************//
  const PinChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
    };
    try {
      await axios.post(`Message/PinMessage`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        getArchivedList();
        getMessageList();
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************PinChat Chat End  ************************//

  //************************UnPinChat Chat ************************//
  const UnPinChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
    };
    try {
      await axios.post(`Message/UnPinMessage`, responseData).then((res) => {
        // toast.success(res.data.message);
        // setToastMessage(res.data.message);
        // setShowToast(true);
        getArchivedList();
        getMessageList();
      });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  //************************UnPinChat Chat End  ************************//


  //************************Delete Chat start  ************************//
  // const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const DeleteChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
      toUserUid: UserId,
      forEveryOne: false
    };
    try {
      await axios
        .delete(`Message/DeleteFullUserChat`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShowDelete(!showDelete);
          //   toast.success(res.data.message);
          // setToastMessage(res.data.message);
          // setShowToast(true);
          getArchivedList();
          //getMessageList();
        });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  //************************Delete Post End  ************************//
  //************************Delete Chat start  ************************//
  const handleClearClose = () => {
    setShowClear(false);
  };

  const ClearChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId,
      toUserUid: UserId,
    };
    try {
      await axios
        .delete(`Message/ClearFullUserChat`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShowClear(!showClear);
          //   toast.success(res.data.message);
          // setToastMessage(res.data.message);
          // setShowToast(true);
          getArchivedList();
          getMessageList();
          getChatList();
        });
    } catch (e) {
      console.log(e);

      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  //************************Delete Post End  ************************//

  useEffect(() => {
    if (showDelete || showClear) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showDelete, showClear]);
  return (
    <>
      <div
        className="postMenu pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >

        <MoreHorizSharpIcon className="material-symbols-sharp" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
          >
            {archiveMessage === false ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    ArchiveChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">Archive Chat</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    UnArchiveChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">UnArchive Chat</span>
                  </div>
                </Link>
              </Dropdown.Item>
            )}
{/* jkjk */}

            {archiveMessage === false && isPined === false ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    PinChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">Pin Chat</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : isPined === true ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    UnPinChat();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="dropdownTxt f-14">UnPin Chat</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : (
              ""
            )}

            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  setShowClear(!showClear);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="dropdownTxt f-14">Clear Message</span>
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  setShowDelete(!showDelete);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="dropdownTxt f-14">Delete Message</span>
                </div>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </div>

      {/* <Modal
        show={show}
        size="md"
        centered
        onHide={handleDeleteClose}
        toggle={() => setShow(!show)}
        // className='modal-dialog-centered m'
      >
        <Modal.Header
          className="bg-transparent p-4"
          closeButton
          toggle={() => {
            setShow(!show);
          }}
        >
          <h6 className="f-16 fw-bold">
            Are you sure you want to Delete Chat?
          </h6>
        </Modal.Header>
        <Modal.Body className="text-center">
          <button color="" className="closebtn" onClick={() => DeleteChat()}>
            Delete
          </button>
        </Modal.Body>
      </Modal> */}

      {showDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to Delete Chat?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => DeleteChat()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showClear && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to Clear Chat?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleClearClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => ClearChat()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default MessageInteraction;
