import React from "react";
import { post } from "../../assets/Images/ImagesLink";


const Loading = () => {
  return (
    <div className="loading-container">
      <div className="lds-ripple">
        {/* <div><img src={ post.loader} alt="" srcset="" /></div>         */}
      </div>
    </div>
  );
};

export default Loading;
