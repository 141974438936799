
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../API/axios';
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import ModalForGuest from '../ModalForGuest';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const DetailJobApply = ({ isAppliedByYou, jobId, getAllJobList, item, profileConfig, userPer, AddUserCcv, userCcv }) => {

    console.log('isAppliedByYou', isAppliedByYou);

    const [checkApplied, setCheckApplied] = useState(isAppliedByYou);

    const sessionId = localStorage.getItem("sessionId");
    const guestModeData = localStorage.getItem("guestMode");
    const userUid = localStorage.getItem("userUid");
    const [showResumeModal, setShowResumeModal] = useState(false);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [bookmarkModal, setBookmarkModal] = useState(false);
    const [showAppliedModal, setShowAppliedModal] = useState(false);
    const handleShow = () => setShowAppliedModal(true);
    const handleCloseModal = () => setShowAppliedModal(false);
    const closeModalData = () => {
        setBookmarkModal(false);
    };
    const applyNew = async () => {
        try {
            const body = {
                deviceType: "Web",
                sessionId: sessionId,
                jobId: jobId,
                type: 1,
                userUid: userUid,
            };
            const res = await axios.post("/JobAction/ApplyJob", body);
            setToastMessage(res.data.message);
            setShowToast(true);
            getAllJobList();
            setCheckApplied(true)
            // setAppliedId(jobId);
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    const unapplyNew = async () => {
        try {
            const body = {
                deviceType: "Web",
                sessionId: sessionId,
                jobId: jobId,
                type: 1,
                userUid: userUid,
            };
            const res = await axios.post("/JobAction/UnApplyJob", body);
            setToastMessage(res.data.message);
            setShowToast(true);
            handleCloseModal();
            getAllJobList();
            setCheckApplied(false)
            // setAppliedId(appliedJobId);
        } catch (e) {
            console.log(e.response.data.message);
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    console.log('isAppliedByYou', checkApplied);

    return (
        <>
            <div className="mt-auto jobAction d-flex justify-content-between">
                {item?.isOwned !== true ? (
                    checkApplied === true ? (
                        <button
                            className="mainBtn  appliedBtn"
                            style={{ width: '100px' }}
                            onClick={() => {
                                guestModeData === "true"
                                    ? setBookmarkModal(true)
                                    : handleShow();
                                // setIsappliedF(false);
                            }}
                        >
                            <span className="default-text">Applied</span>
                            <span className="hover-text">Unapply</span>
                        </button>
                    ) : (
                        <button
                            className="mainBtn"
                            onClick={() =>
                                guestModeData === "true"
                                    ? setBookmarkModal(true)
                                    : (profileConfig.hasCcv === null || profileConfig.hasCcv === false) &&
                                        userPer?.percentage < 60
                                        ? setShowResumeModal(!showResumeModal)
                                        : (applyNew())
                            }
                        >
                            Apply Now
                        </button>
                    )
                ) : (
                    ""
                )}
                <Link to={`/JobDetail/${item.jobId}`}>
                    <button
                        className="mainBtn4"
                        to={`/JobDetail/${item.jobId}`}
                    >
                        View
                    </button>
                </Link>
            </div >

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />


            {
                showResumeModal && (
                    <div
                        className="modal fade show postAdd"
                        tabIndex="-1"
                        role="dialog"
                        style={{ display: "block" }}
                    >
                        <div className="modal-dialog  modal-dialog-centered " role="document">
                            <div className="modal-content d-flex justify-content-around p-2">
                                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                    <h6>Please complete your profile up to 60% and must have valid resume to apply for job</h6>

                                    {/* <span
                                        className="material-symbols-sharp me-2 pointer"
                                        onClick={() => {
                                            setShowResumeModal(false);
                                        }}
                                    >
                                        close
                                    </span> */}
                                    <CloseSharpIcon
                                        className="material-symbols-sharp me-2 pointer"
                                        onClick={() => {
                                            setShowResumeModal(!showResumeModal);
                                        }}
                                    />
                                </div>
                                <div className="text-center mt-2">
                                    <Link to={`/User/${userUid}`}>
                                        <button
                                            color=""
                                            className="closebtn"
                                            style={{ width: "150px" }}
                                            // onClick={() => {
                                            //     userCcv !== "True" && AddUserCcv();
                                            // }}
                                        >
                                            Manage Profile
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


            {
                showAppliedModal && (
                    <div
                        className="modal fade show postAdd"
                        tabIndex="-1"
                        role="dialog"
                        style={{ display: "block" }}
                    >
                        <div className="modal-dialog  modal-dialog-centered " role="document">
                            <div className="modal-content d-flex justify-content-around p-2">
                                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                    <h6>Unapply Job</h6>

                                    {/* <span
                                        className="material-symbols-sharp me-2 pointer"
                                        onClick={() => {
                                            handleCloseModal();
                                        }}
                                    >
                                        close
                                    </span> */}
                                    <CloseSharpIcon
                                        className="material-symbols-sharp me-2 pointer"
                                        onClick={() => {
                                            handleCloseModal();
                                        }}
                                    />
                                </div>

                                <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="mainBtn1"
                                        onClick={() => unapplyNew()}
                                    >
                                        Unapply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {bookmarkModal && <ModalForGuest closeModalData={closeModalData} />}
        </>
    )
}

export default DetailJobApply