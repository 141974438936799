import React from 'react'
import Select from 'react-select'
const TestPage = () => {
    return (
        <div>
            <Select />
        </div>
    )
}

export default TestPage