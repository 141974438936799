import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import axios from "../../API/axios";
import { resume, userAuthImage } from "../../assets/Images/ImagesLink";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Progress } from "reactstrap";
import Select from "react-select";
import Flatpickr from 'react-flatpickr';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const UpdateProfile = ({ getUserProfile }) => {
  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [userCcv, setUserCcv] = useState("");

  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`Resume/CheckUserResume`, data)
        .then((resData) => {
          console.log('resDataaaa', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const [createResumeMessage, setCreateResumeMessage] = useState('')
  console.log('createResumeMessage', createResumeMessage);

  const [showResumeModal, setShowResumeModal] = useState(false)
  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Resume/AddUserResume`, data).then((resData) => {
        console.log('add user resData', resData);
        setCreateResumeMessage(resData?.message)
        setUserCcv(resData.data.responseData);
        getUserProfilePer();
        // getUserProfile()
        setShowResumeModal(true)
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  useEffect(() => {
    userConfig();
    CheckUserCcv();
    // setShowResumeModal(true)
  }, []);
  const navigate = useNavigate();
  const redirectResume = async => {
    navigate(`/ManageResume/${userUid}`);
  }
  //************************ Profile Picture   ************************//
  const [selectedprofileImage, setselectedprofileImage] = useState(null);

  const [profileImage, setprofileImage] = useState(null);
  // console.log(profileImage);
  const [profileModal, setprofileModal] = useState(false);
  const handleprofileModal = () => {
    setprofileModal(false);
  };
  const fileInputRefprofile = useRef(null);
  // const handleProfileClick = () => {
  //   fileInputRefprofile.current.click();
  // };
  // const handleProfileImageChange = (event) => {
  //   const selectedFile = event.target.files[0];

  //   setprofileImage(selectedFile);
  //   // setprofileImage(event.target.files[0].name);
  //   setselectedprofileImage(URL.createObjectURL(selectedFile));
  // };

  const handleProfileClick = () => {
    fileInputRefprofile.current.click();
  };

  const handleProfileImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setprofileImage(selectedFile);
    setselectedprofileImage(URL.createObjectURL(selectedFile));
    setprofileModal(true); // Open the modal after selecting the image
  };
  const handleProfileModalClose = () => {
    setprofileModal(false);
  };
  const ProfileImageUpload = async () => {
    try {
      if (profileImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("ProfilPic", profileImage);
        const res = await axios.post("/UserAccount/SetProfilePic", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setprofileModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Profile Picture End  ************************//

  //************************ Background Image   ************************//
  const [bgSelectedImage, setBgSelectedImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  // console.log(backgroundImage);
  const [bgModal, setBgModal] = useState(false);
  const handleBgModal = () => {
    setBgModal(false);
  };
  const fileInputRefBg = useRef(null);
  const handleBgClick = () => {
    fileInputRefBg.current.click();
  };
  const handleBgImageChange = (event) => {
    const selectedFile = event.target.files[0];
    // setBackgroundImage(event.target.files[0].name);
    setBackgroundImage(selectedFile);
    setBgSelectedImage(URL.createObjectURL(selectedFile));
    setBgModal(true);
  };

  const BgImageUpload = async () => {
    try {
      if (backgroundImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("BackGroundImage", backgroundImage);
        const res = await axios.post(
          "/UserAccount/SetBackgroundImage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setBgModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Background Image End  ************************//

  //************************ DOB   ************************//
  const [startDate, setStartDate] = useState("");
  const [dob, setDob] = useState(false);
  const handledob = () => {
    setDob(false);
  };
  const DobUpload = async () => {
    try {
      if (startDate !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append(
          "Dob",
          startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD")
        );
        const res = await axios.post("/UserAccount/SetDob", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setDob(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ DOB  End ************************//

  //************************ Gender   ************************//
  const [genderModal, setGenderModal] = useState(false);
  const handleGenderModal = () => {
    setGenderModal(false);
  };
  const [genderValue, setGenderValue] = useState(null);
  const [genderOptionList, setGenderOptionList] = useState([]);
  const getGenderData = () => {
    try {
      axios.get(`/Master/GetGenderDDL`).then((response) => {
        // console.log("gender data", response.data.responseData);
        setGenderOptionList(response.data.responseData);
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  let genderOptions = genderOptionList.map((val) => {
    return { value: val.gndrId, label: val.name, id: val.gndrId };
  });

  const selectGender = (val) => {
    // console.log("gender", val.value);
    setGenderValue(val.value);
  };

  useEffect(() => {
    getGenderData();
  }, []);

  const GenderUpload = async () => {
    try {
      if (genderValue !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("GndrId", genderValue);
        const res = await axios.post("/UserAccount/SetGender", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setGenderModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Gender End   ************************//

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City List End  ********//

  //************************ language   ************************//
  const [languageModal, setlanguageModal] = useState(false);
  const handlelanguageModal = () => {
    setlanguageModal(false);
  };
  const [languageValue, setlanguageValue] = useState(null);
  const [languageOptionList, setlanguageOptionList] = useState([]);
  const getlanguageData = () => {
    try {
      axios.get(`/Master/GetLanguageDDL`).then((response) => {
        // console.log("language data", response.data.responseData);
        setlanguageOptionList(response.data.responseData);
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  let languageOptions = languageOptionList.map((val) => {
    return { value: val.langId, label: val.name, id: val.langId };
  });

  const selectlanguage = (val) => {
    // console.log("language", val.value);
    setlanguageValue(val.value);
  };

  useEffect(() => {
    getlanguageData();
  }, []);

  const languageUpload = async () => {
    try {
      if (cityId !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("CityID", cityId);
        const res = await axios.post("/UserAccount/SetCity", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setlanguageModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Language End   ************************//

  //************************ About   ************************//
  const [length, setLength] = useState('');
  const [aboutContent, setAboutContent] = useState("");
  const [aboutModal, setAboutModal] = useState(false);
  const handleAboutModal = () => {
    setAboutModal(false);
  };
  const AboutUpload = async () => {
    try {
      if (aboutContent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("AboutContent", aboutContent);
        const res = await axios.post("/UserAccount/SetAbout", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setAboutModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ About  End ************************//

  //************************ Website   ************************//
  const [websitecontent, setWebsitecontent] = useState("");
  const [websiteModal, setWebsiteModal] = useState(false);
  const handlewebsiteModal = () => {
    setWebsiteModal(false);
  };
  const websiteUpload = async () => {
    try {
      if (websitecontent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("Website", websitecontent);
        const res = await axios.post("/UserAccount/SetWebsite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setWebsiteModal(false);
        userConfig();
        getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Website  End ************************//

  //************************User Profile Stats Start  ************************//
  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  //************************User Profile Stats End  ************************//

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      className: state.isFocused
        ? "react-select__option--is-focused"
        : state.isSelected
          ? "react-select__option--is-selected"
          : "",
    }),
  };

  useEffect(() => {
    if (
      websiteModal ||
      aboutModal ||
      languageModal ||
      genderModal ||
      dob ||
      bgModal ||
      profileModal
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [
    websiteModal,
    aboutModal,
    languageModal,
    genderModal,
    dob,
    bgModal,
    profileModal,
  ]);
  console.log('profileConfig', profileConfig);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      <div className="UpdateProfile mb-3 p-2">
        <p className="f-16 fw-bold">Complete Your Profile</p>

        <div className="d-flex mt-2 align-items-center">
          <Progress
            style={{ height: "6px" }}
            value={Number(userPer?.percentage)}
            color={
              userPer?.percentage < 30
                ? "danger"
                : userPer?.percentage > 80
                  ? "success"
                  : userPer?.percentage > 30 && userPer?.percentage < 60
                    ? "warning"
                    : "info"
            }
            className=""
          />
          <span className="f-14 ms-4">{userPer?.percentage}%</span>
        </div>
        <div className="row">
          {profileConfig.hasProfileImage === true ? (
            <div className="col-md-4 d-flex mb-2 align-items-center ">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1">Profile picture uploaded </p>
            </div>
          ) : (<>
            <div className="col-md-4 d-flex circle mb-2 pointer">
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p
                className="f-14 fw-bold ms-1"
                onClick={() => {
                  // setprofileModal(true);
                  handleProfileClick();
                }}
              >
                Add Profile Picture
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRefprofile}
              style={{ display: "none" }}
              onChange={handleProfileImageChange}
              accept="image/*"
            />
          </>
          )}
          {profileConfig.hasBackgroundImage === true ? (
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1">Background Image uploaded </p>
            </div>
          ) : (<>
            <div
              className="col-md-4 d-flex circle mb-2 pointer"
              onClick={() => {
                // setBgModal(true);
                handleBgClick();
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">
                Add Background Image{" "}
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRefBg}
              style={{ display: "none" }}
              onChange={handleBgImageChange}
              accept="image/*"
            />
          </>
          )}
          {profileConfig.hasDob === true ? (
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1">DOB</p>
            </div>
          ) : (
            <div
              className="col-md-4 d-flex mb-2 align-items-center circle pointer"
              onClick={() => {
                setDob(true);
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">Add DOB</p>
            </div>
          )}
          {profileConfig.hasCity === true ? (
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1">City</p>
            </div>
          ) : (
            <div
              className="col-md-4 d-flex mb-2 align-items-center circle pointer"
              onClick={() => {
                setlanguageModal(true);
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">Add City</p>
            </div>
          )}

          {profileConfig.hasGender === true ? (
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1"> Gender </p>
            </div>
          ) : (
            <div
              className="col-md-4 d-flex mb-2 align-items-center circle pointer"
              onClick={() => {
                setGenderModal(true);
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">Add Gender</p>
            </div>
          )}


          {profileConfig.hasAbout === true ? (
            <div className="col-md-4 d-flex align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1">About</p>
            </div>
          ) : (
            <div
              className="col-md-4 d-flex align-items-center circle pointer"
              onClick={() => {
                setAboutModal(true);
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">Add About</p>
            </div>
          )}
          {profileConfig.hasWebsite === true ? (
            <div className="col-md-4 d-flex align-items-center">
              <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
              <p className="f-14 ms-1"> Website</p>
            </div>
          ) : (
            <div
              className="col-md-4 d-flex align-items-center circle pointer"
              onClick={() => {
                setWebsiteModal(true);
              }}
            >
              <AddCircleSharpIcon className="material-symbols-sharp " />
              <p className="f-14 fw-bold ms-1">Add  Website</p>
            </div>
          )}

          {userCcv === "True" ? (
            <div className="col-md-4 d-flex mb-2 align-items-center pointer">
              <Link to={`/ManageResume/${userUid}`} className="d-flex align-items-center">
                <CheckCircleOutlineSharpIcon className="material-symbols-sharp check" />
                <p className="f-14 ms-1">Manage Resume</p>
              </Link>
            </div>
          ) : (
            profileConfig?.hasDob && profileConfig?.hasGender && profileConfig?.hasCity ?
              <div
                className="col-md-4 d-flex mb-2 align-items-center circle pointer"
                onClick={AddUserCcv}
              >
                <AddCircleSharpIcon className="material-symbols-sharp " />
                <p className="f-14 fw-bold ms-1">Create  Resume</p>
              </div> : <div
                className="col-md-4 d-flex mb-2 align-items-center circle disbaled"
              >
                <AddCircleSharpIcon className="material-symbols-sharp " />
                <p className="f-14 fw-bold ms-1">Create  Resume</p>
              </div>
          )}

        </div>
      </div>

      {/* {profileModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Profile Image</h6>
                <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleprofileModal();
                  }} />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  
                  <div className="d-flex justify-content-center align-items-center mb-2 mt-2">
                    <div className="uploadImage-cont">
                      <img
                        src={userAuthImage.dummy1}
                        alt="Upload here"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={handleProfileClick}
                      />
                    </div>
                    <p>Choose File</p>
                  </div>

                  <div className="empty-line mb-3"></div>
                  <input
                    type="file"
                    ref={fileInputRefprofile}
                    style={{ display: "none" }}
                    onChange={handleProfileImageChange}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="uploaded-imagebg m-2 d-flex justify-content-center">
                {selectedprofileImage && (
                  <img src={selectedprofileImage} alt="Selected" height={200} />
                )}
              </div>
              <div className="col-md-12 text-end mb-3 me-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={ProfileImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {profileModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Profile Pic</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleProfileModalClose}
                />
              </div>
              <div className="d-flex">

              </div>
              <div className="uploaded-imagebg mt-2">
                {selectedprofileImage && (
                  <img src={selectedprofileImage} alt="Selected" height={200} />
                )}
              </div>
              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={ProfileImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {bgModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Background Image</h6>
                <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleBgModal();
                  }} />
              </div>
              <div className="d-flex d-flex justify-content-center">
                <div>

                  <div className="d-flex justify-content-center align-items-center mb-2 mt-2">
                    <div className="uploadImage-cont">
                      <img
                        src={userAuthImage.dummy1}
                        alt="Upload here"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={handleBgClick}
                      />
                    </div>
                    <p>Choose File</p>
                  </div>

                  <div className="empty-line mb-3"></div>
                  <input
                    type="file"
                    ref={fileInputRefBg}
                    style={{ display: "none" }}
                    onChange={handleBgImageChange}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="uploaded-imagebg mt-2 d-flex justify-content-center">
                {bgSelectedImage && (
                  <img src={bgSelectedImage} alt="Selected" height={200} />
                )}
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={BgImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {bgModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Background Image</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleBgModal();
                  }}
                />
              </div>

              <div className=" mt-2">
                {bgSelectedImage && (
                  <img src={bgSelectedImage} alt="Selected" height={200} width={'100%'} />
                )}
              </div>

              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={BgImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {dob && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>DOB</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handledob();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex align-items-center flex-column">
                  {/* <h5>DOB</h5> */}
                  <div className="dateofbirth">
                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      placeholder="Select Date"
                      options={{
                        maxDate: "today",// Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={DobUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {genderModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Gender</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleGenderModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Gender</h4> */}
                  <div className="col-md-12">
                    {/* <label htmlFor="about">Gender</label> */}
                    <ReactSelect
                      options={genderOptions}
                      onChange={selectGender}
                      loadOptions={selectGender}
                      placeholder="Select Gender"
                      styles={customStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={GenderUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {languageModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>City</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlelanguageModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Language</h4> */}
                  <div className="col-md-12 mt-2">
                    {/* <label htmlFor="about">Language</label> */}
                    {/* <ReactSelect
                      options={languageOptions}
                      onChange={selectlanguage}
                      loadOptions={selectlanguage}
                      placeholder="Select Language"
                      styles={customStyles}
                    /> */}
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={languageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {aboutModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>About</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleAboutModal();
                  }}
                />
              </div>

              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 about-textArea">
                    {/* <textarea
                      id="address"
                      name="about"
                      rows="4"
                      cols="55"
                      value={aboutContent}
                      onChange={(e) => setAboutContent(e.target.value)}
                    ></textarea> */}
                    <textarea
                      id="address"
                      name="about"
                      rows="4"
                      cols="55"
                      value={aboutContent}
                      onChange={(e) => {
                        setAboutContent(e.target.value)
                        setLength(e.target?.value.length)
                      }}
                      maxLength={500}
                    ></textarea>
                    <p className='info'>{aboutContent?.length && length}/500</p>
                  </div>
                </div>
              </div>
              <p className="p-2 text-danger f-14">
                About content should be less than 500 words
              </p>
              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={AboutUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {websiteModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Website</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlewebsiteModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-4 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 web-input">
                    {/* <label htmlFor="about" className="web-text">Website </label> */}
                    <input
                      type="text"
                      style={{ width: "100%" }}
                      value={websitecontent}
                      onChange={(e) => setWebsitecontent(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={websiteUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showResumeModal && (
        <div
          className="modal fade show modal-md"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="p-2 pt-4 text-center reumeModal">
                <CheckSharpIcon />
                <p>Resume details added successfully!</p>
              </div>
              {/* <Form> */}
              <div className="row modal-p settings CreateCompany">
                <div className="text-center mt-4">
                  {/* <button
                    type="button"
                    className="closebtn me-2"
                    // onClick={handleModalClose}
                    style={{ width: "60px" }}
                  >
                    close
                  </button> */}
                  <button
                    type="button"
                    className="mainBtn1"
                    onClick={redirectResume}
                    style={{ width: "100px" }}
                  >
                    Manage
                  </button>
                </div>
              </div>
              {/* </Form> */}
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default UpdateProfile;
