import React, { useEffect, useState } from "react";
import { FeatureImages, JobSearch, NewsFeed, post } from "../../assets/Images/ImagesLink";
import "../../assets/css/JobSearch.css";
import { Col, Row } from "react-bootstrap";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link } from "react-router-dom";
import moment from "moment";
import { ShimmerDiv } from "shimmer-effects-react";
import JobAction from "../JobSearch/JobAction";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import JobApply from "../JobSearch/JobApply";
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';

const FeaturedJobs = ({
  JobId,
  reset,
  filterJobList,
  setReset,
  jobsList,
  setJobsList,
  getAllJobList,
  setSearchValue,
  searchValue,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [similarList, setSimilarList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isError, setIsError] = useState(false);

  const [showResumeModal, setShowResumeModal] = useState(false);
  console.log("similarList", similarList);

  const SimilarJob = async (page) => {
    // const data = {
    //   "deviceType": "Web",
    //   "pageNumber": page,
    //   "pageSize": 12
    // };
    try {
      setLoading(true);
      const resData = await axios.get(
        `JobsBy/SimilarJobs?DeviceType=Web&JobID=${JobId}&pageNumber=${page}&pageSize=12 `
      );
      setIsLoading(false);
      setTotalPages(resData.data.responseData.paging.totalPages);
      const newPosts = resData.data.responseData.viewJobs;
      setSimilarList((prev) => {
        const postSet = new Set(prev.map((post) => post.jobId));
        const uniqueNewPosts = newPosts.filter(
          (post) => !postSet.has(post.jobId)
        );
        return [...prev, ...uniqueNewPosts];
      });
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.error(error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    SimilarJob(page);
  }, [page]);

  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //************************Save job End  ************************//
  const [savedJobs, setSavedJobs] = useState(new Set());
  const saveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/SaveJob`, responseData).then((res) => {
        console.log("save post", res);
        setToastMessage(res.data.message);
        setShowToast(true);
        setSavedJobs((prev) => new Set(prev).add(jobId));
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Save job End  ************************//

  //************************UnSave job start  ************************//
  const unsaveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/UnSaveJob`, responseData).then((res) => {
        // console.log(res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(false);
        setSavedJobs((prev) => {
          const newSavedJobs = new Set(prev);
          newSavedJobs.delete(jobId);
          return newSavedJobs;
        });
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnSave job End  ************************//

  //************************Apply and Unapply job  ************************//
  //   const [isappliedF, setIsappliedF] = useState(false);
  //   const [appliedId, setAppliedId] = useState("");
  const [showAppliedModal, setShowAppliedModal] = useState(false);
  const handleCloseModal = () => setShowAppliedModal(false);
  const handleShow = () => setShowAppliedModal(true);
  const [appliedJobId, setAppliedJobId] = useState("");
  const [bookmarkModal, setBookmarkModal] = useState(false);
  const closeModalData = () => {
    setBookmarkModal(false);
  };
  const applyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: jobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      // console.log('body', body);
      const res = await axios.post("/JobAction/ApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      // closeModal();
      // setJobList([]);
      getAllJobList(page);
      setAppliedId(jobId);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const unapplyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: appliedJobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      const res = await axios.post("/JobAction/UnApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      handleCloseModal();
      getAllJobList(page);
      setAppliedId(appliedJobId);
    } catch (e) {
      console.log(e.response.data.message);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        // console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  const [appliedId, setAppliedId] = useState("");
  const [isappliedF, setIsappliedF] = useState(false);
  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  return (
    <>
      <div className="feature-main-container">
        <div className="feature-image">
          <img src={NewsFeed.AdImg2} alt="" srcset="" />
          {/* <img src={JobSearch.ad2} alt="" srcset="" /> */}
        </div>
        <div className="feature-jobs-card-cont">
          <div className="feature-cards-container">
            <Row>
              <Col md={12}>
                <div className="feature-card-main-text mb-2 f-600 mt-2">
                  Similar Jobs
                </div>
                {isLoading ? (
                  <div className="mt-3">
                    <ShimmerDiv mode="light" height={250} width={'100%'} />
                  </div>
                ) : isError ? (
                  <div className="mt-2 error-image-cont">
                    <img src={post.errorbig} alt="404 Not Found" />
                  </div>
                ) : (
                  similarList &&
                  similarList?.map((item, index) => {
                    console.log('ghgsd', item);

                    const now = moment();
                    const itemTime = moment.utc(item?.creationTime);
                    const duration = moment.duration(now.diff(itemTime));

                    const years = duration.years();
                    const months = duration.months();
                    const days = duration.days();
                    const hours = duration.hours();
                    const minutes = duration.minutes();

                    let result = "";

                    if (years > 0) {
                      result = `${years} year${years > 1 ? "s" : ""} ago`;
                    } else if (months > 0) {
                      result = `${months} month${months > 1 ? "s" : ""} ago`;
                    } else if (days > 0) {
                      result = `${days} day${days > 1 ? "s" : ""} ago`;
                    } else if (hours > 0) {
                      result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
                    } else if (minutes > 0) {
                      result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
                    } else {
                      result = "just now";
                    }

                    return (
                      <div className="col-md-12 mt-4">
                        <div className="jobCard p-3">
                          <div
                            className="d-flex justify-content-start align-items-center"
                            style={{ height: "60px" }}
                          >
                            <Link to={`/Company/${item.compUid}`}>
                              <div className="pointer me-2">
                                <img
                                  src={item?.compLogo}
                                  alt=""
                                  width={50}
                                  height={50}
                                  style={{ borderRadius: "50px" }}
                                />
                              </div>
                            </Link>
                            <Link to={`/Company/${item.compUid}`}>
                              <span className="f-14 f-700 job-company-name">
                                {item.compName}
                              </span>
                            </Link>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-2 emoji-container">
                            <Link to={`/JobDetail/${item.jobId}`}>
                              <p className="f-16 fw-bold pointer">
                                {/* {item.title.length > 20
                                  ? `${item.title.substring(0, 20)}...`
                                  : item.title} */}
                                {item.title}
                              </p>
                            </Link>
                            {/* <span className="emoji-name job-name-details">
                              {item.title}
                            </span> */}

                            {savedJobs.has(item?.jobId) ||
                              item?.isSaved === true ? (
                              <div className="d-flex align-items-center postAction">
                                {/* <span
                                  className="material-symbols-sharp pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      unsaveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                >
                                  bookmark
                                </span> */}
                                <BookmarkSharpIcon
                                  className="material-symbols-sharp pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      unsaveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                />
                              </div>
                            ) : (
                              <div className="d-flex align-items-center postAction">
                                <BookmarkBorderSharpIcon
                                  className="material-symbols-sharp pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      saveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                />
                              </div>
                            )}
                          </div>
                          <p className="f-14">
                            {/* {item.shortDescription.length > 15
                              ? `${item.shortDescription.substring(0, 15)}...`
                              : item.shortDescription} */}
                            {item.shortDescription}
                          </p>
                          <h6 className="f-10 mt-1">{item.cityName}</h6>
                          <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                            <small className="f-8">Posted {result}</small>
                          </div>
                          <div className="jobType mt-2">
                            <div className="badge badge-job me-3">
                              {item.jbTypName}
                            </div>
                            <div className="badge badge-job me-3">
                              {item.workLocationTypeName}
                            </div>
                            <div className="badge badge-job">
                              {item.designationName}
                            </div>
                          </div>
                          <div className="mt-3">
                            <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={getAllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                          </div>

                        </div>
                      </div>
                    );
                  })
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default FeaturedJobs;
