import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to the external URL after the component loads
        window.location.href = "https://wlj.app/NotFound";
    }, [navigate]);

    return null; // You could add a loading spinner or message if needed
};

export default NotFound;
