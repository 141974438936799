import React, { useState, useEffect } from "react";
import "../../../assets/css/History.css";
import {
  Button,
  Col,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
  Card,
  CardBody,
  NavLink,
} from "reactstrap";
import search from "../../../assets/Images/Job/History/search.svg";
import location from "../../../assets/Images/Job/History/location.svg";
import job from "../../../assets/Images/Job/History/job.svg";
// import axios from "axios";
import Select from "react-select";
import { Tabs, Tab } from "@tarragon/swipeable-tabs";
// import { Row, Tab, Tabs } from "react-bootstrap";
import AppliedJobs from "./AppliedJobs";
import SavedJobs from "./SavedJobs";
import JobOffers from "./JobOffers";
import CallingForInterviews from "./CallingForInterviews";
import Header from "../../Navigation/Header";
import { Helmet } from "react-helmet";
// import { selectThemeColors } from '@utils'
// import '@styles/react/libs/react-select/_react-select.scss'

const History = () => {

  const [selectedTab, setSelectedTab] = useState("Tab 1");

  const changeTab = (event) => {
    setSelectedTab(event.label);
  };
  const [activeTab, setActiveTab] = useState(1);

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <AppliedJobs />
          </>
        );
      case 2:
        return (
          <>
            <SavedJobs />
          </>
        );
      case 3:
        return (
          <>
            <JobOffers />
          </>
        );
      case 4:
        return (
          <>
            <CallingForInterviews />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>WeLink Jobs: Effortlessly Manage Jobs Online</title>
        <meta name="description" content="Easily manage jobs with WeLink Jobs. Experience efficiency on one of the most trusted job search sites." />
        {/* <meta name="keywords" content="react, meta tags, seo" /> */}
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content="WeLink Jobs: Effortlessly Manage Jobs Online" />
        <meta property="og:description" content="Easily manage jobs with WeLink Jobs. Experience efficiency on one of the most trusted job search sites." />
        {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
        <meta property="og:url" content="https://welinkjobs.com/ManageJobs" />
      </Helmet>
      <div className="jobSearch-main-container">

        <div className="job-history row deskT my-3 mt-5">

          <div className="row p-0">
            <div className="col-md-6 col-lg-4">
              <AppliedJobs />
            </div>

            <div className="col-md-6 col-lg-4">
              <SavedJobs />
            </div>

            {/* <div className="col-md-6 col-lg-4">
              <JobOffers />
            </div> */}

            <div className="col-md-6 col-lg-4">
              <CallingForInterviews />
            </div>
          </div>
        </div>

        <div className="job-history row mobT mt-5 connectionPage">
          <div className="tabButton d-flex p-3 w-100 mt-4 ps-0 connectionTab">
            {/* {loading && <Loading />} */}
            <NavLink
              onClick={() => setActiveTab(1)}
              className={`text-center ${activeTab === 1 ? "activeTab" : ""}`}
            >
              <p className="f-14 tabName">Applied Jobs</p>
            </NavLink>
            <NavLink
              onClick={() => setActiveTab(2)}
              className={`text-center ${activeTab === 2 ? "activeTab" : ""}`}
            >
              <p className="f-14 tabName">Saved Jobs</p>
            </NavLink>
            <NavLink
              onClick={() => setActiveTab(3)}
              className={`text-center ${activeTab === 3 ? "activeTab" : ""}`}
            >
              <p className="f-14 tabName">Job Offers</p>
            </NavLink>
            <NavLink
              onClick={() => setActiveTab(4)}
              className={`text-center ${activeTab === 4 ? "activeTab" : ""}`}
            >
              <p className="f-14 tabName">Calling for Interviews</p>
            </NavLink>
          </div>
          {/* <Tabs value={selectedTab} onChange={changeTab}>
            <Tab label="Applied Jobs" key={0} className="col-md-6 col-lg-3">
              <AppliedJobs />
            </Tab>
            <Tab label="Saved Jobs" key={1} className="col-md-6 col-lg-3">
              <SavedJobs />
            </Tab>
            <Tab label="Job Offers" key={2} className="col-md-6 col-lg-3">
              <JobOffers />
            </Tab>
            <Tab
              label="Calling for Interviews"
              key={3}
              className="col-md-6 col-lg-3"
            >
              <CallingForInterviews />
            </Tab>
          </Tabs> */}
          {renderTabContent()}
        </div>
      </div>
    </>
  );
};

export default History;
