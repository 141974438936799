import React from "react";
import { Form } from "react-bootstrap";

const SettingUp = () => {
  return (
    <>
      <div className="setting-up-main-container p-3">
        <div className="   f-14 f-600 mb-3">Settings Up</div>
        <div className="setting-items">
          <input type="checkbox" name="abcd" id="" />
          <span className="ms-2 f-12">Close Comments</span>
        </div>
        <div className="setting-items">
          <input type="checkbox" name="abcd" id="" />
          <span className="ms-2 f-12">Anonymous Writer</span>
        </div>
        <div className="setting-items d-flex justify-content-between">
          <div className="setting-checkbox">
            <input type="checkbox" name="abcd" id="" />
            <span className="ms-2 f-12">Publish</span>
          </div>
          <div className="time">Tonight - 3PM </div>
        </div>
      </div>
    </>
  );
};

export default SettingUp;
