// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ element: Component, isProtected, redirectTo }) => {
//     const token = localStorage.getItem("token");
//     const guestMode = localStorage.getItem("guestMode");

//     // If the route is protected and the user is not authenticated or not in guest mode, redirect to sign-in
//     if (isProtected && !token && !guestMode) {
//         return <Navigate to="/SignIn" replace />;
//     }

//     // If the user is authenticated, prevent access to SignIn/SignUp
//     if (!isProtected && token) {
//         return <Navigate to="/" replace />;
//     }


//     // if (guestMode) {
//     //     const restrictedRoutes = ['/Draft', '/MyConnections', '/ManageJobs', '/JobApplications', '/Notification', '/Message', '/Settings/profile', '/Settings/changePassword', '/Settings/blockedUser', '/Settings/account'];
//     //     if (restrictedRoutes.includes(window.location.pathname)) {
//     //         return <Navigate to="/NewsFeed" replace />;
//     //     }
//     // }
//     if (guestMode) {
//         const restrictedRoutes = ['/Draft', '/MyConnections', '/ManageJobs', '/JobApplications', '/Notification', '/Message', '/Settings/profile', '/Settings/changePassword', '/Settings/blockedUser', '/Settings/account'];
//         const currentPath = window.location.pathname;

//         // Check if the current path is restricted or starts with restricted routes
//         const isRestrictedPath = restrictedRoutes.some(route => currentPath.startsWith(route)) ||
//             currentPath.startsWith('/Message/');

//         if (isRestrictedPath) {
//             return <Navigate to="/Newsfeed" replace />;
//         }
//     }

//     // If in guest mode, let them access protected routes but with limited functionality
//     return <Component />;
// };

// export default PrivateRoute;



import React from "react";
import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ element: Component, isProtected }) => {
//     const token = localStorage.getItem("token");
//     const guestMode = localStorage.getItem("guestMode") === "true";

//     // Restricted routes for guest mode
//     const restrictedRoutes = [
//         "/Draft",
//         "/MyConnections",
//         "/ManageJobs",
//         "/JobApplications",
//         "/Notification",
//         "/Message",
//         "/Settings/profile",
//         "/Settings/changePassword",
//         "/Settings/blockedUser",
//         "/Settings/account",
//     ];

//     const currentPath = window.location.pathname;

//     // Allow guests to access NewsFeed specifically
//     if (currentPath === "/NewsFeed" && guestMode && !token) {
//         return <Component />;
//     }

//     // Redirect guests from restricted routes
//     if (guestMode) {
//         const isRestrictedPath =
//             restrictedRoutes.some(route => currentPath.startsWith(route)) ||
//             currentPath.startsWith("/Message/");

//         if (isRestrictedPath) {
//             return <Navigate to="/NewsFeed" replace />;
//         }
//     }

//     // Redirect unauthenticated users to SignIn for protected routes
//     if (isProtected && !token && !guestMode) {
//         return <Navigate to="/SignIn" replace />;
//     }

//     // Prevent authenticated users from accessing non-protected routes like SignIn/SignUp
//     if (!isProtected && token) {
//         return <Navigate to="/" replace />;
//     }

//     // Render the protected component
//     return <Component />;
// };

const PrivateRoute = ({ element: Component, isProtected, redirectTo }) => {
    const token = localStorage.getItem("token");
    const guestMode = localStorage.getItem("guestMode");

    const guestAccessibleRoutes = [
        "/NewsFeed",
        "/Blogs",
        "/Companies",
        "/SearchJob",
        "/User/:uid",
        "/Company/:CompUid",
        "/Post/:PostId",
        "/JobDetail/:JobId",
    ];

    const currentPath = window.location.pathname;

    // If the route is protected but accessible in guest mode
    if (guestAccessibleRoutes.some(route => currentPath.startsWith(route))) {
        return <Component />;
    }

    // If protected route and user is not authenticated
    if (isProtected && !token && !guestMode) {
        return <Navigate to={redirectTo} replace />;
    }

    // If not protected and user is already authenticated
    if (!isProtected && token) {
        return <Navigate to="/" replace />;
    }

    return <Component />;
};

export default PrivateRoute;





