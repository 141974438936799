import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/navigation.css";
import {
  header,
  icons,
  people,
  post,
  SignInImages,
} from "../../assets/Images/ImagesLink";
import { IoIosArrowDown } from "react-icons/io";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { ShimmerDiv } from "shimmer-effects-react";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const RightNavigation = () => {
  const guestModeData = localStorage.getItem("guestMode");

  const sessionId = localStorage.getItem("sessionId");
  const [recommendationList, setRecommendationList] = useState([]);
  const [recommendedPage, setRecommendedPage] = useState(1);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isfollowed, setIsfollowed] = useState(false);

  const [searchUserName, setSearchUserName] = useState("");
  const [searchconnectList, setSearchconnectList] = useState([]);

  //************************ Get Recommendations List  ************************//
  const GetRecommendation = async () => {
    try {
      const result = await axios.post(`UserFollow/GetRecommendationList`, {
        sessionId,
        deviceType: "Web",
        pageNumber: 1,
        pageSize: 8,
      });
      const Response = result.data.responseData.viewList;
      setRecommendationList((prev) => {
        const postSet = new Set(prev.map((post) => post.userUid));
        const uniqueNewPosts = Response.filter(
          (post) => !postSet.has(post.userUid)
        );
        return [...prev, ...uniqueNewPosts];
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    GetRecommendation();
  }, [recommendedPage, isfollowed]);

  //************************  Get Recommendations List End  ************************//

  //************************ Connection  List   ************************//
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [ConnectionList, setConnectionList] = useState([]);
  const [ConnectionListForGuest, setConnectionListForGuest] = useState("");
  const [compListForGuest, setCompListForGuest] = useState("");

  const [isError, setIsError] = useState(false);

  const ConnectionListAll = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: 1,
      pageSize: 8,
      type: "ConnectionList",
      search: searchUserName,
    };
    try {
      const resData = await axios.post("Connection/GetConnectionList", data);
      // console.log('resDAta', resData.status);
      setIsLoading(false);
      if (searchUserName !== "") {
        setConnectionList([]);
        setSearchconnectList(resData?.data?.responseData?.viewList);
      } else {
        setSearchconnectList([]);
        // console.log("resData.data.responseData.viewList", resData);
        if (resData.status === 204) {
          setIsError(true)
        } else {
          setConnectionList((prev) => {
            const newViewList = resData?.data?.responseData?.viewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
            );
            return [...prev, ...newViewList];
          });
        }
      }

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ConnectionListAll();
  }, [page, searchUserName]);

  const ConnectionListAllForGuest = async (id) => {
    try {
      await axios
        .get("UserFollow/GetMostFollowingUser?DeviceType=Web")
        .then((resData) => {
          // console.log("ConnectionListAllForGuest", resData.data.responseData);
          setConnectionListForGuest(resData.data.responseData);
          setIsLoading(false);
          // setConnectionListForGuest((prev) => [
          //   ...prev,
          //   ...resData.data.responseData.viewList,
          // ]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const ComanpanyListAllForGuest = async (id) => {
    try {
      await axios
        .post("Entity/GetMostFollowedList?DeviceType=Web")
        .then((resData) => {
          // console.log("ComanpanyListAllForGuest====", resData.data.responseData);
          setCompListForGuest(resData.data.responseData);
          setIsLoading(false);
          // setConnectionListForGuest((prev) => [
          //   ...prev,
          //   ...resData.data.responseData.viewList,
          // ]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (guestModeData) {
      ConnectionListAllForGuest();
      ComanpanyListAllForGuest();
    }
  }, []);
  // console.log("ConnectionList", ConnectionList);
  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
        setRecommendedPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const navigate = useNavigate();

  const navigateConnect = useNavigate();

  const redirectToReceivedTab = () => {
    navigate("/MyConnections", { state: { activeTab: 4 } });
  };
  const redirectToConnectionTab = () => {
    navigateConnect("/MyConnections", { state: { activeTab: 1 } });
  };

  //************************Connection List ************************//

  //************************ short mutual list ************************//
  const [shortMutualList, setShortMutualList] = useState({});
  // console.log('shortMutualList', shortMutualList);

  useEffect(() => {
    if (recommendationList && recommendationList.length > 0) {
      recommendationList.forEach((item) => {
        fetchShortMutualList(item.userUid);
      });
    }
  }, [recommendationList]);

  const fetchShortMutualList = async (id) => {
    // console.log("connect data", id);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUid: id,
    };
    try {
      const response = await axios.post(
        "UserFollow/GetShortMutualUserInfo",
        data
      );
      setShortMutualList((prevState) => ({
        ...prevState,
        [id]: response.data.responseData,
      }));
      setIsLoading(false);
      // if (response.data.status === "Warning") {
      // }
    } catch (error) {
      console.log(error);

      // if (error.response && error.response.status === 404) {
      // } else {
      //   console.log(error);
      // }
      setIsLoading(false);
    }
  };

  //************************ short mutual list ************************//

  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef([]);
  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  const handleClickOutside = (event) => {
    if (
      openDropdown !== null &&
      dropdownRefs.current[openDropdown] &&
      !dropdownRefs.current[openDropdown].contains(event.target)
    ) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);
  const [recommendUserId, setRecommendUserId] = useState("");
  const [isConnected, SetIsConnected] = useState(false);

  // console.log("recommendationList", recommendationList);

  const handleRecommendFollow = (item) => {
    FollowedUser(item.userUid);
    // console.log("item.userUid", item.userUid);
  };

  const handleRecommendConnect = (item) => {
    // console.log("fn run", item.userUid);
    ConnectUser(item.userUid);
  };
  const [connectedId, setConnectedId] = useState("");
  const ConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    // console.log("connect rs", data);
    try {
      await axios
        .post("Connection/SendConnectionRequest", data)
        .then((resData) => {
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          // GetRecommendation();
          SetIsConnected(true);
          setRecommendationList((prev) =>
            prev.filter((item) => item.userUid !== id)
          );
          GetRecommendation();
          //   CheckConnectUser();
          // CheckFollowedUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [followedId, setFollowedId] = useState("");
  // console.log('isfollowed', isfollowed);
  const FollowedUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    // console.log("follow before", data);
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        // console.log("follow fn after", resData);
        // toast.success(resData.data.message);
        // getUserProfileList();
        setToastMessage(resData.data.message);
        setShowToast(true);
        setRecommendationList((prev) =>
          prev.filter((item) => item.userUid !== id)
        );
        GetRecommendation();
        setIsfollowed(true);
        // getUserProfile();
        // CheckFollowedUser();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Cancel Request   ************************//
  const [show, setShow] = useState(false);
  const cancelRequestClose = () => {
    setShow(false);
  };
  // const [skillId, setSkillId] = useState("");
  const [userUID, setuserUID] = useState("");
  const CancelRequest = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: userUID,
    };
    // console.log(data);
    try {
      await axios
        .post("Connection/CancelConnectionRequest ", data)
        .then((resData) => {
          // console.log(resData);
          setShow(!show);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          setConnectedId(userUID);
          SetIsConnected(false);
          // sentRequestList();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Cancel Request ************************//

  return (
    <>
      <div className="sideLinks">
        {guestModeData === "true" ? (
          <div>
            <div className="hashtags pt-4">
              <div className="header1 d-flex justify-content-between align-items-center">
                <h4 className="heading mb-0">Top Users</h4>
              </div>
              <ul className="ps-0 listLink scroller">
                {isLoading ? (
                  <div className="mt-3">
                    <ShimmerDiv mode="light" height={250} width={"100%"} />
                  </div>
                ) : (
                  ConnectionListForGuest &&
                  ConnectionListForGuest?.map((item, index) => {
                    return (
                      <>
                        <NavLink to={`/User/${item.userUid}`} key={index}>
                          <li className="navLinks d-flex align-items-center mt-3">
                            <div className="imgBg1">
                              <img
                                src={item.profilPic}
                                alt=""
                                width={35}
                                height={35}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                            <div>
                              <span className="profile-name">
                                {item.fullName}
                              </span>
                              <p className="lastseen connectionFollower">
                                {item.followersCount} followers
                              </p>
                            </div>
                          </li>
                        </NavLink>
                      </>
                    );
                  })
                )}
              </ul>
            </div>
            <div className="hashtags pt-4">
              <div className="header1 d-flex justify-content-between align-items-center">
                <h4 className="heading mb-0">Top Companies</h4>
              </div>
              <ul className="ps-0 listLink scroller">
                {isLoading ? (
                  <div className="mt-3">
                    <ShimmerDiv mode="light" height={250} width={"100%"} />
                  </div>
                ) : (
                  compListForGuest &&
                  compListForGuest?.map((item, index) => {
                    return (
                      <>
                        <NavLink to={`/Company/${item.uid}`} key={index}>
                          <li className="navLinks d-flex align-items-center mt-3">
                            <div className="imgBg1">
                              <img
                                src={item.image}
                                alt=""
                                width={35}
                                height={35}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                            <div>
                              <span className="profile-name">{item.name}</span>
                              <p className="lastseen connectionFollower">
                                {item.followersCount} followers
                              </p>
                            </div>
                          </li>
                        </NavLink>
                      </>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <div className="hashtags pt-4">
              <div className="header1 d-flex justify-content-between align-items-center">
                <h4 className="heading mb-0">My Connection</h4>
              </div>
              <ul className="ps-0 listLink scroller">
                {/* {console.log(searchconnectList?.length, ConnectionList?.length)} */}

                {isLoading ? (
                  <div className="mt-3">
                    <ShimmerDiv mode="light" height={250} width={"100%"} />
                  </div>
                ) : isError || (searchconnectList?.length === undefined && ConnectionList?.length === 0) ? (
                  <div className="mt-2">
                    <img
                      src={post.errorSmall}
                      alt="404 Not Found"
                      width={200}
                    />
                  </div>
                ) : searchconnectList?.length === 0 ? (
                  <>
                    {ConnectionList?.slice(0, 5)?.map((item, index) => (
                      <NavLink to={`/User/${item.userUid}`} key={index}>
                        <li className="navLinks d-flex align-items-center mt-3">
                          <div className="imgBg1">
                            <img
                              src={item.profilPic}
                              alt=""
                              width={35}
                              height={35}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                          <div>
                            <span className="profile-name">
                              {item.fullName}
                            </span>
                            <p className="lastseen connectionFollower">
                              {item.followersCount} followers
                            </p>
                          </div>
                        </li>
                      </NavLink>
                    ))}
                    {ConnectionList.length === 0 ? '' : <a
                      className="f-14 d-flex justify-content-center mt-3 pointer"
                      onClick={redirectToConnectionTab}
                    >
                      View All
                    </a>}

                  </>
                ) : <>
                  {searchconnectList?.slice(0, 5)?.map((item, index) => (
                    <>
                      {/* {console.log('xhjgjhx', item, index)} */}
                      <NavLink to={`/User/${item.userUid}`} key={index}>
                        <li className="navLinks d-flex align-items-center mt-3">
                          <div className="imgBg1">
                            <img
                              src={item.profilPic}
                              alt=""
                              width={35}
                              height={35}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                          <div>
                            <span className="profile-name">
                              {item.fullName}
                            </span>
                            <p className="lastseen connectionFollower">
                              {item.followersCount} followers
                            </p>
                          </div>
                        </li>
                      </NavLink>
                    </>
                  ))}

                </>
                }
              </ul>
            </div>

            <div className="hashtags">
              <div className="header1 d-flex justify-content-between align-items-center">
                <h4 className="heading mb-0">Recommendations</h4>
              </div>
              <ul className="ps-0 listLink recommnedationLinks scroller1">
                {recommendationList &&
                  recommendationList?.slice(0, 8)?.map((item, index) => (
                    <li
                      className=" navLinks d-flex align-items-center justify-content-between mt-3"
                      key={index}
                    >
                      <NavLink
                        to={`/User/${item.userUid}`}
                        className="d-flex justify-content-between"
                      >
                        {item.profilPic === "" ? (
                          <div className="imgBg1">
                            <img
                              src={people.DefaultProfile}
                              alt=""
                              width={35}
                              height={35}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                        ) : (
                          <div className="imgBg1">
                            <img
                              src={item.profilPic}
                              alt=""
                              width={35}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                        )}
                        <div className="recomend-cont">
                          <span className="profile-name">{item.fullName}</span>
                          <div className="text ms-2">
                            {item.followersCount} Followers
                          </div>
                          {shortMutualList[item.userUid] && (
                            <div className="d-flex mt-1 ms-2">
                              <div className="circle-container">
                                {shortMutualList[item.userUid]?.pics?.map(
                                  (pic, picIndex) => (
                                    <img
                                      src={pic}
                                      alt={`Circle ${picIndex + 1}`}
                                      className="circle-img"
                                      key={picIndex}
                                    />
                                  )
                                )}
                              </div>
                              {/* <div className="text">
                                {shortMutualList[item.userUid]?.text}
                              </div> */}
                              <div className={`f-12 text ${shortMutualList?.[item.userUid]?.count === 1 ? 'ms-2' : 'ms-5'}`} >
                                {shortMutualList[item.userUid]?.text}
                              </div>
                            </div>
                          )}
                        </div>
                      </NavLink>
                      <div>
                        <div
                          onClick={() => toggleDropdown(index)}
                          className="postMenu"
                        >
                         
                          <MoreHorizSharpIcon className="material-symbols-sharp"/>
                          {openDropdown === index && (
                            <Dropdown.Menu
                              show={openDropdown === index}
                              ref={(el) => (dropdownRefs.current[index] = el)}
                              className="InteractionModal"
                              align="start"
                            >
                              {item.isFollowedByYou === true ||
                                (followedId === item?.userUid &&
                                  isfollowed === true) ? (
                                ""
                              ) : (
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                  onClick={() => {
                                    handleRecommendFollow(item);
                                    setFollowedId(item.userUid);
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="dropdownTxt f-14">
                                      Follow
                                    </span>
                                  </div>
                                </Dropdown.Item>
                              )}

                              <Dropdown.Divider />
                              {item.isConnectedByYou === true ||
                                (connectedId === item?.userUid &&
                                  isConnected === true) ? (
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                >
                                  <Link
                                    onClick={() => {
                                      setShow(!show);
                                      setuserUID(item.userUid);
                                      setConnectedId(item?.userUid);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <span className="dropdownTxt f-14">
                                        Cancel Connect Request
                                      </span>
                                    </div>
                                  </Link>
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                >
                                  <Link
                                    onClick={() => {
                                      handleRecommendConnect(item);
                                      setConnectedId(item?.userUid);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <span className="dropdownTxt f-14">
                                        Connect
                                      </span>
                                    </div>
                                  </Link>
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                <a
                  className="f-14 d-flex justify-content-center mt-3 pointer"
                  onClick={redirectToReceivedTab}
                >
                  View All
                </a>
              </ul>
            </div>

            <div className="search-container">
              <SearchSharpIcon  className="material-symbols-sharp"/>

              {/* </div> */}

              <div className="mb-1 search-input-container">
                <input
                  type="email"
                  className="form-control p-2 connection-input search-input"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchUserName(e.target.value);
                  }}
                  value={searchUserName}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Cancel Request?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    cancelRequestClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    cancelRequestClose();
                  }}/>
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => CancelRequest()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default RightNavigation;
