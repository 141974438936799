import React from "react";
import MainPage from "./MainPage";
import { header } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
const UserAgreement = () => {
  return (
    <>
      <div className="about-main intro-section p-3 pt-1">
        {/* <MainPage /> */}
        <div className="logo-content-cont d-flex align-items-center">
          <div className="weLinkLogo ps-2">
            <img src={header.mainLogo} alt="" />
          </div>
          <div>
            <nav>
              <ul className="d-flex privacy-nav-text">
                <li className="p-4">
                  <Link to="/about">ABOUT</Link>
                </li>
                <li className="p-4">
                  <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                </li>
                <li className="p-4">
                  <Link to="/termsAndCondition">TERMS AND CONDITION</Link>
                </li>
                <li className="p-4">
                  <Link to="/userAgreement">USER AGREEMENT</Link>
                </li>
                <li className="p-4">
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="user-agreement-cont">
          <div className="user-agreement">
            <h1 className="f-700 f-45 mb-2 content-color">User Agreement</h1>

            <div>
              <h2 className="f-28 mb-2 content-color">Contract</h2>
              <p className="mb-3 f-14 content-color">
                When you use our website and/or mobile application, you agree to
                all these terms in addition to others under the Privacy Policy
                or others. You agree that by clicking “Create an account”, “Join
                Welinkjobs”, “Sign Up” or similar, registering, accessing, or
                using our services, you are agreeing to enter into a legally
                obligatory contract with Welinkjobs.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Services</h2>
              <p className="mb-3 f-14 content-color">
                This Contract applies to Welinkjobs, Welinkjobs Application,
                Welinkjobs Services and any other related sites, apps,
                communications, and other services. Registered users of our
                Services are “Members” and unregistered users are “Guests”. This
                applies to Members and Guests. You are entering into this
                Contract with Welinkjobs (also referred to as “we” and “us”).
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Members and Guests</h2>
              <p className="mb-3 f-14 content-color">
                When you register and join the Welinkjobs Services, you become a
                Member. If you have chosen not to register for our Services, you
                may access a limited features as a “Guest.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Change</h2>
              <p className="mb-3 f-14 content-color">
                We may adjust this Contract, our Privacy Policy and our Cookies
                Policy from time to time. If we make material changes to it, we
                will provide you notice through our Services, or by other means,
                to provide you the opportunity to review the changes before they
                become effective. Obligations
              </p>
              <p className="content-color">Service Eligibility</p>
              <p className="mb-3 f-14 content-color">
                To use the Services, you agree that: (1) you must be the minimum
                age of 16 years old or older (However, if law requires that you
                must be older in order for Welinkjobs to lawfully provide the
                Services to you without parental consent - including using of
                your personal data - then the Minimum Age is such older age);
                (2) you will only have one Welinkjobs account, which must be in
                your real name; (3) you are not already restricted by Welinkjobs
                from using the Services. Creating an account with false
                information is a violation of our terms, including accounts
                registered on behalf of others or persons under the age of 16;
                (4) use a strong password and keep it confidential; (5) not
                transfer any part of your account; and (6) follow the law and
                our policies. You are responsible for anything that happens
                through your account unless you close it.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Payment</h2>
              <p className="mb-3 f-14 content-color">
                If you buy any of our paid Services, you agree to pay us the
                applicable fees and taxes and to any additional terms, if any,
                specic to the paid Services. Failure to pay these fees will
                result in the termination of your paid Services. Also, you agree
                that:
              </p>
              <ul className="terms-condition">
                <li className="mb-3 f-14 content-color">
                  • Your purchase may be subject to foreign exchange fees or
                  difference in prices based on location (e.g. exchange rates).
                </li>
                <li className="mb-3 f-14 content-color">
                  • If you purchase a subscription, your payment method
                  automatically will be charged at the start of each
                  subscription period for the fees and taxes applicable to that
                  period. To avoid future charges, cancel before the renewal
                  date.
                </li>
                <li className="mb-3 f-14 content-color">
                  • All of your purchases of Services are subject to Welinkjobs
                  are nonrefundable unless otherwise agreed specifically.
                </li>
              </ul>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Notices</h2>
              <p className="mb-3 f-14 content-color">
                You agree that we will provide notices and messages to you
                within the Service or sent to the contact information you
                provided us. You agree to keep your contact information up to
                date.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Sharing</h2>
              <p className="mb-3 f-14 content-color">
                Our Services allow messaging and sharing of information in many
                ways, such as your profile, articles, group posts, links to news
                articles, job postings, and messages. Information and content
                that you share, or post may be seen by other Members, Visitors,
                or others subject to your settings. connections, restricting
                your profile visibility from search engines, or opting not to
                notify others of your Welinkjobs prole update). For job
                searching activities, we default to not notifying your
                connections network or the public. So, if you apply for a job
                through our Service or opt to signal that you are interested in
                a job, our default is to share it only with the job poster.
              </p>
              <p className="mb-3 f-14 content-color">
                We are not obligated to publish any information or content on
                our Service and can remove it with or without notice.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Rights and Limits</h2>
              <p className="mb-3 f-14 content-color">
                As between you and Welinkjobs, you own the content and
                information that you submit or post to the Services, and you are
                granting Welinkjobs and our affiliates the following
                non-exclusive license of a worldwide, transferable and
                sublicensable right to use, copy, modify, distribute, publish
                and process, information and content that you provide through
                our Services and the services of others, without any further
                consent, notice and/or compensation to you or others.
              </p>
              <p className="mb-3 f-14 content-color">
                You and Welinkjobs agree that we may access, store, process and
                use any information and personal data that you provide in
                accordance with the Privacy Policy and your settings.
              </p>
              <p className="mb-3 f-14 content-color">
                You agree to only provide content or information that does not
                violate the law nor anyone’s rights (including intellectual
                property rights). You also agree that your profile information
                will be truthful. Welinkjobs may be required by law to remove
                certain information or content in certain countries.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Service Availability</h2>
              <p className="mb-3 f-14 content-color">
                We may change, suspend or discontinue any of our Services. We
                may also modify our prices effective prospectively upon
                reasonable notice to the extent allowed under the law.
              </p>
              <p className="mb-3 f-14 content-color">
                We don’t promise to store or keep showing any information and
                content that you’ve posted. You agree that we have no obligation
                to store, maintain or provide you a copy of any content or
                information that you or others provide, except to the extent
                required by applicable law and as noted in our Privacy Policy.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Other Content</h2>
              <p className="mb-3 f-14 content-color">
                Your use of others’ content and information posted on our
                Services, is at your own risk. By using the Services, you may
                encounter content or information that might be inaccurate,
                incomplete, delayed, misleading, illegal, offensive or otherwise
                harmful. Welinkjobs generally does not review content provided
                by our Members or others. You agree that we are not responsible
                for others’ (including other Members’) content or information.
                We cannot always prevent this misuse of our Services, and you
                agree that we are not responsible for any such misuse. You also
                acknowledge the risk that you or your organization may be
                mistakenly associated with content about others when we let
                connections and followers know you or your organization were
                mentioned in the news.
              </p>
              <p className="mb-3 f-14 content-color">
                Welinkjobs may help connect Members offering their services with
                Members seeking services. Welinkjobs does not perform nor
                employs individuals to perform these services. You must be
                age-eligible to offer, perform or procure these services. You
                acknowledge that Welinkjobs does not supervise, direct, control
                or monitor Members in the performance of these services and
                agree that (1) Welinkjobs is not responsible for the offering,
                performance or procurement of these services, (2) Welinkjobs
                does not endorse any particular Member’s offered services, and
                (3) nothing shall create an employment, agency, or joint venture
                relationship between Welinkjobs and any Member offering
                services. If you are a Member offering services, you represent
                and warrant that you have all the required licenses.
              </p>
              <p className="mb-3 f-14 content-color">
                Similarly, Welinkjobs may help you register for and/or attend
                events organized by Members and connect with other Members who
                are attendees at such events. You agree that (1) Welinkjobs is
                not responsible for the conduct of any of the Members or other
                attendees at such events, (2) Welinkjobs does not endorse any
                particular event listed on our Services, (3) Welinkjobs does not
                review and/or vet any of these events, and (4) that you will
                adhere to these terms and conditions that apply to such events.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Limits</h2>
              <p className="mb-3 f-14 content-color">
                Welinkjobs reserves the right to limit your use of the Services,
                including the number of your connections and your ability to
                contact other Members. Welinkjobs reserves the right to
                restrict, suspend, or terminate your account if you breach this
                Contract or the law or are misusing the Services.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">
                Intellectual Property Rights
              </h2>
              <p className="mb-3 f-14 content-color">
                Welinkjobs reserves all of its intellectual property rights in
                the Services. Trademarks and logos used in connection with the
                Services are the trademarks of their respective owners.
                Welinkjobs, and “in” logos and other Welinkjobs trademarks,
                service marks, graphics and logos used for our Services are
                trademarks.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Automated Processing</h2>
              <p className="mb-3 f-14 content-color">
                We use the information and data that you provide and that we
                have about Members to make recommendations for connections,
                content and features that may be useful to you. Keeping your
                profile accurate and up to date helps us to make these
                recommendations more accurate and relevant.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">
                Disclaimer and Limit of Liability
              </h2>
              <h2 className="f-28 mb-2 content-color">No Warranty</h2>
              <p className="mb-3 f-14 content-color">
                Welinkjobs and its affiliates make no representation or warranty
                what so ever about the Services, and provide the Services on an
                AS IS” basis. To the maximum extent permitted by law, Welinkjobs
                and its affiliates disclaim any implied or statutory warranty of
                any kind; we will not be liable in connection with this Contract
                for lost of profile, lost of business/opportunity, reputation,
                loss of data, delay, interruption, or any direct/indirect,
                incidental, consequential, special or punitive damages. In all
                cases, our liability in all cases shall not exceed the total
                fees paid or payable by you to us for relevant services or USD
                100, whichever less.
              </p>
              <p className="mb-3 f-14 content-color">
                The limitations of liability in this section are part of the
                basis of the bargain between you and Welinkjobs and shall apply
                to all claims of liability even if Welinkjobs or its affiliates
                has been told of the possibility of any such damage, and even if
                these remedies fail their essential purpose.
              </p>
              <p className="mb-3 f-14 content-color">
                These limitations of liability do not apply to liability for
                death or personal injury or for fraud or intentional misconduct,
                but only to the extent that the damages were directly caused by
                WeLinkJobs.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Termination</h2>
              <p className="mb-3 f-14 content-color">
                Both you and Welinkjobs may terminate this Contract at any time
                with notice to the other. On termination, you lose the right to
                access or use the Services. The following shall survive
                termination:
              </p>
              <ul className="terms-condition">
                <li className="mb-3 f-14 content-color">
                  • Our rights to use and disclose the Data and information;
                </li>
                <li className="mb-3 f-14 content-color">
                  • Members and/or Visitors’ rights to further re-share content
                  and information you shared through the Services;
                </li>
                <li className="mb-3 f-14 content-color">
                  • Sections of this Contract those are intended by their nature
                  to survive termination;
                </li>
                <li className="mb-3 f-14 content-color">
                  •Any amounts owed by either party prior to termination remain
                  owed after termination.
                </li>
              </ul>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">
                Governing Law and Dispute Resolution
              </h2>
              <p className="mb-3 f-14 content-color">
                You and Welinkjobs agree that the laws of lebanon shall
                exclusively govern any dispute relating to this Contract and/or
                the Services. You and Welinkjobs both agree that all claims and
                disputes can be litigated only in the courts of lebanon, and you
                and Welinkjobs each agree to personal jurisdiction in those
                courts.
              </p>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">General Term</h2>
              <p className="mb-3 f-14 content-color">
                If any part of this Contract was found unenforceable, the court
                should modify the terms to make that part enforceable while
                still achieving its intent as much as possible, otherwise, the
                unenforceable part shall be removed and enforce the rest of this
                Contract. This Contract is the only agreement between its
                parties regarding the Services and supersedes all prior
                agreements for the Services. Not practicing any right under the
                Contract doesn’t mean in any way a waiver of such right.
              </p>
              <p className="mb-3 f-14 content-color">
                You may not assign or transfer this Contract to anyone without
                WeLinkjobs prior written consent. However, Welinkjobs may assign
                this Contract to its affliates or a party that buys it without
                your consent. There are no third-party beneficiaries to this
                Contract. You agree that you will:
              </p>
              <ul className="p-0 terms-condition">
                <li className="mb-2 f-14 content-color">
                  • Comply with all applicable laws, including, without
                  limitation, privacy laws, intellectual property laws,
                  anti-spam laws, export control laws, tax laws, and regulatory
                  requirements.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Provide accurate information to us and keep it updated.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Use your real name on your profile; and
                </li>
                <li className="mb-2 f-14 content-color">
                  • Use the Services in a professional manner.
                </li>
                <p className="mb-2 f-14 content-color">
                  • You agree that you will not:
                </p>
                <li className="mb-2 f-14 content-color">
                  • Create a false identity on Welinkjobs, misrepresent your
                  identity, create a Member profile for anyone other than
                  yourself (a real person), or use or attempt to use another’s
                  account;
                </li>
                <li className="mb-2 f-14 content-color">
                  • Develop, support or use software, devices, scripts, robots
                  or any other means or processes (including crawlers, browser
                  plugins and add-ons or any other technology) to scrape the
                  Services or otherwise copy profiles and other data from the
                  Services.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Override any security feature or bypass or circumvent any
                  access controls or use limits of the Service (such as caps on
                  keyword searches or profile views);
                </li>
                <li className="mb-2 f-14 content-color">
                  • Copy, use, disclose or distribute any information obtained
                  from the Services, whether directly or through third parties
                  (such as search engines), without the consent of Welinkjobs;
                </li>
                <li className="mb-2 f-14 content-color">
                  • Disclose information that you do not have the consent to
                  disclose (such as confidential information of others including
                  your employer);
                </li>
                <li className="mb-2 f-14 content-color">
                  • Violate the intellectual property rights of others,
                  including copyrights, patents, trademarks, trade secrets or
                  other proprietary rights.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Violate the intellectual property or other rights of
                  Welinkjobs, including, without limitation, (i) copying or
                  distributing our learning videos or other materials or (ii)
                  copying or distributing our technology, unless it is released
                  under open-source licenses; (iii) using the word “Welinkjobs”
                  or our logos in any business name, email, or URL.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Post anything that contains software viruses, worms, or any
                  other harmful code.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Post anything that contains software viruses, worms, or any
                  other harmful code.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Reverse engineer, decompile, disassemble, decipher, or
                  otherwise attempt to derive the source code for the Services
                  or any related technology that is not open source.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Imply or state that you are affliated with or endorsed by
                  Welinkjobs without our express consent (e.g., representing
                  yourself as a Welinkjobs trainer);
                </li>
                <li className="mb-2 f-14 content-color">
                  • Rent, lease, loan, trade, sell/re-sell or otherwise monetize
                  the Services or related data or access to the same, without
                  Welinkjobs’s consent.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Deep-link to our Services for any purpose other than to
                  promote your profile or a Group on our Services, without
                  Welinkjobs’s consent;
                </li>
                <li className="mb-2 f-14 content-color">
                  • Use bots or other automated methods to access the Services,
                  add or download contacts, send or redirect messages.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Monitor the Services’ availability, performance or
                  functionality for any competitive purpose.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Engage in “framing,” “mirroring,” or otherwise simulating
                  the appearance or function of the Services.
                </li>
                <li className="mb-2 f-14 content-color">
                  • Overlay or otherwise modify the Services or their appearance
                  (such as by inserting elements into the Services or removing,
                  covering, or obscuring an advertisement included on the
                  Services); and/or
                </li>
                <li className="mb-2 f-14 content-color">
                  • Interfere with the operation of, or place an unreasonable
                  load on, the Services (e.g., spam, denial of service attack,
                  viruses, gaming algorithms).
                </li>
              </ul>
            </div>

            <div>
              <h2 className="f-28 mb-2 content-color">Contact Us</h2>
              <p className="mb-3 f-14 content-color">
                You may contact us through the Services. For legal notices or
                service of process, you may write us at this email:
                info@welinkjobs.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAgreement;
