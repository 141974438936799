import React from "react";
import {} from "../../assets/Images/ImagesLink";
import "../../assets/css/Editors.css";
import { Col, Row } from "react-bootstrap";
import EditorTopic from "./EditorTopic";
import EditorTags from "./EditorTags";
import CoverArticle from "./CoverArticle";
import SettingUp from "./SettingUp";
import EditorPan from "./EditorPan";

const Editor = () => {
  return (
    <>
      <div className="editor-main-container p-3">
        <Row>
         
            <Col md={3} className="editor-bg">
            {/* <div className="editor-pan-container"> */}
              <EditorTopic />
              <hr />
              <EditorTags />
              <hr />
              <CoverArticle />
              <hr />
              <SettingUp />
              <hr />
              <div className="editor-button-container d-flex justify-content-between mt-4">
                <div className="cancel-button">
                  <button className="f-12 f-600">Cancel</button>
                </div>
                <div className="cancel-button publishBtn">
                  <button className="f-12 f-600">Publish Article</button>
                </div>
              </div>
              {/* </div> */}
            </Col>
         

          <Col md={9}>
            <EditorPan />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Editor;
