
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import ErrorToast from '../ErrorToast';
import WarningToast from '../WarningToast';
import SuccessToast from '../SuccessToast';
import axios from '../../API/axios';
import moment from 'moment';
import Flatpickr from 'react-flatpickr'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';



const ApplicationStatus = ({ item }) => {
    const list = [
        { value: 'Declined', label: 'Declined' },
        { value: 'Shortlisted', label: 'Shortlisted' },
        // { value: 'Schedule', label: 'Schedule' },
        // { value: 'Hired', label: 'Hired' },
        // { value: 'Rejected', label: 'Rejected' },
        // { value: 'Blocked', label: 'Blocked' },
    ];

    const InterviewPlaceType = [
        { value: '1', label: 'OnSite' },
        { value: '2', label: 'Remote' },
        { value: '3', label: 'Other' },
    ]

    const [statusModal, setStatusModal] = useState(false);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [currentJobId, setCurrentJobId] = useState(null);
    const [currentHiringStatus, setCurrentHiringStatus] = useState(null);
    const [remark, setRemark] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);
    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [hiringStatus, setHiringStatus] = useState(item?.hiringStatusName);

    const sessionId = localStorage.getItem("sessionId");



    const handleStatusModal = (jobId = null, hiringStatus = null) => {
        setCurrentJobId(jobId);
        setCurrentHiringStatus(hiringStatus);
        setStatusModal(!statusModal);
    };

    const handleSubmit = () => {
        if (currentJobId && currentHiringStatus) {
            HiringStatus(currentJobId, currentHiringStatus);
        }
        setStatusModal(false);
    };

    const HiringStatus = (jobId, hiringStatus) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            jbApplctnId: jobId,
            hiringStatus: hiringStatus,
            remark: remark,
        };
        console.log(data);
        axios
            .post(`JobApplication/UpdateHiringStatus`, data)
            .then((response) => {
                const res = response.data.responseData;
                setToastMessage(response.data.message);
                setShowToast(true);
                setRemark('')
            })
            .catch((e) => {
                console.log(e);
                if (e.response && e.response.status === 404) {
                    setWarningToastMessage(e.response.data.message);
                    setShowWarningToast(true);
                } else {
                    setErrorToastMessage(e.response.data.message);
                    setShowErrorToast(true);
                }
            });
    };


    const [startDate, setStartDate] = useState("");
    const [interviewPT, setInterviewPT] = useState("");
    const [placeDetail, setPlaceDetail] = useState("");

    const handleScheduleModal = (jobId = null) => {
        setCurrentJobId(jobId);
        setScheduleModal(!scheduleModal);
    };

    const handleSubmitSechdule = () => {
        if (currentJobId) {
            Schedule(currentJobId);
        }
        setScheduleModal(false);
    };


    const Schedule = (jobId) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            jbApplctnId: jobId,
            scheduleTime: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
            placeType: interviewPT,
            placeDetails: placeDetail,
            remark: remark,
        };
        console.log(data);
        axios
            .post(`JobApplication/ScheduleInterview`, data)
            .then((response) => {
                const res = response.data.responseData;
                setToastMessage(response.data.message);
                setShowToast(true);
                setRemark('')
                setStartDate('')
                setInterviewPT('')
                setPlaceDetail('')
            })
            .catch((e) => {
                console.log(e);
                if (e.response && e.response.status === 404) {
                    setWarningToastMessage(e.response.data.message);
                    setShowWarningToast(true);
                } else {
                    setErrorToastMessage(e.response.data.message);
                    setShowErrorToast(true);
                }
            });
    };

    useEffect(() => {
        if (statusModal || scheduleModal) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [statusModal, scheduleModal]);
    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };
    return (
        <>
            <ReactSelect
                name="country"
                onChange={(e) => {
                    console.log(e?.label);
                    if (e?.label === 'Schedule') {
                        setHiringStatus(e?.label);
                        handleScheduleModal(item?.jbApplctnId);
                    } else {
                        setHiringStatus(e?.label);
                        handleStatusModal(item?.jbApplctnId, e?.label);
                    }
                }}
                value={list.find(c => c.label === hiringStatus)}
                className="react-select w-100 applicationReact"
                classNamePrefix="select"
                options={list}
                isClearable={true}
                menuPortalTarget={document.body} // This renders the dropdown outside the table
            // styles={{
            //     width: '150px',
            //     marginRight: '10px'
            // }}
            />

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />
            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />
            <ErrorToast
                show={showErrorToast}
                message={errorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
            {statusModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content d-flex justify-content-around">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Update Hiring Status</h6>
                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => handleStatusModal()}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => handleStatusModal()}
                                />
                            </div>
                            <div className="d-flex modalBorder pb-4 d-flex justify-content-center px-4">
                                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="remark">Remark</label>
                                        <input
                                            type="text"
                                            style={{ width: "100%" }}
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="mainBtn1 me-4"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {scheduleModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content d-flex justify-content-around">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Add Interview Schedule Details</h6>
                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => handleScheduleModal()}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => handleScheduleModal()}
                                />
                            </div>
                            {/* <div className="d-flex modalBorder pb-4 d-flex justify-content-center"> */}
                            <div className="row settings mt-2  mb-2 userInput d-flex flex-column align-items-center px-4">
                                <div className="col-md-12 mt-2">
                                    <label htmlFor="remark">Schedule Time</label>

                                    <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob'
                                        options={{
                                            minDate: "today",
                                            enableTime: true, // Enables time selection
                                            time_24hr: true,  // Enables 24-hour time format, if needed
                                            dateFormat: "Y-m-d H:i", // Format: Year-Month-Day Hour:Minute
                                            minuteIncrement: 1,
                                            onReady: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Call the function to stop scroll on year
                                            },
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Also prevent scroll when the picker opens
                                            },
                                        }} />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label htmlFor="remark">Place Type</label>
                                    <ReactSelect
                                        name="country"
                                        onChange={(e) => {
                                            console.log(e?.label);
                                            setInterviewPT(e?.label)
                                        }}
                                        defaultValue={interviewPT}
                                        className="react-select w-100"
                                        classNamePrefix="select"
                                        options={InterviewPlaceType}
                                        isClearable={true}
                                    />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label htmlFor="remark">Place Details</label>
                                    <input
                                        type="text"
                                        style={{ width: "100%" }}
                                        value={placeDetail}
                                        onChange={(e) => setPlaceDetail(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label htmlFor="about">
                                        Remark
                                    </label>
                                    <textarea
                                        id="about"
                                        name="about"
                                        rows="2"
                                        cols="65"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="mainBtn1 me-4"
                                    onClick={handleSubmitSechdule}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ApplicationStatus;
