import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import "../CreateCompany/CreateCompany.css";
import Select from "react-select";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import { number, string } from "yup";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const options = [
  { value: "Mumbai", label: "Mumbai" },
  { value: "Thane", label: "Thane" },
  { value: "Pune", label: "Pune" },
];
const UpdateJob = ({
  showModal,
  closeModal,
  openModal,
  item,
  AllJobList,
  setJobList,
  jobList,
  totalPages,
  setTotalPages,
  page,
}) => {
  // console.log('item', item);
  const sessionId = localStorage.getItem("sessionId");
  const [display, setDisplay] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [singleJobData, setSingleJobData] = useState("");
  const [jobListData, setJobListData] = useState("");
  console.log("singleJobData", singleJobData);
  const JobList = async () => {
    try {
      await axios
        .get(`Job/GetJobDetails?DeviceType=Web&JobId=${item.jobId}`)
        .then((resData) => {
          setSingleJobData(resData.data.responseData.viewJob);
          const jobData = resData.data.responseData.viewJob;
          setJobListData(jobData);
          setjobTitle(jobData?.title);
          setjobDescription(jobData?.shortDescription);
          setMinimumSalary(jobData?.minSalary);
          setMaximumSalary(jobData?.maxSalary);
          setCurrency(jobData?.currency);
          setHashtags(jobData?.hashTags);
          setCompanyId({ value: jobData?.compUid, label: jobData?.compName });
          setCityId({ value: jobData?.cityId, label: jobData?.cityName });
          setJobTypeId({ value: jobData?.jbTypId, label: jobData?.jbTypName });
          setWorkTypeId({
            value: jobData?.workLevelType,
            label: jobData?.workLevelTypeName,
          });
          setWorkLocationId({
            value: jobData?.workLocationType,
            label: jobData?.workLocationTypeName,
          });
          setWorkLocationId({
            value: jobData?.workLocationType,
            label: jobData?.workLocationTypeName,
          });
          setValue({
            value: jobData?.designationId,
            label: jobData?.designationName,
          });
          setsalaryId({
            value: jobData?.salaryTypeId,
            label: jobData?.salaryTypeId,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  console.log("jobListData", jobListData);

  useEffect(() => {
    JobList();
  }, []);

  const [jobTitle, setjobTitle] = useState("");
  const [jobDescription, setjobDescription] = useState("");
  const [minimumSalary, setMinimumSalary] = useState("");
  const [maximumSalary, setMaximumSalary] = useState("");
  const [currency, setCurrency] = useState("");
  const [hashtags, setHashtags] = useState("");

  // const reset = () => {
  //     setjobTitle('')
  //     setjobDescription('')
  //     setMinimumSalary('')
  //     setMaximumSalary('')
  //     setCurrency('')
  //     setHashtags('')
  //     setCompanyData([])
  //     setCityData([])
  //     setWorkLocationData([])
  //     setWorkTypeData([])
  //     setsalaryData([])
  //     // setsourceTypeData([])
  //     setJobTypeData([])
  //     setOptions([])
  // }

  //********Company List Start  ********//
  const [companyData, setCompanyData] = useState([]);
  const [companySearch, setCompanySearch] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const getCompany = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Company/GetOwnCompaniesList`, data)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.compUid,
          label: item.compName,
        }));
        setCompanyData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompany();
  }, []);
  //********Company List End  ********//
  console.log("companySearch", companySearch);

  //********Job Type List Start  ********//
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTypeSearch, setJobTypeSearch] = useState("");
  const [jobTypeId, setJobTypeId] = useState(null);
  const getJobType = () => {
    axios
      .get(`Master/GetJobTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbTypId,
          label: item.name,
        }));
        setJobTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (jobTypeSearch) {
      getJobType();
    }
  }, [jobTypeSearch]);
  //********Job Type List End  ********//

  //********Work Level Type List Start  ********//
  const [workTypeData, setWorkTypeData] = useState([]);
  const [WorkTypeSearch, setWorkTypeSearch] = useState("");
  const [workTypeId, setWorkTypeId] = useState(null);
  const getWorkType = () => {
    axios
      .get(`Master/GetWorkLevelTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (WorkTypeSearch) {
      getWorkType();
    }
  }, [WorkTypeSearch]);
  //********Work Level Type List End  ********//

  //********Work Location Type List Start  ********//
  const [workLocationData, setWorkLocationData] = useState([]);
  const [WorkLocationSearch, setWorkLocationSearch] = useState("");
  const [workLocationId, setWorkLocationId] = useState(null);
  const getWorkLocationType = () => {
    axios
      .get(`Master/GetWorkLocationTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkLocationType();
  }, []);
  //********Work Location Type List End  ********//
  //********Work Location Type List Start  ********//
  const [salaryData, setsalaryData] = useState([]);
  const [salaryDataSearch, setSalaryDataSearch] = useState("");
  const [salaryId, setsalaryId] = useState(null);
  const getsalaryType = () => {
    axios
      .get(`Master/GetSalaryTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbSlryTypId,
          label: item.name,
        }));
        setsalaryData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getsalaryType();
  }, []);

  //********Work Location Type List End  ********//

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState("");
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  console.log("citySearch", citySearch);

  //********City List End  ********//

  //************************Designation Start  ************************//
  const [value, setValue] = useState(null);
  const [pageD, setPageD] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [totalPagesDesignation, setTotalPagesDesignation] = useState(0);

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  const [designationData, setDesignationData] = useState([]);
  const [designationSearch, setDesignationSearch] = useState("");
  const [designationId, setDesignationId] = useState(null);

  //   const addOptions = () => {
  // 	console.log("===================>");

  //     setIsLoading(true);
  //     axios
  //       .get(
  //         `Master/GetDesignationDDL?search=${typed}&pageNumber=${
  //           pageD + 1
  //         }&pageSize=100`
  //       )
  //       .then((response) => {
  //         console.log("response=========>", response);

  //         const data = response.data.data.map((item) => ({
  //           value: item.dsgntnId,
  //           label: item.name,
  //         }));
  // 		console.log("data=========>",data);

  //         setPageD((prevPage) => prevPage + 1);
  //         setIsLoading(false);
  // 		console.log("(prevOptions) => [...prevOptions, ...data]",(prevOptions) => [...prevOptions, ...data]);

  //         setDesignationData((prevOptions) => [...prevOptions, ...data]);
  //         setTotalPagesDesignation(response.data.paging.totalPages);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsLoading(false);
  //       });
  //   };

  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetDesignationDDL?search=${designationSearch}&pageNumber=${pageD + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log("response=====>", response);
        const data = response?.data?.responseData?.data.map((item) => ({
          value: item.dsgntnId,
          label: item.name,
        }));
        setPageD((prevPage) => prevPage + 1);
        setIsLoading(false);
        setDesignationData((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesDesignation(response.data.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  console.log("designationData", designationData);

  const handleOpen = () => {
    setPageD(1);
  };

  const handleChange = (value) => {
    setValue(value);
    setPageD(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPageD(0);
  };

  const handleMenuScrollToBottom = () => {
    if (pageD < totalPagesDesignation) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (designationSearch) {
      addOptions();
    }
  }, [designationSearch]);

  //************************ Designation End  ************************//

  const handleSubmit = async () => {
    setDisplay(true);
    const body = {
      deviceType: "Web",
      sessionId: sessionId,
      compUid: companyId,
      locationId: null,
      cityID: cityId,
      jbTypId: jobTypeId,
      workLevelType: workTypeId,
      workLocationType: workLocationId,
      designationId: designationId,
      salaryTypeId: salaryId,
      minSalary: minimumSalary,
      maxSalary: maximumSalary,
      currency: null,
      title: jobTitle,
      description: jobDescription,
      hashTags: hashtags,
      sourceTypeId: null,
      receiveApplicantsType: 1,
      jobId: item.jobId,
    };
    // if (
    //   cityId ||
    //   jobTypeId ||
    //   workTypeId ||
    //   workLocationId ||
    //   salaryId ||
    //   minimumSalary ||
    //   maximumSalary ||
    //   jobTitle ||
    //   jobDescription ||
    //   hashtags === ""
    // ) {
    //   setWarningToastMessage("Please fill all fields");
    //   setShowWarningToast(true);
    // } else {
    try {
      const res = await axios.put("/Job/UpdateJob", body);
      console.log('gashgdjdgsa', res);

      setToastMessage(res.data.message);
      setShowToast(true);
      AllJobList(page);
      closeModal();
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
    // }
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  useEffect(() => {
    if (jobListData) {
      setCompanyId(jobListData?.compUid);
      setCompanySearch(jobListData?.compName);
      setCityId(jobListData?.cityId);
      setCitySearch(jobListData?.cityName);
      setJobTypeId(jobListData?.jbTypId);
      setJobTypeSearch(jobListData?.jbTypName);
      setWorkTypeId(jobListData?.workLevelType);
      setWorkTypeSearch(jobListData?.workLevelTypeName);
      setWorkLocationId(jobListData?.workLocationType);
      setWorkLocationSearch(jobListData?.workLocationTypeName);
      setDesignationId(jobListData?.designationId);
      setDesignationSearch(jobListData?.designationName);
      setsalaryId(jobListData?.salaryTypeId);
      setSalaryDataSearch(jobListData?.salaryTypeName);
    }
  }, [jobListData]);

  return (
    <>
      {showModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Update Job</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={jobTitle}
                      onChange={(e) => setjobTitle(e.target.value)}
                    />
                    {display && !jobTitle ? (
                      <span className="error_msg_lbl">Enter Job Title</span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="4"
                      cols="50"
                      value={jobDescription}
                      onChange={(e) => setjobDescription(e.target.value)}
                    ></textarea>
                    {display && !jobDescription ? (
                      <span className="error_msg_lbl">
                        Enter Job Description
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Company Name<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={companyData.find(
                        (option) => option.value === companyId
                      )}
                      onChange={(e) => {
                        setCompanyId(e.value);
                      }}
                      options={companyData}
                      placeholder={"Search and Select Company Name"}
                      onInputChange={(inputValue) => {
                        setCompanySearch(inputValue);
                      }}
                      invalid={display && companySearch === ""}
                      styles={customStyles}
                    />
                    {display && !companySearch ? (
                      <span className="error_msg_lbl">
                        Select Company Name{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      City Name<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={cityData.find((option) => option.value === cityId)}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      invalid={display && citySearch === ""}
                      styles={customStyles}
                    />
                    {display && !citySearch ? (
                      <span className="error_msg_lbl">Select City </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Type<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={jobTypeData.find(
                        (option) => option.value === jobTypeId
                      )}
                      onChange={(e) => {
                        setJobTypeId(e.value);
                      }}
                      options={jobTypeData}
                      placeholder={"Search and Select Job Type"}
                      onInputChange={(inputValue) => {
                        setJobTypeSearch(inputValue);
                      }}
                      invalid={display && jobTypeSearch === ""}
                      styles={customStyles}
                    />
                    {display && !jobTypeSearch ? (
                      <span className="error_msg_lbl">Select Job Type </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Work Level Type{" "}
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={workTypeData.find(
                        (option) => option.value === workTypeId
                      )}
                      onChange={(e) => {
                        setWorkTypeId(e.value);
                      }}
                      options={workTypeData}
                      placeholder={"Search and Select  Work Level Type"}
                      onInputChange={(inputValue) => {
                        setWorkTypeSearch(inputValue);
                      }}
                      invalid={display && workTypeId === ""}
                      styles={customStyles}
                    />
                    {display && !WorkTypeSearch ? (
                      <span className="error_msg_lbl">
                        Select Work Level Type{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Work Location Type{" "}
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={workLocationData.find(
                        (option) => option.value === workLocationId
                      )}
                      onChange={(e) => {
                        setWorkLocationId(e.value);
                      }}
                      options={workLocationData}
                      placeholder={"Search and Select Work Location Type"}
                      onInputChange={(inputValue) => {
                        setWorkLocationSearch(inputValue);
                      }}
                      invalid={display && WorkLocationSearch === ""}
                      styles={customStyles}
                    />
                    {display && !WorkLocationSearch ? (
                      <span className="error_msg_lbl">
                        Select Work Location Type{" "}
                      </span>
                    ) : null}
                  </div>

                  {/* <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Designation <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      value={value}
                      options={options}
                      optionRenderer={renderOption}
                      placeholder={"Search and Select Designation"}
                      onOpen={handleOpen}
                      onChange={handleChange}
                      onMenuScrollToBottom={handleMenuScrollToBottom}
                      onInputChange={handleInputChange}
                      isClearable
                      styles={customStyles}
                    />
                  </div> */}

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Designation <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={designationData.find(
                        (option) => option.value === designationId
                      )}
                      onChange={(e) => {
                        setDesignationId(e.value);
                      }}
                      options={designationData}
                      placeholder={"Search and Select Designation"}
                      onInputChange={(inputValue) => {
                        setDesignationSearch(inputValue);
                      }}
                      invalid={display && designationSearch === ""}
                      styles={customStyles}
                    />
                    {display && !designationId ? (
                      <span className="error_msg_lbl">Select Designation </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Salary Type <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      value={salaryData.find(
                        (option) => option.value === salaryId
                      )}
                      onChange={(e) => {
                        setsalaryId(e.value);
                      }}
                      options={salaryData}
                      placeholder={"Search and Select Salary Type"}
                      onInputChange={(inputValue) => {
                        setSalaryDataSearch(inputValue);
                      }}
                      invalid={display && salaryDataSearch === ""}
                      styles={customStyles}
                    />
                    {display && !salaryDataSearch ? (
                      <span className="error_msg_lbl">Select Salary Type </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Minimum Salary <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={minimumSalary}
                      onChange={(e) => setMinimumSalary(e.target.value)}
                    />
                    {display && !minimumSalary ? (
                      <span className="error_msg_lbl">
                        Enter Minimum Salary
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Maximum Salary <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={maximumSalary}
                      onChange={(e) => setMaximumSalary(e.target.value)}
                    />
                    {display && !maximumSalary ? (
                      <span className="error_msg_lbl">
                        Enter Maximum Salary
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Hashtags <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={hashtags}
                      onChange={(e) => setHashtags(e.target.value)}
                    />
                    {display && !hashtags ? (
                      <span className="error_msg_lbl">Enter Hashtags</span>
                    ) : null}
                  </div>

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default UpdateJob;
