import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/people.css";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { people, post } from "../../assets/Images/ImagesLink";
import axios from "../../API/axios";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import ModalForGuest from "../ModalForGuest";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import SuccessToast from "../SuccessToast";
import { ShimmerButton, ShimmerCategoryItems, ShimmerDiv } from "shimmer-effects-react";
import { ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import PeopleInteraction from "./PeopleInteraction";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Helmet } from "react-helmet";

const People = () => {
  const guestModeData = localStorage.getItem("guestMode");
  const [isError, setIsError] = useState(false);
  const [searchUserName, setSearchUserName] = useState("");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  console.log("searchUserName", searchUserName);
  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "grape", label: "Grape" },
  ];
  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem("userUid");


  //************************User Profile Get Start  ************************//
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setuserList] = useState([]);
  const [searchuserList, setSearchuserList] = useState([]);
  const [nextBatchLoading, setNextBatchLoading] = useState('')


  const getUserProfileList = (page, searchUserName) => {
    setNextBatchLoading(true);
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 24,
    };
    axios
      .post(`UserProfile/GetAllUserList?Search=${searchUserName}`, data)
      .then((response) => {
        setIsLoading(false);
        console.log("people res", response);

        const totalPages = response.data.responseData.paging.totalPages;
        setTotalPages(totalPages);

        const newPosts = response.data.responseData.userList;

        if (searchUserName !== '') {
          // return newPosts;
          setuserList([])
          setSearchuserList(newPosts)
        } else {
          setSearchuserList([])
          setuserList((prev) => {
            const userSet = new Set(prev?.map((user) => user?.userUid));
            const uniqueNewPosts = newPosts.filter(
              (user) => !userSet.has(user?.userUid)
            );
            return [...prev, ...uniqueNewPosts];
          });
        }

        if (page >= totalPages) {
          setNextBatchLoading(false); // Stop loading if the last page is reached
        } else {
          setNextBatchLoading(false); // Continue loading for more pages
        }

        console.log("all post data", newPosts);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setIsError(true);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setNextBatchLoading(false);
      });
  };

  useEffect(() => {
    setuserList([]); // Reset user list when searchUserName changes
    getUserProfileList(1, searchUserName); // Start from page 1 for a new search
  }, [searchUserName]);

  useEffect(() => {
    if (page <= totalPages) {
      getUserProfileList(page, searchUserName);
    }
  }, [page]);


  //************************User Profile Get End  ************************//

  //************************ Follow User Start  ************************//
  const [isfollowed, setIsfollowed] = useState(false);
  const [followedId, setFollowedId] = useState('');

  const [followedCompanies, setFollowedCompanies] = useState({});
  // const FollowUser = async (id) => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //     userUID: id,
  //   };
  //   try {
  //     await axios.post("UserFollow/FollowUser", data).then((resData) => {
  //       console.log("resData", resData);
  //       // getUserProfileList();
  //       setIsfollowed(true);
  //       // toast.success(resData.data.message);
  //       setFollowedCompanies((prev) => ({ ...prev, [id]: true }));
  //       setToastMessage(resData.data.message);
  //       setShowToast(true);
  //       // setFollowedId(id)
  //     });
  //   } catch (e) {
  //     if (e.response && e.response.status === 404) {
  //       setWarningToastMessage(e.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(e.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };
  //************************ Follow User End  ************************//

  //************************ Unfollow User  Start  ************************//

  const [userID, setUserID] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // const unFollowUser = async () => {
  //   const responseData = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //     userUID: selectedUserId,
  //   };
  //   console.log("unfollow res", responseData);
  //   try {
  //     await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
  //       // toast.success("User Unfollowed");
  //       console.log("res", res);
  //       setToastMessage(res.data.message);
  //       setShowToast(true);
  //       // getUserProfileList();
  //       setShowModal(false);
  //       setFollowedCompanies((prev) => ({ ...prev, [selectedUserId]: false }));
  //       setIsfollowed(false)
  //       // setFollowedId(selectedUserId)
  //     });
  //   } catch (e) {
  //     if (e.response && e.response.status === 404) {
  //       setWarningToastMessage(e.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(e.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };

  // Follow User
  const FollowUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        setFollowedCompanies((prev) => ({ ...prev, [id]: true }));
        setToastMessage(resData.data.message);
        setShowToast(true);
        // Optionally, you can update the userList or searchuserList state if needed
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  // Unfollow User
  const unFollowUser = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: selectedUserId,
    };
    try {
      await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
        setFollowedCompanies((prev) => ({ ...prev, [selectedUserId]: false }));
        setToastMessage(res.data.message);
        setShowToast(true);
        setShowModal(false);
        // Optionally, you can update the userList or searchuserList state if needed
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  const [followModal, setFollowModal] = useState(false);
  const closeModalData = () => {
    setFollowModal(false);
  };
  //************************ Unfollow User  End  ************************//

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleToggleDropdown = (index) => {
    setShowDropdown((prev) => (prev === index ? null : index));
  };

  const handleShowModal = (item) => {
    setSelectedItem(item);
    console.log("unfollow item", item);
    setSelectedUserId(item.userUid);
    setShowModal(true);
  };
  //   console.log("selectedUserId", selectedUserId);

  const handleUnfollowCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleConfirmAction = () => {
    unFollowUser();
    handleUnfollowCloseModal();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //   const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);


  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showModal]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    scrollToTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>Connect with Professionals | People on WeLink Jobs</title>
        <meta name="description" content="Enhance your professional network on WeLink Jobs. Connect with top talent and explore new career opportunities." />
        {/* <meta name="keywords" content="react, meta tags, seo" /> */}
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content="Connect with Professionals | People on WeLink Jobs" />
        <meta property="og:description" content="Enhance your professional network on WeLink Jobs. Connect with top talent and explore new career opportunities." />
        {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
        <meta property="og:url" content="https://welinkjobs.com/People" />
      </Helmet>
      <div className="friend-main-container mt-3">
        <Row>
          <div className="headerSection  d-block d-md-flex justify-content-between">
            <Col md={7}>
              <div className="friendsHeaderText">
                <h1>People</h1>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <input
                  type="email"
                  className="form-control p-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Search user"
                  onChange={(e) => {
                    setSearchUserName(e.target.value);
                    getUserProfileList();
                  }}
                  value={searchUserName}
                />
              </div>
            </Col>
          </div>
        </Row>
        <div className="frindsCardContainer">
          {/* <ShimmerDiv mode="light" height={100} width={100} /> */}
          {/* <div className="shimmer-cont">
            <div className="shimmer-header d-flex">
              <ShimmerDiv mode="light" height={60} width={60} rounded={50} />
              <div className="shimmer-title ms-3">
                <ShimmerTitle mode="light" line={2} gap={8} />
              </div>
            </div>
            <div className="square-shimmer mt-2">
              
            </div>
          </div> */}
          <Row>
            {isLoading ? (
              // <div className="mt-3">
              //   <ShimmerThumbnail height={250} />
              // </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                  {/* <ShimmerThumbnail height={200} mode="light" /> */}
                </div>
                <div className="col-md-6">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                  {/* <ShimmerThumbnail height={200} mode="dark" /> */}
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                </div>
                <div className="col-md-6 mt-2">
                  <ShimmerDiv mode="light" height={200} width={'100%'} />
                </div>
              </div>
            ) : isError ? (
              <div className="mt-2 error-image-cont error-cont">
                <img src={post.errorSmall} alt="404 Not Found" width={200} />
              </div>
            ) : (
              searchuserList.length === 0 ?
                userList &&
                userList?.map((item, index) => {
                  if (userUid === item.userUid) {
                    return null;
                  }
                  const isUserFollowed = followedCompanies[item.userUid] || item.isFollowedByYou;
                  return (
                    <Col md={6} key={index}>

                      <div className="PeopleCard">
                        <div className="PeopleCardHeader">
                          <img
                            src={
                              item.backGroundImage === null ||
                                item.backGroundImage === ""
                                ? people.background
                                : item.backGroundImage
                            }
                            alt=""
                            width="100%"
                            height="120px"
                          />
                          <div className="overlay"></div>
                          <div className="centerAlignment">
                            <Link to={`/User/${item.userUid}`}>
                              <div className="">
                                <img
                                  src={
                                    item.profilPic === null
                                      ? people.DefaultProfile
                                      : item.profilPic
                                  }
                                  alt=""
                                  srcset=""
                                  style={{ borderRadius: "50px" }}
                                  width={50}
                                  height={50}
                                />
                              </div>
                            </Link>
                            <div className=" d-block d-md-flex align-items-baseline justify-content-between">
                              <div className=" ">
                                <div className="mainCardText mb-0">
                                  <Link to={`/User/${item.userUid}`}>
                                    <h1>{item.fullName}</h1>
                                  </Link>
                                </div>
                                <div className="mainCardText">
                                  <h1>{item.nameTag}</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="PeopleCardContainer p-2 ms-3  d-flex align-items-baseline justify-content-between">
                          <div>
                            <div className="mainCardText">
                              <h1>{item.title}</h1>
                            </div>
                            <div className="cardSubText">
                              <h1>{item.headLine}</h1>
                            </div>
                          </div>
                          <div className="follower-button text-end">
                            {console.log('hgdsahgd', item.fullName, followedId, item?.userUid, item?.isFollowedByYou)}


                            <PeopleInteraction userUid={item?.userUid} isFollowedByYou={item?.isFollowedByYou} getUserProfileList={getUserProfileList} index={index} />

                            <div className="cardSubText  d-flex justify-content-center mt-2">
                              <h1>{item.follwersCounts} followers</h1>
                            </div>
                          </div>
                        </div>
                      </div>


                    </Col>
                  );
                }) : searchuserList &&
                searchuserList?.map((item, index) => {
                  if (userUid === item.userUid) {
                    return null;
                  }
                  const isUserFollowed = followedCompanies[item.userUid] || item.isFollowedByYou;
                  return (
                    <Col md={6} key={index}>
                      <div className="PeopleCard">
                        <div className="PeopleCardHeader">
                          <img
                            src={
                              item.backGroundImage === null ||
                                item.backGroundImage === ""
                                ? people.background
                                : item.backGroundImage
                            }
                            alt=""
                            width="100%"
                            height="120px"
                          />
                          <div className="overlay"></div>
                          <div className="centerAlignment">
                            <Link to={`/User/${item.userUid}`}>
                              <div className="">
                                <img
                                  src={
                                    item.profilPic === null
                                      ? people.DefaultProfile
                                      : item.profilPic
                                  }
                                  alt=""
                                  srcset=""
                                  style={{ borderRadius: "50px" }}
                                  width={50}
                                  height={50}
                                />
                              </div>
                            </Link>
                            <div className=" d-block d-md-flex align-items-baseline justify-content-between">
                              <div className=" ">
                                <div className="mainCardText mb-0">
                                  <Link to={`/User/${item.userUid}`}>
                                    <h1>{item.fullName}</h1>
                                  </Link>
                                </div>
                                <div className="mainCardText">
                                  <h1>{item.nameTag}</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="PeopleCardContainer p-2 ms-3  d-flex align-items-baseline justify-content-between">
                          <div>
                            <div className="mainCardText">
                              <h1>{item.title}</h1>
                            </div>
                            <div className="cardSubText">
                              <h1>{item.headLine}</h1>
                            </div>
                          </div>
                          <div className="follower-button">
                            {console.log('hgdsahgd', item?.isFollowedByYou, item?.userUid, followedId, isfollowed, item?.isFollowedByYou === true || (followedId === item?.userUid && isfollowed === true))}
                            <PeopleInteraction userUid={item?.userUid} isFollowedByYou={item?.isFollowedByYou} getUserProfileList={getUserProfileList} index={index} />
                            <div className="cardSubText  d-flex justify-content-center mt-2">
                              <h1>{item.follwersCounts} followers</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
            )}
            {nextBatchLoading && <div className="text-center">
              <img src={post?.loader} width={50} />
            </div>}
          </Row>
        </div>
      </div >



      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>
                  Are you sure you want to unfollow{" "}
                  <b>{selectedItem?.fullName} ?</b>
                </h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUnfollowCloseModal();
                  }}
                />
              </div>

              <div className="text-end mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => handleConfirmAction()}
                >
                  Unfollow
                </button>
              </div>
            </div>
          </div>
        </div>
      )
      }

      {followModal && <ModalForGuest closeModalData={closeModalData} />}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

    </>
  );
};

export default People;
