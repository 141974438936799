import React, { useEffect, useState, useRef } from "react";
import { post } from "../../assets/Images/ImagesLink";
// import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import locationIcon from "../../assets/Images/connection/locationIcon.png";
import userAvatar from "../../assets/Images/connection/Group 647.png";
import { ShimmerDiv } from "shimmer-effects-react";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';


const Following = ({ uid, getUserProfileStat, userData }) => {
  const sessionId = localStorage.getItem("sessionId");
  console.log("userData", userData?.profileInfo.isOwn);
  //************************ Following  List   ************************//
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [FollowingList, setFollowingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // console.log('FollowingList', FollowingList);
  const FollowingListAll = async () => {
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 20,
      userUid: uid,
    };
    try {
      await axios.post("UserFollow/GetFollowingList", data).then((resData) => {
        // setFollowingList(resData.data.responseData.viewList);
        // console.log("connect data", resData.data.responseData?.followingList);
        setIsLoading(false);
        if (resData.data.status === "Warning") {
          setIsError(true);
        } else {
          setFollowingList((prev = []) => {
            // Get the new list from the API response, default to an empty array if null or undefined
            const newViewList = resData.data.responseData?.followingList || [];

            // Filter out duplicate entries
            const filteredNewViewList = newViewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.userUid === item.userUid)
            );

            // Combine the previous list with the filtered new list
            return [...prev, ...filteredNewViewList];
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FollowingListAll();
  }, [page]);


  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  console.log("FollowingList", FollowingList);
  //************************Following List ************************//

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [unfollow, setUnfollow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  console.log('selectedUserId', selectedUserId, unfollow);

  const unFollowUser = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: selectedUserId,
    };
    console.log("unfllow new res", responseData);
    try {
      await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
        // toast.success("User Unfollowed");
        // console.log("res", res);
        setToastMessage(res.data.message);
        setShowToast(true);
        setFollowingList((prev) => prev.filter((item) => item.userUid !== selectedUserId));
        FollowingListAll()
        getUserProfileStat();
        setUnfollow(true)
      });
    } catch (e) {
      console.log('cxc', e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = (index) => {
    setShowDropdown((prev) => (prev === index ? null : index));
  };

  const handleShowModal = (item) => {
    setSelectedItem(item);
    console.log("unfollow item", item);
    setSelectedUserId(item.userUid);
    setShowModal(true);
  };
  //   console.log("selectedUserId", selectedUserId);

  const handleUnfollowCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleConfirmAction = () => {
    unFollowUser();
    handleUnfollowCloseModal();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showModal]);






  return (
    <>
      {isLoading ? (
        <div className="mt-3">
          {/* <ShimmerThumbnail height={250} rounded /> */}
          <ShimmerDiv mode="light" height={250} width={'100%'} />
        </div>
      ) : isError ? (
        <div className="mt-2 text-center">
          <img src={post.errorSmall} alt="404 Not Found" width={200} />
        </div>
      ) : (
        FollowingList &&
        FollowingList?.map((item, index) => {
          // console.log('dgahjd', item);
          return (
            <>
              <div className="aboutMe mb-3 p-3" key={index}>
                <div className="d-flex align-items-center">
                  <Link to={`/User/${item.userUid}`}>
                    <div className="ImgAvatar">
                      <img
                        src={item?.profilPic}
                        alt=""
                        width={60}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </Link>
                  <div className="ms-3">
                    <div className="userName">
                      <Link to={`/User/${item.userUid}`}>
                        <h1>{item?.fullName} <span className="f-12 fw-100">{item?.userName}</span>
                          <span className='f-12 fw-100'>{item?.nameTag}</span></h1>
                      </Link>
                    </div>
                    <div className="designation">
                      <h1>{item?.title}</h1>
                    </div>
                    <div className="designation">
                      <h1>{item?.headLine}</h1>
                    </div>
                    <div className="location">
                      <div>
                        {item?.location && (
                          <>
                            <img src={locationIcon} alt="" />
                            <span className="locationText">
                              {item?.location}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="vectorUserImage">
                      <img src={userAvatar} alt="" />
                      <span className="followerText ms-2">
                        {item?.followersCount} Followers
                      </span>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div className="d-flex justify-content-end">
                      {userData?.profileInfo.isOwn === true ? (
                        selectedUserId === item.userUid && unfollow !== false ?
                          '' : <div
                            onClick={() => handleToggleDropdown(index)}
                            className="postMenu"
                          >
                            <MoreHorizSharpIcon className="material-symbols-sharp"
                            />
                            {showDropdown === index && (
                              <Dropdown.Menu
                                show={showDropdown === index}
                                ref={dropdownRef}
                                className="InteractionModal"
                                align="start"
                              >
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                >
                                  <Link onClick={() => handleShowModal(item)}>
                                    <div className="d-flex align-items-center">
                                      <ReportGmailerrorredSharpIcon className="material-symbols-sharp me-2"
                                      />
                                      <span className="dropdownTxt f-14">
                                        Unfollow user
                                      </span>
                                    </div>
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      )}

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>
                  Are you sure you want to unfollow{" "}
                  <b>{selectedItem?.fullName} ?</b>
                </h6>

                <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUnfollowCloseModal();
                  }}
                >
                  close
                </span>
              </div>

              <div className="text-end mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => handleConfirmAction()}
                >
                  Unfollow
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Following;
