"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("./styles");
const SwipeableTabs_1 = require("./SwipeableTabs");
const Tab_1 = require("./Tab");
const TabGroup = ({ children = [], value, onChange, styleProps = {}, tabBarCSS = "", tabItemCSS = "", blacklistedElement = undefined }) => {
    const inkBarRef = react_1.useRef(null);
    const tabLabels = react_1.useRef([]);
    const handleSwipe = react_1.useCallback((selectedTab) => {
        onChange({
            label: tabLabels.current[selectedTab],
            key: children[selectedTab].key || selectedTab
        });
    }, [tabLabels, children]);
    const handleTabClick = react_1.useCallback((tabName, tabKey) => () => {
        const tabIndex = tabLabels.current.indexOf(tabName);
        onChange({ label: tabName, key: tabKey });
        if (!inkBarRef.current)
            return;
        const inkBarStyle = inkBarRef.current.style;
        inkBarStyle.transition = "none";
        inkBarStyle.marginLeft = `${(tabIndex / children.length) * 100}%`;
        inkBarStyle.transition = "0.1s ease-in-out";
    }, [onChange, children, tabLabels.current, inkBarRef]);
    react_1.useEffect(() => {
        tabLabels.current = children.map(child => child.props.label);
    }, []);
    /** Verify that valid <Tab/> children are being passed */
    // useEffect(() => {
    //   if (children.some(child => !(child.type === Tab))) {
    //     const error = new Error("TabsGroup children need to be of <Tab> type");
    //     error.name = "Invalid Children";
    //     throw error;
    //   }
    // }, [children]);
    return (react_1.default.createElement(styles_1.TabGroupContainer, { styleProps: styleProps },
        react_1.default.createElement(styles_1.TabPane, { styleProps: styleProps, tabBarCSS: tabBarCSS },
            react_1.default.createElement(styles_1.TabsList, { styleProps: styleProps }, children.map((child, index) => (react_1.default.createElement(Tab_1.TabHeader, { index: child.key || index, onClick: handleTabClick(child.props.label, child.key || index), width: 100 / children.length, label: child.props.label, isSelected: value === child.props.label, key: child.props.label, styleProps: styleProps, tabItemCSS: tabItemCSS })))),
            react_1.default.createElement(styles_1.TabSelector, { selectedTab: children.map(child => child.props.label).indexOf(value), tabCount: children.length, ref: inkBarRef, styleProps: styleProps })),
        react_1.default.createElement(styles_1.ViewPane, null,
            react_1.default.createElement(SwipeableTabs_1.SwipeableViews, { views: children, onSwipe: handleSwipe, selectedView: children.map(child => child.props.label).indexOf(value), inkBarRef: inkBarRef, blacklistedElement: blacklistedElement }))));
};
exports.Tabs = react_1.memo(TabGroup);
