import React from "react";
import MainPage from "./MainPage";
import { header } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div className="about-main intro-section p-3 pt-1">
        {/* <MainPage /> */}
        <div className="logo-content-cont d-flex align-items-center">
          <div className="weLinkLogo ps-2">
            <img src={header.mainLogo} alt="" />
          </div>
          <div>
            <nav>
              <ul className="d-flex privacy-nav-text">
                <li className="p-4">
                  <Link to="/about">ABOUT</Link>
                </li>
                <li className="p-4">
                  <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                </li>
                <li className="p-4">
                  <Link to="/termsAndCondition">TERMS AND CONDITION</Link>
                </li>
                <li className="p-4">
                  <Link to="/userAgreement">USER AGREEMENT</Link>
                </li>
                <li className="p-4">
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="content-container">
          <div className="policyHeading">
            <h1 className="f-700 f-45 mb-4 content-color">About Us</h1>
            <div className="content-para">
              <p className="mb-3 f-14 content-color">
                Nowadays the marketplace and the growing companies are facing
                real challenges to find the right talent. Most importantly, the
                companies need to understand the facilities a global recruitment
                platform can offer.
              </p>
              <p className="f-14 content-color">
                Welcome to Welinkjobs, the Competitive and interactive network
                which globally involves you to achieve a better opportunity.
                Moreover in Welinkjobs, companies can immediately hire new team
                members in a competitive advantage through an easy search engine
                all in one platform.
              </p>
            </div>
          </div>
          <div className="policy-bottom d-flex  pt-4">
            <div className="vision-cont vision-style">
              <h1 className="f-600 content-color">Vision:</h1>
              <p className="vision-para f-14 content-color">
                Create a comprehensive opportunity for every workforce in the
                world
              </p>
            </div>
            <div className="vision-cont">
              <h1 className="f-600 content-color">Mission:</h1>
              <p className="vision-para f-14 content-color">
                Connect all the members globally to find better solutions for
                career path
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
