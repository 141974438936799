import React from "react";

const EditComment = ({
    isEditing,
    editedComment,
    handleCommentChange,
    handleSave,
    setIsEditing,
}) => {
    return (
        <>
            {isEditing ? (
                <div className="edit-comment">
                    <input
                        type="text"
                        value={editedComment}
                        onChange={(e) => handleCommentChange(e.target.value)} // Update comment when typing
                        className="form-control"
                    />
                    <button onClick={handleSave} className="wljPrimaryBtn mt-2">
                        Save
                    </button>
                    <button
                        onClick={() => setIsEditing(false)}
                        className="wljSecondaryBtn ms-3 mt-2"
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <p className="f-14">{editedComment}</p> // Show the edited comment
            )}
        </>
    );
};

export default EditComment;
