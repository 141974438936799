import React, { useEffect, useState } from "react";
import axios from "../../API/axios";
import { post } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const LikesDetails = ({
  showModal,
  handleLikesCloseModal,
  postId,
  item,
  postLikedType,

  showCommentLikeModal,
  handleCommentLikeCloseModal,
  postCommentId,
  commentsLikedType,

  showCommentReplyLikeModal,
  handleCommentReplyLikeCloseModal,
  postCommentReplyId,
  commentsReplyLikedType,
}) => {
  const [postIdLikeData, setPostIdLikeData] = useState([]);
  const [commentLikeData, setCommentLikeData] = useState([]);

  console.log("postCommentReplyId", postCommentReplyId);
  //   Get post Like Response
  const GetPostsLikes = async () => {
    console.log("soheb");
    try {
      await axios
        .get(`Posts/GetPostLikes?DeviceType=Web&PostId=${postId}`)
        .then((res) => {
          console.log("likes res", res.data.responseData);
          setPostIdLikeData(res.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (showModal) {
      GetPostsLikes();
    }
  }, [postId, showModal]);

  //   Get comment like response
  const GetCommentsLikes = async () => {
    console.log("comment res");
    try {
      await axios
        .get(
          `Posts/GetPostCommentsLikes?DeviceType=Web&PstCmntId=${postCommentId}`
        )
        .then((res) => {
          console.log("comments res====>", res.data.responseData);
          setCommentLikeData(res.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (showCommentLikeModal) {
      GetCommentsLikes();
    }
  }, [postCommentId]);

  //   Get comment reply response
  const [commentReplyLikeData, setCommentReplyLikeData] = useState([]);
  const GetCommentsReplyLikes = async () => {
    console.log("comment reply res");
    try {
      await axios
        .get(
          `Posts/GetCommentRepliesLikes?DeviceType=Web&PstCmntRplyId=${postCommentReplyId}`
        )
        .then((res) => {
          console.log("comments reply res====>", res.data.responseData);
          setCommentReplyLikeData(res.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (showCommentReplyLikeModal) {
      GetCommentsReplyLikes();
    }
  }, [postCommentReplyId]);

  console.log("postIdLikeData", postIdLikeData);
  console.log("postLikedType", postLikedType);

  useEffect(() => {
    if (showModal || showCommentLikeModal || showCommentReplyLikeModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal, showCommentLikeModal, showCommentReplyLikeModal]);

  return (
    <>
      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Reactions</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleLikesCloseModal();
                  }}
                />
              </div>

              <div className="reaction-cont">
                {postIdLikeData &&
                  postIdLikeData.map((item) => {
                    console.log("bvnbcxvznbc", item);
                    return (
                      <div className="post-like-details-cont p-2 d-flex justify-content-between">
                        <Link to={`/User/${item.useruid}`}>
                          <div className="like-header-cont d-flex align-items-center">
                            <div className="like-image-cont me-3">
                              <img src={item.userAvatar} alt="" width={50} height={50} srcset="" />
                            </div>

                            <div>
                              <div className="liked-user-name f-700 f-14 mt-2">
                                {item.userName}
                              </div>
                              <div className="liked-info">
                                {/* <p className="f-12">good profile</p> */}
                              </div>
                            </div>
                            <div className="like-selected-imoji mt-2 ms-2">
                              <div className="like-container pointer d-flex align-items-center">
                                {item?.likeType === 1 ? (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.LikeGif} width={30} />
                                    </div>
                                  </>
                                ) : item?.likeType === 2 ? (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.LoveGif} width={30} />
                                    </div>
                                  </>
                                ) : item?.likeType === 3 ? (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.LaughingGif} width={30} />
                                    </div>
                                  </>
                                ) : item?.likeType === 4 ? (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.WowGif} width={30} />
                                    </div>
                                  </>
                                ) : item?.likeType === 5 ? (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.AngryGif} width={30} />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="liked-imoji">
                                      <img src={post.LikeGif} width={30} />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>

                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCommentLikeModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Comment Reactions</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCommentLikeCloseModal();
                  }}
                />
              </div>
              <div className="reaction-cont">
                {commentLikeData.map((item) => {
                  return (
                    <div className="post-like-details-cont p-2">
                      <div className="like-header-cont d-flex justify-content-between">
                        <Link to={`/User/${item.useruid}`}>
                          <div className="d-flex">
                            <div className="like-image-cont me-3">
                              <img src={item.userAvatar} alt="" srcset="" />
                            </div>

                            <div>
                              <div className="liked-user-name f-700 f-14 mt-2">
                                {item.userName}
                              </div>
                              <div className="liked-info">
                                {/* <p className="f-12">good profile</p> */}
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="like-selected-imoji">
                          <div className="like-container pointer d-flex align-items-center">
                            {item?.likeType === 1 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LikeGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 2 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LoveGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 3 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LaughingGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 4 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.WowGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 5 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.AngryGif} width={30} />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LikeGif} width={30} />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCommentReplyLikeModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Comment Reply Reactions</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCommentReplyLikeCloseModal();
                  }}
                />
              </div>
              <div className="reaction-cont">
                {commentReplyLikeData.map((item) => {
                  return (
                    <div className="post-like-details-cont p-2">
                      <div className="like-header-cont d-flex justify-content-between">
                        <Link to={`/User/${item.useruid}`}>
                          <div className="d-flex">
                            <div className="like-image-cont me-3">
                              <img src={item.userAvatar} alt="" srcset="" />
                            </div>

                            <div>
                              <div className="liked-user-name f-700 f-14 mt-2">
                                {item.userName}
                              </div>
                              <div className="liked-info">
                                {/* <p className="f-12">good profile</p> */}
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="like-selected-imoji">
                          <div className="like-container pointer d-flex align-items-center">
                            {item?.likeType === 1 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LikeGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 2 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LoveGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 3 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LaughingGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 4 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.WowGif} width={30} />
                                </div>
                              </>
                            ) : item?.likeType === 5 ? (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.AngryGif} width={30} />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="liked-imoji">
                                  <img src={post.LikeGif} width={30} />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LikesDetails;
