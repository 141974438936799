import React from "react";
import MainPage from "./MainPage";
import { header } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
import PhoneInTalkSharpIcon from '@mui/icons-material/PhoneInTalkSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
const Contact = () => {
  return (
    <>
      <div className="about-main intro-section p-3 pt-1">
        {/* <MainPage /> */}
        <div className="logo-content-cont d-flex align-items-center">
          <div className="weLinkLogo ps-2">
            <img src={header.mainLogo} alt="" />
          </div>
          <div>
            <nav>
              <ul className="d-flex privacy-nav-text">
                <li className="p-4">
                  <Link to="/about">ABOUT</Link>
                </li>
                <li className="p-4">
                  <Link to="/privacyPolicy">PRIVACY POLICY</Link>
                </li>
                <li className="p-4">
                  <Link to="/termsAndCondition">TERMS AND CONDITION</Link>
                </li>
                <li className="p-4">
                  <Link to="/userAgreement">USER AGREEMENT</Link>
                </li>
                <li className="p-4">
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="privacy-contact-cont d-flex justify-content-around">

          <div className="contact-us-content">
            <h1 className="f-700 f-45 content-color">Contact Us</h1>
            <span>
              <h2 className="f-20 contact-icon d-flex align-items-center">
                <PhoneInTalkSharpIcon className="material-symbols-sharp me-3" />
                +1
                3072436345
              </h2>
            </span>
            <span>
              <h2 className="f-20 contact-icon d-flex align-items-center">
                <MailOutlineSharpIcon className="material-symbols-sharp me-3" />
                info@welinkjobs.com
              </h2>
            </span>
          </div>

          <div className="contact-form-cont d-flex flex-column justify-content-center">
            <input type="text" placeholder="Name" className="contact-input mb-3" />
            <input type="text" placeholder="Email" className="contact-input mb-3" />
            <input type="text" placeholder="Subject" className="contact-input mb-3" />
            <input type="text" placeholder="Message" className="contact-input message-input" />
            <div className="d-flex justify-content-center mt-3">
              <button type="button" className="btn btn-danger">Submit</button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
