
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
const SingleCommentReplyInteraction = ({
    isOwnedPost,
    item,
    commentId,
    AllPostList,
    updateComment,
    handleReplySave,
    handleReplyEdit,
    setEditedReply,
    setRplyCmntId,
    isReplyEditing,
    editedReply,
    postId,
    setPostList,
    postList
}) => {
    console.log("replydelete", item);
    const sessionId = localStorage.getItem("sessionId");
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    //************************Delete Post start  ************************//
    const [show, setShow] = useState(false);
    const handleDeleteClose = () => {
        setShow(false);
    };

    // const deleteCommentReply = async () => {
    //   const responseData = {
    //     deviceType: "Web",
    //     sessionId: sessionId,
    //     id: item.pstCmntRplyId,
    //   };
    //   try {
    //     await axios
    //       .post(`PostAction/DeleteCommentReply`, responseData)
    //       .then((res) => {
    //         setShow(!show);
    //         console.log('resssdd', res);
    //         setToastMessage(res.data.message);
    //         setShowToast(true);
    //         // AllPostList()
    //         const updatedPostList = postList.map((post) => {
    //           if (post.postId === postId) {

    //             // Filter out the deleted comment
    //             const updatedComments = post.postCommentsReplies.filter(
    //               (comment) => comment.pstCmntRplyId !== item.pstCmntRplyId
    //             );

    //             // Update the post with the new comments and decremented commentCount
    //             return {
    //               ...post,
    //               postCommentsReplies: updatedComments,
    //               replyCount: post.replyCount - 1,
    //             };
    //           }
    //           return post; // Return other posts unchanged
    //         });
    //         console.log('updatedPostList', updatedPostList);

    //         setPostList(updatedPostList);
    //       });
    //   } catch (e) {
    //     if (e.response && e.response.status === 404) {
    //       setWarningToastMessage(e.response.data.message);
    //       setShowWarningToast(true);
    //     } else {
    //       setErrorToastMessage(e.response.data.message);
    //       setShowErrorToast(true);
    //     }
    //   }
    // };
    // const deleteCommentReply = async () => {
    //     const responseData = {
    //         deviceType: "Web",
    //         sessionId: sessionId,
    //         id: item.pstCmntRplyId,
    //     };

    //     try {
    //         const res = await axios.post(`PostAction/DeleteCommentReply`, responseData);
    //         setShow(!show);
    //         console.log('resssdd', res);
    //         setToastMessage(res.data.message);
    //         setShowToast(true);

    //         const updatedPostList = postList.map((post) => {
    //             if (post.postId === postId) {
    //                 console.log('jdsds', item.pstCmntId);

    //                 // Update comments by filtering out the reply in the specific comment
    //                 const updatedComments = post.postComments.map((comment) => {
    //                     if (comment.pstCmntId === commentId) {
    //                         // Filter out the deleted reply
    //                         const updatedReplies = comment.postCommentsReplies.filter(
    //                             (reply) => reply.pstCmntRplyId !== item.pstCmntRplyId
    //                         );
    //                         return {
    //                             ...comment,
    //                             postCommentsReplies: updatedReplies,
    //                             replyCount: comment.replyCount - 1, // Decrement the reply count
    //                         };
    //                     }
    //                     return comment; // Return other comments unchanged
    //                 });

    //                 // Return the updated post with new comments
    //                 return {
    //                     ...post,
    //                     postComments: updatedComments,
    //                 };
    //             }
    //             return post; // Return other posts unchanged
    //         });

    //         console.log('updatedPostList', updatedPostList);
    //         setPostList(updatedPostList);


    //     } catch (e) {
    //         if (e.response && e.response.status === 404) {
    //             setWarningToastMessage(e.response.data.message);
    //             setShowWarningToast(true);
    //         } else {
    //             setErrorToastMessage(e.response.data.message);
    //             setShowErrorToast(true);
    //         }
    //     }
    // };

    const deleteCommentReply = async () => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            id: item.pstCmntRplyId,
        };

        try {
            const res = await axios.post(`PostAction/DeleteCommentReply`, responseData);
            setShow(!show);
            console.log('resssdd', res);
            setToastMessage(res.data.message);
            setShowToast(true);

            // Directly update postList since it's a single object
            const updatedComments = postList.postComments.map((comment) => {
                if (comment.pstCmntId === commentId) {
                    // Filter out the deleted reply
                    const updatedReplies = comment.postCommentsReplies.filter(
                        (reply) => reply.pstCmntRplyId !== item.pstCmntRplyId
                    );
                    return {
                        ...comment,
                        postCommentsReplies: updatedReplies,
                        replyCount: comment.replyCount - 1, // Decrement the reply count
                    };
                }
                return comment; // Return other comments unchanged
            });

            // Update postList with the new comments
            const updatedPost = {
                ...postList,
                postComments: updatedComments,
            };

            console.log('updatedPost', updatedPost);
            setPostList(updatedPost); // Update the state with the modified post object

        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };


    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(null);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //************************Delete Post End  ************************//

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [show]);
    return (
        <>
            {(item?.isOwned || isOwnedPost) ? (
                <div
                    className="postMenu pointer commentInteraction"
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    <MoreHorizSharpIcon className="material-symbols-sharp" />
                    {showDropdown && (
                        <Dropdown.Menu
                            show={showDropdown}
                            ref={dropdownRef}
                            className="InteractionModal"
                        >
                            {(item?.isOwned || isOwnedPost) && (
                                <Dropdown.Item as="button" className="dropdownList">
                                    <Link
                                        onClick={() => {
                                            setShow(!show);
                                            toggleDropdown();
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                                            <span className="dropdownTxt f-14">Delete</span>
                                        </div>
                                        <p className="f-12 dropdownPara">
                                            Once Deleted it can't be recovered
                                        </p>
                                    </Link>
                                </Dropdown.Item>
                            )}
                            {item?.isOwned && (
                                <Dropdown.Item as="button" className="dropdownList">
                                    <Link
                                        onClick={() => {
                                            handleReplyEdit();
                                            // setEditedReply(item.reply);
                                            // setRplyCmntId(item.pstCmntRplyId);
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <EditSharpIcon className="material-symbols-sharp me-2" />
                                            <span className="dropdownTxt f-14">Edit</span>
                                        </div>
                                        <p className="f-12 dropdownPara">Edit Comment</p>
                                    </Link>
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    )}
                </div>
            ) : (
                ""
            )}

            {/* <Modal
        show={show}
        size="md"
        centered
        onHide={handleDeleteClose}
        toggle={() => setShow(!show)}
      // className='modal-dialog-centered m'
      >
        <Modal.Header
          className="bg-transparent p-4"
          closeButton
          toggle={() => {
            setShow(!show);
          }}
        >
          <h6 className="f-16 fw-bold">
            Are you sure you want to delete comment Reply?
          </h6>
        </Modal.Header>
        <Modal.Body className="text-center">
          <button
            color=""
            className="closebtn"
            onClick={() => deleteCommentReply()}
          >
            Delete
          </button>
        </Modal.Body>
      </Modal> */}

            {show && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are you sure you want to delete comment Reply?</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleDeleteClose();
                                    }}
                                />
                            </div>
                            <div className="text-center mt-2">
                                <button
                                    type="button"
                                    className="mainBtn1 me-4"
                                    onClick={() => deleteCommentReply()}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default SingleCommentReplyInteraction;
