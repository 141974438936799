
export const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: 'var(--label)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--mainTextColor)',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'var(--label)',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'var(--darkBG)' : 'var(--darkBG)',
        color: state.isSelected ? 'var(--mainTextColor)' : 'var(--mainTextColor)',
        '&:hover': {
            backgroundColor: 'yourHoverBackgroundColor',
            color: 'var(--mainTextColor)',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'var(--darkBG)',
    }),
};


