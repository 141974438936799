import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import ErrorToast from '../ErrorToast';
import WarningToast from '../WarningToast';
import SuccessToast from '../SuccessToast';
import axios from '../../API/axios';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

const ProfileNegativeActions = ({ CheckConnectUser,connectionId, userData, uid, getUserProfile, connectionCheck }) => {
    const sessionId = localStorage.getItem("sessionId");
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    //************************Block User  ************************//

    const BlockUser = async (id) => {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("UserUid", uid);
        try {
            const res = await axios.post("User/BlockUser", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setToastMessage(res.data.message);
            setShowToast(true);
            getUserProfile()
            // });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************Block User  ************************//

    //************************UnBlock User  ************************//

    const unblockUser = async (id) => {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("UserUid", uid);
        try {
            const res = await axios.post("User/UnBlockUser", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setToastMessage(res.data.message);
            setShowToast(true);
            getUserProfile()
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************UnBlock User  ************************//
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);


    //************************Unfollow User  ************************//
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    const unFollowUser = async () => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: uid,
        };
        try {
            await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
                // toast.success("User Unfollowed");
                setToastMessage(res.data.message);
                setShowToast(true);
                setShowModal(false);
                getUserProfile()
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    //************************Unfollow User  ************************//


    //************************Disconnect Request   ************************//
    const [disconectshow, setDisconectShow] = useState(false);
    const DisconnectClose = () => {
        setDisconectShow(false);
    };
    const Disconnect = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: uid,
        };
        console.log(data);
        try {
            await axios
                .post("Connection/DisconnectUser ", data)
                .then((resData) => {
                    console.log(resData);
                    setDisconectShow(!disconectshow);
                    //   toast.success(resData.data.message);
                    setToastMessage(resData.data.message);
                    setShowToast(true);
                    CheckConnectUser();
                });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    //************************Disconnect Request ************************//
    const [show, setShow] = useState(false);
    const cancelRequestClose = () => {
      setShow(false);
    };
    const RejectRequest = async () => {
        const data = {
          deviceType: "Web",
          sessionId: sessionId,
          userUID: uid,
          connectID: connectionId,
          actionType: "Reject",
        };
        console.log(data);
        try {
          await axios
            .post("Connection/ConnectionRequestAction ", data)
            .then((resData) => {
              console.log(resData.data.message);
              //   toast.success(resData.data.message);
              setToastMessage(resData.data.message);
              setShowToast(true);
              CheckConnectUser();
            });
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setWarningToastMessage(error.response.data.message);
            setShowWarningToast(true);
          } else {
            setErrorToastMessage(error.response.data.message);
            setShowErrorToast(true);
          }
        }
      };

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [showModal]);
    return (
        <>
            <div
                className="postMenu pointer ms-auto"
                onClick={() => setShowDropdown(!showDropdown)}
            >
                <MoreHorizSharpIcon className="material-symbols-sharp" />
                {showDropdown && (
                    <Dropdown.Menu
                        show={showDropdown}
                        ref={dropdownRef}
                        className="InteractionModal"
                    >
                        {userData?.profileInfo?.isBlockedByYou ? 
                        (<Dropdown.Item as="button" className="dropdownList">
                            <Link
                                onClick={() => {
                                    unblockUser()
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <span className="dropdownTxt f-14">Unblock</span>
                                </div>
                                <p className="f-12 dropdownPara">
                                    Unblock User
                                </p>
                            </Link>
                        </Dropdown.Item>) : 
                        (<Dropdown.Item as="button" className="dropdownList ">
                            <Link
                                onClick={() => {
                                    BlockUser();
                                }}
                            // onClick={BlockUser}
                            >
                                <div className="d-flex align-items-center">
                                    <span className="dropdownTxt f-14">Block</span>
                                </div>
                                <p className="f-12 dropdownPara">
                                    Block User
                                </p>
                            </Link>   
                        </Dropdown.Item>
                        )
                        }

                        {userData?.profileInfo?.isFollowedByYou === true &&
                            <Dropdown.Item as="button" className="dropdownList">
                                <Link
                                    onClick={() => {
                                        handleShow();
                                    }}
                                // onClick={BlockUser}
                                >
                                    <div className="d-flex align-items-center">
                                        <span className="dropdownTxt f-14">Unfollow</span>
                                    </div>
                                    <p className="f-12 dropdownPara">
                                        Unfollow User
                                    </p>
                                </Link>
                            </Dropdown.Item>
                        }
                        {connectionCheck === "Connected" && 
                        <Dropdown.Item as="button" className="dropdownList">
                            <Link
                                onClick={() => {
                                    setDisconectShow(!disconectshow);
                                }}
                            // onClick={BlockUser}
                            >
                                <div className="d-flex align-items-center">
                                    <span className="dropdownTxt f-14">Disconnect</span>
                                </div>
                                <p className="f-12 dropdownPara">
                                    Disconnect User
                                </p>
                            </Link>
                        </Dropdown.Item>}
                        {connectionCheck === "Received" &&
                             <Dropdown.Item as="button" className="dropdownList ">
                            <Link
                                onClick={() => {
                                    RejectRequest();
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <span className="dropdownTxt f-14"> Reject Request</span>
                                </div>
                                <p className="f-12 dropdownPara">
                                Reject Request User
                                </p>
                            </Link>   
                        </Dropdown.Item>
                        } 
                    </Dropdown.Menu>
                )}
            </div>


            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />


            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6> Unfollow User</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                />
                            </div>

                            <div>
                                <div className="col-md-12 mt-4 d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="mainBtn1"
                                        onClick={() => unFollowUser()}
                                    >
                                        Unfollow
                                    </button>
                                </div>
                            </div>

                            {/* <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={UndoEndorseData}
                >
                  UnEndorse
                </button>
              </div> */}
                        </div>
                    </div>
                </div>
            )}

            {disconectshow && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are You Sure You want to Disconnect User?</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        DisconnectClose();
                                    }}
                                />
                            </div>

                            <div className="text-center mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => Disconnect()}
                                >
                                    Disconnect
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProfileNegativeActions