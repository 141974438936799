
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FloatingLabelInput = ({
    id,
    label,
    type,
    name,
    value,
    onChange,
    onBlur,
}) => {
    const [isFloating, setIsFloating] = useState(false);

    useEffect(() => {
        setIsFloating(!!value);
    }, [value]);

    const handleFocus = () => {
        setIsFloating(true);
    };

    const handleBlurInput = (event) => {
        setIsFloating(!!event.target.value);
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <div className={`floating-label-input ${isFloating ? 'floating' : ''}`}>
            <input
                id={id}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlurInput}
            />
            <label htmlFor={id}>{label}</label>
            <style jsx>{`
        .floating-label-input {
          position: relative;
          margin-bottom: 1rem;
          margin-top:1rem;
        }
        .floating-label-input input {
          width: 100%;
          padding: 10px;
          border:none;
          border-bottom: 1px solid #ccc;
          {/* border-radius: 4px; */}
        }
        .floating-label-input label {
          position: absolute;
          top: ${isFloating ? '0' : '50%'};
          left: 10px;
          transform: translateY(${isFloating ? '-50%' : '-50%'});
          transition: all 0.2s;
          background: transparent;
          padding: 0 5px;
          color:rgb(155, 155, 155);
        }
        .floating-label-input.floating label {
          top: -10px;
          font-size: 12px;
        }
        .password-input{
            position:relative;
        }
        .password-input .floating-label-input{
            width:100%
        }
        .password-toggle{
            position:absolute;
            right: 10px;
            bottom: 25px;
        }
      `}</style>
        </div>
    );
};

FloatingLabelInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
};

export default FloatingLabelInput;
