import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import UpdateDraft from "./UpdateDraft";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';

const DraftInteraction = ({ postId, DraftPost }) => {
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputFileRef = useRef(null);
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [postData, setPostData] = useState("");

  const PostsDetails = async () => {
    setShowModal(true);

  };

  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deletePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/DeletePost`, responseData).then((res) => {
        setShow(!show);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setTimeout(() => {
          DraftPost();
        }, 2000);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };



  const CompleteDraft = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    console.log(data);
    try {
      await axios.post(`Posts/CompleteDraftPost `, data).then((resData) => {
        console.log(resData);
        setShowModal(false);
        // toast.success(resData.data.message);
        setToastMessage(resData.data.message);
        setShowToast(true);
        setTimeout(() => {
          DraftPost();
        }, 2000);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  const [showPost, setShowPost] = useState(false);

  useEffect(() => {
    if (showPost && inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [showPost]);

  const handleShowPost = () => {
    setShowModal(false);
    setShowPost(true);
  };

  const checkDuplicate = (name) => {
    return images.some((image) => image.name === name);
  };

  const imageSelect = (event) => {
    // console.log("image selected");
    // setShowTestModal(true);
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const imageName = selectedFiles[i].name;
      if (!checkDuplicate(imageName)) {
        const imageUrl = URL.createObjectURL(selectedFiles[i]);
        setImages((prevImages) => [
          ...prevImages,
          {
            name: imageName,
            url: imageUrl,
            file: selectedFiles[i],
          },
        ]);
      } else {
        alert(`${imageName} is already added to the list`);
      }
    }
    event.target.value = null; // Reset file input
  };

  const [images, setImages] = useState([]);

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className="image_container d-flex justify-content-center position-relative"
      >
        <img src={image.url} alt={image.name} />
        <span className="position-absolute" onClick={() => deleteImage(index)}>
          &times;
        </span>
      </div>
    ));
  };

  const Privacy = [
    { value: "1", label: "Public" },
    { value: "2", label: "Friends" },
    { value: "3", label: "OnlyMe" },
    { value: "4", label: "Custom" },
  ];

  const handleClosePost = () => {
    setShowPost(false);
  };

  const [postContent, setPostContent] = useState("");
  const [postID, setPostID] = useState("");
  const [display, setDisplay] = useState(false);
  const [imageNames, setImageNames] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);




  useEffect(() => {
    if (show || showPost || showModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [show, showPost, showModal]);

  return (
    <>
      <div
        className="postMenu pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
          >
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  CompleteDraft();
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <SaveSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Complete Post</span>
                </div>
                <p className="f-12 dropdownPara">
                  This Post will be saved in your feed
                </p>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button" className="dropdownList">
              <Link onClick={openModal}>
                <div className="d-flex align-items-center">
                  <UpdateSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Update Post</span>
                </div>
                <p className="f-12 dropdownPara">Update post</p>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  setShow(!show);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Delete</span>
                </div>
                <p className="f-12 dropdownPara">
                  Once Deleted it can't be recovered
                </p>
              </Link>
            </Dropdown.Item>

          </Dropdown.Menu>
        )}
      </div>

      {/* // Update Modal */}



      {showModal && (
        <UpdateDraft
          postId={postId}
          showModal={showModal}
          closeModal={closeModal}
          DraftPost={DraftPost}
        />
      )}




      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are you sure you want to delete post?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deletePost()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default DraftInteraction;
