import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import axios, { MessageHub } from "../../API/axios";
import ChatInteraction from "./ChatInteraction";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { header, people } from "../../assets/Images/ImagesLink";
import { Link, useLocation } from "react-router-dom";
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import AddReactionSharpIcon from '@mui/icons-material/AddReactionSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
//import { useIsFocused, useFocusEffect } from '@react-navigation/stack';
// const MessageHub = `https://localhost:7298/hubs`
const ChatBox = ({
  isShown,
  isLoadingChat,
  chatInfo,
  getChatList,
  setChatList,
  chatList,
  UserId,
  msgId,
  connectionUser,
  getMessageList,
  setMsgId,
  userName,
}) => {
  // console.log('MessageHub', MessageHub);
  console.log("chatInfo", chatInfo);
  const sessionId = localStorage.getItem("sessionId");
  let location = useLocation();
  const profilPic = localStorage.getItem("profilPic");
  let chatId = "";
  let chatmessageId = "";
  let isRead = false;
  //************************Reply Message Start  ************************//
  const [newReceiveChat, setNewReceiveChat] = useState("");
  //console.log('newReceiveChat', newReceiveChat);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const handleEmojiClose = () => {
    setShowEmojiPicker(false);
  };

  const footerRef = useRef();
  useEffect(() => {
    footerRef.current?.scrollIntoView({ behavior: "smooth" });
  });

  const [content, setContent] = useState("");
  const ReplyMessage = async () => {
    // console.log('ReplyMessage');
    const messageDetails = {
      deviceType: "Web",
      sessionId: sessionId,
      msgID: msgId === "" ? chatInfo?.msgID : msgId,
      type: 1,
      msg: content,
    };
    try {
      await axios
        .post("Message/ReplyMessage", messageDetails)
        .then((response) => {
          // console.log('response', response);
          //getChatList()
          // ConnectionListAll()
          //console.log('chatId'+response.data.responseData.chatID);
          //localStorage.setItem("MessageChatId",response.data.responseData.chatID);
          //chatId=response.data.responseData.chatID;
          getMessageList();
        });
      setContent("");
    } catch (error) {
      console.log(error);
    }
  };
  const InitiateMessage = async () => {
    // console.log('InitiateMessage');
    const messageDetails = {
      deviceType: "Web",
      sessionId: sessionId,
      type: 1,
      msg: content,
      toUserUid: UserId,
    };

    try {
      await axios
        .post("Message/InitiateMessage", messageDetails)
        .then((response) => {
          // console.log('response', response.data.responseData.id);
          // console.log('response', response);
          getChatList();
          // ConnectionListAll()
          getMessageList();
          setMsgId(response.data.responseData.id);
        });
      setContent("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = () => {
    if (content.length > 0) {
      ReplyMessage();
    }
  };

  const handleIntiatiateMessage = () => {
    if (content.length > 0) {
      InitiateMessage();
    }
  };

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(MessageHub, {
        skipNegotiation: false,
      })
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        console.log("Connected!", connection);

        connection.on("ReplyChat", (message) => {
          //chatmessageId = message.chatID;
          //chatId= localStorage.getItem('MessageChatId');
          let receiver = localStorage.getItem("toUserUid");
          let sender = localStorage.getItem("userUid");

          if (chatId != message.chatID) {
            chatmessageId = null;
            chatId = message.chatID;

            if (
              message.receiverUid == sender &&
              message.senderUid == receiver &&
              window.location.toString().includes(location.pathname)
            ) {
              message.status = "Receive";
              message.isOwned = false;
              ReadChat();
            } else if (
              message.senderUid == sender &&
              message.receiverUid == receiver
            ) {
              message.status = "Send";
              message.isOwned = true;
            }
            if (
              (message.status != null &&
                message.senderUid == sender &&
                message.receiverUid == receiver) ||
              (message.status != null &&
                message.receiverUid == sender &&
                message.senderUid == receiver)
            ) {
              setChatList((prevChatList) => {
                const updatedChatList = [...prevChatList, message];
                return updatedChatList;
              });
              //chatmessageId=chatId;
            }
          }
        });
        connection.on("DeleteChat", (message) => {
          chatmessageId = localStorage.getItem("DeletedChatId");
          //if((chatId==chatmessageId && chatmessageId!=null && chatmessageId!=""))
          //{
          let receiver = localStorage.getItem("toUserUid");
          let sender = localStorage.getItem("userUid");
          chatId = message.chatID;
          if (
            message.receiverUid == sender &&
            message.senderUid == receiver &&
            message.isCompleteDeleted == true
          ) {
            message.status = "Receive";
            message.isOwned = false;
            //ReadChat();
          } else if (
            message.senderUid == sender &&
            message.receiverUid == receiver
          ) {
            message.status = "Send";
            message.isOwned = true;
          }
          if (
            (message.status != null &&
              message.senderUid == sender &&
              message.receiverUid == receiver) ||
            (message.status != null &&
              message.receiverUid == sender &&
              message.senderUid == receiver)
          ) {
            setChatList((prevChatList) => {
              const updatedChatList = [...prevChatList];
              //find message.chatID in prevChatList and change msg to "This message was deleted"
              for (let i = 0; i < updatedChatList.length; i++) {
                if (updatedChatList[i].chatID == message.chatID) {
                  if (message.status == "Send") {
                    updatedChatList[i].msg = message.sndrSideMsg;
                  } else if (message.status == "Receive") {
                    updatedChatList[i].msg = message.rcvrSideMsg;
                  }
                  updatedChatList[i].isRead = false;
                  break;
                }
              }
              return updatedChatList;
            });
            chatmessageId = null;
          }

          //}
        });
        connection.on("ReadChat", (message) => {
          let receiver = localStorage.getItem("toUserUid");
          let sender = localStorage.getItem("userUid");
          chatId = message.chatID;
          if (message.receiverUid == sender && message.senderUid == receiver) {
            setChatList((prevChatList) => {
              const updatedChatList = [...prevChatList];

              for (let i = 0; i < updatedChatList.length; i++) {
                if (
                  updatedChatList[i].chatID == message.chatID &&
                  message.receiverUid == sender
                ) {
                  if (
                    message.senderUid == receiver &&
                    message.receiverUid == sender
                  ) {
                    updatedChatList[i].isRead = message.isRead;
                  }
                  break;
                }
              }
              return updatedChatList;
            });
            chatmessageId = null;
          }

          //}
        });
        connection.on("ReadAllChat", (message) => {
          let receiver = localStorage.getItem("toUserUid");
          let sender = localStorage.getItem("userUid");

          setChatList((prevChatList) => {
            const updatedChatList = [...prevChatList];
            if (message.length > 0) {
              const updatedChatList = [...prevChatList, message];

              for (let i = 0; i < updatedChatList.length; i++) {
                for (let j = 0; j < message.length; j++) {
                  chatId = message[j].chatID;
                  if (
                    updatedChatList[i].chatID == message[j].chatID &&
                    message[j].receiverUid == sender
                  ) {
                    if (
                      message[j].senderUid == receiver &&
                      message[j].receiverUid == sender
                    ) {
                      updatedChatList[i].isRead = message[j].isRead;
                    }
                    break;
                  }
                }
              }
            }
            return updatedChatList;
          });
          chatmessageId = null;
        });
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);
  //************************Reply Message End  ************************//
  // console.log('chatInfo', chatInfo);

  useEffect(() => {
    //if (newReceiveChat) {
    setChatList((prevChatList) => {
      const updatedChatList = [...prevChatList, newReceiveChat];
      return updatedChatList;
    });
    //}newReceiveChat
  }, []);
  //console.log('chatlist', chatList);

  //************************ReadChat Chat ************************//
  const ReadChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
    };
    console.log("Read ChatId" + chatId);
    try {
      await axios.post(`Message/ReadChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        //setToastMessage(res.data.message);
        //setShowToast(true);
        //getArchivedList();
        //getMessageList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        // setWarningToastMessage(e.response.data.message);
        //setShowWarningToast(true);
      } else {
        //setErrorToastMessage(e.response.data.message);
        //setShowErrorToast(true);
      }
    }
  };
  //************************ReadChat Chat End  ************************//
  //************************UnReadChat Chat ************************//
  const UnReadChat = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      chatID: chatId,
    };
    try {
      await axios.post(`Message/UnReadChat`, responseData).then((res) => {
        // toast.success(res.data.message);
        //setToastMessage(res.data.message);
        //setShowToast(true);
        //getArchivedList();
        //getMessageList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        //setWarningToastMessage(e.response.data.message);
        //setShowWarningToast(true);
      } else {
        //setErrorToastMessage(e.response.data.message);
        //setShowErrorToast(true);
      }
    }
  };
  //************************UnReadChat Chat End  ************************//
  console.log("chatList", chatList, UserId, chatInfo);

  return (
    <>
      {UserId === 'All' ? <img src={header.messageError} width={'100%'} /> : (
        <>
          {chatList?.length === 0 ? (
            <div
              className={`${!isShown ? "d-block" : "d-md-block d-none"
                } chat-box-main-container pt-0`}
            >
              <div className="username-header d-flex justify-content-between align-items-center pt-4 ps-3 pe-4">
                <Link to={`/User/${chatInfo.toUserUid}`}>
                  <div className="chatbox-left-cont d-flex align-items-center">
                    <div>
                      <div className="me-2">
                        <img
                          src={chatInfo?.toUserImage}
                          alt=""
                          width={40}
                          height={40}
                          style={{ borderRadius: "50px" }}
                        />
                      </div>
                    </div>
                    <div className="chat-box-username pe-3 f-16 f-600 me-4">
                      {chatInfo?.toUserFullName}
                    </div>
                  </div>
                </Link>
                <hr />
                <div className="menu-list-icon">
                </div>
              </div>

              <div className="chat-main-content pb-4 ps-0 p-3">
                <div className="devide-section d-flex align-items-center justify-content-center mb-4">
                  <div className="horizontal-line"></div>
                  {/* <div className="today-text f-12 f-600 ms-3 me-3">Today</div> */}
                  {/* <div className="horizontal-line"></div> */}
                </div>

                {/* {isLoading ?
            <div className='mt-3'>
              <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={100}
                imageHeight={100}
                title
              />
            </div>
            : */}
                {chatList &&
                  chatList?.map((item, index) => {
                    console.log("itemchat", item);
                    return item.isOwned === false ? (
                      <div
                        className="messages-cont d-flex align-items-center mb-5 ps-3"
                        ref={footerRef}
                        key={index}
                      >
                        <div className="me-1">
                          <img
                            src={chatInfo?.toUserImage}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                        </div>

                        <div className="message-box f-10 f-400 me-1">
                          <div>{item.msg}</div>
                          <div className="message-time f-10 f-400 text-end">
                            <span className=" f-10">
                              {moment.utc(item.time).local().format("LT")}
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <ChatInteraction
                            getChatList={getChatList}
                            chatId={item.chatID}
                            item={item}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="messages-cont self-message d-flex align-items-center mb-3"
                        ref={footerRef}
                      >
                        {/* <div className="empty-msg-card chat-empty"></div> */}
                        <div className="ms-2">
                          <img
                            src={profilPic}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                        <div className="message-box  self-message-content f-10 f-400 ms-3 ">
                          <div>{item.msg}</div>

                          <div className="d-flex  align-items-center justify-content-end text-end">
                            <div className="message-time f-10 f-400">
                              <span className=" f-10">
                                {(moment().utc().format("ll") ===
                                  moment.utc(item.time).format("ll")) ===
                                  true
                                  ? moment.utc(item.time).local().format("LT")
                                  : moment
                                    .utc(item.time)
                                    .local()
                                    .format("DD MMMM YYYY [at] h:mm a")}
                              </span>{" "}
                              <span>
                                {item?.isRead === true && item?.isOwned === true
                                  ? "seen"
                                  : ""}
                              </span>{" "}
                            </div>
                            {item?.isRead === false ? (
                              <DoneAllSharpIcon class="material-symbols-sharp" style={{ width: '15px' }} />
                            ) : (
                              <DoneAllSharpIcon class="material-symbols-sharp text-read" style={{ width: '15px' }} />
                            )}
                          </div>
                        </div>
                        <div className="ms-4 selfChat">
                          <ChatInteraction
                            getChatList={getChatList}
                            chatId={item.chatID}
                            item={item}
                          />
                        </div>
                      </div>
                    );
                  })}

                <div className="write-message-text d-flex align-items-center">
                  {/* <div className="empty-msg-card chat-empty me-3"></div> */}
                  <div className="me-2">
                    <img
                      src={profilPic}
                      alt=""
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                    />
                  </div>
                  <div className="write-message-cont d-flex justify-content-between me-2">
                    <input
                      type="text"
                      placeholder="Write messages down here…"
                      value={content}
                      className="w-100"
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                      onKeyPress={(e) =>
                        e.key === "Enter" && handleIntiatiateMessage()
                      }
                    />
                    <div className="icons-cont mt-2 d-flex">
                      <AddReactionSharpIcon
                        className="material-symbols-sharp me-2"
                        onClick={handleEmojiClick}
                        style={{ cursor: "pointer" }}
                      />
                      <SendSharpIcon
                        className="material-symbols-sharp"
                        onClick={() => handleIntiatiateMessage()}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : UserId !== "" ? (
            <div
              className={`${!isShown ? "d-block" : "d-md-block d-none"
                } chat-box-main-container pt-0`}
            >
              <div className="username-header d-flex justify-content-between align-items-center pt-4 ps-3 pe-4">
                <Link to={`/User/${chatInfo.toUserUid}`}>
                  <div className="chatbox-left-cont d-flex align-items-center">
                    <div className="me-2">
                      <img
                        src={chatInfo?.toUserImage}
                        alt=""
                        width={40}
                        height={40}
                        style={{ borderRadius: "50px" }}
                      />
                    </div>
                    <div className="chat-box-username pe-3 f-16 f-600 me-4">
                      {chatInfo?.toUserFullName}
                      {/* <div className="online-text-responsive f-12 f-400">online</div> */}
                    </div>
                    {/* <div className="online-text f-12 f-400">online</div> */}
                  </div>
                </Link>
              </div>

              <div className="chat-main-content pb-4 ps-0 p-3">
                <div className="devide-section d-flex align-items-center justify-content-center mb-4">
                  <div className="horizontal-line"></div>
                  {/* <div className="today-text f-12 f-600 ms-3 me-3">Today</div> */}
                  <div className="horizontal-line"></div>
                </div>

                {isLoadingChat ? (
                  <div className="mt-3">
                    <ShimmerCategoryItem
                      hasImage
                      imageType="circular"
                      imageWidth={100}
                      imageHeight={100}
                      title
                    />
                  </div>
                ) : (
                  chatList &&
                  chatList?.map((item, index) => {
                    console.log("itemchat", item);
                    return item.isOwned === false ? (
                      <div>
                        <div
                          className="messages-cont d-flex align-items-center mb-5 ps-3"
                          ref={footerRef}
                          key={index}
                        >
                          {/* <div className="empty-msg-card chat-empty me-3"></div> */}
                          <div className="me-1">
                            <Link to={`/User/${chatInfo.toUserUid}`}>
                              <img
                                src={chatInfo?.toUserImage}
                                alt=""
                                width={30}
                                height={30}
                                style={{ borderRadius: "50px" }}
                              />
                            </Link>
                          </div>
                          <div className="message-box f-10 f-400 me-1">
                            <div>{item.msg}</div>
                            <div>
                              <div className="message-time f-10 f-400 text-end">
                                <span className=" f-10">
                                  {moment.utc(item.time).local().format("LT")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <ChatInteraction
                              getChatList={getChatList}
                              chatId={item.chatID}
                              item={item}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div
                          className="messages-cont self-message d-flex align-items-center mb-3"
                          ref={footerRef}
                        >
                          {/* <div className="empty-msg-card chat-empty"></div> */}
                          <div className="ms-1">
                            <img
                              src={profilPic}
                              alt=""
                              width={30}
                              height={30}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                          <div className="message-box  self-message-content f-10 f-400 ms-1 ">
                            <div>{item.msg}</div>
                            <div className="d-flex align-items-center justify-content-end text-end">
                              <div className="message-time f-10 f-400">
                                <span className="f-10">
                                  {moment.utc(item.time).local().format("LT")}
                                </span>
                                <span></span>{" "}
                              </div>
                              {item?.isRead === false ? (
                                <DoneAllSharpIcon class="material-symbols-sharp" style={{ width: '15px' }} />
                              ) : (
                                <DoneAllSharpIcon class="material-symbols-sharp text-read" style={{ width: '15px' }} />
                              )}
                            </div>
                            <div>{/* <small>Read</small> */}</div>
                          </div>
                          <div className="ms-4 selfChat">
                            <ChatInteraction
                              getChatList={getChatList}
                              chatId={item.chatID}
                              item={item}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}

                <div className="write-message-text d-flex align-items-center">
                  {/* <div className="empty-msg-card chat-empty me-3"></div> */}
                  <div className="me-2">
                    <img
                      src={profilPic}
                      alt=""
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                    />
                  </div>
                  <div className="write-message-cont d-flex justify-content-between me-2 pb-2">
                    <input
                      type="text"
                      placeholder="Write messages down here…"
                      className="w-100"
                      value={content}
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                      onKeyPress={(e) => e.key === "Enter" && handleKeyPress()}
                    />
                    <div className="icons-cont mt-2 d-flex">
                      <AddReactionSharpIcon
                        className="material-symbols-sharp me-2"
                        onClick={handleEmojiClick}
                        style={{ cursor: "pointer" }}
                      />
                      <SendSharpIcon
                        className="material-symbols-sharp"
                        onClick={() => handleKeyPress()}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {showEmojiPicker && (
            <div className="messageEmoji">
              <EmojiPicker
                onEmojiClick={(e, emojiObject) => {
                  // { console.log(e.emoji, emojiObject) }
                  setContent(content + e.emoji);
                  setShowEmojiPicker(false);
                }}
              />
              <div
                className="emoji-close"
                onClick={handleEmojiClose}
                style={{ cursor: "pointer" }}
              >
                <CloseSharpIcon className="material-symbols-sharp" />
              </div>
            </div>
          )}
        </>
      )}

    </>
  );
};

export default ChatBox;
