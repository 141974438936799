// import React, { useEffect, useRef, useState } from "react";
// import "../../assets/css/newsFeed.css";
// import AllPost from "./AllPost";
// import UserAuth from "../Auth/UserAuth";
// import axios from "../../API/axios";
// import { Helmet } from "react-helmet";

// const NewsFeed = () => {
//   const [data, setData] = useState(null);
//   const [showUserAuthModal, setShowUserAuthModal] = useState(false);
//   const sessionId = localStorage.getItem('sessionId');
//   const adContainerRef = useRef(null); // Reference to the ad container

//   const handleCloseUserAuthModal = () => {
//     setShowUserAuthModal(false);
//     document.body.classList.remove('modal-open'); // Re-enable scroll when modal is closed
//   };

//   const fetchData = async () => {
//     try {
//       const result = await axios.post(`/UserProfile/GetOwnInfo`, {
//         sessionId,
//         deviceType: "Web",
//       });
//       const response = result.data.responseData;
//       setData(response);

//       const hasModalShown = sessionStorage.getItem('hasModalShown');
//       sessionStorage.setItem('hasModalShown', 'true');

//       if (!hasModalShown && (response?.dob === null || response?.genderId === null || response?.cityID === null)) {
//         setShowUserAuthModal(true);
//         document.body.classList.add('modal-open'); // Disable scroll when modal is open
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [sessionId]);

//   useEffect(() => {
//     if (!showUserAuthModal) {
//       document.body.classList.remove('modal-open'); // Ensure scroll is enabled if modal is closed
//     }
//   }, [showUserAuthModal]);

//   const refreshAds = () => {
//     console.log('Refreshing ads...');

//     if (adContainerRef.current) {
//       adContainerRef.current.innerHTML = ""; // Clear previous ads
//       const newAd = document.createElement("ins");
//       newAd.className = "adsbygoogle";
//       newAd.style = "display: block; width: 100%; max-width: 250px; min-height: 50px; margin: 0 auto;";
//       newAd.setAttribute("data-ad-client", "ca-pub-8691062301030113");
//       newAd.setAttribute("data-ad-slot", "7349331462"); // Use the same slot for now
//       newAd.setAttribute("data-ad-format", "fluid");
//       newAd.setAttribute("data-full-width-responsive", "true");

//       // Append the new ad element to the container
//       adContainerRef.current.appendChild(newAd);

//       // Reinitialize the AdSense ad
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     }
//   };
//   useEffect(() => {
//     refreshAds(); // Initial ad render
//   }, []);
//   useEffect(() => {
//     console.log('Adsbygoogle:', window.adsbygoogle); // Check if adsbygoogle is defined
//   }, []);
//   return (
//     <>
//       <Helmet>
//         <title>WeLink Jobs NewsFeed | Best Social Media Platform For Job Search</title>
//         <meta name="description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
//         <meta name="keywords" content="newsfeed, updates, latest news" />
//         <meta property="og:title" content="WeLink Jobs | Best Social Media Platform For Job Search" />
//         <meta property="og:description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
//         <meta property="og:url" content="https://welinkjobs.com/NewsFeeds" />
//         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
//       </Helmet>
//       <div className="container-fluid">
//         <div className="row mt-3">
//           <div className="col-md-8 newsfeedPosts">
//             <AllPost onPostsLoaded={refreshAds} />
//           </div>
//           <div className="col-md-4 feedAdvertise">
//             <div className="feedfixedContainer">
//               <h1>New Update</h1>
//               <div className="featuredScroll">
//                 <div className="advertisementview">
//                   <div
//                     className="advertisementContainer"
//                     ref={adContainerRef} // Reference to the ad container
//                   >
//                     {/* The ad will be inserted here */}
//                   </div>
//                   <h1 className="adText">Advertisement</h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div id="userModal">
//         {showUserAuthModal && (
//           <UserAuth
//             onClose={handleCloseUserAuthModal}
//             data={data}
//             showUserAuthModal={showUserAuthModal}
//             setShowUserAuthModal={setShowUserAuthModal}
//             fetchData={fetchData}
//           />
//         )}
//       </div>
//     </>
//   );
// };

// export default NewsFeed;




import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/newsFeed.css";
import AllPost from "./AllPost";
import FeedAdvertise from "./FeedAdvertise";
import UserAuth from "../Auth/UserAuth";
import { GetProfileInfo } from '../../Services/Service'
import axios from "../../API/axios";
import ModalComponent from "./ModalComponent";
import { Helmet } from "react-helmet";

const NewsFeed = () => {
  const [data, setData] = useState(null);
  const [showUserAuthModal, setShowUserAuthModal] = useState(false);
  const sessionId = localStorage.getItem('sessionId');

  const handleCloseUserAuthModal = () => {
    setShowUserAuthModal(false);
    document.body.classList.remove('modal-open'); // Re-enable scroll when modal is closed
  };

  const fetchData = async () => {
    try {
      const result = await axios.post(`/UserProfile/GetOwnInfo`, {
        sessionId,
        deviceType: "Web",
      });
      const Response = result.data.responseData;
      setData(Response);

      const hasModalShown = sessionStorage.getItem('hasModalShown');
      sessionStorage.setItem('hasModalShown', 'true');

      if (!hasModalShown && (Response?.dob === null || Response?.genderId === null || Response?.cityID === null)) {
        // Show the modal if user data is incomplete
        setShowUserAuthModal(true);
        document.body.classList.add('modal-open'); // Disable scroll when modal is open
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sessionId]);

  useEffect(() => {
    if (!showUserAuthModal) {
      document.body.classList.remove('modal-open'); // Ensure scroll is enabled if modal is closed
    }
  }, [showUserAuthModal]);


  const adInitialized = useRef(false);
  const adContainerRef = useRef(null);

  const refreshAds = () => {
    console.log('Refreshing ads...');

    if (adContainerRef.current) {
      adContainerRef.current.innerHTML = ""; // Clear previous ads
      const newAd = document.createElement("ins");
      newAd.className = "adsbygoogle";
      newAd.style = "display: block; width: 100%; max-width: 250px; min-height: 50px; margin: 0 auto;";
      newAd.setAttribute("data-ad-client", "ca-pub-8691062301030113");
      newAd.setAttribute("data-ad-slot", "7349331462"); // Use the same slot for now
      newAd.setAttribute("data-ad-format", "fluid");
      newAd.setAttribute("data-full-width-responsive", "true");

      // Append the new ad element to the container
      adContainerRef.current.appendChild(newAd);

      // Reinitialize the AdSense ad
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  };
  useEffect(() => {
    refreshAds(); // Initial ad render
  }, []);
  useEffect(() => {
    console.log('Adsbygoogle:', window.adsbygoogle); // Check if adsbygoogle is defined
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
  //   script.crossOrigin = "anonymous";
  //   document.body.appendChild(script);

  //   if (!adInitialized.current) {
  //     window.adsbygoogle = window.adsbygoogle || [];
  //     window.adsbygoogle.push({});
  //     adInitialized.current = true;
  //   }

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>WeLink Jobs NewsFeed | Best Social Media Platform For Job Search</title>
        <meta name="description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
        {/* <meta name="keywords" content="react, meta tags, seo" /> */}
        <meta name="keywords" content="newsfeed, updates, latest news" />
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content="WeLink Jobs | Best Social Media Platform For Job Search" />
        <meta property="og:description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
        {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
        <meta property="og:url" content="https://welinkjobs.com/NewsFeeds" />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-8 newsfeedPosts">
          <AllPost onPostsLoaded={refreshAds} />
          </div>
          <div className="col-md-4 feedAdvertise">
            <div className="feedfixedContainer">
              <div className="featuredScroll">
                <p>Latest code</p>
              <div
                    className="advertisementContainer"
                    ref={adContainerRef} // Reference to the ad container
                  >
                    {/* The ad will be inserted here */}
                  </div>


                {/* <FeedAdvertise /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="userModal">
        {showUserAuthModal && (
          <UserAuth
            onClose={handleCloseUserAuthModal}
            data={data}
            showUserAuthModal={showUserAuthModal}
            setShowUserAuthModal={setShowUserAuthModal}
            fetchData={fetchData}
          />
        )}
      </div>
    </>
  );
};

export default NewsFeed;