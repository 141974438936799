
import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom'
import axios from '../../API/axios';
import toast from 'react-hot-toast';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from 'react-flatpickr'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const CCVAchievement = ({ showCCVAchivement, handleCloseCCVAchivement, ExperienceId, getUserCcv }) => {
    const sessionId = localStorage.getItem("sessionId");
    const [achivement, setAchivement] = useState('')
    const [AchivementDate, setAchivementDate] = useState('')
    const AddAchivement = async () => {
        const data =
        {
            "deviceType": "Web",
            "sessionId": sessionId,
            "ccvExpId": ExperienceId,
            "achievement": achivement,
            "achivementDate": AchivementDate === '' ? '' : moment(AchivementDate).format("YYYY-MM-DD"),
        }
        try {
            await axios.post(`CcvAction/AddCcvExperienceAcheivement`, data).then((resData) => {
                console.log('resData', resData);
                toast.success(resData.data.message)
                getUserCcv()
                handleCloseCCVAchivement()
                setAchivement('')
            });
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (showCCVAchivement) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [showCCVAchivement]);
    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };
    return (
        <>
            {showCCVAchivement && (
                <div className="modal fade show" tabIndex="-1" achivement="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" achivement="document">
                        <div className="modal-content">
                            <div className='setting-header d-flex justify-content-between align-items-center'>
                                <h6>Add Achivement</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer" onClick={handleCloseCCVAchivement}
                                />
                            </div>
                            <Form>
                                <div className='row p-4 settings CreateCompany'>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Achivement</label>
                                        <input type='text'
                                            value={achivement}
                                            onChange={e => setAchivement(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Achivement Date</label>
                                        <Flatpickr className='form-control' value={AchivementDate} onChange={date => setAchivementDate(date[0])} id='dob' options={{
                                            maxDate: "today", // Disables future dates
                                            onReady: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Call the function to stop scroll on year
                                            },
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Also prevent scroll when the picker opens
                                            },
                                        }} />
                                    </div>
                                    {/* <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Certificate Name</label>
                                        <input type='text'
                                            value={cerName}
                                            onChange={e => setCerName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Organization Type</label>
                                        <CreatableSelect
                                            defaultValue={orgId}
                                            onChange={(selectedOption) => setOrgId(selectedOption)}
                                            options={orgData}
                                            isClearable
                                            onCreateOption={(newOption) => {
                                                handleOpenCreateUserCertification()

                                            }}
                                            placeholder={'Select Org Type'}
                                        />
                                    </div>


                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Recognition</label>
                                        <input type='text'
                                            value={recognition}
                                            onChange={e => setRecognition(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Credential Id</label>
                                        <input type='text'
                                            value={credentialId}
                                            onChange={e => setCredentialId(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Location</label>
                                        <CreatableSelect
                                            value={valueLocation}
                                            options={optionsLocation}
                                            optionRenderer={renderOptionLocation}
                                            onOpen={handleOpenLocation}
                                            onChange={handleChangeLocation}
                                            onMenuScrollToBottom={handleMenuScrollToBottomLocation}
                                            onInputChange={handleInputChangeLocation}
                                            isClearable
                                            // onCreateOption={(newOption) => {
                                            //     handleOpenCreateCCVExperience()

                                            // }}
                                            placeholder={'Select Location'}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">City</label>
                                        <Select
                                            defaultValue={cityId}
                                            onChange={(selectedOption) => setCityId(selectedOption)}
                                            options={cityData}
                                            placeholder={'Search and Select City'}
                                            onInputChange={(inputValue) => {
                                                setCitySearch(inputValue);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Description</label>
                                        <textarea id="about" name="about" rows="4" cols="50"
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Issue Date</label>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Expiration Date</label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                    </div>
                                    <div className="col-md-12 mt-2 d-flex ">
                                        <input
                                            type="checkbox"
                                            style={{ width: '40px' }}
                                            //   id={`check-${item.id}`}
                                            //   checked={selectedWorkLevelList.includes(item.id)}
                                            onChange={() => setPresent(true)}
                                        />
                                        <label htmlFor="about">IsExpirable</label>
                                    </div> */}


                                    <div className='text-end mt-4'>
                                        <button type="button" className="mainBtn1"
                                            onClick={AddAchivement}
                                            style={{ width: '170px' }}
                                        >Add Achivement</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default CCVAchievement