import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom';
import ReactSelect from 'react-select';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import axios from '../../API/axios';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import WarningSharpIcon from '@mui/icons-material/WarningSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
const Account = () => {
    const sessionId = localStorage.getItem("sessionId");
    const userUid = localStorage.getItem("userUid");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const options = [
        { value: "Mumbai", label: "Mumbai" },
        { value: "Thane", label: "Thane" },
        { value: "Pune", label: "Pune" },
    ];

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    /************************Deactivate Account   ************************/
    const [show, setShow] = useState(false);
    const DeactivateAccount = () => {
        setShow(false);
    };

    const DeactivateAcc = async () => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
        };
        try {
            await axios.post(`OAuth/DeactivateAccount`, responseData).then(() => {
                setShow(!show);
                console.log('res', responseData);
                // setToastMessage(responseData.data.message);
                // setShowToast(true);
            });
        } catch (error) {
            console.log('error', error);
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    /************************Deactivate Account   ************************/
    /************************Delete Account   ************************/
    const [showDelete, setShowDelete] = useState(false);
    const DeleteAccount = () => {
        setShowDelete(false);
    };

    const DeleteAcc = async () => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
        };
        try {
            await axios.post(`OAuth/DeleteAccount`, responseData).then(() => {
                setShow(!show);
                console.log('res', responseData);
                setToastMessage(responseData.data.message);
                setShowToast(true);
            });
        } catch (error) {
            console.log('error', error);
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    /************************Delete Account   ************************/
    useEffect(() => {
        if (show || showDelete || showModal) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [show, showDelete, showModal]);
    return (
        <>
            <div className='pb-1'>
                <div className='setting-header'>
                    <h4>Manage Account</h4>
                </div>
                <div className='p-3 deactivate'>
                    <div className='d-flex mb-3 align-items-center'>
                        <ErrorSharpIcon className="material-symbols-sharp" />

                        <span className='cardHeader'>Deactivate your account</span>
                    </div>
                    <div>
                        <span >This will deactivate your account</span>
                        <ul className='mt-3'>
                            <li>You’re about to initiate the activity of deactivating your WELINKJOBS account, {firstName} {lastName} with ID {userUid}, and your public profile will be no longer  accessible or viewable on Welinkjobs.com, Welinkjobs for Android. Or Welinkjobs for iOS.</li>
                            <li>After again your successful login deactivated account will be activated and accessible or viewable on <a href='https://welinkjobs.com'>Welinkjobs.com</a> </li>
                        </ul>
                    </div>
                    <div className='text-end'>
                        <button className='deactivatebtn' onClick={() => {
                            setShow(!show);
                        }}>Deactivate account</button>
                    </div>
                </div>
                <div className='p-3 closeAccount'>
                    <div className='d-flex mb-3 align-items-center'>
                        <WarningSharpIcon className="material-symbols-sharp" />
                        <span className='cardHeader'>Delete your account</span>
                    </div>
                    <div>
                        <span >This will Delete your account</span>
                        <ul className='mt-3'>
                            <li>You’re about to initiate the activity of deleting your WELINKJOBS account, {firstName} {lastName} with ID {userUid}, and your public profile will be no longer available on Welinkjobs.com, Welinkjobs for Android. Or Welinkjobs for iOS.</li>
                            <li>You can restore your WELINKJOBS account if it was by mistake deleted for up to 45 days after deletion.
                            </li>
                            {/* <li>You can restore your X account if it was accidentally or wrongfully deactivated for up to 30 days after deactivation.</li> */}
                        </ul>
                    </div>
                    <div className='text-end'>
                        <button className='closebtn' onClick={() => {
                            setShowDelete(!showDelete);
                        }}>Delete account</button>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className='setting-header d-flex justify-content-between align-items-center'>
                                <h6>Confirm to close account</h6>
                                <CloseSharpIcon className="material-symbols-sharp me-2 pointer" onClick={closeModal}
                                />
                            </div>
                            <Form>
                                <div className='row p-4 settings'>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="Username">Current Password <span style={{ color: 'red' }}>*</span></label>
                                        <input type="password" />
                                    </div>
                                    <div className="col-md-12 ">
                                        <label htmlFor="about">Cause <span style={{ color: 'red' }}>*</span></label>
                                        <ReactSelect
                                            className="custom-select"
                                            styles={{
                                                // control: (provided) => ({
                                                //     ...provided,
                                                //     width: 170, 
                                                // }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    // width: 300,

                                                }),
                                            }}
                                            options={options}
                                            placeholder="Address Type"
                                        />
                                    </div>
                                    <div className="col-md-12 ">
                                        <label htmlFor="about">Describe </label>
                                        <textarea id="about" name="about" rows="4" cols="50">
                                        </textarea>
                                    </div>


                                    {/* <div className='text-end'>
                                        <button type='submit' className='mainBtn1 mt-3 text-center'>Update</button>
                                    </div> */}
                                    <div className='text-end mt-4'>
                                        <button type="button" className="closebtn me-3" onClick={closeModal}>Cancel</button>
                                        <button type="button" className="btnDanger">Close account</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )
            }


            {show && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are you sure you want to deactivate account</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        DeactivateAccount();
                                    }}
                                />
                            </div>

                            <div className="text-center mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => DeactivateAcc()}
                                >
                                    Deactivate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showDelete && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are you sure you want to delete account</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        DeleteAccount();
                                    }}
                                />
                            </div>

                            <div className="text-center mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => DeleteAcc()}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default Account