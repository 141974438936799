import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import Footer from './Navigation/Footer';
import Header from './Navigation/Header';
import LeftNavigation from './Navigation/LeftNavigation';

const LeftSidebar = () => {
    const [isDark, setIsDark] = useLocalStorage('isDark', false);

    useEffect(() => {
        document.body.style.backgroundColor = isDark ? '#1a1a1e' : '#F2F2F2';
        // if (isDark) {
        //     import("flatpickr/dist/themes/dark.css");
        // } else {
        //     import("flatpickr/dist/themes/light.css");
        // }
    }, [isDark]);
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen((prevState) => !prevState);
        console.log("Menu is toggling...");
    };
    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true)
        console.log("Menu is toggling...");
    };

    return (
        <div className='app'>
            <Header isDark={isDark} setIsDark={setIsDark} />
            <div className="container-fluid ps-0">
                <div className="row rootLayout desktopView">
                    <div className="col-md-3 left">
                        <LeftNavigation isOpen={isOpen} toggleMenu={toggleMenu} setIsOpen={setIsOpen}
                            openModal={openModal}
                        />
                    </div>
                    <div className="col-md-9 leftSidebarData">
                        <Outlet />
                    </div>
                </div>
                <div className="row rootLayout mobileView">
                    <div className="col-md-12 ">
                        <Outlet />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftSidebar;
