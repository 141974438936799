import React, { useState } from 'react'
import { Form } from 'react-router-dom'
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import axios from '../../API/axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
const ChangePassword = () => {
    const sessionId = localStorage.getItem("sessionId");
    const [display, setDisplay] = useState(false);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };
    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')


    const changepass = async () => {
        setDisplay(true);
        const data = {
            deviceType: "Web",
            sessionId,
            oldPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        };
        console.log("data create exp", data);
        try {
            await axios
                .post(`OAuth/ChangePassword`, data)
                .then((resData) => {
                    console.log("resData crt exp", resData.data.responseData);
                    setToastMessage(resData.data.message);
                    setShowToast(true);
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                });
            setDisplay(false)
        } catch (error) {
            console.log('error', error);
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else if (error.response && error.response.status === 400) {
                setErrorToastMessage(error.response.data.title);
                setShowErrorToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    return (
        <>
            <div className='setting-header'>
                <h4>Change Password</h4>
            </div>
            <Form>
                <div className='row p-4 settings'>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">Current Password <span style={{ color: 'red' }}>*</span></label>
                        {/* <input type="password" value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            invalid={display && currentPassword === ""} />
                        {display && !currentPassword ? (
                            <span className="error_msg_lbl">
                                Please Add Current Password
                            </span>
                        ) : null} */}
                        <div className='d-flex'>
                            <input type={showPassword1 ? "text" : "password"} value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                invalid={display && currentPassword === ""} />

                            <div
                                className="password-toggle"
                                onClick={togglePasswordVisibility1}
                            >
                                {showPassword1 ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        {display && !currentPassword ? (
                            <span className="error_msg_lbl">
                                Please Add Current Password
                            </span>
                        ) : null}
                    </div>
                    <div className="col-md-6 mt-2"></div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">New Password <span style={{ color: 'red' }}>*</span></label>
                        {/* <input type="password" value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            invalid={display && newPassword === ""} />
                        {display && !newPassword ? (
                            <span className="error_msg_lbl">
                                Please Add New Password
                            </span>
                        ) : null} */}
                        <div className='d-flex'>
                            <input type={showPassword2 ? "text" : "password"} value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                invalid={display && newPassword === ""} />

                            <div
                                className="password-toggle"
                                onClick={togglePasswordVisibility2}
                            >
                                {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        {display && !newPassword ? (
                            <span className="error_msg_lbl">
                                Please Add New Password
                            </span>
                        ) : null}
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">Confirm New Password <span style={{ color: 'red' }}>*</span></label>
                        <div className='d-flex'>
                            <input type={showPassword ? "text" : "password"} value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                invalid={display && confirmPassword === ""} />

                            <div
                                className="password-toggle"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        {display && !confirmPassword ? (
                            <span className="error_msg_lbl">
                                Please Add Confirm Password
                            </span>
                        ) : null}

                    </div>
                    <div className='text-end'>
                        <button type='submit' className='mainBtn1 mt-3 text-center' onClick={() => {
                            changepass()
                        }}>Update</button>
                    </div>
                </div>
            </Form>

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default ChangePassword