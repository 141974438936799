
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import { customStyles } from "../../selectStyles";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const CCVAward = ({
  showCCVAward, handleCloseCCVAward, resumeId, getUserCcv
}) => {


  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState("");

  const [display, setDisplay] = useState(false);

  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  //********Location DDL Start  ********//
  const [valueLocation, setValueLocation] = useState(null);
  const [pageLocation, setPageLocation] = useState(0);
  const [typedLocation, setTypedLocation] = useState("");
  const [optionsLocation, setOptionsLocation] = useState([]);
  const [totalPagesLocation, setTotalPagesLocation] = useState(0);
  // console.log('value', value);
  const logValueLocation = debounce((typed) => {
    setTypedLocation(typed);
  }, 250);
  // console.log('typed', typed);
  const addOptionsLocation = () => {
    axios
      .get(
        `Master/GetLocalitiesDDL?search=${typedLocation}&pageNumber=${pageLocation + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response);
        const data = response.data.responseData.localityList.map((item) => ({
          value: item.locationId,
          label: item.locationName,
        }));
        setPageLocation((prevPage) => prevPage + 1);
        setOptionsLocation((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesLocation(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenLocation = () => {
    setPageLocation(1);
  };

  const handleChangeLocation = (valueLocation) => {
    setValueLocation(valueLocation.value);
    setPageLocation(0);
  };

  const handleInputChangeLocation = (typedLocation) => {
    logValueLocation(typedLocation);
    if (!typedLocation) setPageLocation(0);
  };

  const handleMenuScrollToBottomLocation = () => {
    if (pageLocation < totalPagesLocation) {
      addOptionsLocation();
    }
  };

  // const renderOptionLocation = ({
  //   focusedOption,
  //   focusOption,
  //   key,
  //   labelKey,
  //   option,
  //   selectValue,
  //   style,
  //   valueArray,
  // }) => {
  //   // Your rendering logic here
  // };

  useEffect(() => {
    if (typedLocation) {
      addOptionsLocation();
    }
  }, [typedLocation]);

  //********Location DDL End  ********//

  //********Org Type DDL Start  ********//
  const [orgData, setOrgData] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const getOrgType = () => {
    axios
      .get(`Master/GetOrgTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setOrgData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getOrgType();
  }, []);
  //********Org Type DDL End  ********//

  //************************Companied DDL Start  ************************//
  const [valueC, setValueC] = useState(null);
  const [pageC, setPageC] = useState(0);
  const [typeC, setTypeC] = useState("");
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [optionsC, setOptionsC] = useState([]);
  const [totalPagesCom, setTotalPagesCom] = useState(0);

  const logValueC = debounce((typeC) => {
    setTypeC(typeC);
  }, 250);

  const Companies = () => {
    setIsLoadingC(true);
    axios
      .get(
        `Master/GetCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response);
        const data = response?.data?.responseData?.orgList.map((item) => ({
          value: item.uiDno,
          label: item.name,
        }));
        setPageC((prevPage) => prevPage + 1);
        setIsLoadingC(false);
        setOptionsC((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesCom(response.data.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingC(false);
      });
  };

  const handleOpenC = () => {
    setPageC(1);
  };

  const handleChangeC = (value) => {
    setValueC(value.value);
    setPageC(0);
  };

  const handleInputChangeC = (typed) => {
    logValueC(typed);
    if (!typed) setPageC(0);
  };

  const handleMenuScrollToBottomC = () => {
    if (pageC < totalPagesCom) {
      Companies();
    }
  };

  const renderOptionC = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typeC) {
      Companies();
    }
  }, [typeC]);

  //************************ Companies DDL End  ************************//

  //********Institution DDL Start  ********//
  const [valueIns, setValueIns] = useState(null);
  const [pageIns, setPageIns] = useState(0);
  const [typedIns, setTypedIns] = useState("");
  const [optionsIns, setOptionsIns] = useState([]);
  const [totalPagesIns, setTotalPagesIns] = useState(0);
  const logValueIns = debounce((typedIns) => {
    setTypedIns(typedIns);
  }, 250);
  // console.log('typed', typed);
  const addOptionsIns = () => {
    axios
      .get(
        `Master/GetInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response.data.responseData.orgList);
        const data = response.data.responseData.orgList.map((item) => ({
          value: item.uiDno,
          label: item.name,
        }));
        setPageIns((prevPage) => prevPage + 1);
        setOptionsIns((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesIns(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenIns = () => {
    setPageIns(1);
  };

  const handleChangeIns = (valueIns) => {
    if (valueIns) {
      setValueIns(valueIns.value);
    } else {
      setValueIns(""); // or handle the clear case appropriately
    }

    setPageIns(0);
  };

  const handleInputChangeIns = (typedIns) => {
    logValueIns(typedIns);
    if (!typedIns) setPageIns(0);
  };

  const handleMenuScrollToBottomIns = () => {
    if (pageIns < totalPagesIns) {
      addOptionsIns();
    }
  };

  const renderOptionIns = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typedIns) {
      addOptionsIns();
    }
  }, [typedIns]);

  //********Institution DDL End  ********//



  //********General Org DDL Start  ********//
  const [valuegnr, setValuegnr] = useState(null);
  const [pagegnr, setPagegnr] = useState(0);
  const [typedgnr, setTypedgnr] = useState("");
  const [optionsgnr, setOptionsgnr] = useState([]);
  const [totalPagesgnr, setTotalPagesgnr] = useState(0);
  const logValuegnr = debounce((typedgnr) => {
    setTypedgnr(typedgnr);
  }, 250);
  console.log('typed', valuegnr);
  const addOptionsgnr = () => {
    axios
      .get(
        `Master/GetAllOrganizationsDDL?search=${typedgnr}&pageNumber=${pagegnr + 1
        }&pageSize=100&DeviceType=Web`
      )
      .then((response) => {
        console.log(response.data.responseData.orgList);
        const data = response.data.responseData.list.map((item) => ({
          value: item.uiDno,
          label: item.name,
          typeId: item.typeId,
          isOrgVrfd: item.isVerified,
          isOrgRgd: item.isRgstrd,
        }));
        setPagegnr((prevPage) => prevPage + 1);
        setOptionsgnr((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesgnr(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpengnr = () => {
    setPagegnr(1);
  };



  const handleChangegnr = (valuegnr) => {
    if (valuegnr) {
      setValuegnr(valuegnr.value);
      setOrgType(valuegnr.typeId)
    } else {
      setValuegnr(""); // or handle the clear case appropriately
      setOrgType("")
    }

    setPagegnr(0);
  };

  const handleInputChangegnr = (typedgnr) => {
    logValuegnr(typedgnr);
    if (!typedgnr) setPagegnr(0);
  };

  const handleMenuScrollToBottomgnr = () => {
    if (pagegnr < totalPagesgnr) {
      addOptionsgnr();
    }
  };

  const renderOptiongnr = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typedgnr) {
      addOptionsgnr();
    }
  }, [typedgnr]);

  //********General Org DDL End  ********//

  const addReset = () => {
    setCerName('')
    setRecognition('')
    setOrgId(null)
    setCredentialId('')
    setDescription('')
    setCityId(null)
    setStartDate('')
    setPresent(false)
    setEndDate('')
  }

  const sessionId = localStorage.getItem("sessionId");
  const [cerName, setCerName] = useState("");
  const [recognition, setRecognition] = useState("");
  const [startDate, setStartDate] = useState("");
  const [present, setPresent] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [credentialId, setCredentialId] = useState("");
  const [orgType, setOrgType] = useState("");
  const [orgName, setOrgName] = useState("");

  const AddAward = async () => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmId: resumeId,
      name: cerName,
      recognition: recognition,
      issuingOrgType: orgType,
      orgId: valuegnr,
      description: description,
      issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
      // "locationId": valueLocation === '' ? '' : valueLocation,
      locationId: null,
      cityId: cityId === "" ? "" : cityId,
    };
    if (cerName === '' || orgType === '' || valuegnr === null || cityId === null || startDate === '') {
      setErrorToastMessage('Please fill all the required fields');
      setShowErrorToast(true);
    } else {
      try {
        await axios.post(`ResumeData/AddAwardInfo`, data).then((resData) => {
          console.log("resData", resData);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserCcv();
          handleCloseCCVAward();
          addReset()
        });
      } catch (error) {
        console.log(error);
        // toast.error(error.response.data.message);
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };

  const CreateAward = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      gnrlOrgName: orgName,
      rsmId: resumeId,
      // issuingOrgType: orgId,
      type: orgId,
      logoImage: "",
      //   locationId: valueLocation === "" ? "" : valueLocation,
      locationId: null,
      cityId: cityId === "" ? "" : cityId,
      name: cerName,
      recognition: recognition,
      description: description,
      issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
    };
    if (cerName === '' || orgName === '' || orgId === null || cityId === null || startDate === '') {
      setErrorToastMessage('Please fill all the required fields');
      setShowErrorToast(true);
    } else {
      try {
        await axios
          .post(`ResumeData/CreateAndAddRsmAwardInfo`, data)
          .then((resData) => {
            console.log("resData", resData);
            // toast.success(resData.data.message);
            setToastMessage(resData.data.message);
            setShowToast(true);
            getUserCcv();
            handleCloseCreateUserAward();
            handleCloseCCVAward();
            addReset()
          });
      } catch (error) {
        console.log(error);
        // toast.error(error.response.data.message);
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };

  const [createUserAward, setCreateUserAward] = useState(false);

  const handleOpenCreateUserAward = () => setCreateUserAward(true);
  const handleCloseCreateUserAward = () => setCreateUserAward(false);



  useEffect(() => {
    if (showCCVAward || createUserAward) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showCCVAward, createUserAward]);

  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      {showCCVAward && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Award</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-3 pointer"
                  onClick={() => {
                    handleCloseCCVAward()
                    addReset()
                  }}
                />
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Award Name<span className='text-danger ms-2'>*</span></label>
                    <input
                      type="text"
                      value={cerName}
                      onChange={(e) => setCerName(e.target.value)}
                      invalid={display && cerName === ""}
                    />
                    {display && !cerName ? (
                      <span className="error_msg_lbl">
                        Enter Award Name{" "}
                      </span>
                    ) : null}
                  </div>

                  {/* <div className="col-md-12 mt-2">
                    <label htmlFor="about">Organization Type<span className='text-danger ms-2'>*</span></label>
                    <CreatableSelect
                      defaultValue={orgId}
                      onChange={(selectedOption) =>
                        setOrgId(selectedOption.value)
                      }
                      options={orgData}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateUserAward();
                        handleCloseCCVAward()
                      }}
                      placeholder={"Select Org Type"}
                      invalid={display && orgId === ""}
                    />
                    {display && !orgId ? (
                      <span className="error_msg_lbl">
                        Enter Organization Type{" "}
                      </span>
                    ) : null}
                  </div> */}

                  {/* <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Organization Type
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={orgId}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setOrgId(selectedOption.value);
                        } else {
                          setOrgId(""); // or handle the clear case appropriately
                        }
                      }}
                      options={orgData}
                      isClearable
                      placeholder={"Select Org Type"}
                      invalid={display && orgId === ""}
                    />
                    {display && !orgId ? (
                      <span className="error_msg_lbl">
                        Please Select Organization Type{" "}
                      </span>
                    ) : null}
                  </div>


                  {orgId === 1 ? <div className="col-md-12 mt-2">
                    <label htmlFor="about">Company</label>
                    <CreatableSelect
                      defaultValue={valueC}
                      options={optionsC}
                      optionRenderer={renderOptionC}
                      placeholder={"Search and Select Company"}
                      onOpen={handleOpenC}
                      onChange={handleChangeC}
                      onMenuScrollToBottom={handleMenuScrollToBottomC}
                      onInputChange={handleInputChangeC}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateUserAward();
                        handleCloseCCVAward()
                        addReset()
                      }}
                    />
                  </div> : orgId === 2 ? <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Institution
                    </label>
                    <CreatableSelect
                      defaultValue={valueIns}
                      options={optionsIns}
                      optionRenderer={renderOptionIns}
                      onOpen={handleOpenIns}
                      onChange={handleChangeIns}
                      onMenuScrollToBottom={handleMenuScrollToBottomIns}
                      onInputChange={handleInputChangeIns}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateUserAward();
                        handleCloseCCVAward()
                        addReset()
                      }}
                      placeholder={"Search and Select Institution"}
                    />
                  </div> : orgId === 3 ? <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      General Organization
                    </label>
                    <CreatableSelect
                      defaultValue={valuegnr}
                      options={optionsgnr}
                      optionRenderer={renderOptiongnr}
                      onOpen={handleOpengnr}
                      onChange={handleChangegnr}
                      onMenuScrollToBottom={handleMenuScrollToBottomgnr}
                      onInputChange={handleInputChangegnr}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateUserAward();
                        handleCloseCCVAward()
                        addReset()
                      }}
                      placeholder={"Search and Select General Org"}
                    />
                  </div> : ''} */}
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Organization
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <CreatableSelect
                      defaultValue={valuegnr}
                      options={optionsgnr}
                      optionRenderer={renderOptiongnr}
                      onOpen={handleOpengnr}
                      onChange={handleChangegnr}
                      onMenuScrollToBottom={handleMenuScrollToBottomgnr}
                      onInputChange={handleInputChangegnr}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateUserAward();
                        handleCloseCCVAward();
                        addReset()
                      }}
                      className="iconsDropdown"
                      placeholder={"Search and Select Org"}
                      styles={customStyles}
                      formatOptionLabel={(option) => (
                        <div>
                          {option.label}
                          {option.isOrgVrfd === true ? (
                            <img src={resume.verified} width={15} className="ms-1" />
                          ) : option.isOrgRgd === true ? (
                            <img src={resume.register} width={15} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={15} className="ms-1" />
                          )}
                        </div>
                      )}
                    />
                    {display && !valuegnr ? (
                      <span className="error_msg_lbl">
                        Please Select Organization{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Recognition
                      {/* <span className='text-danger ms-2'>*</span> */}
                    </label>
                    <input
                      type="text"
                      value={recognition}
                      onChange={(e) => setRecognition(e.target.value)}
                    // invalid={display && recognition === ""}
                    />
                    {/* {display && !recognition ? (
                      <span className="error_msg_lbl">
                        Enter Recognition{" "}
                      </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">City<span className='text-danger ms-2'>*</span></label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      invalid={display && cityId === ""}
                      styles={customStyles}
                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">
                        Enter City{" "}
                      </span>
                    ) : null}
                  </div>


                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Issue Date<span className='text-danger ms-2'>*</span></label>

                    <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])}
                      id='dob' options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }} />
                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Issue Date{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">Description
                      {/* <span className='text-danger ms-2'>*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="4"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    // invalid={display && description === ""}
                    ></textarea>
                    {/* {display && !description ? (
                      <span className="error_msg_lbl">
                        Enter Description{" "}
                      </span>
                    ) : null} */}
                  </div>
                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleCloseCCVAward}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={AddAward}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
      {createUserAward && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Create Award</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseCreateUserAward()
                    addReset()
                  }}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Award Name<span className='text-danger ms-2'>*</span></label>
                    <input
                      type="text"
                      value={cerName}
                      onChange={(e) => setCerName(e.target.value)}
                      invalid={display && cerName === ""}
                    />
                    {display && !cerName ? (
                      <span className="error_msg_lbl">
                        Enter Award Name{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Organization Type
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={orgId}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setOrgId(selectedOption.value);
                        } else {
                          setOrgId(""); // or handle the clear case appropriately
                        }
                      }}
                      options={orgData}
                      isClearable
                      placeholder={"Select Org Type"}
                      invalid={display && orgId === ""}
                      styles={customStyles}
                    />
                    {display && !orgId ? (
                      <span className="error_msg_lbl">
                        Please Select Organization Type{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Organization Name<span className='text-danger ms-2'>*</span></label>
                    <input
                      type="text"
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      invalid={display && orgName === ""}
                    />
                    {display && !orgName ? (
                      <span className="error_msg_lbl">
                        Enter Organization Name{" "}
                      </span>
                    ) : null}
                  </div>
                  {/* <div className="col-md-6 mt-2">
                    <label htmlFor="about">Organization Type<span className='text-danger ms-2'>*</span></label>
                    <input
                      type="text"
                      value={orgType}
                      onChange={(e) => setOrgType(e.target.value)}
                      invalid={display && orgType === ""}
                    />
                    {display && !orgType ? (
                      <span className="error_msg_lbl">
                        Enter Organization Type{" "}
                      </span>
                    ) : null}
                  </div> */}

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Recognition
                      {/* <span className='text-danger ms-2'>*</span> */}
                    </label>
                    <input
                      type="text"
                      value={recognition}
                      onChange={(e) => setRecognition(e.target.value)}
                    // invalid={display && recognition === ""}
                    />
                    {/* {display && !recognition ? (
                      <span className="error_msg_lbl">
                        Enter Recognition{" "}
                      </span>
                    ) : null} */}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">City<span className='text-danger ms-2'>*</span></label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) => setCityId(selectedOption.value)}
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      invalid={display && cityId === ""}
                      styles={customStyles}

                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">
                        Enter City{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Issue Date<span className='text-danger ms-2'>*</span></label>

                    <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                      maxDate: "today",
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      }, // Disables future dates
                    }} />
                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please select Issue Date{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">Description
                      {/* <span className='text-danger ms-2'>*</span> */}
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="4"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    // invalid={display && description === ""}
                    ></textarea>
                    {/* {display && !description ? (
                      <span className="error_msg_lbl">
                        Enter Description{" "}
                      </span>
                    ) : null} */}
                  </div>

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={CreateAward}
                      style={{ width: "170px" }}
                    >
                      Create Award
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default CCVAward;

