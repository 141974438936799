import React from 'react'
// import FloatingLabelInput from 'react-floating-label-input';
import { Form } from 'react-router-dom';
// import FloatingSelectInput from '../FloatingSelectInput';
// import Select from 'react-select';
import ReactSelect from 'react-select';

const GeneralSetting = () => {
    const options = [
        { value: "Mumbai", label: "Mumbai" },
        { value: "Thane", label: "Thane" },
        { value: "Pune", label: "Pune" },
    ];
    return (
        <>
            <div className='setting-header'>
                <h4>General Setting</h4>
            </div>
            <Form>
                <div className='row p-4 settings'>
                    <div className="col-md-6 ">
                        <label htmlFor="Username">Username <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="col-md-6 "></div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">First Name <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="col-md-6 mt-2">

                        <label htmlFor="Username">Last Name<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="col-md-6 mt-2 selectdropdown">
                        <label htmlFor="Username">Country <span style={{ color: 'red' }}>*</span></label>
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Country"
                        />
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">Location</label>
                        <input type="text" />
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">DOB <span style={{ color: 'red' }}>*</span></label>
                        <input type="date" />
                    </div>
                    <div className="col-md-6 mt-2"></div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">Email Address <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="Username">Phone</label>
                        <input type="text" />
                    </div>
                    <div className='text-end'>
                        <button type='submit' className='mainBtn1 mt-3 text-center'>Update</button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default GeneralSetting