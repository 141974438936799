import React from 'react'
import { NavLink } from 'react-router-dom'

const GuestModeFooter = () => {
    return (
        <>
            <div className='footerGuest p-2'>
                <a>To Stay connected for Jobs & More, <NavLink to="/SignUp" className='fw-bold'>Create an account</NavLink>  OR <span>
                    <NavLink to="/SignIn">
                        <button type="button" className="mainBtn p-2" >
                            Sign In
                        </button>
                    </NavLink>

                </span></a>
            </div>
        </>
    )
}

export default GuestModeFooter