import React from 'react'
import '../../assets/css/ResumeDesign.css'
import { Col, Row } from 'react-bootstrap'
import { resume } from "../../assets/Images/ImagesLink";


const Resume = () => {
	return (
		<>
			<div className="main-container-resume">
				<Row>
					<Col md={6}>
						<div className="userProfile">
							<div className="logo">
								<img src={resume.logo} alt="" srcset="" />
							</div>
							<div className="userNameText mt-3">
								<h1>Akhtar</h1>
								<div className="subText userNameText mb-3"><h1>Ali Khan</h1></div>
							</div>
							<div className="emptyCont mt-2 mb-3"></div>
							
							<div className="userImage">
								<img src={resume.profileImg} alt="" srcset="" />
							</div>
							<div className="mainText mt-3"><h1>PROFILE</h1></div>
							<div className="emptyCont mt-2 mb-2"></div>

							<div className="ProfileText mt-3"><h1>Project Development Head at</h1></div>
							<div className="companyText"><h4>MICROHIND Networks and Technologies PVT LTD</h4></div>
							<div className="addText"><h6>Mumbai, Maharashtra, India</h6></div>
						</div>

						<div className="education-container">

							<div className="mainText mt-3"><h1>EDUCATION</h1></div>
							<div className="emptyCont mt-1 mb-3"></div>

							<div className="collegeCont d-flex align-items-center">
								<div className="collegeLogo me-3">
									<img src={resume.medal} alt="" srcset="" />
								</div>
								<div className="educationBottom mb-2">
									<div className="engCollegeText">
										<h1>Shah and Anchor Kurchi Engineering College</h1>
									</div>
									<div className="degreeText">
										<h4>Bachelor of Engineering (BE), Information Technology 2014</h4>
									</div>
								</div>
							</div>

							<div className="collegeCont d-flex align-items-center">
								<div className="collegeLogo ">
									<img src={resume.degree} alt="" srcset="" />
								</div>
								<div className="educationBottom">
									<div className="engCollegeText">
										<h1>M.H. SABOO Siddik polytechinc, Byculla</h1>
									</div>
									<div className="degreeText degSubCont">
										<h4>DIPLOMA, Information Technology 2010 - 2014</h4>
									</div>
								</div>
							</div>

							<div className="collegeCont d-flex align-items-center">
								<div className="collegeLogo me-3">
									<img src={resume.book} alt="" srcset="" />
								</div>
								<div className="educationBottom">
									<div className="engCollegeText">
										<h1>AKI-VIKHROLI</h1>
									</div>
									<div className="degreeText schoolText">
										<h4>SSC, High School 2006 - 2009</h4>
									</div>
								</div>
							</div>

						</div>

						<div className="education-container">
							<div className="mainText mt-3"><h1>FOLLOW</h1></div>
							<div className="emptyCont mt-3 mb-3"></div>
							<div className="collegeCont d-flex align-items-baseline">
								<div className="collegeLogo me-3">
									<img src={resume.facebook} alt="" srcset="" />
								</div>
								<div className="engCollegeText customText mb-3">
									<h1>Facebook \ Profile</h1>
								</div>
							</div>

							<div className="collegeCont d-flex align-items-baseline">
								<div className="collegeLogo me-3">
									<img src={resume.instagram} alt="" srcset="" />
								</div>
								<div className="engCollegeText customText">
									<h1>Instagram \ Profile</h1>
								</div>
							</div>
						</div>
					</Col>

					<Col md={6} className='p-0'>
						<div className="userDetailContainer">

							<div className="contact">
								<div className="contactText"><h1>CONTACT</h1></div>
								<div className="emptyCont mt-3 mb-2"></div>
								<div className="mobileNumber mt-3"><h4>9029321221 <span className='homeText'>(Home)</span></h4></div>
								<div className="mobileNumber email "><h4>akthar_alikhan@hotmail.com</h4></div>

								<div className="mobileNumber mt-3"><h4>www.linkdin.com/in/</h4></div>
								<div className="mobileNumber email "><h4>imakhtaralikhan<span className='homeText'>(Linkdin)</span></h4></div>


							</div>

							<div className="summary">
								<div className="contactText mt-4"><h1>SUMMARY</h1></div>
								<div className="emptyCont mt-2 mb-2"></div>
								<div className="summaryText"><h4>Do whateveer , that makes your <span className='soulText'>SOUL</span> better..</h4></div>
							</div>

							<div className="skills">
								<div className="contactText mt-4"><h1>TOP SKILLS</h1></div>
								<div className="emptyCont mt-2 mb-2"></div>
								<ul className='skillDetail'>
									<li className='listStyle'>Software Development</li>
									<li className='listStyle'>Web Development</li>
									<li className='listStyle'>Brand Management</li>
								</ul>
							</div>

							<div className="workExperience">
								<div className="contactText mt-4"><h1>WORK EXPERIENCE</h1></div>
								<div className="emptyCont mt-3 mb-3"></div>
								<div className="companyName">
									<h1><span className='companytxt'>MICROHIND</span> Networks and Technologies </h1>
									<h2 className='workSubText' >Bussiness Development Head </h2>
									<div className="subText"><h3>January 2017- Present (5 years 8 months)</h3></div>
									<h6>Mumbai Area, India</h6>
								</div>
							</div>

							<div className="skillsExpertize">
								<div className="contactText mt-4"><h1>SKILLZ AND EXPERTIZE</h1></div>
								<div className="emptyCont emptyLine mt-3 mb-3"></div>
								<div className="skillsListCont d-flex">
									<ul className='skillList me-4'>
										<li className='skillData'>Photoshop</li>
										<li>Illustrator</li>
										<li>Dreamweaver</li>
										<li>AfterEffects</li>
										<li>Ms Word</li>
										<li>Ms Exel</li>
									</ul>
									<ul className='skillList '>
										<li className='skillData'>Flexibily</li>
										<li>Personality</li>
										<li>Work in Group</li>
										<li>Creativity</li>
										<li>Ms Word</li>
										<li>Ms Exel</li>
									</ul>
								</div>
							</div>

							<div className="skillsExpertize hobbies">
								<div className=" contactText mt-4"><h1>HOBBIES AND INTERESTS</h1></div>
								<div className="emptyCont emptyLine mt-3 mb-3"></div>
								<div className="hobbies-container">
									<div className="logoContainer d-flex justify-content-around align-items-end">

										<div className="hobbies-cont">
											<div className="Logo"><img src={resume.cycle} alt="cycle" srcset="" /></div>
											<div className="hobbiesText"><h1>Cycle</h1></div>
										</div>
										<div className="hobbies-cont">
											<div className="Logo"><img src={resume.bag} alt="bag" srcset="" /></div>
											<div className="hobbiesText"><h1>Travelling</h1></div>
										</div>
										<div className="hobbies-cont">
											<div className="Logo"><img src={resume.music} alt="music" srcset="" /></div>
											<div className="hobbiesText"><h1>Music</h1></div>
									 	</div>
										<div className="hobbies-cont">
											<div className="Logo"><img src={resume.video} alt="video" srcset="" /></div>
											<div className="hobbiesText"><h1>Video</h1></div>
										</div>
										<div className="hobbies-cont">
											<div className="Logo"><img src={resume.paint} alt="paint" srcset="" /></div>
											<div className="hobbiesText"><h1>Paint</h1></div>
										</div>

										
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default Resume
