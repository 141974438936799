import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const ModalForGuest = ({ closeModalData }) => {
  return (
    <>
      <div
        className="modal modal-md fade show postAdd"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content d-flex justify-content-around">
            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
              <h6 className='mb-0'>To act on Welinkjobs</h6>
              {/* <span
                className="material-symbols-sharp me-2 pointer"
                onClick={closeModalData}
              >
                close
              </span> */}
              <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                  onClick={closeModalData}
                  />
            </div>

            <div
              className="card-body d-flex flex-wrap justify-content-center p-3 f-14"
              id="container"
            >
              <div>
                <p>You are exploring as guest mode.!!!</p>
                <p className='mb-3'>Once you join Welinkjobs, you can do more. to Stay connected for Jobs & More.</p>
                <a>To Stay connected for Jobs & More, <NavLink to="/SignUp" className='fw-bold'>Create an account</NavLink>  OR <div className="mt-3">
                  <NavLink to="/SignIn">
                    <button type="button" className="mainBtn">
                      Sign In
                    </button>
                  </NavLink>

                </div></a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalForGuest