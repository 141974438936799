
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/popover";
import SignIn from "./Views/SignIn";
import SignUp from "./Views/SignUp";
import ForgotPassword from "./Views/ForgotPassword";
import Settings from "./Views/Settings";
import RootLayout from "./Views/RootLayout";
import ChangePassword from "./Views/Settings/ChangePassword";
import NewsFeed from "./Views/NewsFeed/NewsFeed";
import Connection from "./Views/Connections/Connection";
import GeneralSetting from "./Views/Settings/GeneralSetting";
import Profile from "./Views/Settings/Profile";
import Account from "./Views/Settings/Account";
import Auth from "./Views/Settings/Auth";
import Address from "./Views/Settings/Address";
import BlockedUser from "./Views/Settings/BlockedUser";
import PageNotifications from "./Views/Settings/PageNotifications";
import Privacy from "./Views/Settings/Privacy";
import ManageSession from "./Views/Settings/ManageSession";
import CreateCompany from "./Views/CreateCompany/CreateCompany";
import Companies from "./Views/Companies/Companies";
import People from "./Views/People/People";
import Resume from "./Views/Resume/Resume";
import PersonalProfile from "./Views/PersonalProfile/PersonalProfile";
import Notification from "./Views/Notification/Notification";
import Business from "./Views/BusinessPage/BusinessPage";
import History from "./Views/Job/History/History";
import JobSearch from "./Views/JobSearch/JobSearch";
import ManageJob from "./Views/ManageJob/ManageJob";
import JobNotification from "./Views/JobNotification/JobNotification";
import LeftSidebar from "./Views/LeftSidebar";
import Message from "./Views/Message/Message";
import TestPage from "./TestPage";
import Post from "./Views/Post/Post";
import Blogs from "./Views/Blogs/Blogs";
import BlogDetailPage from "./Views/Blogs/BlogDetailPage";
import Articles from "./Views/Articles/Articles";
import Editor from "./Views/Editor/Editor";
import UserAuth from "./Views/Auth/UserAuth";
import CustomModal from "./Views/Custome-modal/CustomModal";
import Header from "./Views/Navigation/Header";
import MyCompanies from "./Views/MyCompanies/MyCompanies";
import Skills from "./Views/PersonalProfile/Skills";
import Draft from "./Views/Post/Draft";
import JobDetails from "./Views/JobDetails/JobDetails";
import ManageResume from "./Views/ManageResume/ManageResume";
import PrivacyAndTerms from "./Views/PrivacyAndTerms/PrivacyAndTerms";
import MainPage from "./Views/PrivacyAndTerms/MainPage";
import Contact from "./Views/PrivacyAndTerms/Contact";
import About from "./Views/PrivacyAndTerms/About";
import PrivacyPolicy from "./Views/PrivacyAndTerms/PrivacyPolicy";
import TermsAndCondition from "./Views/PrivacyAndTerms/TermsAndCondition";
import UserAgreement from "./Views/PrivacyAndTerms/UserAgreement";
import ViewResume from "./Views/ManageResume/ViewResume";
import ConfirmAccount from "./Views/ConfirmAccount";
import Interview from "./Views/JobNotification/Interview";
import ScrollToTop from "./ScrollToTop";
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import useLocalStorage from "use-local-storage";
import NotFound from "./NotFound";


const App = () => {
  const [isDark, setIsDark] = useLocalStorage("isDark", false);
  console.log('isDark', isDark);

  useEffect(() => {
    document.body.style.backgroundColor = isDark ? "#1a1a1e" : "#F2F2F2";
    // if (isDark) {
    //   import("flatpickr/dist/themes/dark.css");
    // } else {
    //   import("flatpickr/dist/themes/light.css");
    // }
  }, [isDark]);

  useEffect(() => {
    let linkElement;

    if (isDark) {
      // Remove the light theme if it exists
      linkElement = document.querySelector(`link[href="https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/light.css"]`);
      if (linkElement) {
        document.head.removeChild(linkElement);
      }

      // Add the dark theme
      linkElement = document.createElement("link");
      linkElement.rel = "stylesheet";
      linkElement.href = "https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/dark.css";
      document.head.appendChild(linkElement);
    } else {
      // Remove the dark theme if it exists
      linkElement = document.querySelector(`link[href="https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/dark.css"]`);
      if (linkElement) {
        document.head.removeChild(linkElement);
      }

      // Add the light theme
      linkElement = document.createElement("link");
      linkElement.rel = "stylesheet";
      linkElement.href = "https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/light.css";
      document.head.appendChild(linkElement);
    }

    return () => {
      // Cleanup: remove the theme when the component unmounts or before adding a new one
      if (linkElement) {
        document.head.removeChild(linkElement);
      }
    };
  }, [isDark]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const guestMode = localStorage.getItem("guestMode");

    // Automatically set guestMode for new users without a token
    if (!token && !guestMode) {
      localStorage.setItem("guestMode", "true");
    }
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        // {
        //   path: "/", element: <PrivateRoute
        //     element={NewsFeed}
        //     isProtected={true} // Protected route
        //     redirectTo="/SignIn"
        //   />
        // },
        // {
        //   path: "/NewsFeed",
        //   element: (
        //     <PrivateRoute
        //       element={NewsFeed}
        //       isProtected={true} // NewsFeed is accessible in guest mode
        //     />
        //   ),
        // },
        {
          path: "/",
          element: <Navigate to="/NewsFeed" replace />
        },
        {
          path: "/NewsFeed",
          element: (
            <PrivateRoute
              element={NewsFeed}
              isProtected={false} // Accessible in guest mode
            />
          ),
        },
        {
          path: "/Draft", element:
            <PrivateRoute
              element={Draft}
              isProtected={true} // Protected route
              redirectTo="/SignIn"
            />
        },
        {
          path: "/User/:uid", element: <PrivateRoute
            element={PersonalProfile}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/User/:uid/Skills", element: <PrivateRoute
            element={Skills}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/MyConnections", element: <PrivateRoute
            element={Connection}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/CreateCompany", element: <PrivateRoute
            element={CreateCompany}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/Companies", element: <PrivateRoute
            element={Companies}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/MyCompanies", element: <PrivateRoute
            element={MyCompanies}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/People", element: <PrivateRoute
            element={People}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        // { path: "/Notification", element: <Notification /> },
        {
          path: "/Notification", element: <PrivateRoute
            element={Notification}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/Company/:CompUid", element: <PrivateRoute
            element={Business}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/ManageJob", element: <PrivateRoute
            element={ManageJob}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/settings",
          element: <PrivateRoute
            element={Settings}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />,
          children: [
            { path: "changePassword", element: <ChangePassword /> },
            { path: "general", element: <GeneralSetting /> },
            { path: "profile", element: <Profile /> },
            { path: "account", element: <Account /> },
            { path: "2fAuth", element: <Auth /> },
            { path: "address", element: <Address /> },
            { path: "blockedUser", element: <BlockedUser /> },
            { path: "pageNotification", element: <PageNotifications /> },
            { path: "privacy", element: <Privacy /> },
            { path: "manageSessions", element: <ManageSession /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <LeftSidebar />,
      children: [
        {
          path: "/JobApplications", element: <PrivateRoute
            element={JobNotification}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/CreateCompany", element: <PrivateRoute
            element={CreateCompany}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/Interview", element: <PrivateRoute
            element={Interview}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/Message/:UserId", element: <PrivateRoute
            element={Message}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/ManageResume/:UserId", element: <PrivateRoute
            element={ManageResume}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        },
        {
          path: "/ManageJobs",
          element: (
            <>
              <PrivateRoute
                element={History}
                isProtected={true} // Protected route
                redirectTo="/SignIn"
              />
            </>
          ),
        },
        { path: "/Post/:PostId", element: <Post /> },
      ],
    },
    { path: "/Resume", element: <Resume /> },
    {
      path: "/SignIn",
      element: (
        <PrivateRoute
          element={SignIn}
          isProtected={false} // Only accessible if not logged in or in guest mode
          redirectTo="/"
        />
        // <PrivateRoute
        //   element={<SignIn />}
        //   redirectTo="/"
        // />
      ),
    },
    {
      path: "/SignUp",
      element: (
        <PrivateRoute
          element={SignUp}
          isProtected={false} // Only accessible if not logged in or in guest mode
          redirectTo="/"
        />
      ),
    },
    { path: "/ForgotPassword", element: <ForgotPassword /> },
    { path: "/ConfirmAccount", element: <ConfirmAccount /> },
    {
      path: "/ManageJobs",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          {/* <History /> */}
          <PrivateRoute
            element={History}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        </>
      ),
    },
    {
      path: "/SearchJob",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          {/* <JobSearch /> */}
          <PrivateRoute
            element={JobSearch}
            isProtected={true} // Protected route
            redirectTo="/SignIn"
          />
        </>
      ),
    },
    {
      path: "/JobDetail/:JobId",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          <JobDetails />

        </>
      ),
    },

    {
      path: "/Resume/:UserId",
      element: (
        <>
          <ViewResume />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <About />
        </>
      ),
    },
    {
      path: "/privacyPolicy",
      element: (
        <>
          <PrivacyPolicy />
        </>
      ),
    },
    {
      path: "/termsAndCondition",
      element: (
        <>
          <TermsAndCondition />
        </>
      ),
    },
    {
      path: "/userAgreement",
      element: (
        <>
          <UserAgreement />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <Contact />
        </>
      ),
    },
    {
      path: "/privacyAndTerms",
      element: (
        <>
          <PrivacyAndTerms />
        </>
      ),
    },
    { path: "/TestPage", element: <TestPage /> },

    {
      path: "/Blogs",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          <Blogs />
        </>
      ),
    },
    {
      path: "/Blogs/:slug",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          <BlogDetailPage />
        </>
      ),
    },
    {
      path: "/Blogs/category/:slug",
      element: (
        <>
          <Header isDark={isDark} setIsDark={setIsDark} />
          <Blogs />
        </>
      ),
    },
    {
      path: "/MainPage",
      element: (
        <>
          <MainPage />
        </>
      ),
    },
    { path: "/Articles", element: <Articles /> },
    { path: "/Editor", element: <Editor /> },
    { path: "/UserAuth", element: <UserAuth /> },
    { path: "/CustomModal", element: <CustomModal /> },
    {
      path: "*", // Catch-all route for 404s
      element: <NotFound />, // Redirect to the NotFound component
    },
  ]);

  return (
    <>
      {/* <ScrollToTop /> */}
      <div data-theme={isDark ? "dark" : "light"}>
        <RouterProvider router={router} />
      </div>
    </>
    // <RouterProvider router={router}>
    //   <ScrollToTop /> {/* Place it here inside the RouterProvider */}
    //   <div data-theme={isDark ? "dark" : "light"}>
    //     <RouterProvider router={router} />
    //   </div>
    // </RouterProvider>
  );
};

export default App;
