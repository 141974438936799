import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Form } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from 'react-hot-toast';
import axios from '../../API/axios';
import moment from 'moment';
import Select from "react-select";
import { customStyles } from '../../selectStyles';
import Flatpickr from 'react-flatpickr';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const UpdateCCV = ({ showUpdateCCV, handleCloseCCV, userCcv, getUserCcv }) => {
    // console.log(userCcv?.resumeInfo?.fullName);
    const sessionId = localStorage.getItem("sessionId");
    const [fullName, setFullName] = useState(userCcv?.resumeInfo?.fullName)
    const [email, setEmail] = useState(userCcv?.resumeInfo?.email)
    const [dob, setDob] = useState(userCcv?.resumeInfo?.dob)
    const [phoneNo, setPhoneNo] = useState(userCcv?.resumeInfo?.phoneNumber)
    const [websiteLink, setWebsiteLink] = useState(userCcv?.resumeInfo?.webSiteLink)
    const [summary, setSummary] = useState(userCcv?.resumeInfo?.summary)
    const [title, setTitle] = useState(userCcv?.resumeInfo?.title)

    // Master/GetCountriesDDL?search=India
    const [cityData, setCityData] = useState([])
    const [cityId, setCityId] = useState(null)
    const [citySearch, setCitySearch] = useState(null)
    const getCity = () => {
        axios.get(`Master/GetCitiesDDL?search=${citySearch}`)
            .then(response => {
                const res = response.data.responseData
                const data = res.map(item => ({ value: item.cityId, label: item.cityName }));
                setCityData(data)
            })
            .catch(error => {
                console.log(error);
            }
            );
    };
    useEffect(() => {
        if (citySearch) {
            getCity()
        }
    }, [citySearch])


    const [countryData, setCountryData] = useState([])
    const [countryId, setCountryId] = useState(null)
    const [countrySearch, setCountrySearch] = useState(null)
    const getCountry = () => {
        axios.get(`Master/GetCountriesDDL?search=${countrySearch}`)
            .then(response => {
                const res = response.data.responseData
                const data = res.map(item => ({ value: item.countyId, label: item.countryName, code: item.phoneCode }));
                setCountryData(data)
            })
            .catch(error => {
                console.log(error);
            }
            );
    };
    useEffect(() => {
        if (countrySearch) {
            getCountry()
        }
    }, [countrySearch])


    const handleSubmit = async () => {
        const data =
        {
            "deviceType": "Web",
            "sessionId": sessionId,
            "rsmId": userCcv?.resumeInfo?.resumeId,
            "fullName": fullName || userCcv?.resumeInfo?.fullName,
            "dob": dob === '' ? userCcv?.resumeInfo?.dob : moment(dob).format("YYYY-MM-DD"),
            "title": title || userCcv?.resumeInfo?.title,
            "userImage": "string",
            "currentCityId": cityId,
            "nationalityCountyId": countryId,
            "countryCodeCountryId": countryId,
            "email": email || userCcv?.resumeInfo?.email,
            "phoneNumber": phoneNo || userCcv?.resumeInfo?.phoneNumber,
            "webSiteLink": websiteLink || userCcv?.resumeInfo?.webSiteLink,
            "summary": summary || userCcv?.resumeInfo?.summary
        }
        try {
            await axios.put(`Resume/UpdateResume`, data).then((resData) => {
                // console.log('resData', resData);
                toast.success(resData.data.message)
                getUserCcv()
                handleCloseCCV()
            });
        } catch (error) {
            console.log(error);
            // toast.error(error.response.data.message);
        }
    };
    useEffect(() => {
        if (showUpdateCCV) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';

        }
    }, [showUpdateCCV]);

    useEffect(() => {
        setCountryId(userCcv?.resumeInfo?.countryCodeCountryId)
        setCityId(userCcv?.resumeInfo?.currentCityId)
        setDob(userCcv?.resumeInfo?.dob)
    }, [userCcv?.resumeInfo])

    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };
    return (
        <>
            {showUpdateCCV && userCcv?.resumeInfo && (
                <div className="modal modal-lg fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className='setting-header d-flex justify-content-between align-items-center'>
                                <h6>Update CCV</h6>
                                <CloseSharpIcon className="material-symbols-sharp me-2 pointer" onClick={handleCloseCCV} />

                            </div>
                            <Form>
                                <div className='row p-4 settings CreateCompany'>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Title</label>
                                        <input type='text'
                                            value={title || userCcv?.resumeInfo?.title}
                                            onChange={e => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Full Name</label>
                                        <input type='text'
                                            defaultValue={fullName || userCcv?.resumeInfo?.fullName}
                                            onChange={e => setFullName(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Date Of Birth</label>
                                        {/* <input type='text'
                                        // value={jobTitle}
                                        // onChange={e => setjobTitle(e.target.value)}
                                        /> */}
                                        {/* <DatePicker
                                            selected={dob || userCcv?.resumeInfo?.dob} onChange={(date) => setDob(date)}
                                        /> */}
                                        <Flatpickr className='form-control' value={dob} onChange={date => setDob(date[0])} id='dob' options={{
                                            maxDate: "today", // Disables future dates
                                            onReady: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Call the function to stop scroll on year
                                            },
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Also prevent scroll when the picker opens
                                            },
                                        }} />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Phone No</label>
                                        <input type='text'
                                            value={phoneNo || userCcv?.resumeInfo?.phoneNumber}
                                            onChange={e => setPhoneNo(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Email</label>
                                        <input type='text'
                                            value={email || userCcv?.resumeInfo?.email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">City</label>
                                        <Select
                                            // defaultValue={cityId}
                                            value={cityData.find((option) => option.value === cityId)}
                                            onChange={(selectedOption) => setCityId(selectedOption.value)}
                                            options={cityData}
                                            placeholder={'Search and Select City'}
                                            onInputChange={(inputValue) => {
                                                setCitySearch(inputValue);
                                            }}
                                            styles={customStyles}

                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Country</label>
                                        <Select
                                            // defaultValue={countryId}
                                            value={countryData.find((option) => option.value === countryId)}
                                            onChange={(selectedOption) => setCountryId(selectedOption.value)}
                                            options={countryData}
                                            placeholder={'Search and Select Country'}
                                            onInputChange={(inputValue) => {
                                                setCountrySearch(inputValue);
                                            }}
                                            styles={customStyles}

                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Website Link</label>
                                        <input type='text'
                                            value={websiteLink || userCcv?.resumeInfo?.webSiteLink}
                                            onChange={e => setWebsiteLink(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Summary</label>
                                        <textarea id="about" name="about" rows="4" cols="50"
                                            value={summary || userCcv?.resumeInfo?.summary}
                                            onChange={e => setSummary(e.target.value)}
                                        >
                                        </textarea>
                                    </div>


                                    <div className='text-end mt-4'>
                                        <button type="button" className="mainBtn1"
                                            onClick={handleSubmit}
                                        >Update</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default UpdateCCV