import React, { useEffect, useState } from "react";
import "../../../assets/css/History.css";
import axios from "../../../API/axios";
import { JobSearch, icons } from "../../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
import moment from "moment";
import JobApply from "../../JobSearch/JobApply";

const SavedJobs = ( {
  getAllJobList , 
}) => {
  // erUid = localStorage.getItem("userUid");
  // const [toastMessage, setToastMessage] = useState("");
  // const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
const userUid = localStorage.getItem("userUid");

  const sessionId = localStorage.getItem("sessionId");
  //************************saved jobs Start  ************************//
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [savedJobs, setSavedJobs] = useState([]);

  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        // console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);

  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  console.log('savedJobs', savedJobs);
  const getSavedJobs = (page) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 12
    };
    axios
      .post(`ManageJob/GetSavedJobs`, data)
      .then((response) => {
        console.log("getSavedJobs", response.data.responseData);
        // const res = response.data.responseData;
        // setSavedJobs(res);
        setTotalPages(response.data.responseData.paging.totalPages);
        const newPosts = response.data.responseData.jobsList;
        setSavedJobs((prev) => {
          const postSet = new Set(prev.map((post) => post.jobId));
          const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.jobId));
          return [...prev, ...uniqueNewPosts];
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    if (page <= totalPages) {
      getSavedJobs(page);
    }
  }, [page]);

  const handleInfiniteScroll = async () => {
    try {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  //************************saved jobs End  ************************//
  return (
    <>
      <div className="card-container saved-job-cont">
        <div className="jobCardHeader d-flex justify-content-between">
          <div className="job-card-text">
            <h1 className="f-14 f-600 p-2">Saved Jobs ({savedJobs === undefined ? 0 : savedJobs?.length})</h1>
          </div>
        </div>

        {savedJobs &&
          savedJobs?.map((item, index) => {
            const now = moment();
            const itemTime = moment.utc(item?.creationTime);
            const duration = moment.duration(now.diff(itemTime));

            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();

            let result = '';

            if (years > 0) {
              result = `${years} year${years > 1 ? 's' : ''} ago`;
            } else if (months > 0) {
              result = `${months} month${months > 1 ? 's' : ''} ago`;
            } else if (days > 0) {
              result = `${days} day${days > 1 ? 's' : ''} ago`;
            } else if (hours > 0) {
              result = `${hours} hour${hours > 1 ? 's' : ''} ago`;
            } else if (minutes > 0) {
              result = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            } else {
              result = 'just now';
            }

            return (
              <div className="jobCard p-3  mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Link to={`/JobDetail/${item.jobId}`}>
                    <div
                      className="pointer"
                    // onClick={() => {
                    //   setShowjobDetails(true);
                    //   setJobId(item.jobId);
                    // }}
                    >
                      <img src={item?.compLogo} alt="" width={50} height={50} style={{ borderRadius: '50px' }} />
                      <span className="ms-2">{item.compName}</span>

                    </div>
                  </Link>
                  {/* <JobAction
                    item={item}
                    AllJobList={AllJobList}
                    setJobList={setJobList}
                    jobList={jobList}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    page={page}
                  /> */}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <Link to={`/JobDetail/${item.jobId}`}>
                    <p
                      className="f-16 fw-bold pointer"
                    // onClick={() => {
                    //   setShowjobDetails(true);
                    //   setJobId(item.jobId);
                    // }}
                    >
                      {item.title}
                    </p>
                  </Link>

                </div>
                <p className="f-14">{item.shortDescription}</p>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  {/* <small className="f-8">Posted 3 Days ago</small>
                  <div className="badge badge-date">2 Weeks Left</div> */}
                  <small className="f-8">Posted {result}</small>
                  <div className="badge badge-date">{item?.expiringInDays} days Left</div>
                </div>
                <div className="jobType mt-2">
                  <div className="badge badge-job me-3">
                    {item.jbTypName}
                  </div>
                  <div className="badge badge-job me-3">
                    {item.workLocationTypeName}
                  </div>
                  <div className="badge badge-job">
                    {item.designationName}
                  </div>
                </div>
                <div className=" mt-3" >

                <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={getAllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                </div>
                {/* <div className="d-flex ms-2 mt-2">
                  <div className="circle-container">
                    <img
                      src={icons.default}
                      alt="Circle 1"
                      className="circle-img"
                    />
                    <img
                      src={icons.default}
                      alt="Circle 2"
                      className="circle-img"
                    />
                    <img
                      src={icons.default}
                      alt="Circle 3"
                      className="circle-img"
                    />
                    <img
                      src={icons.plus}
                      alt="Circle 3"
                      className="circle-img"
                    />
                  </div>
                  <div className="text">13 Mutual Connects</div>
                </div> */}
              </div>
            )
          })}


        {/* <div className="seeAllBtn">
          <button>See All</button>
        </div> */}
      </div>
    </>
  );
};

export default SavedJobs;
