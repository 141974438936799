import React, { useEffect, useState } from 'react'
import { JobSearch } from '../../assets/Images/ImagesLink';

const JobFilter = ({
    jobTypeList,
    setSelectedJobTypeData,
    salaryTypeList,
    setSelectedSalaryType,
    workLevelTypeList,
    setSelectedWorkLevel,
    workLocationType,
    setSelectedWorkLocationList,
    setReset
}) => {
    //************************ jobs type filter  ************************//
    const [selectedsinglejobtype, setselectedsinglejobtype] = useState([]);
    const handlejobType = (item) => {
        if (selectedsinglejobtype.includes(item.id)) {
            setReset(true)
            setselectedsinglejobtype(selectedsinglejobtype.filter((id) => id !== item.id));
            // AllJobList();
        } else {
            setReset(true)
            setselectedsinglejobtype([...selectedsinglejobtype, item.id]);
            // AllJobList();
        }
    };
    useEffect(() => {
        setSelectedJobTypeData(selectedsinglejobtype);
    }, [selectedsinglejobtype]);
    //************************ jobs type filter  ************************//


    //************************ salary type filter  ************************//
    const [selectedSalaryTypeList, setSelectedSalaryTypeList] = useState([]);
    const handleSalaryType = (item) => {
        if (selectedSalaryTypeList.includes(item.id)) {
            setReset(true)
            setSelectedSalaryTypeList(selectedSalaryTypeList.filter((id) => id !== item.id));
            // AllJobList();
        } else {
            setReset(true)
            setSelectedSalaryTypeList([...selectedSalaryTypeList, item.id]);
            // AllJobList();
        }

    };
    useEffect(() => {
        setSelectedSalaryType(selectedSalaryTypeList);
    }, [selectedSalaryTypeList]);
    //************************ salary type filter  ************************//


    //************************ work level type filter  ************************//
    const [selectedWorkLevelList, setSelectedWorkLevelList] = useState([]);
    const handleWorkLevelTypeList = (item) => {
        if (selectedWorkLevelList.includes(item.id)) {
            setReset(true)
            setSelectedWorkLevelList(selectedWorkLevelList.filter((id) => id !== item.id));
            // AllJobList();
        } else {
            setReset(true)
            setSelectedWorkLevelList([...selectedWorkLevelList, item.id]);
            // AllJobList();
        }
    };
    useEffect(() => {
        setSelectedWorkLevel(selectedWorkLevelList);
    }, [selectedWorkLevelList]);

    //************************ work level type filter  ************************//


    //************************ work locaion type filter  ************************//
    const [selectedWorkLocation, setSelectedWorkLocation] = useState([]);
    const handleWorkLocation = (item) => {
        if (selectedWorkLocation.includes(item.id)) {
            setReset(true)
            setSelectedWorkLocation(selectedWorkLocation.filter((id) => id !== item.id));
            // AllJobList();
        } else {
            setReset(true)
            setSelectedWorkLocation([...selectedWorkLocation, item.id]);
            // AllJobList();
        }
    };
    useEffect(() => {
        // console.log("selectedWorkLocation", selectedWorkLocation);
        setSelectedWorkLocationList(selectedWorkLocation);
    }, [selectedWorkLocation]);


    //************************ work locaion type filter  ************************//
    return (
        <>
            {/* <div className="py-3 advertiseImage desktopView">
                <img src={JobSearch.ad1} alt="" />
            </div> */}
            <div className="my-4 accordion Jobfilter" id="accordionExample">
                {jobTypeList !== null ? <div className="accordion-item">
                    <h2 className="accordion-header " id="headingOne">
                        <button
                            className="accordion-button f-14"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Job Type List
                        </button>
                    </h2>

                    {jobTypeList !== null
                        ? jobTypeList?.map((item) => (
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                                key={item.id}
                            >

                                <div className="accordion-body pt-0 pb-0">
                                    <ul>
                                        <li className="d-flex justify-content-between mb-1">
                                            <div className="d-flex ">
                                                <div className="form-check form-check-success ps-0 pe-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`check-${item.id}`}
                                                        checked={selectedsinglejobtype.includes(item.id)}
                                                        onChange={() => handlejobType(item)}
                                                    />
                                                </div>
                                                <span className="f-14">{item.name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))
                        : ""}
                </div> : ''}


                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button f-14 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Salary Type List
                        </button>
                    </h2>
                    {salaryTypeList?.map((item) => {
                        return (
                            <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >

                                <div className="accordion-body pt-0 pb-0">
                                    <ul>
                                        <li className="d-flex justify-content-between mb-1">
                                            <div className="d-flex ">
                                                <div className="form-check form-check-success ps-0 pe-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`check-${item.id}`}
                                                        checked={selectedSalaryTypeList.includes(item.id)}
                                                        onChange={() => handleSalaryType(item)}
                                                    />
                                                </div>
                                                <span className="f-14">{item.name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button f-14 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            Work Level Type List
                        </button>
                    </h2>
                    {workLevelTypeList?.map((item) => {
                        return (
                            <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                            >

                                <div className="accordion-body pt-0 pb-0">
                                    <ul>
                                        <li className="d-flex justify-content-between mb-1">
                                            <div className="d-flex ">
                                                <div className="form-check form-check-success ps-0 pe-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`check-${item.id}`}
                                                        checked={selectedWorkLevelList.includes(item.id)}
                                                        onChange={() => handleWorkLevelTypeList(item)}
                                                    />
                                                </div>
                                                <span className="f-14">{item.name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button
                            className="accordion-button f-14 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                        >
                            Work Location
                        </button>
                    </h2>
                    {workLocationType.map((item) => {
                        return (
                            <div
                                id="collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample"
                            >

                                <div className="accordion-body pt-0 pb-0">
                                    <ul>
                                        <li className="d-flex justify-content-between mb-1">
                                            <div className="d-flex ">
                                                <div className="form-check form-check-success ps-0 pe-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`check-${item.id}`}
                                                        checked={selectedWorkLocation.includes(item.id)}
                                                        onChange={() => handleWorkLocation(item)}
                                                    />
                                                </div>
                                                <span className="f-14">{item.name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default JobFilter