import axios from "axios";

// const BASE_URL = `https://account.welinkjobs.com/api`
// export const MessageHub = `https://account.welinkjobs.com/hubs`
const BASE_URL = `https://preprod.welinkjobs.com/api`
export const MessageHub = `https://preprod.welinkjobs.com/hubs`
// const BASE_URL = `https://localhost:7298/api`
// export const MessageHub = `https://localhost:7298/hubs`
export const PublicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2PkhjvWu+lDEv/ane+uVN44MAZBhWn2Xbr5zEu7h9LpXJXhrwKYhtvWR6YmjAR4AcXDwA3P74Hjc8/jsW92Q5B4ddXJrRbsU3lac1GhjCNma31FlW7Mpjr5eqPNTuImJr1WgDR9iRuCFYt4enRkvfywdnDa++QK6fdjS4/kssJxlEXBtlXKoFuSBGjbf0JA56qHo8yqXoYoqgl9Z7e9X8GZv6soB1JDH9dxMmaqEwsxaonDG+8NdR2RcYeJAnx2s/PBokpbCVPCQiSD5mmYWSZePF7L4mqvYS7ByrIj1cBH8qq6TafTcLkrr/TiZjpAADPwuynQDE120BpFaqIEqlQIDAQAB`

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) {
    console.log = () => { };
    console.info = () => { };
    console.warn = () => { };
    console.error = () => { };
    console.debug = () => { };
}

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'access-control-allow-origin': '*',
        // Authorization: `Bearer ${token}`,
        Authorization: ``,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
})

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

export default axiosInstance