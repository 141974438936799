import React from "react";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const EditorTags = () => {
  return (
    <>
      <div className="tags-main-container p-3">
        <div className="tag-main-text f-18 f-600 mb-2">Tags</div>
        <div className="tag-container d-flex justify-content-between p-3 flex-wrap">
          <div className="custom-tag d-flex justify-content-center align-items-center f-14">
            Design
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />
          </div>
          <div className="custom-tag d-flex justify-content-center align-items-center f-12">
            Code
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />

          </div>
          <div className="custom-tag d-flex justify-content-center align-items-center f-12">
            UI/UX
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />

          </div>
          <div className="custom-tag d-flex justify-content-center align-items-center f-12">
            Technology
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />

          </div>
          <div className="custom-tag d-flex justify-content-center align-items-center f-12">
            News Update
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />

          </div>
          <div className="custom-tag d-flex justify-content-center align-items-center f-12">
            Skill
            <CloseSharpIcon
              className="material-symbols-sharp me-2 pointer"
            />

          </div>

        </div>
      </div>
    </>
  );
};

export default EditorTags;
