import React, { useEffect, useRef, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';

// import { Dropdown } from 'react-bootstrap';
// import { icons, generalsetting } from '../assets/Images/ImagesLink'
import '../assets/css/setting.css'
// import { IoIosArrowDown } from "react-icons/io";
const Settings = () => {
    // const sessionId = localStorage.getItem("sessionId");
    const loginType = localStorage.getItem("loginType");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const usernameTag = localStorage.getItem("user-nameTag");
    const [isSecurityOpen, setIsSecurityOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleSecurity = () => {
        setIsSecurityOpen(!isSecurityOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsSecurityOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>

            <div className="row py-2 mt-2">

                <div className="col-md-4">
                    <div className="settingSidebar p-4">

                        <div className="username d-flex">
                            <SettingsSharpIcon className="material-symbols-sharp settingIcon" />

                            <div className='ms-3'>
                                <p className='f-14 mb-0'>{usernameTag}</p>
                                <span className='f-12 innerHeading'>Setting</span>
                            </div>
                        </div>
                        <ul>
                            {/* <NavLink to='general'>
                                <li className='navLinks'><span>General</span></li>
                            </NavLink> */}
                            <NavLink to='profile'>
                                <li className='navLinks'><span>Profile</span></li>
                            </NavLink>
                            {loginType === 'Email' && <NavLink to='changePassword'>
                                <li className='navLinks'><span>Change Password</span></li>
                            </NavLink>}

                            <NavLink to='blockedUser'>
                                <li className='navLinks'><span>Blocked users</span></li>
                            </NavLink>
                            <NavLink to='account'>
                                <li className='navLinks'><span>Account</span></li>
                            </NavLink>
                            {/* <NavLink to='pageNotification'>
                                <li className='navLinks'><span>Notification</span></li>
                            </NavLink> */}
                            {/* <NavLink > */}
                            {/* <li onClick={toggleSecurity} className={`navLinks pointer d-flex justify-content-between  ${isSecurityOpen ? 'securityactive' : ''}`} ><span>Security</span> <IoIosArrowDown className='dropdownIcon' /></li>
                            <ul className='dr' style={{ display: isSecurityOpen ? 'block' : 'none' }}>
                                <NavLink to='privacy'>
                                    <li className='navLinks'><span>Privacy</span></li>
                                </NavLink>
                                <NavLink to='changePassword'>
                                    <li className='navLinks'><span>Password</span></li>
                                </NavLink>
                                <NavLink to='manageSessions'>
                                    <li className='navLinks'><span>Manage Sessions</span></li>
                                </NavLink>
                                <NavLink to='2fAuth'>
                                    <li className='navLinks'><span>2F -  Auth</span></li>
                                </NavLink>
                            </ul> */}
                            {/* </NavLink> */}


                            {/* <NavLink to='address'>
                                <li className='navLinks'><span>Address</span></li>
                            </NavLink> */}

                        </ul>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="settingSidebar">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings

