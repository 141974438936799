import React, { useEffect, useState } from 'react'
import '../../assets/css/Business.css'
import CompanyAction from './CompanyAction'
import axios from '../../API/axios'
import SuccessToast from '../SuccessToast'
import WarningToast from '../WarningToast'
import ErrorToast from '../ErrorToast'
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

const AboutCompany = ({ CompUid, companyDetails }) => {
  const sessionId = localStorage.getItem("sessionId");
  //********Location Start  ********//
  const [locationData, setLocationData] = useState([])
  const getcompanyLocations = () => {
    const data = {
      "deviceType": "Web",
      "compUid": CompUid
    }
    axios.post(`Company/GetAllCompanyLocations`, data)
      .then(response => {
        // console.log(response);
        const res = response.data.responseData
        setLocationData(res)
      })
      .catch(error => {
        console.log(error);
      }
      );
  };
  useEffect(() => {
    getcompanyLocations()
  }, [])
  //********Location End  ********//

  //********Location Delete Start  ********//
  const DeleteLocation = (compLctnId) => {
    const data = {
      "deviceType": "Web",
      "sessionId": sessionId,
      "compLctnId": compLctnId,
      "compUid": CompUid
    }
    console.log(data);
    axios.post(`Company/DeleteCompanyLocation`, data)
      .then(response => {
        console.log(response);
        getcompanyLocations();
        setToastMessage(response.data.message);
        setShowToast(true);
      })
      .catch(e => {
        console.log(e);
        if (e.response && e.response.status === 404) {
          setWarningToastMessage(e.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(e.response.data.message);
          setShowErrorToast(true);
        }
      }
      );
  };

  //********Location Delete End  ********//

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  return (
    <>
      <div className="websiteCont">
        {companyDetails?.website === '' || companyDetails?.website === null ? '' : <div className="UpdateProfile websiteText p-2">
          <p className="f-14 f-700">Website</p>

          <hr />
          <div className=" d-flex circle mt-2 ">
            <a className="f-12 f-400 ms-1 text-content" target='_blank' href={companyDetails?.website?.startsWith('http') ? companyDetails?.website : `http://${companyDetails?.website}`}>
              {companyDetails?.website}
            </a>
          </div>
        </div>}


        <div className="aboutMe p-2">
          <div className="d-flex justify-content-between border-custom pb-2">
            <p className="f-12 f-700">About Company</p>
          </div>
          <p className="f-12 f-400 pt-2 mb-3">{companyDetails?.description}</p>

          <div className="companyCustomText mb-3">
            <h1 className="f-12 f-700">Industry</h1>
            <h6 className="f-10 f-400">
              IT <span>Social Media</span>
            </h6>
          </div>
          <div className="companyCustomText mb-3">
            <h1 className="f-12 f-700">Company size</h1>
            <h6 className="f-10 f-400">Professional</h6>
          </div>
          {console.log('locationData', locationData)
          }
          {locationData.length === 0 ?
            '' : <div className="companyCustomText">
              <h1 className="f-12 f-700">Location</h1>
              {locationData?.map((item, index) => {
                return (
                  <ul className='ps-3'>
                    <li className="f-10 f-400" style={{ listStyle: 'circle' }}>
                      <div className='d-flex justify-content-between align-item-center'>
                        <h6 className="f-12 f-400">{item?.locationName}</h6>
                        {/* <span
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            DeleteLocation(item?.compLctnId)
                          }}
                        >
                          delete
                        </span> */}
                        <DeleteOutlineSharpIcon className="material-symbols-sharp"
                          onClick={() => {
                            DeleteLocation(item?.compLctnId)
                          }} />

                      </div>
                    </li>
                  </ul>
                  // <h6 className="f-10 f-400">{item?.locationName}</h6>
                )
              })}
            </div>
          }
        </div>
      </div>

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  )
}

export default AboutCompany