
import React, { useEffect, useState } from 'react'
import { post } from '../../assets/Images/ImagesLink'
// import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import axios from '../../API/axios';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import { Link } from 'react-router-dom';
import locationIcon from "../../assets/Images/connection/locationIcon.png";
import userAvatar from "../../assets/Images/connection/Group 647.png";
import { ShimmerDiv } from 'shimmer-effects-react';
const Follows = ({ uid, getUserProfileStat }) => {
    const sessionId = localStorage.getItem("sessionId");
    //************************ Follower  List   ************************//
    const [isError, setIsError] = useState(false);
    const [page, setPage] = useState(1);
    const [FollowerList, setFollowerList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    console.log('FollowerList', FollowerList);
    const FollowerListAll = async (id) => {
        const data = {
            deviceType: "Web",
            pageNumber: page,
            pageSize: 100,
            userUid: uid
        };
        try {
            await axios.post(`UserFollow/GetUserEntityFollowingList?DeviceType=Web&Type=Company&UserUid=${uid}`, data).then((resData) => {
                // setFollowerList(resData.data.responseData.viewList);
                console.log("connect data", resData.data.message);


                setIsLoading(false);

                if (resData.data.status === "Warning") {
                    setIsError(true);
                } else {
                    setFollowerList(resData?.data?.responseData);
                }
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.log(error);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        FollowerListAll();
    }, [page]);

    //************************Follower List ************************//
    //************************ Follow User Start  ************************//
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const FollowUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios.post("UserFollow/FollowUser", data).then((resData) => {
                // console.log("resDataaaaa", resData);
                setFollowerList((prev) => prev.filter((item) => item.userUid !== id));
                getUserProfileStat()
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ Follow User End  ************************//


    const FollowCompany = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            uiD_ID: id,
        };
        try {
            await axios.post("Entity/Follow/Company", data).then((resData) => {
                console.log("followed");
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    return (
        <>

            {isLoading ? (
                <div className="mt-3">
                    {/* <ShimmerThumbnail height={250} rounded /> */}
                    <ShimmerDiv mode="light" height={250} width={'100%'} />
                </div>
            ) : isError ? (
                <div className="mt-2 text-center">
                    <img src={post.errorSmall} alt="404 Not Found" width={200} />
                </div>
            ) : (
                FollowerList &&
                FollowerList?.map((item, index) => {
                    console.log('connect dataaaaa', item);
                    return (
                        <>
                            <div className="aboutMe mb-3 p-3" key={index}>
                                <div className="d-flex align-items-center">
                                    <Link to={`/Company/${item.uid}`}>
                                        <div className="ImgAvatar">
                                            <img src={item?.image} alt="" width={60} style={{ borderRadius: '50%' }} />
                                        </div>
                                    </Link>
                                    <div className="ms-3">
                                        <div className="userName">
                                            <Link to={`/Company/${item.uid}`}>
                                                <h1>{item?.name} </h1>
                                            </Link>
                                        </div>
                                        <div className="designation">
                                            <h1>{item?.title}</h1>
                                        </div>
                                        <div className="designation">
                                            <h1>{item?.headLine}</h1>
                                        </div>
                                        <div className="location">
                                            <div>
                                                {item?.location && (
                                                    <>
                                                        <img src={locationIcon} alt="" />
                                                        <span className="locationText">
                                                            {item?.location}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="vectorUserImage">
                                            <img src={userAvatar} alt="" />
                                            <span className="followerText ms-2">
                                                {item?.followersCount} Followers
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='ms-2'>
                                        <Link to={`/Company/${item.uid}`}>
                                            <h4 className='f-14 fw-bold mb-0'>{item?.name}</h4>
                                        </Link>
                                        <p className='f-12'>{item?.title}</p>
                                        <p className='f-12'>{item?.followersCount} Followers </p>
                                    </div> */}
                                    {item?.isFollowedByYou === true ? '' : <div className='ms-auto'>
                                        <button className='mainBtn1' style={{ width: '90px' }} onClick={() => {
                                            FollowCompany(item?.uid)
                                        }} >
                                            Follow</button>
                                    </div>}

                                </div>
                            </div>

                        </>
                    );
                })
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default Follows