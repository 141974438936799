import React from 'react'

const PageNotifications = () => {
    return (
        <>
            <div className='setting-header'>
                <h4>Notification Setting</h4>
            </div>
            <div>
                <div className="notificationTable">
                    <table width='100%'>
                        <thead>
                            <th>Notify me when</th>
                            <th>Web</th>
                            <th>App</th>
                            <th>Email</th>
                            <th>SMS</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Someone liked my posts</td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>Someone commented on my posts</td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>Someone shared on my posts</td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default PageNotifications