import React from 'react'
import ReactSelect from 'react-select';

const Privacy = () => {
    const options = [
        { value: "Mumbai", label: "Mumbai" },
        { value: "Thane", label: "Thane" },
        { value: "Pune", label: "Pune" },
    ];
    return (
        <>
            <div className='setting-header'>
                <h4>Privacy Setting</h4>
            </div>
            <div className='p-3 privacy'>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Who can follow me ?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label htmlFor="about">Address type <span style={{ color: 'red' }}>*</span></label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Who can message me ?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label for="cars">Choose a car:</label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Who can see my friends?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label for="cars">Choose a car:</label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Who can post on my timeline ?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label for="cars">Choose a car:</label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Who can see my birthday?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label for="cars">Choose a car:</label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6">
                        <p>Share my location with public?</p>
                    </div>
                    <div className="col-md-6">
                        {/* <label for="cars">Choose a car:</label> */}
                        <ReactSelect
                            className="custom-select"
                            styles={{
                                // control: (provided) => ({
                                //     ...provided,
                                //     width: 170, 
                                // }),
                                menu: (provided) => ({
                                    ...provided,
                                    // width: 300,

                                }),
                            }}
                            options={options}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className='text-end'>
                    <button type='submit' className='mainBtn1 mt-3 text-center'>Update</button>
                </div>
            </div>


        </>
    )
}

export default Privacy