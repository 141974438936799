import React from 'react'
import MainPage from './MainPage'

const PrivacyAndTerms = () => {
  return (
    <>
    <MainPage/>
    </>
  )
}

export default PrivacyAndTerms
