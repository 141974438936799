import React from 'react'

const CoverArticle = () => {
  return (
    <>
    <div className="cover-article-main-container p-3">

        <div className="cover-article-main-text f-14 f-600 mb-3">Cover Article</div>
        <div className="cover-btn-cont">
            <div className="choose-btn d-flex align-items-center">
                <button className='me-3'>Choose File</button>
                <h4 className='f-12 '>No File Choosen</h4>
            </div>
        </div>
    </div>
    </>
  )
}

export default CoverArticle