import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const Connection = ({
  uid,
  connectionCheck,
  connectionId,
  CheckConnectUser,
}) => {
  const sessionId = localStorage.getItem("sessionId");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //************************ Connect User Start  ************************//


  const ConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios
        .post("Connection/SendConnectionRequest", data)
        .then((resData) => {
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          CheckConnectUser();
          // CheckFollowedUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Connect User  End  ************************//

  const handleRecommendConnect = () => {

  }
  //************************Cancel Request   ************************//
  const [show, setShow] = useState(false);
  const cancelRequestClose = () => {
    setShow(false);
  };
  // const [skillId, setSkillId] = useState("");
  // const [userUID, setuserUID] = useState("");
  const CancelRequest = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    console.log(data);
    try {
      await axios
        .post("Connection/CancelConnectionRequest ", data)
        .then((resData) => {
          console.log(resData);
          setShow(!show);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          CheckConnectUser();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Cancel Request ************************//

  //************************Disconnect Request   ************************//
  const [disconectshow, setDisconectShow] = useState(false);
  const DisconnectClose = () => {
    setDisconectShow(false);
  };
  // const [skillId, setSkillId] = useState("");
  // const [userUID, setuserUID] = useState("");
  const Disconnect = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    console.log(data);
    try {
      await axios
        .post("Connection/DisconnectUser ", data)
        .then((resData) => {
          console.log(resData);
          setDisconectShow(!disconectshow);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          CheckConnectUser();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Disconnect Request ************************//

  //************************Accept Request   ************************//
  const AcceptRequest = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
      connectID: connectionId,
      actionType: "Accept",
    };
    console.log(data);
    try {
      await axios
        .post("Connection/ConnectionRequestAction ", data)
        .then((resData) => {
          console.log(resData.data.message);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          CheckConnectUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Accept Request ************************//

  //************************Reject Request   ************************//
  const RejectRequest = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
      connectID: connectionId,
      actionType: "Reject",
    };
    console.log(data);
    try {
      await axios
        .post("Connection/ConnectionRequestAction ", data)
        .then((resData) => {
          console.log(resData.data.message);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          CheckConnectUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Reject Request ************************//

  useEffect(() => {
    CheckConnectUser();
  }, [uid]);
  console.log('checkFollowedUser', connectionCheck);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [show]);

  return (
    <>
      <div className="connectionButton">
        {connectionCheck === "Sent" ? (
          <button
            type="button"
            className="mainBtn1 cbtn"
            onClick={() => {
              setShow(!show);
              // setuserUID(item.connectsWithUserUid);
            }}
          >
            Cancel Request
          </button>
        ) : connectionCheck === "Received" ? (
          <>
            <button
              type="submit"
              className=" mainBtn1 mainBtn-accept"
              onClick={() => {
                AcceptRequest();
              }}
            >
              Accept Request
            </button>
            {/* <button
              type="submit"
              className="wljSecondaryBtn d-block"
              onClick={() => {
                RejectRequest();
              }}
            >
              Reject Request
            </button> */}
          </>
        ) : connectionCheck === 'Connected' ? '' : (
          <button type="button" className="mainBtn1" onClick={ConnectUser}>
            Connect
          </button>
        )}
      </div>

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Cancel Request?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    cancelRequestClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => CancelRequest()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Connection;
