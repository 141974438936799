import React, { useEffect, useState } from "react";
import "../../assets/css/connection.css";
import { Col, Form, Row } from "react-bootstrap";
// import userOne from "../../assets/Images/connection/user-one.png";
// import userTwo from "../../assets/Images/connection/user-two.png";
// import polygon from "../../assets/Images/connection/Polygon 1.png";
// import locationIcon from "../../assets/Images/connection/locationIcon.png";
// import userAvatar from "../../assets/Images/connection/Group 647.png";
// import adImage from "../../assets/Images/connection/Canvas 3.png";
// import currentIcon from "../../assets/Images/connection/Group 211.png";
// import ReactSelect from "react-select";
// import Select from "react-select";
import axios from "../../API/axios";
import { post } from "../../assets/Images/ImagesLink";
import { ShimmerThumbnail } from "react-shimmer-effects";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { ShimmerDiv } from "shimmer-effects-react";


const BlockedUser = () => {

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const sessionId = localStorage.getItem("sessionId");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [blockUser, setBlockUser] = useState([]);
    const getblockeduser = () => {
        const data = {
            deviceType: "Web",
            sessionId
        };
        axios
            .post(`User/GetUserBlockedUsers`, data)
            .then((response) => {
                console.log("people res", response.data.responseData);
                setIsLoading(false);
                {
                    response.data.responseData.length === 0 ? setIsError(true) :
                        setBlockUser(response.data.responseData)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    setIsError(true);
                } else {
                    console.log(error);
                }
                setIsLoading(false);
            });
    };
    useEffect(() => {
        getblockeduser();
    }, []);


    //************************Block User  ************************//

    const unblockUser = async (id) => {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("UserUid", id);
        try {
            const res = await axios.post("User/UnBlockUser", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log('vashhgd', res);

            setToastMessage(res.data.message);
            setShowToast(true);
            getblockeduser()
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************Block User  ************************//

    return (
        <>
            <div className='setting-header'>
                <h4>Manage Blocked Users </h4>
            </div>
            <div className="p-3 ">
                {isLoading ? (
                    <div className="mt-3">
                        <ShimmerDiv mode="light" height={250} width={'100%'} />
                    </div>
                ) : isError ? (
                    <div className="mt-2 error-image-cont error-cont">
                        <img src={post.errorSmall} alt="404 Not Found" width={200} />
                    </div>
                ) : (
                    blockUser &&
                    blockUser?.map((item, index) => {
                        console.log('item', item);
                        return (
                            <div className="d-flex align-items-center">
                                <div className="image-conti me-2">
                                    <img src={item?.profilPic} alt="" width={50} style={{ borderRadius: '50px', border: '1px solid' }} />
                                </div>
                                <div className="userName">
                                    <h1>{item?.fullName}</h1>
                                </div>
                                <button
                                    className=" followStyle followBtn ms-auto"
                                    onClick={() => { unblockUser(item?.userUid) }}
                                >
                                    Unblock
                                </button>
                            </div>

                        );
                    })
                )}
            </div>


            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default BlockedUser