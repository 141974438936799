const SignInImages = {
    sideImg: require('../Images/SignIn/sideimage.png'),
    whiteLogo: require('../Images/SignIn/White-logo.png'),
    ovalwithtext: require('../Images/SignIn/ovalwithtext.png'),
    google: require('../Images/SignIn/google.png'),
    facebook: require('../Images/SignIn/facebook.png'),
    icloud: require('../Images/SignIn/icloud.png'),
    playstore: require('../Images/SignIn/playstore.png'),
    appstore: require('../Images/SignIn/appstore.png'),
    eye: require('../Images/SignIn/eye.png'),
    google1: require('../Images/SignIn/google1.svg'),
    wljloginbanner: require('../Images/SignIn/wlj-login-banner.jpg'),
    apple: require('../Images/Settings/apple.png'),
}

const header = {
    mainLogo: require('../Images/Header/logo-removebg 1.png'),
    search: require('../Images/Header/Ellipse 1.png'),
    lightMode: require('../Images/Header/sun.png'),
    darkMode: require('../Images/Header/moon.png'),
    notification: require('../Images/Header/notification-bing.png'),
    message: require('../Images/Header/message.png'),
    messageError: require('../Images/People/No chat.png'),
    user: require('../Images/Header/Rectangle 34625130.png'),
    WeLinkWhite: require('../Images/Resume/Main logo white 1.png'),
    ad2: require('../Images/JobSearch/ad2.png'),
    ad1: require('../Images/JobSearch/ad1.png'),
    oglogo: require('../Images/Header/welinkjobs-og-logo.png'),
    whiteoglogo: require('../Images/Header/welinkjobs-white-logo.png'),
}

const icons = {
    news: require('../Images/side-icons/i.png'),
    connection: require('../Images/side-icons/i1.png'),
    company: require('../Images/side-icons/i2.png'),
    companies: require('../Images/side-icons/i3.png'),
    people: require('../Images/side-icons/i4.png'),
    searchJob: require('../Images/side-icons/i5.png'),
    manageJob: require('../Images/side-icons/i6.png'),
    downArrow: require('../Images/side-icons/i7.png'),
    about: require('../Images/side-icons/i8.png'),
    accessibility: require('../Images/side-icons/i9.png'),
    helpcenter: require('../Images/side-icons/i10.png'),
    privacy: require('../Images/side-icons/i11.png'),
    advertising: require('../Images/side-icons/i12.png'),
    business: require('../Images/side-icons/i13.png'),
    app: require('../Images/side-icons/i14.png'),
    copyright: require('../Images/side-icons/i15.png'),
    profile: require('../Images/side-icons/Profile-Picture.png'),
    follow: require('../Images/side-icons/i16.png'),
    default: require('../Images/side-icons/default.png'),
    plus: require('../Images/side-icons/i17.png'),
}

const generalsetting = {
    setting: require('../Images/Settings/setting.png')
}
const NewsFeed = {
    Advertise: require('../Images/NewsFeed/Canvas-Ad.png'),
    AdImg: require('../Images/NewsFeed/adImg.png'),
    AdImg2: require('../Images/Job/Ad2.b61efbf7.png'),
    companyLogo: require('../Images/NewsFeed/companyLogo.png'),
    FeaturedPost: require('../Images/NewsFeed/FeaturedPost.png'),
    postphoto1: require('../Images/NewsFeed/Post Photos.png'),
    postphoto2: require('../Images/NewsFeed/Post Photos (1).png'),
    postphoto3: require('../Images/NewsFeed/Post Photos (2).png'),
    companyLogo1: require('../Images/NewsFeed/Shape.png'),
    jobPost: require('../Images/NewsFeed/job-post.png'),
    PostUser: require('../Images/Post/img_03222023062212.jpg'),
    PostImage: require('../Images/Post/1711532263373-image.jpg'),
    PostImage1: require('../Images/NewsFeed/post5.jpeg'),
    PostImage2: require('../Images/NewsFeed/post6.jpeg'),
    video: require('../Images/NewsFeed/3129671-hd_1920_1080_30fps.mp4')
}
const Profile = {
    Background: require('../Images/Profile/Image.png'),
    User: require('../Images/Profile/1.png'),
    qr: require('../Images/Profile/qr.png'),
    logo: require('../Images/Profile/favicon.png'),
    download: require('../Images/Profile/download.png'),
    Avatar: require('../Images/Profile/user.png')
}

// Resume
const resume = {
    logo: require('../Images/Resume/logo-removebg 1.png'),
    profileImg: require('../Images/Resume/Profile Picture.png'),
    facebook: require('../Images/Resume/facebook.png'),
    instagram: require('../Images/Resume/instagram.png'),
    book: require('../Images/Resume/book.png'),
    degree: require('../Images/Resume/degree.png'),
    medal: require('../Images/Resume/medal.png'),
    cycle: require('../Images/Resume/cycle.png'),
    music: require('../Images/Resume/music.png'),
    video: require('../Images/Resume/video.png'),
    paint: require('../Images/Resume/paint.png'),
    bag: require('../Images/Resume/bag.png'),
    viewResume: require('../Images/Resume/1695105336158-cropped-image.jpg'),
    barcode: require('../Images/Resume/logo1.svg'),
    approved: require('../Images/Resume/Approved.png'),
    deleted: require('../Images/Resume/Deleted.png'),
    disabled: require('../Images/Resume/Disabled.png'),
    recognized: require('../Images/Resume/Recognized.png'),
    synced: require('../Images/Resume/Synced.png'),
    unregister: require('../Images/Resume/Unregistered.png'),
    register: require('../Images/Resume/Registered.png'),
    verified: require('../Images/Resume/Verified.png'),
    tick: require('../Images/Resume/tick.jpg')
}

const people = {
    profile: require('../Images/People/user-one.png'),
    profile1: require('../Images/People/Component 3.png'),
    profile2: require('../Images/People/Mask group21.png'),
    background: require('../Images/People/default-bg.jpg'),
    DefaultProfile: require('../Images/People/default-profile.png')
}

const business = {
    coverImage: require('../Images/Business-page/Cover Image.png'),
    metaImage: require('../Images/Business-page/meta.png'),
    user1: require('../Images/Business-page/Profile Picture/PP3 Copy 7.png'),
    user2: require('../Images/Business-page/Profile Picture/PP1 Copy.png'),
    user3: require('../Images/Business-page/Profile Picture/PP2 Copy.png'),
    user4: require('../Images/Business-page/Profile Picture/PP3 Copy 10.png'),
    user5: require('../Images/Business-page/Profile Picture/PP3 Copy 8.png'),
    user6: require('../Images/Business-page/Profile Picture/PP3 Copy 9.png'),
    user7: require('../Images/Business-page/Profile Picture/PP3.png'),
    user8: require('../Images/Business-page/Profile Picture/PP6.png'),
    user9: require('../Images/Business-page/Profile Picture/PP7.png'),
    user10: require('../Images/Business-page/Profile Picture/PP9.png'),
    saveImage: require('../Images/Business-page/Path.png'),

    postImage1: require('../Images/Business-page/Post/Group 2.png'),
    postImage2: require('../Images/Business-page/Post/Post Photos (1).png'),
    postImage3: require('../Images/Business-page/Post/Post Photos.png'),

    postUserImage1: require('../Images/Business-page/Post/user1.png'),
    postUserImage2: require('../Images/Business-page/Post/user2.png'),
    postUserImage3: require('../Images/Business-page/Post/user3.png'),

    mediaImage: require('../Images/Business-page/Media/Media.png'),
    mediaImage1: require('../Images/Business-page/Media/Media (1).png'),
    mediaImage2: require('../Images/Business-page/Media/Media (2).png'),
    mediaImage3: require('../Images/Business-page/Media/Media (3).png'),
    mediaImage4: require('../Images/Business-page/Media/Media (4).png'),
    mediaImage5: require('../Images/Business-page/Media/Media (5).png'),
    mediaImage6: require('../Images/Business-page/Media/Media (6).png'),
    mediaImage7: require('../Images/Business-page/Media/Media (7).png'),

}

const JobSearch = {
    ad1: require('../Images/JobSearch/ad1.png'),
    ad2: require('../Images/JobSearch/ad2.png'),
    jobLogo: require('../Images/JobSearch/joblogo.png'),
    companyHeader: require('../Images/JobSearch/JobList/Company Header.png'),
    companySubImg: require('../Images/JobSearch/JobList/Logo (2).png'),

    collapsImage: require('../Images/JobSearch/JobList/jobListUsers/PP (1).png'),
    collapsImage1: require('../Images/JobSearch/JobList/jobListUsers/PP Copy 2.png'),
    collapsImage2: require('../Images/JobSearch/JobList/jobListUsers/PP Copy 3.png'),
    collapsImage3: require('../Images/JobSearch/JobList/jobListUsers/PP Copy.png'),
    collapsImage4: require('../Images/JobSearch/JobList/jobListUsers/PP.png'),
}

const FeatureImages = {
    card1: require('../Images/JobSearch/FeatuerCard/Logo (1).png'),
    card2: require('../Images/JobSearch/FeatuerCard/Logo (2).png'),
    card3: require('../Images/JobSearch/FeatuerCard/Logo (3).png'),
    card4: require('../Images/JobSearch/FeatuerCard/Logo (4).png'),
    card5: require('../Images/JobSearch/FeatuerCard/Logo (5).png'),
    card6: require('../Images/JobSearch/FeatuerCard/Logo (6).png'),
    card7: require('../Images/JobSearch/FeatuerCard/Logo (7).png'),
    card8: require('../Images/JobSearch/FeatuerCard/Logo.png'),
    card9: require('../Images/JobSearch/FeatuerCard/Shape.png'),
    card10: require('../Images/JobSearch/FeatuerCard/dribbble (1).png'),
    card11: require('../Images/JobSearch/FeatuerCard/dribbble.png'),
    card12: require('../Images/JobSearch/FeatuerCard/Rectangle 92.png'),
}

const notificationImages = {
    notifyImg1: require('../Images/JobNotification/Vector (1).png'),
    notifyImg2: require('../Images/JobNotification/Vector.png'),
    notifyImg3: require('../Images/JobNotification/downloading.png'),
    notifyImg4: require('../Images/JobNotification/view.png'),
    notifyImg5: require('../Images/JobNotification/Recent Company.png'),
    notifyImg6: require('../Images/JobNotification/Component 3.png'),
    notifyImg7: require('../Images/JobNotification/Mask group.png'),
    notifyImg8: require('../Images/JobNotification/Mask group21.png'),
    notifyImg9: require('../Images/JobNotification/user-one.png'),

    notifyImg10: require('../Images/JobNotification/image 2.png'),
    notifyImg11: require('../Images/JobNotification/Recent Company (1).png'),
    notifyImg12: require('../Images/JobNotification/Recent Company (2).png'),
    notifyImg13: require('../Images/JobNotification/Recent Company (4).png'),
    notifyImg14: require('../Images/JobNotification/Recent Company (5).png'),
    notifyImg15: require('../Images/JobNotification/Recent Company (6).png'),
    notifyImg16: require('../Images/JobNotification/Recent Company1.png'),
    notifyImg17: require('../Images/JobNotification/Recent Company33.png'),
    notifyImg18: require('../Images/JobNotification/Recent Company.png'),

}

const blogs = {
    menu: require('../Images/Blogs/Shape.png'),
    menu1: require('../Images/Blogs/Shape (2).png'),
    menu2: require('../Images/Blogs/ic_Grantt.png'),
    blogsImage: require('../Images/Blogs/download.jpg'),
    blogsImage1: require('../Images/Blogs/blogsImage.jpg'),
    blogsCardImage1: require('../Images/Blogs/cardImg (1).jpg'),
    blogsCardImage2: require('../Images/Blogs/cardImg (2).jpg'),
    blogsCardImage3: require('../Images/Blogs/Business Schedule.png'),
}

const userAuthImage = {
    dummy: require('../Images/UserAuth/Rectangle 34625145.png'),
    dummy1: require('../Images/UserAuth/Rectangle 34625146.png'),

}
const post = {
    like: require('../Images/Post/f4ly07ldn7194ciimghrumv3l.svg'),
    celebrate: require('../Images/Post/3c4dl0u9dy2zjlon6tf5jxlqo.svg'),
    support: require('../Images/Post/9whrgl1hq2kfxjqr9gqwoqrdi.svg'),
    love: require('../Images/Post/asmf650x603bcwgefb4heo6bm.svg'),
    loader: require('../Images/LoaderImage/wlj-loader.gif'),
    insightful: require('../Images/Post/39axkb4qe8q95ieljrhqhkxvl.svg'),
    funny: require('../Images/Post/ktcgulanbxpl0foz1uckibdl.svg'),
    error: require('../Images/Post/404-Error.jpeg'),
    errorSmall: require('../Images/Post/No-File-small.png'),
    errorbig: require('../Images/Post/No-File-big.png'),
    LikeGif: require('../Images/Post/Like.gif'),
    LoveGif: require('../Images/Post/Love.gif'),
    LaughingGif: require('../Images/Post/Laughing.gif'),
    WowGif: require('../Images/Post/Wow.gif'),
    AngryGif: require('../Images/Post/Angry.gif'),
    dot: require('../Images/Post/dot.png')

}

export { post, SignInImages, header, icons, generalsetting, NewsFeed, resume, people, Profile, business, JobSearch, userAuthImage, notificationImages, FeatureImages, blogs }
