import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import UpdateJob from "../ManageJob/UpdateJob";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { debounce } from "lodash";
import Select from "react-select";
import { customStyles } from "../../selectStyles";

const JobAction = ({
  item,
  AllJobList,
  setJobList,
  jobList,
  totalPages,
  setTotalPages,
  page,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  // console.log(item, 'item');
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [showJobModal, setShowJobModal] = useState(false);
  // console.log('showJobModal', showJobModal);
  const openJobModal = () => {
    setShowJobModal(true);
  };
  const closeJobModal = () => {
    setShowJobModal(false);
  };

  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deleteJob = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: item.jobId,
    };
    try {
      await axios
        .delete(`Job/DeleteJob`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShow(!show);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          setJobList((prevPostList) =>
            prevPostList.filter((post) => post.jobId !== item.jobId)
          );
          if ((jobList.length - 1) % 10 === 0 && page === totalPages) {
            setTotalPages(totalPages - 1);
          }
          if (page <= totalPages) {
            AllJobList(page);
          }
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//

  const [shareModal, setShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState();
  const sharePost = async () => {
    const sharePost = {
      deviceType: "Web",
      id: item.jobId,
      type: 2,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", sharePost).then((res) => {
        // console.log(res.data.responseData.value);
        setShareUrl(res.data.responseData.value);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const copyPost = async () => {
    const copyData = {
      deviceType: "Web",
      id: item.jobId,
      type: 2,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", copyData).then((res) => {
        navigator.clipboard.writeText(res.data.responseData.value);
        // toast.success('Link Copied')
        setToastMessage(res.data.message);
        setShowToast(true);
      });
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Get Skill DDL Start  ************************//
  const [skillvalue, setskillValue] = useState(null);
  const [skillpage, setskillPage] = useState(0);
  const [skilltyped, setSkilltyped] = useState("");
  const [skillisLoading, setskillIsLoading] = useState(false);
  const [skilloptions, setskillOptions] = useState([]);
  const [skilltotalPages, setskillTotalPages] = useState(0);
  const logValue = debounce((skilltyped) => {
    setSkilltyped(skilltyped);
  }, 250);
  // console.log('skilltyped', skilltyped);
  const addOptions = () => {
    setskillIsLoading(true);
    axios
      .get(
        `Master/GetSkillsDDL?search=${skilltyped}&pageNumber=${skillpage + 1
        }&pageSize=100`
      )
      .then((response) => {
        // console.log(response);
        const data = response.data.responseData.data.map((item) => ({
          value: item.skllId,
          label: item.name,
        }));
        setskillPage((prevPage) => prevPage + 1);
        setskillIsLoading(false);
        setskillOptions((prevOptions) => [...prevOptions, ...data]);
        setskillTotalPages(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setskillIsLoading(false);
      });
  };

  const handleOpen = () => {
    setskillPage(1);
  };

  const handleChange = (skillvalue) => {
    setskillValue(skillvalue.value);
    setskillPage(0);
  };

  const handleInputChange = (skilltyped) => {
    logValue(skilltyped);
    if (!skilltyped) setskillPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (skillpage < skilltotalPages) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (skilltyped) {
      addOptions();
    }
  }, [skilltyped]);

  //************************ Get Skill DDL End  ************************//
  const [showAddSkill, setShowAddSkill] = useState(false);
  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);
  const jobSkills = async () => {
    const Data = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: item?.jobId,
      skillId: skillvalue,
    };
    try {
      await axios.post("Job/AddJobSkills", Data).then((res) => {
        // navigator.clipboard.writeText(res.data.responseData.value)
        // toast.success('Link Copied')
        setToastMessage(res.data.message);
        setShowToast(true);
        handleCloseAddSkill();
      });
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isOwned, setIsOwned] = useState("");
  useEffect(() => {
    jobList.map((item) => {
      setIsOwned(item.isOwned);
    });
  }, []);
  console.log("isOwned new ", isOwned);
  useEffect(() => {
    if (show || shareModal || showAddSkill) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [show, shareModal, showAddSkill]);
  return (
    <>
      {isOwned === false ? (
        <div
          className="postMenu pointer "
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <MoreHorizSharpIcon className="material-symbols-sharp" />
          {showDropdown && (
            <Dropdown.Menu
              show={showDropdown}
              ref={dropdownRef}
              className="InteractionModal"
            >
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShareModal(true);
                    sharePost();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ShareSharpIcon className="material-symbols-sharp me-2" />

                    <span className="dropdownTxt f-14">Share</span>
                  </div>
                  <p className="f-12 dropdownPara">Share Job</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    copyPost();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ContentCopySharpIcon class="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Copy Link</span>
                  </div>
                  <p className="f-12 dropdownPara">Copy Post Link</p>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </div>
      ) : (
        <div
          className="postMenu pointer "
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <MoreHorizSharpIcon className="material-symbols-sharp" />

          {showDropdown && (
            <Dropdown.Menu
              show={showDropdown}
              ref={dropdownRef}
              className="InteractionModal"
            >
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    setShow(!show);
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <DeleteOutlineSharpIcon class="material-symbols-sharp" />
                    <span className="dropdownTxt f-14">Delete</span>
                  </div>
                  <p className="f-12 dropdownPara">
                    Once Deleted it can't be recovered
                  </p>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  // onClick={() => {
                  //     setShow(!show);
                  //     toggleDropdown();
                  // }}
                  onClick={(e) => {
                    e.preventDefault();
                    openJobModal();
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <UpdateSharpIcon class="material-symbols-sharp" />
                    <span className="dropdownTxt f-14">Update</span>
                  </div>
                  <p className="f-12 dropdownPara">Update Job</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShareModal(true);
                    sharePost();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ShareSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Share</span>
                  </div>
                  <p className="f-12 dropdownPara">Share Job</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    copyPost();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ContentCopySharpIcon class="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Copy Link</span>
                  </div>
                  <p className="f-12 dropdownPara">Copy Post Link</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenAddSkill();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <PsychologySharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Add Skills</span>
                  </div>
                  <p className="f-12 dropdownPara">Job Skills</p>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </div>
      )}

      {/* <Modal
        show={show}
        size="md"
        centered
        onHide={handleDeleteClose}
        toggle={() => setShow(!show)}
        // className='modal-dialog-centered m'
      >
        <Modal.Header
          className="bg-transparent p-4"
          closeButton
          toggle={() => {
            setShow(!show);
          }}
        >
          <h6 className="f-16 fw-bold">Are you sure you want to delete Job?</h6>
        </Modal.Header>
        <Modal.Body className="text-center">
          <button color="" className="closebtn" onClick={() => deleteJob()}>
            Delete
          </button>
        </Modal.Body>
      </Modal> */}

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete Job?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }} />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deleteJob()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showJobModal && (
        <UpdateJob
          openModal={openJobModal}
          showModal={showJobModal}
          closeModal={closeJobModal}
          item={item}
          AllJobList={AllJobList}
          setJobList={setJobList}
          jobList={jobList}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={page}
        />
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {/* <Modal
        show={shareModal}
        onHide={() => setShareModal(false)}
        size="xl"
        centered
        toggle={() => setShareModal(!shareModal)}
      >
        <Modal.Header
          closeButton
          className="bg-transparent p-2 border-bottom"
          toggle={() => setShareModal(!shareModal)}
        >
          <h5 className="main-heading">Share Job</h5>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <FacebookShareButton
            url={shareUrl}
            onClick={() => {
              setShareModal(false);
            }}
          >
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <WhatsappShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <LinkedinIcon size={40} />
          </LinkedinShareButton>
          <TwitterShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <TwitterIcon size={40} />
          </TwitterShareButton>
          <Row>
            <Col
              className="mt-1 d-flex justify-content-between"
              xs={2}
              lg={2}
            ></Col>
            <Col
              className="mt-1 d-flex justify-content-between"
              xs={2}
              lg={2}
            ></Col>
          </Row>
        </Modal.Body>
      </Modal> */}

      {shareModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Share Url</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShareModal(false);
                  }}
                />
              </div>

              <div className="m-2">
                <FacebookShareButton
                  url={shareUrl}
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <FacebookIcon size={40} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <WhatsappIcon size={40} />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <LinkedinIcon size={40} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <TwitterIcon size={40} />
                </TwitterShareButton>
              </div>

              <Row>
                <Col
                  className="mt-1 d-flex justify-content-between"
                  xs={2}
                  lg={2}
                ></Col>
                <Col
                  className="mt-1 d-flex justify-content-between"
                  xs={2}
                  lg={2}
                ></Col>
              </Row>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        show={showAddSkill}
        onHide={handleCloseAddSkill}
        size="md"
        centered
      >
        <Modal.Header closeButton className="p-3">
          <Modal.Title>Add Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body className="skillModal">
          <div className="mb-4">
            <Select
              defaultValue={skillvalue}
              options={skilloptions}
              optionRenderer={renderOption}
              onOpen={handleOpen}
              onChange={handleChange}
              onMenuScrollToBottom={handleMenuScrollToBottom}
              onInputChange={handleInputChange}
              isClearable
              // onCreateOption={(newOption) => {
              //   setSkillCheck(true);
              //   setNewCreateoption(newOption); // Update the state when a new option is created
              //   // You can also perform other actions here, like adding the new option to the options list
              // }}
              // onCreateOption={handleCreateOption}
              placeholder={"Select Skill"}
              // invalid={display && value === ""}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <button type="button" className="mainBtn1" onClick={jobSkills}>
            Add Skill
          </button>
        </Modal.Footer>
      </Modal> */}

      {showAddSkill && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Skill</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseAddSkill();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseAddSkill();
                  }}
                />

              </div>
              <div className="mb-4">
                <Select
                  defaultValue={skillvalue}
                  options={skilloptions}
                  optionRenderer={renderOption}
                  onOpen={handleOpen}
                  onChange={handleChange}
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  onInputChange={handleInputChange}
                  isClearable
                  // onCreateOption={(newOption) => {
                  //   setSkillCheck(true);
                  //   setNewCreateoption(newOption); // Update the state when a new option is created
                  //   // You can also perform other actions here, like adding the new option to the options list
                  // }}
                  // onCreateOption={handleCreateOption}
                  placeholder={"Select Skill"}
                  styles={customStyles}
                // invalid={display && value === ""}
                />
              </div>
              <div>
                <button type="button" className="mainBtn1" onClick={jobSkills}>
                  Add Skill
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobAction;
