import React from 'react'
import { NavLink } from 'react-router-dom'
// import { header } from "../../assets/Images/ImagesLink";
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import HubSharpIcon from '@mui/icons-material/HubSharp';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import WorkOutlineSharpIcon from '@mui/icons-material/WorkOutlineSharp';
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import TextsmsSharpIcon from '@mui/icons-material/TextsmsSharp';
const Footer = () => {
    return (
        <>
            <div className="d-flex footer-nav">
                {/* <ul> */}
                <NavLink to='/NewsFeed'>
                    <li className='navLinks'>
                        <HomeSharpIcon className="material-symbols-sharp" /> </li>
                </NavLink>
                <NavLink to='/MyConnections'>
                    <li className='navLinks'><HubSharpIcon className="material-symbols-sharp" /> </li>
                </NavLink>
                <NavLink to='/Companies'>
                    <li className='navLinks'><BusinessSharpIcon className="material-symbols-sharp" /> </li>
                </NavLink>
                <NavLink to='/People'>
                    <li className='navLinks'><PersonSearchSharpIcon className="material-symbols-sharp" /> </li>
                </NavLink>
                <NavLink to='/SearchJob'>
                    <li className='navLinks'><WorkOutlineSharpIcon className="material-symbols-sharp" /> </li>
                </NavLink>
                <NavLink to='/Message/All'>
                    <li className='navLinks'>
                        <TextsmsSharpIcon className="material-symbols-sharp" />
                        </li>
                </NavLink>
                <NavLink to='/Notification'>
                    <li className='navLinks'>
                        <NotificationsNoneSharpIcon className="material-symbols-sharp" />
                        </li>
                </NavLink>
               
                
                 
                {/* </ul> */}
            </div>
        </>
    )
}

export default Footer