import React, { useEffect, useRef, useState } from "react";
// import FloatingLabelInput from 'react-floating-label-input';
import { Form } from "react-router-dom";
// import FloatingSelectInput from '../FloatingSelectInput';
import Select from "react-select";
import ReactSelect from "react-select";
import axios from "../../API/axios";
import DatePicker from "react-datepicker";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import { useFormik } from "formik";
import { createProfileValidations } from "../Validations";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { Input } from "reactstrap";

const options = [
  { value: "USA", label: "United States" },
  { value: "CAN", label: "Canada" },
  // Add more countries as needed
];

const Profile = () => {
  const [data, setData] = useState([]);
  console.log("dataaaaaa", data);
  const fetchData = async () => {
    try {
      const result = await axios.post(`/UserProfile/GetOwnInfo`, {
        sessionId,
        deviceType: "Web",
      });
      const Response = result.data.responseData;
      // console.log('result', Response);
      setData(Response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  //   Get Language Data
  const [languageValue, setlanguageValue] = useState("");
  const [languageOptionList, setlanguageOptionsList] = useState([]);
  const getLanguageData = () => {
    try {
      axios
        .get(`/Master/GetLanguageDDL`)
        .then((response) => {
          // console.log("language data", response.data.responseData);
          setlanguageOptionsList(response.data.responseData);
          // setLoading(false);
        })
        .catch((error) => { });
    } catch (error) { }
  };

  let languageOptions = languageOptionList.map((val) => {
    return { value: val.langId, label: val.name, id: val.langId };
  });

  const selectLanguage = (val) => {
    // console.log("lang", val.value);
    setlanguageValue(val.value);
  };

  useEffect(() => {
    getLanguageData();
  }, []);

  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState("");
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  const [countryData, setCountryData] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [countrySearch, setCountrySearch] = useState(null);
  const getCountry = () => {
    axios
      .get(`Master/GetCountriesDDL?search=${countrySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.countyId,
          label: item.countryName,
          code: item.phoneCode,
        }));
        setCountryData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (countrySearch) {
      getCountry();
    }
  }, [countrySearch]);

  // Get Gender Data
  const [genderValue, setGenderValue] = useState("");
  const [genderOptionList, setGenderOptionList] = useState([]);
  const getGenderData = () => {
    try {
      axios
        .get(`/Master/GetGenderDDL`)
        .then((response) => {
          // console.log("gender data", response.data.responseData);
          setGenderOptionList(response.data.responseData);
          // setLoading(false);
        })
        .catch((error) => { });
    } catch (error) { }
  };

  let genderOptions = genderOptionList.map((val) => {
    return { value: val.gndrId, label: val.name, id: val.gndrId };
  });

  const selectGender = (val) => {
    // console.log("gender", val.value);
    setGenderValue(val.value);
  };

  useEffect(() => {
    getGenderData();
  }, []);
  const [startDate, setStartDate] = useState("");

  const sessionId = localStorage.getItem("sessionId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [bgSelectedImage, setBgSelectedImage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [backgroudImage, setBackgroundImage] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRefBg = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleBgClick = () => {
    fileInputRefBg.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setProfilePic(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  const handleBgImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setBackgroundImage(selectedFile);
    setBgSelectedImage(URL.createObjectURL(selectedFile));
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    dob: "",
    additionalName: "",
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");


  const UpdateProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append("Dob", startDate === null ? '' : moment(startDate).format("L"));
      formData.append("CityID", cityId === null ? '' : cityId);
      formData.append("GenderId", genderValue);
      formData.append("LastName", lastName);
      formData.append("FirstName", firstName);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);

      const res = await axios.post(
        "/UserProfile/UpdateCompleteInfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("response: ", res);
      setToastMessage(res.data.message);
      setShowToast(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  useEffect(() => {
    if (data) {
      setlanguageValue(data?.langId);
      setGenderValue(data?.genderId);
      setCityId(data?.cityID);
      setCitySearch(data?.cityName);
      setCountryId(data?.countryID);
      setCountrySearch(data?.countryName);
      // setStartDate(data?.dob);
      setStartDate(data?.dob ? new Date(data.dob) : null); // Convert string to Date
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
      setProfilePic(data?.profilPic);
      setBackgroundImage(data?.backGroundImage);
    }
  }, [data]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  return (
    <>
      <div className="setting-header">
        <h4>Profile Setting</h4>
      </div>
      <Form>
        <div className="row p-4 settings">
          <div className="col-md-6 mt-2 ps-0 mb-2 userInput">
            <label className="mb-0">
              First Name
            </label>
            <Input
              type="text"
              value={firstName}
              // placeholder="Enter field"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="col-md-6 mt-2 ps-0 mb-2 userInput">
            <label>
              Last Name
            </label>
            <Input
              type="text"
              value={lastName}
              // placeholder="Enter field"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="col-md-6 mt-2 ps-0">
            <label htmlFor="about">Gender</label>
            <ReactSelect
              value={genderOptions.find(
                (option) => option.value === genderValue
              )}
              options={genderOptions}
              onChange={selectGender}
              loadOptions={selectGender}
              placeholder="Select Gender"
            />
          </div>
          <div className="col-md-6 mt-2 ps-0">
            <label htmlFor="about">City</label>
            <Select
              value={cityData.find((option) => option.value === cityId)}
              onChange={(selectedOption) => setCityId(selectedOption.value)}
              options={cityData}
              placeholder={"Select City"}
              onInputChange={(inputValue) => {
                setCitySearch(inputValue);
              }}
              styles={customStyles}
            />
          </div>
          <div className="col-md-6 mt-2 ps-0 mb-2 userInput">
            <label htmlFor="about">DOB</label>
            <div>
              {/* <Flatpickr
                className="form-control"
                defaultValue={startDate}
                onChange={(date) => setStartDate(date[0])}
                id="dob"
                options={{
                  maxDate: "today" // Disables future dates
                }}
              /> */}
              <Flatpickr
                className="form-control"
                value={startDate}
                onChange={(date) => setStartDate(date[0])} // Flatpickr returns an array
                id="dob"
                options={{
                  maxDate: "today", // Disable future dates
                  defaultDate: startDate || undefined, // Set the default selected date
                  onReady: (selectedDates, dateStr, instance) => {
                    preventYearScroll(instance); // Call the function to stop scroll on year
                  },
                  onOpen: (selectedDates, dateStr, instance) => {
                    preventYearScroll(instance); // Also prevent scroll when the picker opens
                  },
                }}
              />
            </div>
          </div>

          <div className="text-end">
            <button
              type="submit"
              className="mainBtn1 mt-3 text-center"
              onClick={UpdateProfile}
            >
              Update
            </button>
          </div>
        </div>
      </Form>
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Profile;
