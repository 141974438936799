
import React, { useEffect, useRef, useState } from 'react'
import { icons, post } from '../../assets/Images/ImagesLink'
// import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import axios from '../../API/axios';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import { Link } from 'react-router-dom';
import { ShimmerDiv } from 'shimmer-effects-react';
import { Dropdown } from "react-bootstrap";
import MutualActions from './MutualActions';

const Mutuals = ({ uid, getUserProfileStat, setMutualCounts, mutualCounts }) => {
    const sessionId = localStorage.getItem("sessionId");
    //************************ Follower  List   ************************//
    const [isError, setIsError] = useState(false);
    const [page, setPage] = useState(1);
    const [mutualList, setMutualList] = useState([]);
    const [shortMutualList, setShortMutualList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    // console.log('FollowerList', FollowerList);
    const MutualListAll = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUid: uid
        };
        try {
            await axios.post("UserFollow/GetMutualConnectUserList", data).then((resData) => {
                setIsLoading(false);
                setMutualList(resData.data.responseData)
                if (resData.data.status === "Warning") {
                    setIsError(true);
                }
                console.log('fdshfja', resData.data);
                setMutualCounts(resData.data.responseData.length)
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.log(error);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        MutualListAll();
    }, [uid, mutualCounts]);

    //************************Follower List ************************//
    //************************ Follow User Start  ************************//
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const FollowUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios.post("UserFollow/FollowUser", data).then((resData) => {
                getUserProfileStat()
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ Follow User End  ************************//

    //************************ short mutual list ************************//
    useEffect(() => {
        if (mutualList && mutualList.length > 0) {
            mutualList.forEach(item => {
                fetchShortMutualList(item.userUid);
            });
        }
    }, [mutualList]);

    const fetchShortMutualList = async (id) => {
        console.log('connect data', id);
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUid: id
        };
        try {
            const response = await axios.post("UserFollow/GetShortMutualUserInfo", data);
            // console.log('ndmfmndfs', response.data.responseData.count);

            setShortMutualList(prevState => ({
                ...prevState,
                [id]: response.data.responseData
            }));
            setIsLoading(false);
            // if (response.data.status === "Warning") {
            //     setIsError(true);
            // }
        } catch (error) {
            console.log(error);
            // if (error.response && error.response.status === 404) {
            //     setIsError(true);
            // } else {
            //     console.log(error);
            // }
            setIsLoading(false);
        }
    };

    //************************ short mutual list ************************//



    return (
        <>

            {isLoading ? (
                <div className="mt-3">
                    {/* <ShimmerThumbnail height={250} rounded /> */}
                    <ShimmerDiv mode="light" height={250} width={'100%'} />
                </div>
            ) : isError ? (
                <div className="mt-2 error-image-cont">
                    <img src={post.errorbig} alt="404 Not Found" width={200} />
                </div>
            ) : (
                mutualList &&
                mutualList?.map((item, index) => {
                    console.log('connect data', item);
                    return (
                        <>
                            <div className="aboutMe mb-3 p-3" key={index}>
                                <div className="d-flex align-items-center">
                                    <Link to={`/User/${item.userUid}`} className='pointer'>
                                        <div className="ImgAvatar">
                                            <img src={item?.profilPic} alt="" width={60} style={{ borderRadius: '50%' }} />
                                        </div>
                                    </Link>
                                    <div className='ms-2'>
                                        <Link to={`/User/${item.userUid}`} className='pointer'>
                                            <h4 className='f-14 fw-bold mb-0'>{item?.fullName} 
                                                <span className='f-12 fw-100'>{item?.nameTag}</span> </h4>
                                        </Link>
                                        {item?.title && <p className='f-12'>{item?.title}</p>}
                                        {item?.headLine && <p className='f-12'>{item?.headLine}</p>}
                                        {item?.followersCount && <p className='f-12'>{item?.followersCount} Followers</p>}
                                        {shortMutualList.length > 0 && <div className='d-flex'>
                                            <div className="circle-container">
                                                {shortMutualList[item.userUid]?.pics?.map((pic, picIndex) => (
                                                    <img src={pic} alt={`Circle ${picIndex + 1}`} className="circle-img" key={picIndex} />
                                                ))}
                                            </div>
                                            <div className="text">{shortMutualList[item.userUid]?.text}</div>
                                        </div>}

                                    </div>
                                    {/* <div className='ms-auto'>
                                        <button className='mainBtn1' style={{ width: '90px' }} onClick={() => {
                                            FollowUser(item?.mutualUserInfo?.userUid)
                                        }} >
                                            Follow</button>
                                    </div> */}

                                    <MutualActions item={item} MutualListAll={MutualListAll} fetchShortMutualList={fetchShortMutualList} />
                                </div>
                            </div>

                        </>
                    );
                })
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default Mutuals