import React from 'react'
import ThumbUpAltSharpIcon from '@mui/icons-material/ThumbUpAltSharp';
const Likes = () => {
    return (
        <div className="aboutMe mb-3 p-3">
            <div className="d-flex align-items-center">
                <div className='ms-2'>
                    <ThumbUpAltSharpIcon className='material-symbols-sharp' />
                    <h4 className='f-16 mb-0'>60</h4>
                </div>
                <div className='ms-auto'>
                    <p className='f-12'>Liked Your Post</p>
                </div>
            </div>
        </div>
    )
}

export default Likes