import React, { useEffect, useState } from "react";
import RightNavigation from "../Navigation/RightNavigation";
import "../../assets/css/ViewResume.css";
import { resume, header, Profile } from "../../assets/Images/ImagesLink";
import axios from "../../API/axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import QRCode from "react-qr-code";
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import PinDropSharpIcon from '@mui/icons-material/PinDropSharp';

const ViewResume = () => {
  const sessionId = localStorage.getItem("sessionId");
  const { UserId } = useParams();

  const [ccvInfo, setCCVInfo] = useState("");
  const [ccvEducation, setCCVEducation] = useState([]);
  const [ccvCertification, setCCVCertification] = useState([]);
  const [ccvAward, setCCVAward] = useState([]);
  const [ccvExperience, setCCVExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  const getUserProfile = () => {
    const data = {
      deviceType: "Web",
      userUid: UserId,
    };
    axios
      .post(`UserProfile/GetUserProfile`, data)
      .then((response) => {
        console.log("userprofile n", response.data.responseData);
        const res = response.data.responseData;
        setCCVInfo(res?.profileInfo);
        setCCVEducation(res?.viewEducationInfo);
        setCCVCertification(res?.viewCertificationInfo);
        setCCVAward(res?.viewAwardsInfo);
        setCCVExperience(res?.viewExperienceInfo);
        setSkills(res?.viewSkillInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfile();
  }, [UserId]);
  console.log("ccvInfo", ccvInfo);
  return (
    <>
      {
        ccvInfo === "" ? (
          <h5 className="mt-5 d-flex justify-content-center">
            No Data Available
          </h5>
        ) : (
          <div className="user-resume">
            <div className="resume-header d-flex align-items-center px-3 py-3">
              <div className="resume-profile-image">
                <img src={ccvInfo?.profilPic} alt="" srcset="" height={{}} />
              </div>
              <div className="resumeHeader ms-3">
                <h3>{ccvInfo?.firstName} {ccvInfo?.lastName}</h3>
                <div className="uid">
                  <span className="activeIcon"></span>
                  <h4 className="ms-3 mb-0">{ccvInfo?.userUid}</h4>
                </div>
              </div>
              <div className="d-flex ms-auto">
                <div className="resume-logo weLinkImage">
                  <img src={header.WeLinkWhite} alt="" srcset="" />
                </div>
                <QRCode
                  value={`https://welinkjobs.com/Resume/${UserId}`}
                  className="ms-1"
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "1px solid white",
                  }}
                />
              </div>
            </div>

            <div className="resume-bottom-section  p-0 m-0 row">
              <div className="col-md-5 leftDetail pt-3">
                <div className="resumeHeading">
                  <p>CONTACT</p>
                </div>
                {ccvInfo?.dob === null ? (
                  ""
                ) : (
                  <li className="custom-content f-12 d-flex align-items-center mt-1">
                    <CalendarMonthSharpIcon className="material-symbols-sharp me-2" />
                    {ccvInfo?.dob}
                  </li>
                )}
                {ccvInfo?.email === null ? (
                  ""
                ) : (
                  <li className="custom-content f-12 d-flex align-items-center mt-1">
                    <EditNoteSharpIcon className="material-symbols-sharp me-2" />
                    {ccvInfo?.email}
                  </li>
                )}
                {ccvInfo?.website === null ? (
                  ""
                ) : (
                  <li className="custom-content f-12 d-flex align-items-center mt-1">
                    <LanguageSharpIcon className="material-symbols-sharp me-2" />
                    {ccvInfo?.website}
                  </li>
                )}
                {ccvInfo?.cityName === null &&
                  ccvInfo?.countryName === null ? (
                  ""
                ) : (
                  <li className="custom-content f-12 d-flex align-items-center mt-1">
                    <PinDropSharpIcon className="material-symbols-sharp me-2" />
                    {ccvInfo?.cityName != null && `${ccvInfo?.cityName}`}
                    {ccvInfo?.countryName != null && ","}
                    {ccvInfo?.countryName != null &&
                      ` ${ccvInfo?.countryName}`}
                  </li>
                )}

                {ccvEducation.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading mt-3">
                      <p>EDUCATION</p>
                    </div>
                    <ul className="mb-4 ps-2">
                      {ccvEducation.map((item) => {
                        return (
                          <li className="listIcon mt-3 ms-3">
                            <h6 className="emp-heading f-13 mb-0 view-title f-600">
                              {item.degree} at {item.institutionName}
                            </h6>
                            <p className="emp-details mb-0 f-12">{item.field}</p>
                            <h6 className="emp-heading my-0 f-12">
                              {moment(item?.startMonth).format("MMM")}{" "}
                              {item?.startYear} -{" "}
                              {item.isPresent === true
                                ? "Present"
                                : `${moment(item.endMonth).format("MMM")} ${item.endYear
                                }`}{" "}
                            </h6>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}

                {skills.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading">
                      <p>SKILLS</p>
                    </div>
                    <ul className="ps-2">
                      {skills.map((item) => {
                        // console.log('item', item);
                        return (
                          <li className="listIcon mb-2 ms-3">
                            <p className="mb-0 emp-details f-12">
                              {" "}
                              {item.skillName}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {ccvCertification.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading">
                      <p>CERTIFICATIONS</p>
                    </div>
                    <ul className="ps-2">
                      {ccvCertification.map((item) => {
                        console.log('itemMMMMM', item);
                        return (
                          <li className="listIcon mt-3 ms-3">
                            <h6 className="emp-heading f-13 mb-0 view-title f-600 mb-1">
                              {item.name}
                            </h6>
                            <h6 className="emp-heading my-0 f-12">
                              ({item.orgName},{moment(item?.issueDate).format("YYYY")})

                            </h6>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
              <div className="col-md-7 rightDetail pt-3">
                {ccvInfo.about === '' ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading">
                      <p>PROFILE</p>
                    </div>
                    <h6 className="emp-heading my-0 f-12 mt-3">
                      {ccvInfo.about}
                    </h6>
                  </>
                )}
                {ccvExperience.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading mt-3">
                      <p>WORK EXPERIENCE</p>
                    </div>
                    <ul className="mb-4 ps-2">
                      {ccvExperience.map((item) => {
                        return (
                          <li className="listIcon mt-3 ms-3">
                            <div className="">
                              <h6 className="emp-heading f-13 mb-0 view-title f-600">
                                {item.jobTitle}
                              </h6>
                              <h6 className="emp-heading my-0 f-12 mt-1">
                                {moment(item?.startDate).format("MMM YYYY")} - {item?.isPresent ? 'Present' : moment(item?.endDate).format("MMM YYYY")}
                              </h6>
                            </div>
                            {/* <div className="d-flex align-items-baseline"> */}
                            <p className="emp-details mb-0 f-12">{item.companyName}</p>
                            <h6 className="emp-heading my-0 f-12">
                              ({item.cityName})
                            </h6>
                            <h6 className="emp-heading my-0 f-12 mt-1">
                              {item.description}
                            </h6>
                            {/* </div> */}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {ccvAward.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="resumeHeading mt-3">
                      <p>AWARDS</p>
                    </div>
                    <ul className="mb-4 ps-2">
                      {ccvAward.map((item) => {
                        return (
                          <li className="listIcon mt-3 ms-3">
                            <div className="d-flex justify-content-between">
                              <h6 className="emp-heading f-13 mb-0 view-title f-600">
                                {item.name}
                              </h6>
                              <h6 className="emp-heading my-0 f-12 mt-1">
                                {moment(item?.issueDate).format("MMM YYYY")}
                              </h6>
                            </div>
                            {/* <div className="d-flex align-items-baseline"> */}
                            <p className="emp-details mb-0 f-12">{item.orgName}</p>
                            <h6 className="emp-heading my-0 f-12">
                              ({item.cityName})
                            </h6>
                            <h6 className="emp-heading my-0 f-12 mt-1">
                              {item.description}
                            </h6>
                            {/* </div> */}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ViewResume;

